import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import { Grid, FormControl } from "@material-ui/core";

import { toast } from "react-toastify";

import { COUNTRY_CODE } from "constants/generalConstants";

import {
  registerWithOtpAction,
  emailRegisterAction
} from "services/endpoints/usermanagement/userManagementActions";
import Landing from "./Landing";
import VerifyMobile from "./VerifyMobile";
import Details from "./Details";

const initialState = {
  status: "request_verification_code",
  registrationMode: "mobile_register", //social_register ,email_register
  mobile: "",
  email: "",
  uid: "",
  type:"mobile"
};

const RegisterForm = () => {
  const formRef = useRef(null);
  const [registrationData, setRegistrationData] = useState(initialState);
  const [isFinalSubmit, setIsFinalSubmit] = useState(false);
  const [finalSubmitStatus, setFinalSubmitStatus] = useState(false);
  const { loading, data: registerWithOtp, error } = useSelector(
    state => state.userManagement.registerWithOtp
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const { page } = useParams();

  useEffect(() => {
    if (registerWithOtp) {
      if (registerWithOtp.message) {
        toast.success(registerWithOtp.message);
      } else if (registerWithOtp.Error || registerWithOtp.error) {
        toast.error(registerWithOtp.Error || registerWithOtp.error);
        if (isFinalSubmit) {
          setIsFinalSubmit(false);
          setFinalSubmitStatus("failed");
        }
      }

      if (registerWithOtp.status) {
        setRegistrationData({
          ...registrationData,
          status: registerWithOtp.status
        });
        const nextStep =
          registerWithOtp.status === "request_verification_code"
            ? "landing"
            : registerWithOtp.status === "verification_code_sent"
              ? "verify"
              : "details";
        navigateToRoute(nextStep);
      }

      if (registrationData.registrationMode === 'email_register') {
        if (registerWithOtp.basic_token) {
          setRegistrationData({
            ...registrationData,
            basicToken: registerWithOtp.basic_token,
            uid: registerWithOtp.uid
          });
          setFinalSubmitStatus("success");
        }
      } else {
        if (registerWithOtp.uid) {
          setRegistrationData({
            ...registrationData,
            uid: registerWithOtp.uid,
            status: "verified"
          });
          navigateToRoute("details");
        }
  
        if (registerWithOtp.basic_token) {
          setRegistrationData({
            ...registrationData,
            basicToken: registerWithOtp.basic_token
          });
          setFinalSubmitStatus("success");
        }
      }

    
    }
  }, [registerWithOtp]);

  useEffect(() => {
    if ((page === "verify" || page === "details")
      && registrationData.registrationMode === "mobile_register"
      && !registrationData.mobile) {
      history.replace('landing');
    }else if (page === "email") {
      setRegistrationData({
        ...registrationData,
        registrationMode: "email_register",
        type:"email"
      });
    }
  }, [page])
  
  useEffect(() => {
    let userDetails = localStorage.getItem("social_register_from_login");
    if (userDetails) {
      onSocialLoginSuccess(JSON.parse(userDetails));
      localStorage.removeItem("social_register_from_login");
    }
  },[])

  const navigateToRoute = path => {
    history.push(`/jobseekers/register/${path}`);
  };

  const onSocialLoginSuccess = socialLogin => {
    setRegistrationData({
      ...registrationData,
      registrationMode: "social_register",
      status: "verified",
      uid: socialLogin.uid,
      fname: socialLogin.name,
      email: socialLogin.email,
      type: socialLogin.type
    });
    navigateToRoute("details");
  };

  const onRegisterWithEmail = () => {
    navigateToRoute("email");
  };

  const handleFormSubmit = values => {
    if (registrationData.status === "verification_code_sent") {
      setRegistrationData({
        ...registrationData,
        verificationCode: values.verificationCode
      });
      dispatch(
        registerWithOtpAction({
          action: "verify_code",
          mobile: COUNTRY_CODE + values.mobile,
          verificationCode: values.verificationCode
        })
      );
    } else if (
      registrationData.status === "verified" ||
      registrationData.registrationMode === "email_register"
    ) {
      setRegistrationData({
        ...registrationData,
        ...values
      });
      const request = {
        action: registrationData.registrationMode,
        type:registrationData.type,
        email: values.email,
        name: values.fname,
        password: values.password
      };
      if (registrationData.registrationMode !== "email_register") {
        request.mobile = COUNTRY_CODE + values.mobile;
        request.uid = registrationData.uid;
        request.verificationCode = values.verificationCode;
      }
      dispatch(emailRegisterAction(request));
      setIsFinalSubmit(true);
    } else {
      setRegistrationData({
        ...registrationData,
        mobile: values.mobile
      });
      dispatch(
        registerWithOtpAction({
          action: "request_verification_code",
          mobile: COUNTRY_CODE + values.mobile
        })
      );
    }
  };

  const onBackButton = () => {
    setRegistrationData({
      ...registrationData,
      status: "request_verification_code",
      registrationMode: "mobile_register",
    });
    navigateToRoute("landing");
  }

  return (
    <>
      <Grid className="message" xs={12}>
        You are just a step away from your Dream job
      </Grid>

      <Grid className="register-form" xs={12}>
        <FormControl fullWidth>
          {!page || page === "landing" ?
             <Landing
             onSubmitForm={handleFormSubmit}
             onSocialLoginSuccess={onSocialLoginSuccess}
             registerWithEmail={onRegisterWithEmail}
             registerData={registrationData}
             loading={loading}
            /> : 
            page === "verify" ?
            <VerifyMobile
            onSubmitForm={handleFormSubmit}
            registerData={registrationData}
            loading={loading}
              /> :
              page === "details" || page === "email" ?
              <Details
              onSubmitForm={handleFormSubmit}
              onBackButton={onBackButton}
              registerData={registrationData}
              loading={loading}
              finalSubmitStatus={finalSubmitStatus}
            />:""
          }
        </FormControl>
      </Grid>
    </>
  );
};

export default RegisterForm;