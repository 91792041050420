export const SAVE_FUEL_SAVINGS = "SAVE_FUEL_SAVINGS";
export const CALCULATE_FUEL_SAVINGS = "CALCULATE_FUEL_SAVINGS";

//App Reducer
export const CHANGE_USER_TYPE = "CHANGE_USER_TYPE";
export const SHOW_SNACK_BAR = "SHOW_SNACK_BAR";

//APIs
export const AUTHENTICATE = "AUTHENTICATE";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_JOB_INFO = "GET_JOB_INFO";
export const REGISTER = "REGISTER";
export const XCRF_TOKEN = "XCRF_TOKEN";
export const VERIFY_OTP = "VERIFY_OTP";
export const GENERATE_OTP = "GENERATE_OTP";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SOCIAL_LOGIN = "SOCIAL_LOGIN";
export const SOCIAL_LOGIN_GOOGLE = "SOCIAL_LOGIN_GOOGLE";

//OTP based register/login
export const REGISTER_WITH_OTP = "REGISTER_WITH_OTP";
export const EDIT_MOBILE_NUMBER = "EDIT_MOBILE_NUMBER";
export const EMAIL_REGISTER = "EMAIL_REGISTER";
export const UPADTE_RESUME_REF = "UPADTE_RESUME_REF";
export const REGISTRATION_COMPLETE = "REGISTRATION_COMPLETE";
export const LINKEDIN_TOKEN = "LINKEDIN_TOKEN";
export const LOGIN_WITH_SOCIAL = "LOGIN_WITH_SOCIAL";
export const LOGIN_STATITICS = "LOGIN_STATITICS";





//Jobs
export const GET_JOB_LIST = "GET_JOB_LIST";
export const GET_JOB_APPLIED = "GET_JOB_APPLIED";
export const GET_JOB_DETAIL = "GET_JOB_DETAIL";
export const GET_SKILLS = "GET_SKILLS";
export const GET_INDUSTRIES = "GET_INDUSTRIES";
export const UPDATE_BASIC_INFO = "UPDATE_BASIC_INFO";
export const GET_CANDIDATE_DETAILS = "GET_CANDIDATE_DETAILS";
export const SEARCH = "SEARCH";
export const FLAG_JOB = "FLAG_JOB";

//Employer

export const EMPLOYER_DETAILS = "EMPLOYER_DETAILS";
export const EMPLOYER_PROFILE_EDIT="EMPLOYER_PROFILE_EDIT";
export const EMPLOYER_JOB_ACTIVATE = "EMPLOYER_JOB_ACTIVATE";
export const EMPLOYER_CANDIDATE_DETAILS = "EMPLOYER_CANDIDATE_DETAILS";
export const EMPLOYER_JOB_COUNT = "EMPLOYER_JOB_COUNT";
