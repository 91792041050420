import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloudUpload } from "@material-ui/icons";
import { Button, Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { updateResumeRefAction} from "services/endpoints/usermanagement/userManagementActions";
import { UserManagementService } from "services/endpoints/usermanagement/UserManagementService";

const maxSize = 2 * 1024 * 1024;

const UploadResume = (props) => {
  const fileInputEl = useRef(null);
  const [file, setFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const { loading, data, error } = useSelector(state => state.userManagement.uploadResume);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      props.onUpdateUploadStatus('fid_updated', { "message": data.message });
      setIsProcessing(false);
    }
  }, [data]);

  const openUploadDialog = () => {
    fileInputEl.current.click();
  }

  const handleFileSelect = (event) => {
    if (fileInputEl.current.files[0].size < maxSize) {
      setFile(fileInputEl.current.files[0]);
      
    } else {
      toast.error("File size should not exceed 2 Mb");
    }
  }


  const uploadFile = () => {
    setIsProcessing(true);
    const reader = new FileReader();
    reader.onload = () => {
      const binaryStr = file;
      UserManagementService.uploadResume(binaryStr,props.authorizationTocken, progressEvent => {
        const percent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        props.onUpdateUploadStatus('inprogress',{"percentage":percent});
        if (percent == 100) {
          toast.success("Resume uploaded successfully.");
        }
      }).then(result => {
        const fid = result && result.fid && result.fid[0].value;
        props.onUpdateUploadStatus('complete', { "fid": fid });
        updateFid(fid);
      });
    };
    reader.readAsBinaryString(file);
  }

  const updateFid = (fid) => {
    dispatch(updateResumeRefAction({"uid":props.uid,"fid":fid},props.authorizationTocken));
  }

  const enterManually = () => {
    props.onUpdateUploadStatus('enter_manually');
  }

  if (props.startUpload && !isProcessing) {
    if (props.authorizationTocken && file) {
      uploadFile();
    } else {
      props.onUpdateUploadStatus('no_resume');
    }
  }

  const fileName = file && (file.name.length > 15 ? file.name.substr(0, 13) + "..." : file.name);
  let btnText = fileName || "Choose Resume" ;

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <label>Upload Resume (optional)</label>
        <Button
          variant="outlined"
          size="large"
          startIcon={<CloudUpload />}
          onClick={openUploadDialog}
          style={{ textTransform: "none", color: "#6f6f6f" }}
        >
          {btnText}
        </Button>
        <label>.pdf, .wrd format / 2mb limit</label>

        <input
          type="file"
                  ref={fileInputEl}
                  name="photo"
                  style={{ display: "none" }}
          onChange={handleFileSelect}
          accept=".doc,.docx,.pdf"
                />
      </Grid>
          <Grid item xs={6}>
            <div className="resume-manual-label">
            Or <span onClick={enterManually}>Manually Fill Profile</span>
            </div>
      </Grid>
    </Grid>
  );
};

export default UploadResume;
