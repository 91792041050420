import { AUTHENTICATE, GET_CATEGORIES, GET_JOB_INFO, XCRF_TOKEN } from "constants/actionTypes";
import { CommonService } from "./CommonService";

export const getCategoriesAction = () => {
  return {
    actionType: GET_CATEGORIES,
    callAPI: () => CommonService.getCategories(),
    stateObject: "categories"
  };
};

export const getJobInfoAction = () => {
  return {
    actionType: GET_JOB_INFO,
    callAPI: () => CommonService.getJobInfo(),
    stateObject: "jobInfo"
  };
};

export const getXcsrfTokenAction = () => {
  return {
    actionType: XCRF_TOKEN,
    callAPI: () => CommonService.getXCSRFToken(),
    stateObject: "xcsrf"
  };
};
