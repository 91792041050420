import React, { Component } from "react";
import { MDBContainer, MDBCol, MDBRow, MDBCard, MDBCardBody, MDBBtn, MDBNav, MDBNavItem, MDBNavLink, MDBTabPane,MDBIcon,
MDBTabContent, MDBSelect, MDBSelectInput, MDBSelectOption, MDBSelectOptions } from "mdbreact";
import { useHistory ,Redirect} from "react-router-dom";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import TopNavigation from 'components/pages/employer/header/topNavigation';
import SideNavigation from 'components/pages/employer/header/sideNavigation';
import Footer from 'components/pages/employer/header/Footer';
import StorageManager from "utils/StorageManager";
import AlgoliaPlaces from 'algolia-places-react';
import StripeCheckout from "react-stripe-checkout";
import { PayPalButton } from "react-paypal-button-v2";
import { toast } from "react-toastify";
import axios from 'axios';

class eCommercePage extends Component {   
state={
  activePill: "1",
  users: "",
  fname:"",
  lname:"",
  email:"",
  zip:"",
  addresses:"",
  address1:"",
  address2:"",
country:"",
state:"",
city:"",
  activeItemJustified: "1"
}
submitHandler = event => {
  event.preventDefault();
  event.target.className += " was-validated";
};


togglePills = tab => {
  if (this.state.activePill !== tab) {
    this.setState({
      ctivePill: tab
    });
  }
}
toggleJustified = tab => e => {
  if (this.state.activeItemJustified !== tab) {
    this.setState({
      activeItemJustified: tab
    });
  }
};
componentDidMount() {
  const token = StorageManager.instance.getAccessToken();
  const csrf = StorageManager.instance.getXCSRFToken();
  const uid = StorageManager.instance.getUid();
/*console.log("jwt",token);
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'bearer '+token,
    'X-CSRF-Token':csrf
  }  
axios.get('https://live-corp2corp.pantheonsite.io/api/'+uid+'/employer?_format=json',{headers})
  .then(res => {
    const items = res.data;
    console.log("Hanish",items);
    this.setState({fname:res.data[0].field_first_name});
    this.setState({lname:res.data[0].field_last_name});
    this.setState({location:res.data[0].field_location});
    this.setState({email:res.data[0].mail});
    const users = res.data;
    this.setState({ users });
    //this.setState({ items });
    console.log("users",this.state.fname);
  })*/
}

selectNextTab = event => {
  event.preventDefault();
  event.target.className += " was-validated";
  const fname =  this.state.fname;
  const lname =  this.state.lname;
  const email =  this.state.email;
  const address1 =  this.state.address1;
  const address2 =  this.state.address2;
  const city =  this.state.city;
 if(fname && lname && email && address1){

  this.setState({
    activePill: (+this.state.activePill + 1).toString()
  });
}else{
  toast.error("Please add all required fields!", {
    position: toast.POSITION.TOP_RIGHT
  })
  return;
}
}
handleToken = (token) => {
  console.log(token.id);
          const jwt = StorageManager.instance.getAccessToken();
          const csrf = StorageManager.instance.getXCSRFToken();
          const uid = StorageManager.instance.getUid();
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'bearer '+jwt,
            'X-CSRF-Token':csrf
          }
          
          const data = {
            "uid": uid,
            "token": token.id
          };
          
          console.log(data);
          axios.post(`https://live-corp2corp.pantheonsite.io/services/stripe`, data,{
            headers: headers
          })
          .then(response => { 
           
            toast.success("Successfully Submitted !", {
              position: toast.POSITION.TOP_RIGHT
            })
            localStorage.setItem('Project_id', "");
            window.location="/employer-dashboard";
          })
}
changeHandler = event => {
  this.setState({ [event.target.name]: event.target.value });
  console.log(event.target);
};

render() {
  const { activePill,value } = this.state;
  const contentId = this.props.match.params.id;
  console.log("hmm",contentId);
  let plan;
  let amount;
  let total;
  plan = "Pro";
  amount = "999";
  total ="999";
return (
    <div className="flexible-content">
    
 
    <MDBContainer>
      <MDBRow className="my-2" center>
        <MDBCard className="w-100">
          <MDBCardBody>
            <MDBRow>
              <MDBCol lg="8" className="mb-4">
                <MDBNav pills color="primary" className="nav-justified">
                  <MDBNavItem>
                    <MDBNavLink to="#" className={activePill==="1" ? "active" : "" } onClick={()=> this.togglePills("1")} >
                      <strong>1. Billing</strong>
                    </MDBNavLink>
                  </MDBNavItem>
                 
                  <MDBNavItem>
                    <MDBNavLink to="#" className={activePill==="3" ? "active" : "" } onClick={()=> this.togglePills("3")}>
                      <strong>2. Payment</strong>
                    </MDBNavLink>
                  </MDBNavItem>
                </MDBNav>
                <MDBTabContent className="pt-4" activeItem={activePill}>
                  <MDBTabPane tabId="1">
                      
                  <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterNameEx"
                className="grey-text"
              >
                First name
              </label>
              <input
                value={this.state.fname}
                name="fname"
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterNameEx"
                className="form-control"
                placeholder="First name"
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterEmailEx2"
                className="grey-text"
              >
                Last name
              </label>
              <input
                value={this.state.lname}
                name="lname"
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterEmailEx2"
                className="form-control"
                placeholder="Last name"
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="grey-text"
              >
                Email
              </label>
              <input
                value={this.state.email}
                onChange={this.changeHandler}
                type="email"
                id="defaultFormRegisterConfirmEx3"
                className="form-control"
                name="email"
                placeholder="Your Email address"
              />
              <small id="emailHelp" className="form-text text-muted">
                We'll never share your email with anyone else.
              </small>
            </MDBCol>
          </MDBRow>
          <MDBRow>
          <MDBCol className="mb-3">
          <label htmlFor="address">Address</label>
                          <input type="text" id="address" className="form-control mb-4" placeholder="1234 Main St" name="address1" onChange={this.changeHandler} value={this.state.address1} />
                          <label htmlFor="address-2">Address 2 (optional)</label>
                          <input type="text" id="address-2" className="form-control mb-4" placeholder="Apartment or suite" name="address2" value={this.state.address2} onChange={this.changeHandler} />

            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                City
              </label>
              <input
                value={this.state.city}
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterPasswordEx4"
                className="form-control"
                name="city"
                placeholder="City"
                required
              />
              <div className="invalid-feedback">
                Please provide a valid city.
              </div>
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                State
              </label>
              <input
                value={this.state.state}
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterPasswordEx4"
                className="form-control"
                name="state"
                placeholder="State"
                required
              />
              <div className="invalid-feedback">
                Please provide a valid state.
              </div>
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                Zip
              </label>
              <input
                value={this.state.zip}
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterPasswordEx4"
                className="form-control"
                name="zip"
                placeholder="Zip"
                required
              />
              <div className="invalid-feedback">
                Please provide a valid zip.
              </div>
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
          </MDBRow>
          <MDBCol md="4" className="mb-3">
            <div className="custom-control custom-checkbox pl-3">
              <input
                className="custom-control-input"
                type="checkbox"
                value=""
                id="invalidCheck"
                required
              />
              <label className="custom-control-label" htmlFor="invalidCheck">
                Agree to terms and conditions
              </label>
              <div className="invalid-feedback">
                You must agree before submitting.
              </div>
            </div>
          </MDBCol>
          
          <MDBBtn color="primary" type="submit" size="lg" block onClick={this.selectNextTab}>Next step</MDBBtn>
               
        </form>
                       </MDBTabPane>
                 
                  <MDBTabPane tabId="2">
                    <div className="d-block my-3">
                      <div className="mb-2">
                 
        
                      <MDBNav tabs className="nav-justified" color='indigo'>
<MDBNavItem>
  <MDBNavLink link to="#" active={this.state.activeItemJustified === "1"} onClick={this.toggleJustified("1")} role="tab" >
    Paypal
  </MDBNavLink>
</MDBNavItem>
<MDBNavItem>
  <MDBNavLink link to="#" active={this.state.activeItemJustified === "2"} onClick={this.toggleJustified("2")} role="tab" >
    Stripe
  </MDBNavLink>
</MDBNavItem>

</MDBNav>
<MDBTabContent
className="card"
activeItem={this.state.activeItemJustified}
>
<MDBTabPane tabId="1" role="tabpanel">
<PayPalButton
        amount="0.1"
        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
        onSuccess={(details, data) => {
          console.log(details);
          console.log("data",data);
          //alert("Transaction completed by " + details.payer.name.given_name);
          const token = StorageManager.instance.getAccessToken();
          const csrf = StorageManager.instance.getXCSRFToken();
          const uid = StorageManager.instance.getUid();
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'bearer '+token,
            'X-CSRF-Token':csrf
          }
           const id = details.id;
          const datadetail = {
            "uid": uid,
            "payment_id": id
      
          };
          
          console.log(data);
          axios.post(`https://live-corp2corp.pantheonsite.io/services/payments`, datadetail,{
            headers: headers
          })
          .then(response => { 
            console.log('succ',response.data.uid)
            toast.success("Successfully Paid !", {
              position: toast.POSITION.TOP_RIGHT
            })
            //localStorage.setItem('payment_id', "");
            //return <Redirect to="/employer-dashboard" />
            //const history = useHistory();
            //history.push("/employer-dashboard");
            localStorage.setItem('Project_id', "");
            window.location="/employer-dashboard";
         
            
          })
          .then(res => {
            localStorage.setItem('Project_id', "");
           
          })
          
          // OPTIONAL: Call your server to save the transaction
          
        }}
        options={{
          clientId: "AWn4etTSMB_32Q3pr6CG1hyaa2JRoRhvapVRVM13u9vkiPUlXJM2WOfTXMAwi4FE1bRof7HMTxphwNQE"
        }}
      />
</MDBTabPane>
<MDBTabPane tabId="2" role="tabpanel">
<StripeCheckout
                 stripeKey="pk_live_0Q4sP8Db9vBFQrtEMADomYMu00IGOSbcGz"
                 token={this.handleToken}
                 amount="99900"
                 name="Corp2Corps.Jobs"
                 //billingAddress
                 //shippingAddress
               />
</MDBTabPane>

</MDBTabContent>

          
                     
                      </div>
                      {/*<div className="mb-2">
                        <input iname="group2" type="radio" className="form-check-input with-gap" id="radioWithGap5"
                          required />
                        <label className="form-check-label" htmlFor="radioWithGap5">Debit card</label>
                      </div>*/}
                      <div className="mb-2">
                          
                      </div>
                    </div>
                  </MDBTabPane>
                </MDBTabContent>
              </MDBCol>
              <MDBCol lg="4" className="mb-4">
                <MDBBtn color="primary" size="lg" block>
                  Place order
                </MDBBtn>
                <MDBCard>
                  <MDBCardBody>
                    <h4 className="mb-4 mt-1 h5 text-center font-weight-bold">Summary</h4>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="8">
                        {plan}
                      </MDBCol>
                      <MDBCol sm="4">
                        $ {amount}
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="8">
                        <strong>Total</strong>
                      </MDBCol>
                      <MDBCol sm="4">
                        <strong>$ {total}</strong>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBRow>
    </MDBContainer>
   
  </div> 
    );
  }
}

export default eCommercePage;