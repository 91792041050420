import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import OtpInput from "react-otp-input";
import { Grid, Box, MenuItem } from "@material-ui/core";
import { TextInput } from "components/common/FormInputs";
const RolesPopup = ({ open, onSubmit }) => {
  const [role, setRole] = useState("candidate");
  const roles = [
    { name: "Candidate", value: "candidate" },
    { name: "Employer", value: "employer" },
    { name: "Recruiter", value: "recruiter" }
  ];
  return (
    <Dialog open={open}>
      <DialogTitle>Choose Role</DialogTitle>
      <DialogContent>
        <DialogContentText>Please choose your user role</DialogContentText>
      </DialogContent>
      <Box marginX={4} marginBottom={4}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <TextInput
              fullWidth
              select
              placeholder="Roles"
              variant="outlined"
              value={role}
              onChange={e => {
                setRole(e.target.value);
              }}
              size="small"
            >
              {roles.map(item => {
                return <MenuItem value={item.value}>{item.name}</MenuItem>;
              })}
            </TextInput>
          </Grid>
        </Grid>
      </Box>
      <DialogActions>
        {/* <Button onClick={handleClose}>Cancel</Button> */}
        <Button
          onClick={() => {
            onSubmit && onSubmit(role);
          }}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RolesPopup;
