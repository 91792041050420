import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import "./MyHero.scss";
import HeroStats from "./HeroStats";
import SearchForm from "../../common/searchForm/SearchForm";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import assets from "../../../assets";
import { getJobInfoAction } from "services/endpoints/common/commonActions";
import lodash from "lodash";
import { Button } from "@material-ui/core";
import { StyledLink } from "components/common/Misc";
import { useHistory } from "react-router-dom";
import UserManager from "utils/UserManager";
const useStyles = makeStyles(theme => ({
  heroContainer: {
    position: "relative"
    // backgroundColor: 'blue',
    // backgroundSize: 'contain',
    // backgroundRepeat: 'no-repeat',
    // backgroundPositionX: '100%'
  },
  heroSlice: {
    // border: '1px solid black',
    textAlign: "center"
  },
  statsSlice: {
    paddingLeft: "40px",
    paddingRight: "40px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      paddingRight: "0px"
    },
    [theme.breakpoints.down("xs")]: {
      order: "4",
      paddingLeft: "35px",
      paddingRight: "35px"
    }
  },
  backgroundContainer: {
    zIndex: "-1",
    position: "absolute",
    top: "0px",
    width: "100%",
    display: "flex",
    flexWrap: "wrap"
  },
  background1: {
    width: "33.333%",
    [theme.breakpoints.down("sm")]: {
      width: "25%",
      backgroundImage:"none"
    },
    backgroundImage: "url(" + assets.images.heroBg1 + ")",
    backgroundSize: "cover",
    backgroundPositionX: "left",
    [theme.breakpoints.down("xs")]: {
      order: "2",
      width: "100%",
      backgroundSize: "100% auto",
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "calc(100% - 0vw)"
    }
  },
  background2: {
    width: "66.666%",
    [theme.breakpoints.down("sm")]: {
      width: "75%",
      minHeight:"600px"
    },
    backgroundImage: "url(" + assets.images.heroBg2 + ")",
    backgroundSize: "cover",
    backgroundPositionX: "right",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  searchForm: {
    paddingTop: "2vw",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "2vw",
    textAlign: "left"
  },
  searchHeader: {
    textAlign: "left",
    fontWeight: "Regular",
    fontFamily: "'Poppins', sans-serif",
    letterSpacing: "0",
    color: "#FFFFFF",
    fontSize: "35px",
    marginBottom: "25px",
    lineHeight: "normal",
    [theme.breakpoints.down("md")]: {
      fontSize: "27px"
    }
  },
  uploadCVButton: {
    marginTop: "25px",
    color: "white",
    borderColor: "white",
    borderRadius: "20px",
    textTransform: "capitalize",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "12px",
    padding: "5px 25px"
  },
  postJobAnchor: {
    color: "white",
    textDecoration: "underline",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "12px",
    display: "table-cell",
    verticalAlign: "bottom",
    display: "inline",
    marginLeft: "20px"
  }
}));

const MyHero = () => {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  const dispatch = useDispatch();
  const { loading, data, error } = useSelector(state => state.common.jobInfo);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  });

  useEffect(() => {
    dispatch(getJobInfoAction());
  }, []);

  const classes = useStyles();
  const history = useHistory();
  return (
  <div className={classes.heroContainer} ref={ref}>
      <Grid container spacing={0} justify="center" alignItems="center">
        <Grid item sm={1} md={2} className={classes.heroSlice}></Grid>
        <Grid item xs={12} sm={4} className={classes.heroSlice + " " + classes.statsSlice}>
          <HeroStats
            jobs={lodash.get(data, "total_jobs", 0)}
            interns={lodash.get(data, "internship", 0)}
            newjobs={lodash.get(data, "new_jobs", 0)}
            comps={lodash.get(data, "total_companies", 0)}
          />
        </Grid>
        <Grid item xs={11} sm={6} md={4} className={classes.heroSlice}>
          <div className={classes.searchForm}>
            <p className={classes.searchHeader}>
              One Search,
              <br />
              Million Opportunities
            </p>
            <Grid container spacing={1}>
              <SearchForm />
            </Grid>
            {!UserManager.isLoggedIn() && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    className={classes.uploadCVButton}
                    onClick={() => {
                      history.push("/jobseekers/register");
                    }}
                  >
                    Upload CV
                  </Button>
                  <StyledLink className={classes.postJobAnchor} to="/employer/register">
                    Post a Job for Free
                  </StyledLink>
                </Grid>
              </Grid>
            )}
          </div>
        </Grid>
        <Grid item sm={1} md={2} className={classes.heroSlice}></Grid>
      </Grid>
      <div className={classes.backgroundContainer} style={{ height: height + "px" }}>
        <div className={classes.background1}></div>
        <div className={classes.background2}></div>
      </div>
    </div>
  );
};

export default MyHero;
