import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainContainer from "../../common/MainContainer";
import FilterResults from "./filterresults/FilterResults";
import DoneTwoToneIcon from '@material-ui/icons/DoneTwoTone';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';

import FavoriteIcon from '@material-ui/icons/Favorite';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ArrowRightTwoToneIcon from '@material-ui/icons/ArrowRightTwoTone';

import { Typography, Grid,Hidden, Paper, Divider, Box } from "@material-ui/core";
import JobApplied from "./joblists/JobApplied";
import { getJobAppliedAction, searchActionAction, getJobDetailAction } from "services/endpoints/jobs/jobActions";
import { JobListHolder } from "./JobListingPage.styled";
import List from "@material-ui/core/List";
import JobDetail from "./joblists/JobDetail";
import lodash from "lodash";
import Skeleton from "@material-ui/lab/Skeleton";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { MDBAlert } from "mdbreact";
import StorageManager from "utils/StorageManager";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const JobListingPage = () => {
  const [item, setItem] = useState({});
  const [filter, setFilter] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const { loading, data, error } = useSelector(state => state.job.jobApplied);

  const { location: jobLocation, title } = queryString.parse(location.search);
  console.log("location", jobLocation);
  const { loading: jobDetailLoading, data: jobDetailData } = useSelector(state => state.job.jobDetail);
  const getJobDetail = id => {
    dispatch(getJobDetailAction(id, StorageManager.instance.getUid()));
  };
  const getJobApplied = () => {
    dispatch(getJobAppliedAction(StorageManager.instance.getUid()));
  };

  const searchJobList = (location, title, categories, skills, job_type) => {
    dispatch(searchActionAction(title, location, categories, skills, job_type));
  };

  useEffect(() => {
    searchJobList(jobLocation, title, filter.categories, filter.skills, filter.job_type);
  }, [filter]);

  useEffect(() => {
    if (jobLocation || title) {
      searchJobList(jobLocation, title);
    } else {
      getJobApplied();
    }
  }, [jobLocation, title]);

  const theme = useTheme();
  const matchesXsDown = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    if (lodash.isArray(data) && data.length > 0) {
      if (!matchesXsDown) {
        setItem(data[0]);
        onItemSelect(data[0]);
      }
    } else if (loading) {
      setItem(null);
    }
  }, [data, error, loading]);

  const history = useHistory();
  const onItemSelect = item => {
    setItem(item);
    if (matchesXsDown) {
      history.push(`/job/${item.job_id}`);
    } else {
      getJobDetail(item.job_id);
    }
  };

  return (
    <MainContainer style={{ background: "#f4f4f4" }}>
     

      <JobListHolder>
        <Grid container spacing={1}>
          {(data && data.length > 0) || loading ? (
            <React.Fragment>
               <Grid item sm={2} xs={12}>
            
               <Paper style={{  marginBottom: "10px" }}  fullWidth>
                <Grid style={{ marginLeft: "4px" }} fullWidth onClick={event =>  history.push('/saved-jobs/')}>
                <Box p={3}>
                <Grid>
                <Divider orientation="vertical" />
                </Grid>
                <Typography style={{color: "#747474"}}><FavoriteIcon style={{ marginRight: "4px",color: "#747474" }}/>Saved Job</Typography>
                </Box>
                </Grid>
            </Paper>
                <Paper style={{  marginBottom: "10px",background:"#13C1D6" }} fullWidth  className="mpa">
                <Grid style={{ marginBottom: "10px" }} fullWidth >
                <Box p={3}>
                <Grid>
                <Divider orientation="vertical" />
                </Grid>

                <Typography style={{color:"white", verticalAlign: 'middle',
                display: 'inline-flex'}}><DoneTwoToneIcon style={{ marginRight: "8px" }}/>Applied Job</Typography>
                </Box>
                </Grid>
            </Paper>

    <Paper style={{  marginBottom: "10px"}} fullWidth onClick={event =>  history.push('/resume-edit/1')}>
                <Grid style={{ marginLeft: "4px" }} fullWidth>
                <Box p={3}>
                <Grid>
                <Divider orientation="vertical" />
                </Grid>
                <Typography style={{color: "#747474"}}><InsertDriveFileIcon style={{ marginRight: "4px",color: "#747474"
                }} />My Resume</Typography> 
                </Box>
                </Grid>

    </Paper>


         <Paper style={{  marginBottom: "10px" }} fullWidth  >
                <Grid style={{ marginBottom: "10px" }} fullWidth onClick={event =>  history.push('/onboarding/1')}>
                <Box p={3}>
                <Grid>
                <Divider orientation="vertical" />
                </Grid>

                <Typography style={{color:"#747474", verticalAlign: 'middle',
                display: 'inline-flex'}}><SettingsTwoToneIcon style={{ marginRight: "8px" }}/>My Settings</Typography>
                </Box>
                </Grid>
            </Paper>
            <Paper style={{  marginBottom: "10px" }} fullWidth  >
                <Grid style={{ marginBottom: "10px" }} fullWidth onClick={event =>  history.push('/jobs/')}>
                <Box p={3}>
                <Grid>
                <Divider orientation="vertical" />
                </Grid>

                <Typography style={{color:"#747474", verticalAlign: 'middle',
                display: 'inline-flex'}}><SearchTwoToneIcon style={{ marginRight: "8px" }}/>Search Jobs</Typography>
                </Box>
                </Grid>
               
            </Paper>
              </Grid>
              <Grid item sm={3} xs={12}>
                {loading ? (
                  <Skeleton width="100%" height={200} />
                ) : (
                  data && data.length > 0 && <JobApplied list={data} onItemSelect={onItemSelect} selected={item} />
                )}
              </Grid>
              <Hidden xsDown>
                <Grid item sm={7}>
                  {jobDetailLoading || loading ? (
                    <Skeleton width="100%" height={200} />
                  ) : (
                    jobDetailData && <JobDetail item={jobDetailData} fullWidth />
                  )}
                </Grid>
              </Hidden>
            </React.Fragment>
          ) : (
            <React.Fragment>
               <Grid item sm={2} xs={12}>
            
            <Paper style={{  marginBottom: "10px" }}  fullWidth>
             <Grid style={{ marginLeft: "4px" }} fullWidth onClick={event =>  history.push('/saved-jobs/')}>
             <Box p={3}>
             <Grid>
             <Divider orientation="vertical" />
             </Grid>
             <Typography style={{color: "#747474"}}><FavoriteIcon style={{ marginRight: "4px",color: "#747474" }}/>Saved Job</Typography>
             </Box>
             </Grid>
         </Paper>
             <Paper style={{  marginBottom: "10px",background:"#13C1D6" }} fullWidth  className="mpa">
             <Grid style={{ marginBottom: "10px" }} fullWidth >
             <Box p={3}>
             <Grid>
             <Divider orientation="vertical" />
             </Grid>

             <Typography style={{color:"white", verticalAlign: 'middle',
             display: 'inline-flex'}}><DoneTwoToneIcon style={{ marginRight: "8px" }}/>Applied Job</Typography>
             </Box>
             </Grid>
         </Paper>

 <Paper style={{  marginBottom: "10px"}} fullWidth onClick={event =>  history.push('/resume-edit/1')}>
             <Grid style={{ marginLeft: "4px" }} fullWidth>
             <Box p={3}>
             <Grid>
             <Divider orientation="vertical" />
             </Grid>
             <Typography style={{color: "#747474"}}><InsertDriveFileIcon style={{ marginRight: "4px",color: "#747474"
             }} />My Resume</Typography> 
             </Box>
             </Grid>

 </Paper>


      <Paper style={{  marginBottom: "10px" }} fullWidth  >
             <Grid style={{ marginBottom: "10px" }} fullWidth onClick={event =>  history.push('/onboarding/1')}>
             <Box p={3}>
             <Grid>
             <Divider orientation="vertical" />
             </Grid>

             <Typography style={{color:"#747474", verticalAlign: 'middle',
             display: 'inline-flex'}}><SettingsTwoToneIcon style={{ marginRight: "8px" }}/>My Settings</Typography>
             </Box>
             </Grid>
         </Paper>
         <Paper style={{  marginBottom: "10px" }} fullWidth  >
             <Grid style={{ marginBottom: "10px" }} fullWidth onClick={event =>  history.push('/jobs/')}>
             <Box p={3}>
             <Grid>
             <Divider orientation="vertical" />
             </Grid>

             <Typography style={{color:"#747474", verticalAlign: 'middle',
             display: 'inline-flex'}}><SearchTwoToneIcon style={{ marginRight: "8px" }}/>Search Jobs</Typography>
             </Box>
             </Grid>
            
         </Paper>
           </Grid>
    <Grid item sm={10} xs={12}>
              <MDBAlert color="warning">Not applied any jobs</MDBAlert>
            </Grid>
            </React.Fragment>
          )}
        </Grid>
      </JobListHolder>
    </MainContainer>
  );
};
export default JobListingPage;
