import React from "react";
import { styled } from "@material-ui/styles";
import { Box, Grid, Container, TextField, Paper } from "@material-ui/core";
import assets from "../../../assets";
import AppButton from "components/common/Buttons";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

export const Root = styled(({ ...other }) => <Grid {...other} />)({
  backgroundColor: "#F7F7F7"
});

export const CardContainer = styled(({ ...other }) => {
  return <Paper {...other} elevation={5} />;
})({
  background: "#fff",
  width: "100%",
  margin: "6vh 12vw",
  padding: "20px 0px"
});

export const HeaderGrid = styled(Grid)({
  margin: "0% 3%",
  display: "flex",
  justifyContent: "space-between"
});

export const ContentGrid = styled(Grid)({
  margin: "2% 10%"
});

export const FormGrid = styled(Grid)({});

export const SkillButton = styled(({ ...props }) => {
  return <AppButton {...props} startIcon={<AddCircleOutlineIcon />} />;
})(({ theme }) => {
  return {
    borderRadius: "10px",
    margin: "5px",
    color: `${theme.palette.primary.main}`,
    borderColor: `${theme.palette.primary.main}`
  };
});
