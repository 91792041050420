import { Grid } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CountUp from "react-countup";
import "./safari.css"

const useStyles = makeStyles(theme => ({
  statsContainer: {
    color: "white"
  },
  safari:{
   padding:"40px",
   paddingLeft:"11%"
  },

  statPara: {
    display: "table-cell",
    verticalAlign: "middle",
    textAlign:"justify"
  },
  statSpan1: {
    fontWeight: "700",
    fontSize: "2.2vw",
    fontFamily: "'Poppins', sans-serif",
    [theme.breakpoints.down("xs")]: {
      fontSize: "5vw"
    }
  },
  statSpan2: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "1.2vw",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.6vw"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.2vw"
    }
  },
  jobs: {
    backgroundColor: "#0F1120CD"
  },
  interns: {
    backgroundColor: "#1268FE"
  },
  newjobs: {
    backgroundColor: "#13C1D6"
  },
  comps: {
    backgroundColor: "#0E2677"
  }
}));

const HeroStats = ({ jobs, interns, newjobs, comps }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={0} className={classes.statsContainer}>
      <Grid item xs={6} className={classes.jobs + " " + classes.stat+ " " + classes.safari}>
        <p className={classes.statPara}>
          <span className={classes.statSpan1}>
            <CountUp end={2000} duration={2} /><span>+</span>
            <br />
          </span>
          <span className={classes.statSpan2}>New Jobs in 30 Days.</span>
        </p>
      </Grid>
      <Grid item xs={6} className={classes.interns + " " + classes.stat+ " " + classes.safari}>
        <p className={classes.statPara}>
          <span className={classes.statSpan1}>
            <CountUp end={81} duration={2} />%
            <br />
          </span>
          <span className={classes.statSpan2}>Pass-Through Rate</span>
        </p>
      </Grid>
      <Grid item xs={6} className={classes.newjobs + " " + classes.stat+ " " + classes.safari}>
        <p className={classes.statPara}>
          <span className={classes.statSpan1}>
            <CountUp end={14000} duration={2} /><span>+</span>
            <br />
          </span>
          <span className={classes.statSpan2}>Recruiters</span>
        </p>
      </Grid>
      <Grid item xs={6} className={classes.comps + " " + classes.stat+ " " + classes.safari}>
        <p className={classes.statPara}>
          <span className={classes.statSpan1}>
            <CountUp end={300} duration={2} />
            {comps > 0 ? "+" : ""}
            <br />
          </span>
          <span className={classes.statSpan2}>Companies</span>
        </p>
      </Grid>
    </Grid>
  );
};
export default HeroStats;
