import React from "react";
import { Grid, Box, Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const ThankYou = () => {
    return (
      <Grid className="thank-you">
        <h2 className="heading">Thank you</h2>
        <p className="message">Resume Uploaded Successfully</p>
        <Box className="actions">
          <Button variant="contained" color="primary">
           <NavLink to="/jobs">FIND JOBS</NavLink>
          </Button>
          <p> OR</p>
          <Button variant="outlined" color="primary">
          <NavLink to="/onboarding/1">UPDATE PROFILE</NavLink>
          </Button>
        </Box>
      </Grid>
    );
};
  
export default ThankYou;