import React from "react";
import { useSelector } from "react-redux";
import { Grid, Paper, Hidden } from "@material-ui/core";
import MainContainer from "./../../../../common/MainContainer";
import { Switch, NavLink, Route, useRouteMatch } from "react-router-dom";
import { MDBView, MDBAnimation,MDBRow,MDBCol } from "mdbreact";
//import assets from "../../../../assets";
//import { Image } from "../../login/LoginPage.styled";
import RegistrationEmployerPage from ".././RegistrationEmployerPage";
import LoginForm from "./LoginForm";
//import Login from "./login/Login";
//import ThankYou from "./register/ThankYou";
import "./Register.scss";
import "./register.css";

const EmployerLogin = () => {
  const registrationComplete  = useSelector(state => state.userManagement.registrationComplete);
  const match = useRouteMatch();
  const showThankyouPage = registrationComplete === true;

  return (
    <div className="register-with-otp">
      <MainContainer>
        <MDBView className="employer-main">
          <div className="layer">
          <MDBAnimation type="fadeInRight" delay=".3s">
            <Grid container>
              <Hidden xsDown>
                <Grid xs={5} md={5} className="main-content" >
                  <Grid xs={12} className="main-message white-text">
                    MAKING TALENT-HIRE UTMOST EFFICIENT
                  </Grid>
                  <Grid xs={12} className="register-image" style={{marginLeft:"1px"}}>
                    {/* <Grid xs={3}>
                  <img src={require('./images/a.svg')} />
                   </Grid>
                   <Grid xs={9}>
                  REGISTER <br/>
                  Register at Corp2Corp.jobs and get accessed to <br/>
                  recently registered prfiles.
                  </Grid>
                  </Grid> */}
              <MDBRow className="mb-3">
            <MDBCol size="1" >
                
                <img className="image" src={require('images/a.svg')} />
              </MDBCol>
              <MDBCol xl="10" md="11" size="10">
                <h5 className="white-text">REGISTER</h5>
                <p className="white-text r-p">
                Register at Corp2Corp.jobs and get accessed to recently registered profiles.
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-3">
            <MDBCol size="1">
            <img className="image" src={require('images/Group397.svg')} />
              
              </MDBCol>
              <MDBCol xl="10" md="11" size="10">
                <h5 className=" white-text">CREATE AND COMPLETE YOUR PROFILE</h5>
                <p className="white-text r-p">
                Fill your complete details and let the jobseekers know much about you for a quiker response. 
                </p>
              </MDBCol>
            </MDBRow>
            {/* <MDBRow className="mb-3">
            <MDBCol size="1">
           <p className="white-text dot">
             .<br/>
             .<br/>
             .<br/>
             .<br/>             
             </p>
              
              </MDBCol>
              </MDBRow> */}
            <MDBRow className="mb-3">
              <MDBCol size="1">
            
                <img className="image" src={require('images/Group398.svg')} />
               {/*} <MDBIcon icon="share" size="lg" className="white-text" />*/}
              </MDBCol>
              <MDBCol xl="10" md="11" size="10">
                <h5 className="white-text heading"> POST REQUIREMENT</h5>
                <p className="white-text r-p">
                Keep posting all your requirement and keep updating with their timelines.You can edit,delete and manage your jobs. 
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-3">
              <MDBCol size="1">
              
                <img className="image" src={require('images/Group399.svg')} />
               {/*} <MDBIcon icon="share" size="lg" className="white-text" />*/}
              </MDBCol>
              <MDBCol xl="10" md="11" size="10">
                <h5 className=" white-text"> HIRE RIGHT TALENT IN TIME</h5>
                <p className="white-text r-p">
                Get connected with the job seekers who are right fitment to your job post and hire the best.  
                </p>
              </MDBCol>
            </MDBRow>
            </Grid>
                </Grid>
              </Hidden>
              <Grid xs={12} sm={7} md={6}>
                <Paper elevation={5} className="login-with-otp">
                  {showThankyouPage ? "" : (
                    <>
                      <Grid className="action-selector" xs={12}>
                        <NavLink
                          to={`${match.url}/register`}
                          activeClassName="active-page"
                         
                        >
                          Register
                        </NavLink>
                        <NavLink
                          to={`${match.url}/login`}
                          activeClassName="active-page"
                        >
                          Login
                        </NavLink>
                      </Grid>
                      <Switch>
                        <Route
                          path={`${match.path}/login`}
                          component={LoginForm}
                        ></Route>
                        <Route 
                          path={`${match.path}/register`}
                          component={RegistrationEmployerPage}
                        ></Route>
                      </Switch>
                    </>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </MDBAnimation>
          </div>
        </MDBView>
      </MainContainer>
    </div>
  );
};

export default EmployerLogin;