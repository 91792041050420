import { NavLink, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HomePage from "./pages/home/HomePage";
import NotFoundPage from "./NotFoundPage";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { hot } from "react-hot-loader";
import AboutPage from "./pages/about/AboutPage";
import Resume from "./pages/resume/Resume";
import PrivacyPage from "./pages/privacy/PrivacyPage";
import TermsPage from "./pages/terms/TermsPage";
import ContactPage from "./pages/contact/ContactPage";
import LoginPage from "./pages/login/LoginPage";
import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import RegistrationPage from "./pages/register/RegistrationPage";
import SocialRegister from "./pages/register/SocialRegister";
import CandidateLogin from "./pages/register/withOtp/CandidateLogin";
import EmployerLogin from "./pages/employer/register/empRegistration/employerLogin"
import ResetPassword from "./pages/register/withOtp/resetPassword/ResetPassword";
import EmployerPassword from "./pages/register/withOtp/resetPasswordEmployer/ResetPassword";

import MedicoRegistration from "./pages/register/MedicoRegistration";
import RegistrationEmployerPage from "./pages/employer/register/RegistrationEmployerPage";
import RegistrationPageEmployer from "./pages/employer/register/RegistrationPage";
import RegistrationTrialPage from "./pages/employer/register/RegistrationTrialPage";
import LoginEmployerPage from "./pages/employer/login/LoginEmployerPage";
import ScrollToTop from "utils/ScrollToTop";
import JobSearch from "./pages/home/JobSearchList";
import { ToastContainer } from "react-toastify";
import { getXcsrfTokenAction } from "services/endpoints/common/commonActions";
import OnBoardingPage from "./pages/register/onboarding/OnBoardingPage";
import OnBoardingPageEdit from "./pages/register/onboardingEdit/OnBoardingPage";
import Verify from "./pages/register/verify";
import Recruiter from "./pages/register/recruiter";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import JobListingPage from "./pages/joblisting/JobListingPage";
import JobListingPageApplied from "./pages/JoblistingApplied/JobListingPage";
import JobListingPageSaved from "./pages/JoblistingSaved/JobListingPage";
import JobDetailPage from "./pages/joblisting/JobDetailPage";
import JobPostingPage from "./pages/jobposting";
import PrivateRoute from "utils/PrivateRoute";
import FullScreenLoader from "./common/FullScreenLoader/FullScreenLoader";
import { getSkillsAction, getIndustriesAction } from "services/endpoints/jobs/jobActions";
import JobPosting from "./pages/employer/JobPosting";
import EmployerDashboard from "./pages/employer/EmployerDashboard";
import EmployerShortlisted from "./pages/employer/EmployerShortlisted";
import EmployerCandidateCreate from "./pages/employer/EmployerCandidateCreate";
import JobpostThankyou from "./pages/employer/JobpostThankyou";
import CandidateEmployerSearch from "./pages/employer/CandidateEmployerSearch";
import EmployerMyCandidatesList from "./pages/employer/EmployerMyCandidatesList";
//import EmployerProfile from "./pages/employer/EmployerProfile";
import EmployerProfile from "./pages/employer/profile/Profile";
import EmployerCandidates from "./pages/employer/EmployerCandidates";
import EmployerRegister from "./pages/employer/EmployerRegister";
import JobEdit from "./pages/employer/JobEdit";
import CandidateEdit from "./pages/employer/CandidateEdit";
import EmployerCandidatesDetails from "./pages/employer/EmployerCandidatesDetails";
import MyCandidates from "./pages/employer/MyCandidates";
import Package from "./pages/employer/Package";
import Packages from "./pages/recruiter/package";
import Checkout from "./pages/employer/Checkout";
import MyJobs from "./pages/employer/MyJobs";
import MyProfile from "./pages/profile/MyProfile";
import RecruiterDashboard from "./pages/recruiter/RecruiterDashboard";
import CandidateCreate from "./pages/recruiter/CandidateCreate";
import MyCandidatesList from "./pages/recruiter/MyCandidatesList";
import CandidateSearch from "./pages/recruiter/CandidateSearch";
import RecruiterCandidatesDetails from "./pages/recruiter/RecruiterCandidatesDetails";
import EmployerRecruiterCandidates from "./pages/recruiter/EmployerRecruiterCandidates";
import RecruiterMyJobs from "./pages/recruiter/RecruiterMyJobs";
import RecruiterJobPosting from "./pages/recruiter/RecruiterJobPosting";
import RecruiterMyCandidates from "./pages/recruiter/RecruiterMyCandidates";
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import RegistrationRecruiterPage from "./pages/recruiter/register/RegistrationRecruiterPage";
import LoginRecruiterPage from "./pages/recruiter/login/LoginRecruiterPage";
import Candidate from "./pages/employer/candidate/candidate";
import SearchPage from "./pages/employer/search/SearchPage";
import Pricing from "./pages/home/categories/ecommerce";
import blog from "./pages/blog/blog";
import blogDetails from "./pages/blog/blogDetails";
import progress from "./pages/progress"
import progress1 from "./pages/progress1"
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import LinkedInTag from 'react-linkedin-insight';
import TagManager from 'react-gtm-module'
import Landing001 from "./pages/templates/landing001/landing001";
import UserManager from "utils/UserManager";

const tagManagerArgs = {
  gtmId: 'GTM-K4NGPG4'
}

TagManager.initialize(tagManagerArgs)
LinkedInTag.init(2199724);
LinkedInTag.track(2397404);
//import MedicoRegistration from "./pages/register/MedicoRegistration";
ReactGA.initialize('UA-156302308-1');
ReactGA.pageview(window.location.pathname + window.location.search);
//const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};
ReactPixel.init('2462814550669506', options);
 
ReactPixel.pageView(); 					// For tracking page view
//ReactPixel.track( event, data ) 		// For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
//ReactPixel.trackCustom( event, data ) 

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#13c1d6",
      fontFamily: "'Poppins', sans-serif"
    },
    secondary: {
      main: "#091E67",
      fontFamily: "'Poppins', sans-serif"
    }
  },
  typography: {
    fontFamily: "'Poppins', sans-serif"
  },
  dropdown:{
    fontFamily: "'Poppins', sans-serif"
  },
  header:{
    fontFamily: "'Poppins', sans-serif"
  },

  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: "10px",
        fontFamily: "'Poppins', sans-serif"
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "0.8rem",
        color: "#707070",
        fontFamily: "'Poppins', sans-serif"
      }
    },
    MuiInputLabel: {
      root: { fontSize: "0.8rem", color: "#707070",  fontFamily: "'Poppins', sans-serif" }
    },
    Button:{
      fontFamily: "'Poppins', sans-serif"
    },
    MuiSelect: {
      select: {
        padding: "10px",
        fontFamily: "'Poppins', sans-serif"
      }
    }
  }
});
class App extends React.Component {
  render() {
    const activeStyle = { color: "blue" };
    const isLoggedIn = UserManager.isLoggedIn();
   // const isEmployer = UserManager.isEmployer();
    return (
      <ThemeProvider theme={theme}>
        <XCRFToken />
        <ToastContainer autoClose={3000} />
        <ScrollToTop />
        <Switch>
        <Route exact path="/linkedin" component={LinkedInPopUp} />
     
              <Route exact path="/" component={HomePage} />
     
          
          <Route path="/about" component={AboutPage} />
          <Route path="/verify/:id" component={Verify} />
          <Route path="/recruiter/:id" component={Recruiter} />
          <Route path="/blog/:id" component={blogDetails} />
          <Route path="/blog" component={blog} />
          <Route path="/privacy-policy" component={PrivacyPage} />
          <Route path="/terms-use" component={TermsPage} />
           <Route path="/contact" component={ContactPage} />
          <Route path="/login" component={CandidateLogin} />
          <Route path="/employer/Pricing" component={Pricing} />
          <Route path="/recruiter/login" component={LoginRecruiterPage} />
         {/* // <Route path="/employer/login" component={LoginEmployerPage} /> */}
          <Route path="/register" component={SocialRegister} />
          <Route path="/jobseekers" component={CandidateLogin} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/forgot" component={EmployerPassword} />
          
          <Route path="/user/register" component={RegistrationPage} />
          <Route path="/medico" component={MedicoRegistration} />
          <Route path="/recruiter/register" component={RegistrationRecruiterPage} />
          {/* <Route path="/employer/register" component={RegistrationEmployerPage} /> */}
          <Route path="/employer/trial" component={RegistrationTrialPage} />
          <PrivateRoute path="/onboarding/:step?" roles={["candidate"]} component={OnBoardingPage} />
          <PrivateRoute path="/user/edit/onboarding/:step?" roles={["candidate"]} component={OnBoardingPageEdit} />
          <Route path="/jobs" component={JobListingPage} />
          <Route path="/job-search" component={JobSearch} />
          
          <Route path="/applied-jobs" roles={["candidate"]} component={JobListingPageApplied} />
          <Route path="/saved-jobs" roles={["candidate"]} component={JobListingPageSaved} />
          <Route path="/job/:id" component={JobDetailPage} />
          <Route path="/sales-and-support" component={Landing001} />
          <Route path="/employer/sales-and-support" component={Landing001} />
          <PrivateRoute path="/job-posting" component={JobPosting} roles={["employer"]} />
          <PrivateRoute path="/recruiter/job-posting" component={RecruiterJobPosting} roles={["recruiter"]} />
          <PrivateRoute path="/employer-dashboard" roles={["employer"]} component={EmployerDashboard} />
          <PrivateRoute path="/candidates" roles={["employer"]} component={Candidate} />
          <PrivateRoute path="/resume-edit/:id" roles={["candidate"]} component={Resume} />
          <PrivateRoute path="/thank-you" roles={["employer"]} component={JobpostThankyou} />
          <PrivateRoute path="/search" roles={["employer"]} component={SearchPage} />
          <PrivateRoute path="/employer/shortlisted" roles={["employer"]} component={EmployerShortlisted} />
          <Route path="/job-applications/:id" component={EmployerCandidates}  roles={["employer"]} />
          <Route path="/recruiter/job-applications/:id" component={EmployerRecruiterCandidates}  roles={["recruiter"]}  />
          <PrivateRoute path="/employer/profile" roles={["employer"]} component={EmployerProfile} />
          <PrivateRoute path="/candidate/:id" roles={["employer"]} component={EmployerCandidatesDetails} />
          <PrivateRoute path="/edit/:id" roles={["employer"]} component={JobEdit} />
          <PrivateRoute path="/candidate-edit/:id" roles={["employer"]} component={CandidateEdit} />
          <PrivateRoute path="/recruiter/candidate/:id" roles={["recruiter"]} component={RecruiterCandidatesDetails} />
          <PrivateRoute path="/my-jobs" roles={["employer"]} component={MyJobs} />
          <PrivateRoute path="/recruiter/my-jobs" roles={["recruiter"]} component={RecruiterMyJobs} />
          <PrivateRoute path="/employer/package" roles={["employer"]} component={Package} />
          <PrivateRoute path="/checkout/:id" roles={["employer"]} component={Checkout} />
          <PrivateRoute path="/my-candidates" roles={["employer"]} component={MyCandidates} />
          <PrivateRoute path="/recruiter/my-candidates" roles={["recruiter"]} component={RecruiterMyCandidates} />
          <PrivateRoute path="/employer-profile" roles={["employer"]} component={EmployerProfile} />
          <PrivateRoute path="/my-profile" roles={["candidate"]} component={MyProfile} />
          <PrivateRoute path="/recruiter-dashboard" roles={["recruiter"]} component={RecruiterDashboard} />
          <PrivateRoute path="/recruiter/package" roles={["employer"]} component={Package} />
          <PrivateRoute path="/candidate-create" roles={["recruiter"]}  component={CandidateCreate} />
          <PrivateRoute path="/employer/candidate-create" roles={["employer"]}  component={EmployerCandidateCreate} />
          <PrivateRoute path="/my-candidatesList" roles={["recruiter"]}  component={MyCandidatesList} />
           <PrivateRoute path="/Candidate-Search" roles={["recruiter"]}  component={CandidateSearch} />
            <PrivateRoute path="/employer/Candidate-Search" roles={["employer"]}  component={CandidateEmployerSearch} />
            <PrivateRoute path="/employer/my-candidatesList" roles={["employer"]}  component={EmployerMyCandidatesList} />
            <PrivateRoute path="/progress" component={progress} />
            <PrivateRoute path="/processing" component={progress1} />
          <PrivateRoute path="/post" component={JobPostingPage} />
          <Route path="/employer" component={EmployerLogin}/>
          <Route path="/test" component={FullScreenLoader} />
          <Route component={NotFoundPage} />
        </Switch>
      </ThemeProvider>
    );
  }
}

const XCRFToken = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSkillsAction());
    dispatch(getIndustriesAction());
  }, []);
  return null;
};

App.propTypes = {
  children: PropTypes.element
};

export default hot(module)(App);
