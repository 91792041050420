import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import assets from "../../../../assets";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({

    logoContainer: {
        textAlign: 'center',
        backgroundColor: '#F4F2F2',
        position: 'relative',
        height: '125px'
    },
    employerContainer: {
        padding: '0px 13vw'
    },
    logo: {
        maxWidth: '90px',
        maxHeight: '90px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }

}));

const EmployerList = () => {
    const classes = useStyles();
    return (
        <div className={classes.employerContainer}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6} sm={3} md={2}>
                    <div className={classes.logoContainer}>
                        <img class={classes.logo} src={assets.images.googlelogo} />
                    </div>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <div className={classes.logoContainer}>
                        <img class={classes.logo} src={assets.images.ibmlogo} />
                    </div>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <div className={classes.logoContainer}>
                        <img class={classes.logo} src={assets.images.siemenslogo} />
                    </div>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <div className={classes.logoContainer}>
                        <img class={classes.logo} src={assets.images.microsoftlogo} />
                    </div>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <div className={classes.logoContainer}>
                        <img class={classes.logo} src={assets.images.bmwlogo} />
                    </div>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <div className={classes.logoContainer}>
                        <img class={classes.logo} src={assets.images.eylogo} />
                    </div>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <div className={classes.logoContainer}>
                        <img class={classes.logo} src={assets.images.pwclogo} />
                    </div>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <div className={classes.logoContainer}>
                        <img class={classes.logo} src={assets.images.boschlogo} />
                    </div>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <div className={classes.logoContainer}>
                        <img class={classes.logo} src={assets.images.nestlelogo} />
                    </div>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <div className={classes.logoContainer}>
                        <img class={classes.logo} src={assets.images.unileverlogo} />
                    </div>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <div className={classes.logoContainer}>
                        <img class={classes.logo} src={assets.images.gelogo} />
                    </div>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <div className={classes.logoContainer}>
                        <img class={classes.logo} src={assets.images.kpmglogo} />
                    </div>
                </Grid>
            </Grid>
        </div>
    );

}

export default EmployerList;
