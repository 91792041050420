import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import StorageManager from "utils/StorageManager";
import { Link } from "react-router-dom";
import { Grid,Paper } from "@material-ui/core";
import EmployerContainer from "./employerContainer/EmployerContainer";
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import MyJobs from "./MyJobs";
import { getEmployerJobCountAction } from "services/endpoints/usermanagement/userManagementActions";
import "./employerDashboard.scss"


const footerValues = {
  companyCount: "10,000",
  jobSeekersCount: "1 Million",
  phone: "-+1 222 222 22222",
  email:"info@corp2corp.jobs"
}


const DashboardPage = () => {
  const dispatch = useDispatch();
  const { data: jobCounts } = useSelector(state => state.userManagement.employerJobCount);
  const uid = StorageManager.instance.getUid();
  const userName = localStorage.getItem("currentUserName");

  useEffect(() => { 
    dispatch(getEmployerJobCountAction({ uid }));
  },[]);


  return (
    <EmployerContainer heading="My Dashboard">
      <div className="flexible-content employer-dashboard">
        {/* <SideNavigation /> */}
        <main>
        <Grid container spacing={3} className="title-area">
            <Grid item xs={12}>
              <div className="welcome-message">Welcome {userName||"User"},</div>
              <div className="job-count-heading">Total jobs Posted <b>{jobCounts && jobCounts.total_jobs||0}</b></div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className="dashboard-card recent-jobs">
                <div className="dashboard-heading">Recent jobs</div>
                <MyJobs isDashboard={true} />
                <Link className="view-all" to="/My-Jobs">
                  VIEW ALL
                </Link>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <Paper className="dashboard-card counts">
                <h2>Total Logins</h2>
                <p>1</p>
                <div></div>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Paper className="dashboard-card counts">
                <h2>Total Job Seekers applied for all jobs</h2>
                <p>{jobCounts && jobCounts.applied_count||0}</p>
                <div></div>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} >
              <div className="dashboard-footer-wrapper">
              <Paper className="dashboard-footer">
                <p className="footer-desc-1"><b>Corp2Corp.jobs</b> is growing fast with all your support. More than <b>{footerValues.companyCount}</b> companies and <b>{footerValues.jobSeekersCount}</b> job seekers Are actively using <b>Corp2Corp.jobs</b> on daily basis.</p>
                <p className="footer-desc-2">If your company is looking for some branding solutions or other Premium support,</p>
                <div className="footer-desc-3">
                  <div className="footer-desc-inner">
                    <PhoneIcon/>
                    <span>Reach our account manager at <br/> <b>{footerValues.phone}</b></span> 
                  </div>
                  <div className="footer-desc-inner">
                    <EmailIcon/>
                    <span>Or email us at <br/> <b>{footerValues.email}</b></span> 
                  </div>
                </div>
                </Paper>
                </div>
            </Grid>
          </Grid>
        </main>
      </div>
    </EmployerContainer>
  );
}

export default DashboardPage;