import React from "react";
import { PhoneAndroidOutlined } from "@material-ui/icons";
import { Button, LinearProgress, Grid } from "@material-ui/core";
import { Formik, Form, FastField } from "formik";

import * as Yup from "yup";
import { FormikTextInput } from "components/common/FormInputs/FormikInputs";

import GoogleLogin from "./../social/GoogleLogin";
import FacebookLogin from "./../social/FacebookLogin";
import LinkedInLogin from "./../social/LinkedInLogin";
import "./../social/SocialLogin.scss";

const Landing = ({
  onSubmitForm,
  onSocialLoginSuccess,
  registerWithEmail,
  registerData,
  loading
}) => {
  
  const onLoginSuccess = data => {
    onSocialLoginSuccess({
      isSuccess: true,
      name: data.name,
      email: data.email,
      uid: data.uid,
      type:data.type
    });
  };

  const handleFormSubmit = values => {
    onSubmitForm(values);
  };

  let validationSchema = {
    mobile: Yup.number()
      .required("Please enter your mobile number")
      .test(
        "len",
        "Please enter a valid mobile number",
        val => val.toString().length === 10
      )
  };

  return (
    <Formik
      initialValues={{ mobile: registerData.mobile }}
      validationSchema={Yup.object().shape(validationSchema)}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit }) => {
        return (
          <>
            <Form name="register" method="post" onSubmit={handleSubmit}>
              <Grid container>
                <div className="social-register">
                  <div className="mobile-number-wrapper">
                    <FastField
                      style={{
                        marginBottom: "20px",
                        transition:
                          "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                        color: "#495057",
                        borderRadius: ".6rem"
                      }}
                      name="mobile"
                      component={FormikTextInput}
                      fullWidth
                      type="number"
                      id="mobile"
                      size="small"
                      labelText="Enter US Mobile Number"
                      placeholder="Enter Your US Mobile Number"
                      variant="outlined"
                      Icon={PhoneAndroidOutlined}
                    />
                  </div>
                </div>
              </Grid>
              <Grid container>
                <Grid xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                      marginTop: "1em",
                      color: "white",
                      borderRadius: "10px"
                    }}
                  >
                    Register
                  </Button>
                  {loading && <LinearProgress />}
                </Grid>
              </Grid>
            </Form>

            <Grid container spacing={2} className="social-login-wrapper">
              <div className="hr-line">
                <span>Or Sign in with</span>
              </div>
              <Grid item xs={4}>
                <LinkedInLogin
                  onLoginSuccess={onLoginSuccess}
                  isRegister ={true}
                />
              </Grid>
              <Grid item xs={4}>
                <GoogleLogin
                  onLoginSuccess={onLoginSuccess}
                  isRegister ={true}
                />
              </Grid>
              <Grid item xs={4}>
                <FacebookLogin
                  onLoginSuccess={onLoginSuccess}
                  isRegister ={true}
                />
              </Grid>
              <Grid item xs={12} className="register-with-email-link">
                <a onClick={registerWithEmail}>Register with email</a>
              </Grid>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};

export default Landing;