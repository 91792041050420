import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Typography, Box, Button, Divider,makeStyles } from "@material-ui/core";
import assets from "assets";
import { Logo, JobSectionHeading } from "../JobListingPage.styled";
import { useTheme } from "@material-ui/styles";
import Hidden from '@material-ui/core/Hidden';
import AppButton from "components/common/Buttons";
import TagList from "components/common/TagList/TagList";
import lodash from "lodash";
import { flagJobAction } from "services/endpoints/jobs/jobActions";
import { toast } from "react-toastify";
import UserManager from "utils/UserManager";
import { useHistory } from "react-router-dom";
import {Helmet} from "react-helmet";
import axios from 'axios';
import StorageManager from "utils/StorageManager";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import {
  EmailIcon,
  FacebookIcon,
  FacebookShareCount,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  FacebookShareButton,
  WorkplaceIcon,
} from "react-share";
const useStyles = makeStyles(theme => ({
  header1: {
    fontWeight: "bold",
    fontFamily: "'Poppins', sans-serif",
    margin: "0px",
    overflow: "hidden",
    fontSize: "0.85em"
  },
  sshare:{
padding:"33px !important"
  },
  jobApplyButton1: {
   
    fontSize: "0.7em",
    marginTop: "0px",
    fontFamily: "'Poppins', sans-serif",
    textTransform: "none",
    padding: "0px",
  },
  jobApplyButton: {
  backgroundColor: "#13C1D6",
  color: "white",
  },
  keyskill:{
    color: "#BABABA",
    display: "inline-block",
    fontSize: "0.8em",
    lineHeight: "1em",
    padding: "2px 8px",
    outline: "none",
    marginTop: "2px",
    cursor: "pointer",
    marginRight: "2px",
    borderRadius: "10px",
    border: "1px solid #BABABA",
    fontFamily: "'Poppins', sans-serif",
    "&:hover": {
      borderColor: `${theme.palette.primary.main}`,
      color: `${theme.palette.primary.main}`
    },
 
  },
}));
const JobDetail = ({ item = {}, fullWidth }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoggedIn = UserManager.isLoggedIn();
  const [isApplied, setIsApplied] = useState(item.job_applied);
  const [isSaved, setIsSaved] = useState(item.saved);
  const { loading, data, error } = useSelector(state => state.job.flagJob);
  const classes = useStyles();

  const flagJob = jobId => {
    dispatch(flagJobAction(jobId));
  };
const flagJobSaved = jobId => {
  const token = StorageManager.instance.getAccessToken();
  const csrf = StorageManager.instance.getXCSRFToken();
  const uid = StorageManager.instance.getUid();
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'bearer '+token,
      'X-CSRF-Token':csrf
    }

   

  const data = {"flag_id":"save_job" ,"entity_type":"node", "entity_id":jobId,"uid":uid};
  axios.post(`https://live-corp2corp.pantheonsite.io/api/flag?_format=json`, data,{
      headers: headers
  })
  .then(res => {
      console.log(res.data);
      setIsSaved(true);
     
    })
}
const unflagJobSaved = jobId => {
  const token = StorageManager.instance.getAccessToken();
  const csrf = StorageManager.instance.getXCSRFToken();
  const uid = StorageManager.instance.getUid();
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'bearer '+token,
      'X-CSRF-Token':csrf
    }

   

  const data = {"flag_id":"save_job" ,"entity_type":"node", "entity_id":jobId,"uid":uid};
  axios.post(`https://live-corp2corp.pantheonsite.io/api/flag?_format=json`, data,{
      headers: headers
  })
  .then(res => {
      console.log(res.data);
      setIsSaved(false);
     
    })
}
  useEffect(() => {
    if (data) {
      setIsApplied(true);
      toast.success(`Successfully applied for ${lodash.get(item, "title")}`);
    }
  }, [data, error]);

  return (
    <Grid container justify="center">
      <Grid item sm={12} md={fullWidth ? 12 : 8} xl={fullWidth ? 10 : 6}>
      <Helmet>
                <meta charSet="utf-8" />
                <title> {lodash.get(item, "company_name")} is hiring {lodash.get(item, "title")} - {item.country} | Corp2Corp </title>
                <meta name="keywords" content="job search, jobs, work, employment, career, resumes, job vacancies,corp2corp,corp2corp.jobs,USA Jobs"></meta>
                <link rel="canonical" href={item.alias} />
                <meta name="description" content={item.company_name+" is hiring "+item.title+"-"+item.country+"- with skills "+ item.key_skills+" Apply today at Corp2Corp.jobs"} ></meta>
                <meta property="og:url" content={item.alias} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={item.company_name+ "is hiring "+item.title+"-"+item.country+"|Corp2Corp" } />
                <meta property="og:description" content={item.company_name+" is hiring "+item.title+"-"+item.country+"- with skills "+ item.key_skills+" Apply today at Corp2Corp.jobs"} />
                <meta property="og:image" content="https://www.corp2corp.jobs/ec144eb64b9a197f5feeec993a74ea02.svg" />

                           </Helmet>
        <Paper>
          <Box p={3}>
            <Grid container justify="space-between">
              <Grid item xs={12}>
                  {/*
                <Logo src={lodash.get(item, "logo_url", assets.images.logo) || assets.images.logo} />
               */}
                {
item.logo_url ?  <img src={lodash.get(item, "logo_url", assets.images.logo)} className={classes.logo} style={{ maxWidth: "200px" }} /> : 
""

      }
      {/*}
                <Typography className={classes.header1}>{item.company_name}</Typography>*/}
                
              </Grid>
              <Hidden only={['xs']}>
              <Grid item xs={10}>
                <Typography style={{ 
                  
                    color: "var(--unnamed-color-13c1d6)",
                   
                    fontWeight: "bold",
                    fontSize: "24px",
                    letterSpacing: "0px",
                    color: "#13C1D6",
                    fontFamily:"'Poppins', sans-serif",

                    opacity: "1" }} display="inline">
                  {lodash.get(item, "title")},
                </Typography>{" "}
                <span variant="caption">{lodash.get(item, "company_name")}</span>
                <div style={{marginLeft:"-5px"}}>
                <Typography variant="caption" display="block" style={{padding:"3px"}}>
                <LocationOnIcon color="secondary" fontSize="small" style={{fontSize:"12px",color:"#1A20AF"}}/>
                <span style={{paddingLeft:"4px"}}>{lodash.get(item, "job_location")}</span>
                </Typography>
                <Typography variant="caption" display="block" style={{padding:"3px"}}>
                  <BusinessCenterIcon color="secondary" fontSize="small" style={{fontSize:"12px",color:"#1A20AF"}}/>
                  <span style={{color: "#707070",paddingLeft:"4px"}}>{lodash.get(item, "employment_type")}</span>
                </Typography>
                </div>
              </Grid>
              </Hidden>
            <Hidden only={['lg', 'md', 'sm', 'xl',]}>
              <Grid item xs={12}>
                <Typography style={{ 
                  
                    color: "var(--unnamed-color-13c1d6)",
                   
                    fontWeight: "bold",
                    fontSize: "24px",
                    letterSpacing: "0px",
                    color: "#13C1D6",
                    fontFamily:"'Poppins', sans-serif",

                    opacity: "1" }} display="inline">
                  {lodash.get(item, "title")},
                </Typography>{" "}
                <span variant="caption">{lodash.get(item, "company_name")}</span>
                <div style={{marginLeft:"-9px"}}>
                <Typography variant="caption" display="block" style={{padding:"3px"}}>
                <LocationOnIcon color="secondary" fontSize="small" style={{fontSize:"12px",color:"#1A20AF"}}/>
                <span style={{paddingLeft:"4px"}}>{lodash.get(item, "job_location")}</span>
                </Typography>
                <Typography variant="caption" display="block" style={{padding:"3px"}}>
                  <BusinessCenterIcon color="secondary" fontSize="small" style={{fontSize:"12px",color:"#1A20AF"}}/>
                  <span style={{color: "#707070",paddingLeft:"4px"}}>{lodash.get(item, "employment_type")}</span>
                </Typography>
                </div>
              </Grid>
              </Hidden>
              <Grid item xs style={{textAlign:"right"}}>
              {!isLoggedIn && (
                  <span style={{display: 'inline-block',paddingRight:"14px"}} 
                  disabled={isSaved}
                  onClick={() => {
                   if (UserManager.isLoggedIn()) flagJobSaved(item.job_id);
                   else {
                     toast.warn("Please login to apply!");
                     history.push("/jobseekers/login");
                   }
                 }}>
                      <FavoriteBorderIcon fontSize="small" style={{cursor:"pointer"}} color="secondary"/> 
                    </span>
                
                )}

                
               {isLoggedIn && (
           
                 <span style={{display: 'inline-block',paddingRight:"1px"}} >
                   {isSaved ? <FavoriteIcon  fontSize="small" style={{color:"red",cursor:"pointer"}}  onClick={() => {
                  if (UserManager.isLoggedIn()) unflagJobSaved(item.job_id);
                  else {
                    toast.warn("Please login to apply!");
                    history.push("/jobseekers/login");
                  }
                }}/> : <FavoriteBorderIcon fontSize="small" style={{color:"red",cursor:"pointer"}} onClick={() => {
                  if (UserManager.isLoggedIn()) flagJobSaved(item.job_id);
                  else {
                    toast.warn("Please login to apply!");
                    history.push("/jobseekers/login");
                  }
                }}/> } 
                   </span>
          
               )}
                <Button
                style={{ width: "113px",
                  height: "44px",
                 
                  background: "#13C1D6 0% 0% no-repeat padding-box",
                  borderRadius: "10px",
                  opacity: "1"}}
                  disabled={isApplied}
                  className={classes.jobApplyButton}
                  variant={isApplied ? "contained" : "outlined"}
                  color={isApplied ? "primary" : "secondary"}
                  onClick={() => {
                    if (UserManager.isLoggedIn()) flagJob(item.job_id);
                    else {
                      var alias = window.location.pathname;
                      //onsole.log("queryString",value);
                      console.log(alias);
                      localStorage.setItem("alias",alias);
                      toast.warn("Please login to apply!");
                     history.push("/jobseekers/login");
                    }
                  }}
                >
                  {isApplied ? "Applied" : "Apply"}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box p={3}>
            <Grid container>
            <Hidden only={['xs']}>
              <Grid item xs={2}>
                <TagList title="Qualifications" list={lodash.get(item, "qualifications")} />
              </Grid>
              <Grid item xs={10}>
                
              {lodash.get(item, "key_skills") &&(
               <div className={classes.skillsContainer}>
                 <Typography style={{fontWeight:"600"}}>Skills</Typography>
                  {lodash.get(item, "key_skills")}
    
                </div>
                )}
                
               
              </Grid>
              </Hidden>
              <Hidden only={['lg', 'md', 'sm', 'xl',]}>
              <Grid item xs={6}>
                <TagList title="Qualifications" list={lodash.get(item, "qualifications")} />
              </Grid>
              <Grid item xs={6}>
              {lodash.get(item, "key_skills") &&(
               <div className={classes.skillsContainer}>
                 <Typography variant="h6">Skills</Typography>
                  {lodash.get(item, "key_skills")}
    
                </div>
                )} 
              </Grid>
              </Hidden>
              <Grid item xs={12}>
                <Box mt={2}>
                  <p style={{ fontFamily: "'Poppins', sans-serif" }} dangerouslySetInnerHTML={{ __html: lodash.get(item, "description") }}></p>
                
                  <Grid item xs={12}>
              
                <Button
                  disabled={isApplied}
                  style={{ width: "124px",
                  height: "44px",
                 
                  background: "#13C1D6 0% 0% no-repeat padding-box",
                  borderRadius: "10px",
                  opacity: "1"}}
                  className={classes.jobApplyButton}
                  variant={isApplied ? "contained" : "outlined"}
                  color={isApplied ? "primary" : "secondary"}
                  onClick={() => {
                    if (UserManager.isLoggedIn()) flagJob(item.job_id);
                    else {
                      var alias = window.location.pathname;
                      //onsole.log("queryString",value);
                      console.log(alias);
                      localStorage.setItem("alias",alias);
                      toast.warn("Please login to apply!");
                     history.push("/jobseekers/login");
                    }
                  }}
                >
                  {isApplied ? "Applied" : "Apply Now"}
                </Button>
                <span style={{position:"relative",float:"right"}}>

          <FacebookShareButton
            url={lodash.get(item, "alias")}
            quote={lodash.get(item, "title")}
            className="sshare"
            style={{padding:"2px !important"}}
          >
            <FacebookIcon size={32} square />
          </FacebookShareButton>
        
          <TwitterShareButton
            url={lodash.get(item, "alias")}
            title={lodash.get(item, "title")}
            className="sshare"
            style={{padding:"2px !important"}}
          >
          <TwitterIcon size={32} square />
          </TwitterShareButton>
          <LinkedinShareButton
            url={lodash.get(item, "alias")}
            style={{padding:"2px !important"}}
            className="sshare"
          >
         <LinkedinIcon size={32} square />
          </LinkedinShareButton>
</span>
              </Grid>
            
                </Box>

                {/* <JobSectionHeading>Job Summary</JobSectionHeading>
            Looking for very good and hard working fresh software developers who can learn quickly, different technologies and can do web and app
            development, DB, middle-ware and front end development.
            <JobSectionHeading>Responsibilities and Duties</JobSectionHeading>
            Web and app development, Database integration and frontend development.
            <JobSectionHeading>Key Skills</JobSectionHeading>
            Should have knowledge of any programming language and Database. Required Experience and Qualifications Any fresher having BE/B.Tech/M.Tech
            degree.
            <JobSectionHeading>Benefits</JobSectionHeading> We will also offer training support and knowledge about online commerce, edutech, digital
            marketing, building for Bharat, how startups are built, and more.
            <JobSectionHeading>Job Types: Full-time, Fresher</JobSectionHeading>
            Salary: ₹20,000.00 to ₹50,000.00 /month
            <JobSectionHeading>Experience:</JobSectionHeading>
            Programming: 1 year (Preferred) total work: 1 year (Preferred)
            <JobSectionHeading>Education: Bachelor’s (Preferred)</JobSectionHeading>
            Location: Kalyananagar (Preferred)
            <JobSectionHeading>Industry:</JobSectionHeading>
            Software Development */}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default JobDetail;
