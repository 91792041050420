
import StorageManager from "utils/StorageManager";
import UserManager from "utils/UserManager";
import lodash from "lodash";
import { toast } from "react-toastify";
import {loginStatiticsAction} from "services/endpoints/usermanagement/userManagementActions";

export function setLoginTokens(result) {
    StorageManager.instance.setAccessToken(result.access_token);
    //StorageManager.instance.setBasicToken(btoa(`${username}:${password}`));
    StorageManager.instance.setUid(lodash.get(result, "current_user.uid"));
    StorageManager.instance.setXCSRFToken(lodash.get(result, "csrf_token"));
    StorageManager.instance.setLogoutToken(lodash.get(result, "logout_token"));
}

export function handleSocialLoginSuccess(response, isRegister,onLoginSuccess,type,dispatch) {
  let redirect = false;
  if (isRegister) {
    if (response.status === 0) {
      onLoginSuccess(formatResponse(response,type));
      UserManager.logout1();
    } else {
      setLoginTokens(response);
      let request = { type, uid: lodash.get(response, "current_user.uid") };
      dispatch(loginStatiticsAction(request,response.access_token));
      redirect = "/onboarding/1";
      
    }
  } else {
    if (response.status === 0) {
      toast.error("Please complete the registration process");
      UserManager.logout1();
      localStorage.setItem("social_register_from_login",JSON.stringify(formatResponse(response,type)));
      redirect = "/jobseekers/register";
    } else {
      setLoginTokens(response);
      let request = { type, uid: lodash.get(response, "current_user.uid") };
      dispatch(loginStatiticsAction(request,response.access_token));
      redirect = "/onboarding/1";
    }
  }
  return redirect;
}

export function handleSocialLoginFailure(social) {
  toast.error(`Failed to login with ${social}`);
} 

function formatResponse(response,type) {
  return {
    email: response.current_user.email,
    name: response.current_user.name,
    uid: response.current_user.uid,
    type
  }
}