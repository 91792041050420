import React, { useContext } from "react";
import AppButton from "components/common/Buttons";
import { Grid } from "@material-ui/core";
import { OnBoarding } from "../onboarding/useSteps";

const NextPrevious = ({ style, validateForm }) => {
  const [state, increment, decrement, isNextAvailable, isPrevAvailable] = useContext(OnBoarding);
  return (
    <Grid container justify="flex-end" style={{ marginTop: "1em", ...style }} spacing={2}>
      {
        <Grid item>
          <AppButton
            item
            selected={true}
            style={{ borderRadius: "10px" }}
            onClick={() => {
              validateForm && validateForm(increment);
            }}
          >
            {isNextAvailable ? "Next" : "Submit"}
          </AppButton>
        </Grid>
      }
     
    </Grid>
  );
};

export default NextPrevious;
