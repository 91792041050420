import React, { Component,useState  } from 'react';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav,MDBBtn, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBIcon,MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact';
import UserManager from "utils/UserManager";
class TopNavigation extends Component {
    state = {
        collapse: false
    }

    onClick = () => {
        this.setState({
            collapse: !this.state.collapse,
        });
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
  
   

    render() {
      
        const isPro = UserManager.isPro();
        return (
            <MDBNavbar className="flexible-navbar" light expand="md" scrolling>
                
                <MDBNavbarToggler onClick = { this.onClick } />
                <MDBCollapse isOpen = { this.state.collapse } navbar>
                    <MDBNavbarNav left className="d-block d-md-none">
                        <MDBNavItem active>
                            <MDBNavLink to="/employer-dashboard">Home</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <a rel="noopener noreferrer" className="nav-link Ripple-parent" href="/employer-dashboard">Post Job</a>
                        </MDBNavItem>
                        <MDBNavItem>
                            <a rel="noopener noreferrer" className="nav-link Ripple-parent" href="/My-jobs">My Jobs</a>
                        </MDBNavItem>
                        <MDBNavItem>
                            <a rel="noopener noreferrer"  className="nav-link Ripple-parent" href="/My-candidates">Shortlisted Candidate</a>
                        </MDBNavItem>
                    </MDBNavbarNav>
                    <MDBNavbarNav right>
                    
                
              
                {isPro &&(
                <MDBNavItem>
                     <MDBBtn color="danger">
        PRO <MDBIcon icon="gem" className="ml-1" />
      </MDBBtn>
                                          </MDBNavItem>
                )}
                      {!isPro &&(
                <MDBNavItem>
                     <MDBBtn href="/pricing" color="danger">
        GO PRO <MDBIcon icon="gem" className="ml-1" />
      </MDBBtn>
                                          </MDBNavItem>
                )}
            
                   
                        <MDBNavItem>
                      <MDBDropdown>
                        <MDBDropdownToggle caret color="white">
                        <MDBIcon icon="user" />
                        </MDBDropdownToggle>
                        <MDBDropdownMenu basic>
                            <MDBDropdownItem>My Profile</MDBDropdownItem>
                            
                            <MDBDropdownItem    onClick={() => {
           
           
            UserManager.logout();
          }}
          >Logout</MDBDropdownItem>
                        </MDBDropdownMenu>
                        </MDBDropdown>
                        </MDBNavItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBNavbar>
        );
    }
}

export default TopNavigation;