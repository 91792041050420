import React from "react";
import { Grid, Typography, FormGroup, FormControlLabel, Checkbox, FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { CardContainer, ContentGrid } from "../Register.styled";
import RegisterHeader from "../header/RegisterHeader";
import { Formik, Form, ErrorMessage, Field, FastField } from "formik";
import * as Yup from "yup";
import { FormikTextInput, FormikCheckBoxGroup, FormikCheckBox } from "components/common/FormInputs/FormikInputs";
import NextPrevious from "../header/NextPrevious";
import { JobService } from "services/endpoints/jobs/JobService";
import StorageManager from "utils/StorageManager";
import { toast } from "react-toastify";
import lodash from "lodash";
import { useHistory } from "react-router-dom";

const AdditionalInformation = ({ candidate }) => {
  return (
    <React.Fragment>
      <CardContainer>
       
        <ContentGrid>
          <Typography variant="h6" style={{ marginBottom: "1em" }}>
            Job preferences
          </Typography>
          <AdditionalInformationForm candidate={candidate} />
        </ContentGrid>
      </CardContainer>
      {/* <CardContainer style={{ marginTop: 0 }}>
        <SalarySurveyForm />
      </CardContainer> */}
    </React.Fragment>
  );
};

const AdditionalInformationForm = ({ candidate }) => {
  const history = useHistory();
  const jobTypes = [
    { name: "Full-time", value: "Full-time" },
    { name: "Part-time", value: "Part-time" },
    { name: "Contract", value: "Contract" },
    { name: "Temporary", value: "Temporary" },
    { name: "Internship", value: "Internship" },
    { name: "Commission", value: "Commission" },
    { name: "Fresher", value: "Fresher" },
    { name: "Volunteer", value: "Volunteer" },
    { name: "Walk-In", value: "Walk-In" }
  ];

  const SalaryType = [
    { name: "Monthly", value: "Monthly" },
    { name: "Yearly", value: "Yearly" }
  ];
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        uid: StorageManager.instance.getUid(),
        job_preferences: lodash.get(candidate, "job_preferences", ""),
        job_types: lodash.get(candidate, "desired_job_types", []),
        desired_salary: lodash.get(candidate, "desired_salary", ""),
        willing_to_relocate: lodash.get(candidate, "willing_to_relocate", 0)
      }}
      validationSchema={Yup.object().shape({
        job_preferences: Yup.string().required("This field is required"),
        job_types: Yup.array().required("This field is required"),
        desired_salary: Yup.number().required("This field is required")
      })}
      onSubmit={(values, actions) => {
        console.log("onSubmit", values);
        return JobService.updateAdditionalInfo(values).then(response => {
          console.log("response", response);
          if (response.status === 1) {
            toast.success("Successfully saved");
            return Promise.resolve(response);
          } else {
            toast.error("Error saving");
          }
          return Promise.reject(response);
        });
      }}
    >
      {({ errors, touched, handleSubmit, isSubmitting, submitForm }) => {
        return (
          <>
            <Form name="login" method="post" onSubmit={handleSubmit}>
              <FastField
                style={{ marginBottom: "2em" }}
                name="job_preferences"
                component={FormikTextInput}
                fullWidth
                labelText="Desired Job Title"
                size="small"
                placeholder="Job title"
                variant="outlined"
              />
              <Typography>Desired Job Types</Typography>
              <FastField
                style={{ marginBottom: "2em" }}
                name="job_types"
                component={FormikCheckBoxGroup}
                items={jobTypes}
                placeholder="Job title"
                fullWidth
                size="small"
                variant="outlined"
              />

              <Grid container spacing={2}>
                <Grid xs={12} style={{ marginTop: "2em" }}>
                  <Typography>Desired Salary</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignItems="center" justify="center" spacing={1}>
                    <Grid item xs={2}>
                      <InputLabel>USD</InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <FastField
                        name="desired_salary"
                        component={FormikTextInput}
                        fullWidth
                        size="small"
                        placeholder="Desired salary"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      (Annually)
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item sm={6} xs={12} m={2}>
                  <Select fullWidth variant="outlined">
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"year"}>Per year</MenuItem>
                    <MenuItem value={"month"}>Per month</MenuItem>
                  </Select>
                </Grid> */}
              </Grid>

              <FastField
                name="willing_to_relocate"
                component={FormikCheckBox}
                fullWidth
                size="small"
                labelText="I am willing to relocate"
                variant="outlined"
              />
            </Form>
            {/* <pre>
              <strong>Errors:</strong> {JSON.stringify(errors, null, 2)}
              <strong>Touched:</strong> {JSON.stringify(touched, null, 2)}
              {formValues && <strong>Submitted values:</strong>}
              {JSON.stringify(formValues, null, 2)}
            </pre> */}
            <NextPrevious
              validateForm={increment => {
                submitForm().then(response => {
                  console.log("submit value", response);
                  const alias = localStorage.getItem("alias");
                  if(alias){
                    history.push(alias);
                  }else{
                  history.push("/jobs");
                  }
                  //history.push("/jobs");
                });
              }}
            />
          </>
        );
      }}
    </Formik>
  );
};

const SalarySurveyForm = () => {
  return (
    <div>
      <Typography align="center" style={{ fontFamily: "Poppins, sans-serif", fontSize: "2em", fontweight: "700" }}>
        Optional: Participate in our Salary Survey
      </Typography>
      <ContentGrid>
        <Formik
          initialValues={{
            UserName: "",
            Password: ""
          }}
          validationSchema={Yup.object().shape({
            UserName: Yup.string().required("Please enter your email"),
            Password: Yup.string().required("Please enter your password"),
            salary: Yup.number().required("Please enter salary")
          })}
          onSubmit={(values, actions) => {
            actions.resetForm();
            if (loginAction) {
              loginAction(values.UserName, values.Password);
            }
          }}
        >
          {({ errors, touched, handleSubmit, isSubmitting }) => {
            return (
              <Form name="login" method="post" onSubmit={handleSubmit}>
                <FormControlLabel
                  control={<Checkbox value="participateSurvey" />}
                  label="CONFIDENTIAL - Salary information you provide is anonymous and will not be shown in your resume."
                />
                <Grid container sm={12} spacing={2}>
                  <Grid xs={12} style={{ marginTop: "2em" }}>
                    <Typography>Salary at accenture, UI/UX Designer</Typography>
                  </Grid>
                  <Grid item sm={6} xs={12} m={2}>
                    <Grid container alignItems="center">
                      <Grid xs={2}>
                        <InputLabel>USD</InputLabel>
                      </Grid>
                      <Grid xs={10}>
                        <FastField
                          //   style={{ marginBottom: "2em" }}
                          name="salary"
                          component={FormikTextInput}
                          fullWidth
                          id="salary"
                          size="small"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={6} xs={12} m={2}>
                    <Select fullWidth variant="outlined">
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"year"}>Per year</MenuItem>
                      <MenuItem value={"month"}>Per month</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </ContentGrid>
    </div>
  );
};

export default AdditionalInformation;
