import React, { Component } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import { Grid, Hidden, Paper, Container, Typography, TextField, FormControl, InputAdornment, Icon, Button, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import assets from "../../../assets";
import MyAppBar from "../../common/myappbar/MyAppBar";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { TextInput } from "../../common/FormInputs";
import LockIcon from "@material-ui/icons/Lock";
import MainContainer from "../../common/MainContainer";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ImageIcon, StyledLink } from "components/common/Misc";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import TopNavigation from 'components/pages/employer/header/topNavigation';
import SideNavigation from 'components/pages/employer/header/sideNavigation';
import Footer from 'components/pages/employer/header/Footer';
import StorageManager from "utils/StorageManager";
import CKEditor from "react-ckeditor-component";
import { authenticateAction } from "services/endpoints/usermanagement/userManagementActions";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBStepper, MDBStep, MDBBtn, MDBCard, MDBCardBody, MDBInput,MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption, MDBInputGroup,MDBDropdown,MDBDropdownToggle,MDBIcon,MDBDropdownMenu,MDBDropdownItem,MDBFileInput} from "mdbreact";
import AlgoliaPlaces from 'algolia-places-react';

import axios from 'axios';



class JobpostThank extends React.Component {
  
render() {
  return (
    
     <MainContainer>
    <div className="flexible-content">
      
    {/*<TopNavigation />*/}
    <main className="p-5">
    <MDBContainer>
      <MDBCard>
            <MDBCardBody>
              <h2 className="text-center font-weight-bold pt-4 pb-5">
                <strong>Successfully Posted Your Job</strong>
              </h2>
              <div className="text-center pt-4 pb-5">
              <MDBBtn href="/employer-dashboard" color="primary" outline rounded>My Dashboard</MDBBtn>
      <MDBBtn rounded href="/My-Jobs" outline>Manage Jobs</MDBBtn>
         </div>
              <div>
          </div>
        </MDBCardBody>
          </MDBCard>  
          </MDBContainer>
          </main>
         
        </div>   
        </MainContainer> 
  );
  };
}

export default JobpostThank;