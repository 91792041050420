import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import { Grid, Hidden, Paper, Container, Typography, TextField, FormControl, InputAdornment, Icon, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import assets from "../../../assets";
import { Image, Root, VerticalCenter, ImageGrid, LoginCard } from "../login/LoginPage.styled";
import MyAppBar from "../../common/myappbar/MyAppBar";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { TextInput } from "../../common/FormInputs";
import LockIcon from "@material-ui/icons/Lock";

import SvgIcon from "@material-ui/core/SvgIcon";
import { ImageIcon, StyledLink } from "components/common/Misc";
import { authenticateAction, registerAction, generateOtpAction, verifyOtpAction,socialLoginAction } from "services/endpoints/usermanagement/userManagementActions";
import RegisterForm from "./RegisterForm";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import OtpVerification from "./otpverification/OtpVerification";
import SocialLogin from "components/common/SocialLogin/SocialLogin";
import UserManager from "utils/UserManager";
import StorageManager from "utils/StorageManager";
import MainContainer from "../../common/MainContainer";
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import AlgoliaPlaces from 'algolia-places-react';
import Select from 'react-select';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader';
import "./register.css"
import {  
  
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBModalFooter,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBView,
  MDBContainer,
  MDBFormInline,
  MDBCard,
  MDBCardBody,
  location,
  MDBInput,
   MDBIcon } from "mdbreact";

import {Helmet} from "react-helmet";
const options = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10+', label: '10+' },
  { value: '15+', label: '15+' },
  { value: '20+', label: '20+' },

];
class MedicoRegistration extends React.Component {
  state = {
    fname: {
      value: "",
      valid: false
    },
    lname: {
      value: "",
      valid: false
    },
    country: [] ,
    selectedOption: null,
    upload:false,
    async_test: [],
    email: {
      value: "",
      valid: false
    },
    ThankYou: false,
    password: {
      value: "",
      valid: false
    },
    experties: {
      value: "",
      valid: false
    },
    designation: {
      value: "",
      valid: false
    },
    buttonText:"Submit",
    state: {
      value: "",
      valid: false
    },
    location: {
      value: "",
      valid: false
    },
    fid: {
      value: "",
      valid: false
    }
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: { value: event.target.value, valid: !!event.target.value } });
  };
 async componentWillMount() {  
   await axios.get('https://live-corp2corp.pantheonsite.io/api/register/skills').then(response => {  
            console.log(response);  
            this.setState({  
                    country: response.data  

            })  
    })  
}  
Country() {  
    return (this.state.country.map(data => ({ label: data.label, value: data.value })))  
}  
onChangeHandler=event=>{
  console.log(event.target.files[0]);

this.setState({ selectedFile:event.target.files[0] });
const token = StorageManager.instance.getAccessToken();
const csrf = StorageManager.instance.getXCSRFToken();
const uid = StorageManager.instance.getUid();
console.log(event.target.files[0].name);
const fileName = event.target.files[0].name;

console.log(fileName);
const headers = {
  'Content-Type': 'application/octet-stream',
  'Authorization': 'basic aGFuaXNoQHdpbm5vcHJvLmNvbTox',
  'X-CSRF-Token':csrf,
  'Content-Disposition':`file;filename="${fileName}"`
}
console.log(headers);
axios.post(`https://live-corp2corp.pantheonsite.io/file/upload/node/candidate_details/field_resume?_format=hal_json`, event.target.files[0] ,{
  headers: headers
})
.then(res => {
  localStorage.setItem('fid', res.data.fid[0].value);
  console.log("File Upload",res.data.fid[0].value);
})


}
handleChange1 = async_test => {
  this.setState(
    { async_test },
    () => console.log(`Option selected:`, this.state.async_test)
  );
};
  handleChange = selectedOption => {
    console.log("d",selectedOption.value);
    this.setState(
      { selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
    if(this.state.password.value == ""){
      toast.error("Please enter password", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if(this.state.email.value == ""){
      toast.error("Please enter email", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if(this.state.experties.value == ""){
      toast.error("Please enter Skill /Specialization", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    const exp = this.state.selectedOption;
    const skills = this.state.experties.value;
    const fname = this.state.fname.value;
    const lname = this.state.lname.value;
    const email = this.state.email.value;
    const password = this.state.password.value;
    //const lname = this.state.lname;
    const designation = this.state.designation.value;
   
    const fid = this.state.fid;
    
    if (this.state.fid.value==="") {
      toast.error("Please wait ..!", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    const phone =  this.state.phone;
    const locationRow = this.state.location;
    const location = locationRow.suggestion.name+','+locationRow.suggestion.administrative+','+locationRow.suggestion.country;
    if (password==="" && location==="" && skills==="" && exp==="" && email==="" && fname==="") {
      toast.error("Required Fields..!", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    console.log("fname",fname);
    console.log("lname",lname);
    console.log("skills",skills);
    console.log("exp",exp);
    console.log("selectedOption",exp);
    console.log("designation",designation);
    console.log("fid",fid);
    console.log("phone",phone);
    console.log("location",location);
    console.log("email",email);
    console.log("pass",password);
   /* let values = skills.map(function(skill){
      return skill.value;
    });*/
    //https://live-corp2corp.pantheonsite.io/services/register-candidate
    //alert(values);
    //alert("sas");
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const logout = StorageManager.instance.getLogoutToken();
    //const dispatch = useDispatch();


    //UserManager.logout1();
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token':csrf
    }
    
    const data = {
      "first_name": fname,
      "last_name": lname,
      "email": email,
      "location": location,
      "designation": designation,
      "experience":exp,
      "mobile_number": phone,
      "password": password,
      "skills":skills,
      "resume_fid": fid

    };
    
    console.log(data);
 
    axios.post(`https://live-corp2corp.pantheonsite.io/services/register-medico`, data,{
      headers: headers
    })
    .then(response => { 
      console.log('succ',response)
      if(response.data.status === 0){
        toast.error("Email id already in use!", {
          position: toast.POSITION.TOP_RIGHT
        });
       
        return;
      }
      toast.success("Successfully Submitted !", {
        position: toast.POSITION.TOP_RIGHT
      });
      this.setState({
        ThankYou: true
    })
      
      /*const data = {
        "name": email,
        "pass":password,
        withCredentials: true,
  
      };
      axios.post(`https://live-corp2corp.pantheonsite.io/user/login?_format=json`, data,{
        headers: headers,
       withCredentials: true
      })
      .then(res => {
        console.log("dsaasd",res);
     
        // StorageManager.instance.setAccessToken(result.access_token);
        //StorageManager.instance.setBasicToken('aGFuaXNoQHdpbm5vcHJvLmNvbTox');
        StorageManager.instance.setBasicToken(btoa(`${email}:${password}`));
        StorageManager.instance.setUid(res.data.current_user.uid);
        StorageManager.instance.setXCSRFToken(res.data.csrf_token);
        StorageManager.instance.setLogoutToken(res.data.logout_token);
        StorageManager.instance.setAccessToken(res.data.access_token);
         //Not in the array
        console.log("Success1employer",res.data);

          //Not in the array
          console.log("Success1employer",res.data.current_user.roles);
          window.location.replace("onboarding/1");
        //console.log(res.data);
       // window.location.reload();
      })*/
      {/*const data = {"flag_id":"recruiter" ,"entity_type":"user", "entity_id":response.data.uid,"uid":uid};
      axios.post(`https://live-corp2corp.pantheonsite.io/api/flag?_format=json`, data,{
          headers: headers
      })
      .then(res => {
          console.log(res.data);
          return <Redirect to='/my-candidatesList'/>;
         
        })
        */}
       // const username = event.target.email.value;
        //const password = event.target.password.value
        //const loginAction = (username, password) => {
         
       // };
    })
  }
  render() {
    const { selectedOption } = this.state;
    const getUploadParams = (file) => {
      this.setState({
        buttonText: "Wait Resume Uploading ..."
    })
      console.log("sasas",file);
      return { url: 'https://httpbin.org/post' }
    }
  
    const handleChangeStatus = ({file, meta, remove }, status) => {
      
      if (status === 'headers_received') {
        console.log(meta)

        const token = StorageManager.instance.getAccessToken();
        const csrf = StorageManager.instance.getXCSRFToken();
        const uid = StorageManager.instance.getUid();
        console.log(file.name);
        const fileName = file.name;
        const headers = {
          'Content-Type': 'application/octet-stream',
          'Authorization': 'basic aGFuaXNoQHdpbm5vcHJvLmNvbTox',
          'X-CSRF-Token':csrf,
          'Content-Disposition':`file;filename="${fileName}"`
        }
        console.log(headers);
      
        axios.post(`https://live-corp2corp.pantheonsite.io/file/upload/node/candidate_details/field_resume?_format=hal_json`, file ,{
          headers: headers
        })
        .then(res => {
          localStorage.setItem('fid', res.data.fid[0].value);
          this.setState({fid:res.data.fid[0].value});
          console.log("File Upload",res.data.fid[0].value);
          this.setState({
            upload: true
        })
       
        })
        //remove()
      } else if (status === 'aborted') {
        console.log(`${meta.name}, upload failed...`)
      }
    }
    return (
      <MainContainer>
                <MDBView>
          <MDBMask overlay='indigo-strong' />
          <MDBContainer
            style={{ height: '100%',minHeight:'500px', width: '100%', paddingTop: '3rem' }}
            className='d-flex justify-content-center align-items-center'
          >
            <MDBRow>
              
              {/*<div className='white-text text-center text-md-left col-md-6 mt-xl-5 mb-5 candidate-r'>
                <h1 className='display-8 font-weight-bold'>Register </h1>
                <hr className='hr-light' />
                <h6 className='mb-4'>
                Register in Corp2Corp.Jobs and get connected with 1000s of our clients/recruiters who are on hiring spree. 
                </h6>
                <h1 className='display-8 font-weight-bold'>Complete Your Profile  </h1>
                <hr className='hr-light' />
                <h6 className='mb-4'>
                Fill your complete details and help our clients/recruiters find you with your skills.     
                </h6>
                <h1 className='display-8 font-weight-bold'>  Upload Resume  </h1>
                <hr className='hr-light' />
                <h6 className='mb-4'>
                Upload your updated resume and keep it up to date.   
                </h6>
                <h1 className='display-8 font-weight-bold'>   Find Your Job    </h1>
                <hr className='hr-light' />
                <h6 className='mb-4'>
                Find and apply to jobs seamlessly and reach to our clients/recruiters on the go.  
                </h6>
               
              </div>
    */}
              <MDBCol md='12'  className='mb-4'>
                <MDBCard className='dark-grey-text candidate-register'>
                  <MDBCardBody className='z-depth-2'>
 
      <div>
      {!this.state.ThankYou ?
      <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>
            <MDBCol md="6" className="mb-3">
              <label
                htmlFor="defaultFormRegisterNameEx"
                className="grey-text"
              >
                First name
              </label>
              <input
                value={this.state.fname.value}
                className={this.state.fname.valid ? "form-control" : "form-control"}
                name="fname"
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterNameEx"
                placeholder="First name"
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
            <MDBCol md="6" className="mb-3">
              <label
                htmlFor="defaultFormRegisterEmailEx2"
                className="grey-text"
              >
                Last name
              </label>
              <input
                value={this.state.lname.value}
                className={this.state.lname.valid ? "form-control" : "form-control"}
                name="lname"
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterEmailEx2"
                placeholder="Last name"
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
            </MDBRow>
            <MDBRow>
            <MDBCol md="6" className="mb-3">
              <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="grey-text"
              >
                Email
              </label>
              <input
                value={this.state.email.value}
                className={this.state.email.valid ? "form-control" : "form-control"}
                onChange={this.changeHandler}
                type="email"
                id="defaultFormRegisterConfirmEx3"
                name="email"
                placeholder="Your Email address"
                required
              />
               <div className="invalid-feedback">
                Please provide a valid designation.
              </div>
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
           
            
            <MDBCol md="6" className="mb-3">
            <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                Passoword
              </label>
              <input
                value={this.state.password.value}
                className={this.state.password.valid ? "form-control" : "form-control"}
                onChange={this.changeHandler}
                type="password"
                id="defaultFormRegisterPasswordEx4"
                name="password"
                placeholder="Password"
                required
              />
              <div className="invalid-feedback">
                Please provide a valid password.
              </div>
              <div className="valid-feedback">Looks good!</div>
              </MDBCol>
              </MDBRow>
            <MDBRow>
            <MDBCol md="12" className="mb-3">
            <label
                htmlFor="defaultFormRegisterdesignationdEx4"
                className="grey-text"
              >
                Designation/Job Title
              </label>
              <input
                value={this.state.designation.value}
                className={this.state.designation.valid ? "form-control" : "form-control"}
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterdesignationdEx4"
                name="designation"
                placeholder="Designation"
                required
              />
              <div className="invalid-feedback">
                Please provide a valid designation.
              </div>
              <div className="valid-feedback">Looks good!</div>
              </MDBCol>
          </MDBRow>
          <MDBRow>
          <MDBCol md="6" className="mb-3">
            <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="grey-text"
              >
                Phone Number
              </label>
              <PhoneInput
                  country={'us'}
                  required
                  
                  inputProps={{
                    name: 'phone',
                    required: true,
                    //autoFocus: true
                  }}
                  inputStyle={{
                    width:"100%",
                    height:"40px"
                  }

                  }
                  value={this.state.phone}
                  onChange={phone => this.setState({ phone })}
                  
               />
            
              </MDBCol>
              <MDBCol md="6" className="mb-3">
            <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="grey-text"
              >
                Experiance
              </label>
              <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={options}
      />
             
              </MDBCol>
          
          </MDBRow>
          <MDBRow>
            <MDBCol md="12" className="mb-3">
            <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                Location
              </label>
              <AlgoliaPlaces
                              placeholder='Location'
                              variant="outlined"
                             
                      className={this.state.location.valid ? "form-control is-valid" : "form-control is-invalid"}
                              fullWidth
                              icon="user"
                              className="form-control"
                              required
                              options={{
                                appId: 'plE79E3YU95N',
                                apiKey: 'ab6d2643563c402f95aaf5dca19afa95',
                                language: 'en',
                                countries: ['us'],
                                type: 'city',
                                // Other options from https://community.algolia.com/places/documentation.html#options
                              }}
                              onChange={ location => this.setState({location}) }
                            
                            
                        
                              onSuggestions={({ rawAnswer, query, suggestions }) => 
                                console.log('Fired when dropdown receives suggestions. You will receive the array of suggestions that are displayed.')}
                        
                              onCursorChanged={({ rawAnswer, query, suggestion, suggestonIndex }) => 
                                console.log('Fired when arrows keys are used to navigate suggestions.')}
                        
                              onClear={() => 
                                console.log('Fired when the input is cleared.')}
                        
                              onLimit={({ message }) => 
                                console.log('Fired when you reached your current rate limit.')}
                        
                              onError={({ message }) => 
                                console.log('Fired when we could not make the request to Algolia Places servers for any reason but reaching your rate limit.')}
                        />
                 <div className="invalid-feedback">
                   Please provide a valid location.
                 </div>
                 <div className="valid-feedback">Looks good!</div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="12" className="mb-3">
              <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                 Skills/Specialization
              </label>
             {/* <Select
        value={this.state.async_test}
        onChange={this.handleChange1}
        options={this.Country()}
        isMulti
             />*/}
              <input
                value={this.state.experties.value}
                className={this.state.experties.valid ? "form-control" : "form-control"}
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterdesignationdEx4"
                name="experties"
                placeholder="Comma seprated.."
                required
              />
              <div className="invalid-feedback">
                Please provide a valid Skill /Specialization.
              </div>
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
            <MDBCol md="6" className="mb-3">
              <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                Resume
              </label>
              <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        maxFiles={1}
        multiple={false}
        canCancel={false}
        inputContent="Drop A File"
           accept=".pdf,.doc,.docx"
      inputContent={(files, extra) => (extra.reject ? 'Pdf, Doc and Docx files only' : 'Drag Resume')}
        styles={{
          dropzone: { width: 400, height: 100 },
          dropzoneActive: { borderColor: 'green' },
        }}
      />  
              <div className="invalid-feedback">
                Please provide a valid state.
              </div>
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
            
          </MDBRow>
          <MDBCol md="12" className="mb-3">
            <div className="custom-control custom-checkbox pl-3">
              <input
                className="custom-control-input"
                type="checkbox"
                value=""
                id="invalidCheck"
                required
              />
              <label className="custom-control-label" htmlFor="invalidCheck">
                Agree to terms and conditions
              </label>
              <div className="invalid-feedback">
                You must agree before submitting.
              </div>
            </div>
          </MDBCol>
          <div className="white-text d-flex justify-content-center align-items-center flex-column">
          {!this.state.upload ?
          <MDBBtn color="cyan" type="submit" disabled>
            {this.state.buttonText}
          </MDBBtn>
          :
          <MDBBtn color="cyan" type="submit" >
          submit
        </MDBBtn>
  }
          </div>
          {/*<MDBModalFooter className="mx-5 pt-3 mb-1">
              <div className="font-small grey-text d-flex justify-content-end">
             
             
                <Box align="center" style={{ marginTop: "1em", width: "100%" }} maxWidth="sm">
                Already member?
                    
                    <StyledLink to="/login">
                      <Button variant="outlined" fullWidth display="block" style={{ marginTop: "1em", marginBottom: "1em", width: "80%" }}>
                        <Typography variant="caption" style={{ fontFamily: "'Poppins', sans-serif", padding: "6px" }} display="inline">
                          Login
                        </Typography>
                      </Button>
                    </StyledLink>
                  </Box>
              </div>
    </MDBModalFooter>*/}
         
        </form>
        :
        <div>
        <h1 className='mb-4'>Its Done! </h1>
        <h6 className='mb-4'>Thanks for registering with us.</h6>
        <h6 className='mb-4'>We have received your profile and resume and will get back soon. Please note that all information received will always remain confidential. Our team will start working on getting you a right opportunity.</h6> 
       <h6 className='mb-4'> Best Wishes!
        </h6>
        </div>

        
  }

      </div>
      </MDBCardBody>
      </MDBCard>
      </MDBCol>
      </MDBRow>
      </MDBContainer>
      </MDBView>
      </MainContainer>
    );
  }
}

export default MedicoRegistration;