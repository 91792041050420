import React from "react";
import { Typography } from "@material-ui/core";
import lodash from "lodash";
import AppButton from "../Buttons";
import useMasterList from "utils/useMasterList";
const TagList = ({ title, list = [], isSkills = true }) => {
  const { skills } = useMasterList();
  return (
    <div>
      <Typography>{title}</Typography>
      {lodash.isArray(list) &&
        list
          .filter(item => {
            return lodash.find(skills, "id", item);
          })
          .map(item => {
            return (
              <AppButton key={item} style={{ padding: "0px", fontSize: "0.5rem", margin: "0 4px" }}>
                {lodash.find(skills, "id", item).name}
              </AppButton>
            );
          })}
      {lodash.isString(list) &&
        list.split(",").map(item => {
          return (
            <AppButton key={item} style={{ padding: "0px", fontSize: "0.5rem", margin: "0 4px" }}>
              {item}
            </AppButton>
          );
        })}
    </div>
  );
};

export default TagList;
