import React, { Component } from 'react';
import { render } from 'react-dom';
import QueryString from 'query-string';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import LinkedInPage from './LinkedInPage';

class Demo1 extends Component {
    
  render() {
    const params = QueryString.parse(window.location.search);
    if (params.code || params.error) {
      return (
        <LinkedInPopUp />
      );
    }
    return (
      <LinkedInPage onLoginSuccess={this.props.onLoginSuccess} btnStyle={this.props.btnStyle}/>
    );
  }
}

export default Demo1;