import API from "services/API";
import endpoints from "services/endpoints";
import StorageManager from "utils/StorageManager";

export class JobService {
  static async getJobList(uid, offset) {
    const data = { uid, offset };
    return API.execute(endpoints.getJobList, "post", { data }, false, false);
  }
  static async getJobApplied(uid) {
    const data = { uid };
    return API.execute(endpoints.getJobApplied, "post", { data }, false, false);
  }
  static async getJobSaved(uid) {
    const data = { uid };
    return API.execute(endpoints.getJobSaved, "post", { data }, false, false);
  }

  static async getJobDetail(jobId, uid) {
    const data = { job_id: jobId, uid };
    return API.execute(endpoints.getJobDetail, "post", { data });
  }

  static async getMasterList(type) {
    const data = { type };
    return API.execute(endpoints.getMasterList, "post", { data }, false, false);
  }

  static async updateBasicInfo(info) {
    const data = { ...info };
    return API.execute(endpoints.updateCandidateBasicInfo, "post", { data });
  }

  static async createJob(type) {
    const data = { type };
    return API.execute(endpoints.createJob, "post", { data });
  }

  static async getCandidateDetails(uid) {
    const data = { uid };
    return API.execute(endpoints.getCandidateDetails, "post", { data });
  }

  static async search(title, location, categories, skills, job_type) {
    const data = { title, location, categories, skills, job_type };
    return API.execute(endpoints.search, "post", { data });
  }

  static async updateEducation(values) {
    const data = { ...values };
    return API.execute(endpoints.updateEducation, "post", { data });
  }

  static async updateWorkHistory(values) {
    const data = { ...values };
    return API.execute(endpoints.updateWorkHistory, "post", { data });
  }

  static async updateAdditionalInfo(values) {
    const data = { ...values };
    return API.execute(endpoints.updateAdditionalInfo, "post", { data });
  }

  static async flagJob(jobId) {
    const data = {
      entity_id: [jobId],
      entity_type: ["node"],
      flag_id: [{ target_id: "apply_job", target_type: "flag" }],
      uid: [StorageManager.instance.getUid()]
    };
    const params = { _format: "json" };
    return API.execute(endpoints.flagJob, "post", { data, params });
  }

  static async uploadResume(file, onUploadProgress) {
    const data = file;
    const params = {
      _format: "hal_json"
    };
    const headers = {
      "Content-Disposition": `file;filename="${file.name}"`,
      "Content-Type": "application/octet-stream",
      "Authorization":"basic aGFuaXNoQHdpbm5vcHJvLmNvbTox"
    };
    return API.execute(endpoints.uploadResume, "post", { data, headers, params, onUploadProgress }, true, true, 'aGFuaXNoQHdpbm5vcHJvLmNvbTox');
  }
}
