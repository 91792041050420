export default class StorageManager {
  static instance = new StorageManager();

  setAccessToken = token => {
    localStorage.setItem("access_token", token);
  };

  setRefreshToken = token => {
    localStorage.setItem("refresh_token", token);
  };

  setXCSRFToken = token => {
    localStorage.setItem("xcsrf_token", token);
  };
  setLogoutToken = token => {
    localStorage.setItem("logout_token", token);
  };
  setBasicToken = token => {
    localStorage.setItem("basic_token", token);
  };

  getLogoutToken = () => {
    return localStorage.getItem("logout_token");
  };
  getAccessToken = () => {
    return localStorage.getItem("access_token");
  };

  getBasicToken = () => {
    return localStorage.getItem("basic_token");
  };

  getRefreshToken = () => {
    return localStorage.getItem("refresh_token");
  };

  getXCSRFToken = () => {
    return localStorage.getItem("xcsrf_token");
  };

  getUid = () => {
    return localStorage.getItem("uid");
  };

  setUid = uid => {
    localStorage.setItem("uid", uid);
  };
}
