import React from 'react';

import axios from 'axios';
import { MDBTable, MDBTableBody, MDBTableHead,MDBBtn, MDBIcon, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBContainer } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import TopNavigation from 'components/pages/recruiter/header/topNavigation';
import SideNavigation from 'components/pages/recruiter/header/sideNavigation';
import Footer from 'components/pages/employer/header/Footer';
import StorageManager from "utils/StorageManager";

import { Link } from '@material-ui/core';

export default class PersonList extends React.Component {
  state = {
    items: []
  }
 
  
  componentDidMount() {
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+token,
        'X-CSRF-Token':csrf
      }
    axios.get('https://live-corp2corp.pantheonsite.io/api/'+uid+'/my-candidates',{
        headers: headers
      })
      .then(res => {
        const items = res.data;
        console.log("Hanish",items);
        this.setState({ items });
      })
  }
    deleteItem = item => { // bound arrow function handler
        const token = StorageManager.instance.getAccessToken();
        const csrf = StorageManager.instance.getXCSRFToken();
        const uid = StorageManager.instance.getUid();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'bearer '+token,
            'X-CSRF-Token':csrf
          }
            this.setState({ item: item });
            const data = {"flag_id":"apply_job" ,"entity_type":"node", "entity_id":item.nid,"uid":item.uid};
            axios.post(`https://live-corp2corp.pantheonsite.io/api/unflag?_format=json`, data,{
                headers: headers
              })
              .then(res => {
                
                console.log(res.data);
                window.location.reload(); 
              })
            console.log(item);
            axios.get('https://live-corp2corp.pantheonsite.io/api/my-candidates',{
                headers: headers
              })
              .then(res => {
                const items = res.data;
                console.log("Hanish",items);
                this.setState({ items });
              })
        }
acceptItem = item => { // bound arrow function handler

    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+token,
        'X-CSRF-Token':csrf
      }
        this.setState({ item: item });
        const data = {"flag_id":"short_list" ,"entity_type":"user", "entity_id":item.uid,"uid":uid};
        axios.post(`https://live-corp2corp.pantheonsite.io/api/flag?_format=json`, data,{
            headers: headers
          })
          .then(res => {
            
            console.log(res.data);
            window.location.reload(); 
          })
}
  render() {

    return (
        <div className="flexible-content">
      
          <TopNavigation />
          <SideNavigation />
          <main id="content" className="p-5">
          <MDBContainer>
                <MDBRow className="mb-4">
                        <MDBCol>
                            <MDBCard>
                                <MDBCardBody>
                                    <MDBTable responsiveXl striped>
                                    <MDBTableHead color="primary-color" textWhite>
                                        <tr>
                                        <th>Job Title</th>
                                        <th>Name</th>
                                        <th>Location</th>
                                        <th>Experiance</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Salary</th>
                                        <th>View Profile</th>
                                       
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                    
                                    { this.state.items.map(item => <tr><td>{item.job_title}</td><td>{item.name}</td><td>{item.location}</td><td>{item.experience}</td><td>{item.email}</td><td>{item.mobile}</td><td>{item.salary}</td><td> <MDBBtn href={ `/recruiter/candidate/${item.uid}` } color="primary">View Profile</MDBBtn></td>{/*<td><MDBBtn onClick={e => this.deleteItem(item)} key={item.nid} value={item.uid} tag="a" size="sm" floating color="red"> <MDBIcon icon="times" /> </MDBBtn><MDBBtn onClick={e => this.acceptItem(item)} tag="a" size="sm" floating color="green"> <MDBIcon icon="check" /> </MDBBtn></td>*/}</tr>)}
                                        
                                    </MDBTableBody>
                                    </MDBTable>
                                </MDBCardBody>  
                                </MDBCard>       
                            </MDBCol>
                    </MDBRow>
          </MDBContainer>

          </main>
       
          </div> 
     )
  }
}