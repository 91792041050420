import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import MainContainer from "../common/MainContainer";
import StorageManager from "utils/StorageManager";
import axios from 'axios';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function LinearBuffer() {
  const classes = useStyles();
  const [completed, setCompleted] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);
  const headers = {
    'Content-Type': 'application/json'
  }
  const uid = StorageManager.instance.getUid();
  const responds = axios.get('https://live-corp2corp.pantheonsite.io/my-resume/'+uid, {  headers: headers })
  .then(res => {
   console.log("kiki",res.data[0].field_description);
   const v1 = res.data[0].field_description;
   if(v1 == ""){
     
    let user = 
    {
      "tasks": {
        "import-my-file": {
          "operation": "import/url",
          "url": "https://live-corp2corp.pantheonsite.io/"+res.data[0].field_resume
        },
        "convert-my-file": {
          "operation": "convert",
          "filename":res.data[0].uid+".html",
          "input": "import-my-file",
          "output_format": "html"
        },
        "export-my-file": {
                "operation": "export/azure/blob",
                "input": "convert-my-file",
                 "storage_account": "corp2corpfile",
               // "storage_access_key": "+MSfvjxoYF/gxaTR7WLPqS0iZiFe2kjP8HHFe4dQ1kQkS5lLz/tPGP9hkfL3pw/TPGT1ZZLdZ2nj463SZj6IAg==",
               "storage_access_key": "WkmI+OO8NHUb5uVgZOu3M7elXJZoAxy0ijBL/votoabHnex5TMCo034LLPB4RlTe7dvjmvDu8Ois9vvM2Z3ELw==",
                
               "sas_token": "",
                "container": "corp2corpfile",
                "blob": ""
        }
      }
    }
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6Ijg1YWVkYzQyZjhjMDZiMzIzMjgyMTkxZWJiNjg1ZjhmYzM2NTVkZjFhZDU2YzliNjZiYjRjNjBkZmU0NTcwNmE1NjZkZTg3YzMwM2Y4N2I1In0.eyJhdWQiOiIxIiwianRpIjoiODVhZWRjNDJmOGMwNmIzMjMyODIxOTFlYmI2ODVmOGZjMzY1NWRmMWFkNTZjOWI2NmJiNGM2MGRmZTQ1NzA2YTU2NmRlODdjMzAzZjg3YjUiLCJpYXQiOjE1ODc3MjQyOTksIm5iZiI6MTU4NzcyNDI5OSwiZXhwIjo0NzQzMzk3ODk4LCJzdWIiOiI0MTg2NzA0MSIsInNjb3BlcyI6WyJ1c2VyLnJlYWQiLCJ1c2VyLndyaXRlIiwidGFzay5yZWFkIiwidGFzay53cml0ZSIsIndlYmhvb2sucmVhZCIsIndlYmhvb2sud3JpdGUiLCJwcmVzZXQucmVhZCIsInByZXNldC53cml0ZSJdfQ.INq4egGGUaPQOPvZ8dKYqxvCSuSMzbxvJk1uznpc_EtpCFTMprFoRqhKnPqjJwm3opaik5CpT0zam9Zc3fi07wmF5iQwCeG0OsDZyMmcwJcaVSsnS2N47hvj1aPjGifRk-CwueE1wEPTTaMXYpy55BgkKva6AlBGW5LKzmDlUiMXRgWLbNMSCG8UQcu379ts3VzDx2_tYPlNQn1mZbf-9wvAJYnBLXFzw6Va4Rj2mLr4qACReXn39WV3rJBXg4Zb-T3FuFyMSW9Vi-BrghxsArlxSTs6jf_v8vRCoTY_WhKtgQD-M99NZc8SOONg-Ctkzllkl8jWqHvv77_GPlkMtPNae_FUAiOJjrIQ49xeXdc1kHifw6_kZCne5BkmJsFvigXzMR2WVMNcE4Ec4vZLxsxHFOI75snei8TO_-KDio8iDnB1Imco5cKwS_aUeJwNxJEg85r-zzPm5PzLTKTUGDA96vYsr3dWHuQkAGnq7cyYZ-mN8HAw9dK1BAg4oe55d4vQikESaFLTflH1ExP7FMApC7WTYrgcIn6P-lIUobohmScQzbuYWdkjKSmz052GYF2wx3VxYHPcjOyNUUEufk1lUlsOpHv5Z52YujOLwJ6QWMA8cuQM0PY8wgXMw8Qyiabq8zmF-wpW8R5ZxiI5hR4nS3zML6301eVlNRt3jC8'
  }
  
  axios.post(`https://api.cloudconvert.com/v2/jobs`,user, {  headers: headers })
  .then(res => {
    console.log(res);
    console.log(res.data);
    window.location.replace("/resume-edit/1");
  })
   }
  })

  const progress = React.useRef(() => {});
  React.useEffect(() => {
    progress.current = () => {
      if (completed > 100) {
        setCompleted(0);
        setBuffer(10);
        return;
      } else {
       /* const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setCompleted(completed + diff);
        setBuffer(completed + diff + diff2);*/
      }
    };
  });

  React.useEffect(() => {
    function tick() {
      progress.current();
    }
    const timer = setInterval(tick, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
      <MainContainer>
    <div className={classes.root}>
      <LinearProgress variant="buffer" value={completed} valueBuffer={buffer} />
      <LinearProgress variant="buffer" value={completed} valueBuffer={buffer} color="secondary" />
    </div>
    </MainContainer>
  );
}
