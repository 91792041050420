import React from "react";
import MainContainer from "components/common/MainContainer";
import { Grid } from "@material-ui/core";
import { CardContainer, ContentGrid } from "../register/Register.styled";
import JobPostingForm from "./JobPostingForm";

const JobPostingPage = () => {
  return (
    <MainContainer>
      <Grid container style={{ backgroundColor: "#F7F7F7" }}>
        <CardContainer>
          <ContentGrid>
            <JobPostingForm />
          </ContentGrid>
        </CardContainer>
      </Grid>
    </MainContainer>
  );
};

export default JobPostingPage;
