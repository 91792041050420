
import React from "react";
import MainContainer from "components/common/MainContainer";
import { Box } from "@material-ui/core";
import "./employerContainer.scss"

const EmployerContainer = (props) => {
    return (
        <MainContainer>
            <Box className="employer-container">
                <Box className="heading">{props.heading}</Box>
                {props.children}
            </Box>
        </MainContainer>
    );
};
export default EmployerContainer;
