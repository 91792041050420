import React from 'react';

import axios from 'axios';
import { MDBTable, MDBTableBody, MDBTableHead, MDBBtn, MDBIcon, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBContainer } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import TopNavigation from 'components/pages/employer/header/topNavigation';
import SideNavigation from 'components/pages/employer/header/sideNavigation';
import Footer from 'components/pages/employer/header/Footer';
import StorageManager from "utils/StorageManager";
import { toast } from "react-toastify";
import MainContainer from "components/common/MainContainer";
import Link from '@material-ui/core/Link';
import { Paper, Grid } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import EmployerContainer from "./employerContainer/EmployerContainer";
import { UserManagementService } from "services/endpoints/usermanagement/UserManagementService";

import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import PersonIcon from '@material-ui/icons/Person';
import Tooltip from '@material-ui/core/Tooltip';
import './myjobs.scss';


export default class PersonList extends React.Component {
  state = {
    persons: []
  }
  handleDelete = (event) => {
    event.preventDefault();
    let jobId = event.currentTarget.getAttribute("data-job-id");
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const basic = StorageManager.instance.getBasicToken();
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'basic ' + basic,
      //'X-CSRF-Token':csrf
    }
    axios.delete('https://live-corp2corp.pantheonsite.io/node/' + jobId + '?_format=hal_json', {
      headers: headers
    })
      .then(res => {
        toast.success("Successfully Deleted !", {
          position: toast.POSITION.TOP_RIGHT
        });
        const token = StorageManager.instance.getAccessToken();
        const csrf = StorageManager.instance.getXCSRFToken();
        const uid = StorageManager.instance.getUid();
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
          'X-CSRF-Token': csrf
        }
        axios.get('https://live-corp2corp.pantheonsite.io/api/' + uid + '/my-jobs', {
          headers: headers
        })
          .then(res => {
            const persons = res.data;
            
            this.setState({ persons });
          })
        //this.props.history.push('/employer-dashboard');
      })
  }

  handleUpdateState = (event) => {
    let jobId = event.currentTarget.getAttribute("data-job-id");
    let jobItem = this.state.persons.find(p => p.nid === jobId);
    const uid = StorageManager.instance.getUid();
    let request = {
        uid: uid,
        job_id: jobItem.nid,
        state: jobItem.state === "Approved" ? "Archived" : "Approved"
    }
    UserManagementService.updateJobState(request).then((res) => {
      let newState =this.state.persons.map(p => {
        return p.nid === jobId ? { ...p, state: request.state } : p
      });
      this.setState({ persons: newState });
      toast.success(res.message);
    })
  }

  async componentDidMount() {
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + token,
      'X-CSRF-Token': csrf
    }
    await axios.get('https://live-corp2corp.pantheonsite.io/api/' + uid + '/my-jobs', {
      headers: headers
    })
      .then(res => {
        const persons = res.data;
        this.setState({ persons });
      })
  }

  render() {
    if (this.props.isDashboard) {
      if(!this.state.persons || this.state.persons.length === 0){
        return <div>Please  post your first job. <Link href={"job-posting"} style={{ color: "black" }}>Click here</Link></div>
      } else {
        { return this.state.persons.slice(0,5).map(person => {
          return <Grid container  className="my-jobs-dashboard-item">
              <Grid item xs={12} md={8} className="job-title">
                {person.title}
              </Grid>
                <Grid item xs={12} md={4} className="job-actions">
                  <Link href={"/job/" + person.nid}><VisibilityIcon /></Link>
                  <Link href={"/edit/" + person.nid} style={{ paddingLeft: "20px" }}><EditIcon /></Link>
                  <Link onClick={this.handleDelete} data-job-id={person.nid}><DeleteForeverIcon /></Link>
                  {person.state ==="Approved"?
                    <Link onClick={this.handleUpdateState}  data-job-id={person.nid}><PauseIcon /></Link>:""
                  } 
                  {person.state ==="Paused" ?
                    <Link onClick={this.handleUpdateState}  data-job-id={person.nid}><PlayArrowIcon /></Link>:""
                  }
                </Grid>
          </Grid>
        })}
      }
    } else {
      if (!this.state.persons || this.state.persons.length === 0) return <EmployerContainer heading="Manage Jobs"><div className="flexible-content">

      <Paper className="no-posts"><Typography>Please  post your first job. <Link href={"job-posting"} style={{ color: "black" }}>Click here</Link></Typography></Paper></div ></EmployerContainer >;
      return (
        <EmployerContainer heading="Manage Jobs">
          <div className="flexible-content">

            {this.state.persons.map(person => {
              return <Paper elevation={3} className="job-tile">
                <Grid container>
                  <Grid item xs={12} md={6} lg={8}>
                    <div className="job-status sm-only"><span className={person.state}>{person.state}</span></div>
                    <div className="job-id">Job Id&nbsp;:&nbsp; {person.nid}</div>
                    <div className="job-title">{person.title}</div>
                    <div className="job-skills">
                      {person.skills? person.skills.split(',').map(s => <span>{s}</span>):<span>No Skills</span>}
                    </div>
                    <div className="job-location"><LocationOnIcon />{person.field_job_location}</div>
                    <div className="job-created-dt"><WatchLaterIcon />{person.created}</div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} className="action-grid">
                    <div className="job-status lg-only"><span className={person.state}>{person.state}</span></div>
                    <div className="job-actions">
                    <Tooltip title="View Profile"><Link href={"/job/"+person.nid}><VisibilityIcon /></Link></Tooltip>
                    <Tooltip title="Edit"><Link href={"/edit/" + person.nid} style={{ paddingLeft: "20px" }}><EditIcon /></Link></Tooltip>
                    <Tooltip title="Delete"><Link onClick={this.handleDelete}><DeleteForeverIcon /></Link></Tooltip>
                    {person.state ==="Approved"?
                      <Tooltip title="Archive"><Link onClick={this.handleUpdateState}  data-job-id={person.nid}><PauseIcon /></Link></Tooltip>:""
                    } 
                    {person.state ==="Paused" ?
                      <Tooltip title="Publish"><Link onClick={this.handleUpdateState}  data-job-id={person.nid}><PlayArrowIcon /></Link></Tooltip>:""
                    }
                    </div>
                    <div className="job-counts">
                      <span><VisibilityIcon fontSize="large" style={{fontSize:"24px"}}/>0</span>
                      <span><Link style={{color:"black"}} href= {"/My-candidates?job_id="+person.nid}><PersonIcon fontSize="large"  style={{fontSize:"24px",color:"black"}}/>{person.count}</Link></span>
                    </div>
                  </Grid>
                </Grid>

              </Paper>
            })
            }
          </div>
        </EmployerContainer>
      )
    }
    
  }
}