import React from "react";
import { Formik, Form, ErrorMessage, Field, FastField } from "formik";
import * as Yup from "yup";
import { FormikTextInput } from "components/common/FormInputs/FormikInputs";
import LockIcon from "@material-ui/icons/Lock";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { Button, Select, MenuItem, InputLabel, FormControl, LinearProgress, Grid } from "@material-ui/core";
import { StyledLink } from "components/common/Misc";
const RegisterForm = ({ registerAction, loading, data, error }) => {
  const roles = [
    { name: "Candidate", value: "candidate" },
    { name: "Recruiter", value: "recruiter" },
    { name: "Employer", value: "employer" }
   
  ];
 
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        role: "employer"
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required("Please enter your email"),
        password: Yup.string().required("Please enter your password"),
        role: Yup.string().required("Please select your role")
      })}
      onSubmit={(values, actions) => {
        console.log("Values:", values);
        actions.resetForm();
        if (registerAction) {
          registerAction(values.email, values.password, values.role);
        }
      }}
    >
      {({ errors, touched, handleSubmit, isSubmitting }) => {
        return (
          <>
            <Form name="register" method="post" onSubmit={handleSubmit}>
              <Grid container>
                <FastField
                  style={{ marginTop: "1em" }}
                  name="email"
                  component={FormikTextInput}
                  fullWidth
                  id="email"
                  size="small"
                  placeholder="Email"
                  variant="outlined"
                  Icon={MailOutlineIcon}
                />

                <FastField
                  style={{ marginTop: "1em" }}
                  name="password"
                  component={FormikTextInput}
                  type="password"
                  fullWidth
                  id="password"
                  size="small"
                  placeholder="Password"
                  variant="outlined"
                  Icon={LockIcon}
                />
             
                <Button
                  type="submit"
                  fullWidth
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  style={{ fontFamily: "'Poppins', sans-serif", marginTop: "1em", color: "white" }}
                >
                  Register
                </Button>
                {loading && <LinearProgress />}
              </Grid>
            </Form>
            {/* <pre>
              <strong>Errors:</strong> {JSON.stringify(errors, null, 2)}
              <strong>Touched:</strong> {JSON.stringify(touched, null, 2)}
              {formValues && <strong>Submitted values:</strong>}
              {JSON.stringify(formValues, null, 2)}
            </pre> */}
          </>
        );
      }}
    </Formik>
  );
};

export default RegisterForm;
