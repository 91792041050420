import StorageManager from "utils/StorageManager";
import API from "services/API";
import endpoints from "services/endpoints";

export class CommonService {
  static async getCategories() {
    return API.execute(endpoints.getCategories, "get", {});
  }

  static async getJobInfo() {
    return API.execute(endpoints.getJobInfo, "get", {});
  }

  static async getXCSRFToken() {
    return API.execute(endpoints.getXcsrfToken, "get", {}).then(result => {
      StorageManager.instance.setXCSRFToken(result);
      return result;
    });
  }
}
