import React, { useState, useEffect } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import assets from "../../../../assets";
import CategoryCarousel from "./CategoryCarousel";
import JobList from "../JobList";
import lodash from "lodash";
import { getCategoriesAction } from "services/endpoints/common/commonActions";
import { StyledLink } from "components/common/Misc";

const useStyles = makeStyles(theme => ({
  header: {
    color: "#707070",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "23px",
    paddingTop: "80px",
    textAlign: "center",
    paddingBottom: "0px",
    marginTop: "0px"
  },
  categorySectionContainer: {
    backgroundColor: "#EAF6FA"
  },
  viewAllButton: {
    backgroundColor: "#13C1D6",
    color: "white",
    paddingLeft: "50px",
    paddingRight: "50px",
    borderRadius: "25px",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "bold"
  },
  viewAllButtonContainer: {
    textAlign: "center",
    padding: "62px 0px 75px 0px"
  }
}));

const CategorySection = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, data, error } = useSelector(state => state.common.categories);
  useEffect(() => {
    dispatch(getCategoriesAction());
  }, []);
  const data1 = {"status":1,"msg":"success","data":[{"tid":"13","name":"Information Technology","image_url":"https:\/\/dev-corp2corp.pantheonsite.io\/sites\/default\/files\/2020-05\/001-laptop%402x_0.png","total_jobs":5086},{"tid":"14","name":"Health Care","image_url":"https:\/\/dev-corp2corp.pantheonsite.io\/sites\/default\/files\/2020-05\/002-stethoscope%402x.png","total_jobs":4450},{"tid":"15","name":"Public administration","image_url":"https:\/\/dev-corp2corp.pantheonsite.io\/sites\/default\/files\/2020-05\/003-team%402x.png","total_jobs":3068},{"tid":"16","name":"Education Services","image_url":"https:\/\/dev-corp2corp.pantheonsite.io\/sites\/default\/files\/2020-05\/004-online-course%402x.png","total_jobs":2256},{"tid":"17","name":"Wholesale and retail trade","image_url":"https:\/\/dev-corp2corp.pantheonsite.io\/sites\/default\/files\/2020-05\/004-online-course%402x.png","total_jobs":5309},{"tid":"18","name":"Manufacturing","image_url":"https:\/\/dev-corp2corp.pantheonsite.io\/sites\/default\/files\/2020-05\/006-manufacturing%402x.png","total_jobs":3917},{"tid":"19","name":"Leisure and Hospitality","image_url":"https:\/\/dev-corp2corp.pantheonsite.io\/sites\/default\/files\/2020-05\/007-hospitality%402x.png","total_jobs":5551},{"tid":"20","name":"Construction","image_url":"https:\/\/dev-corp2corp.pantheonsite.io\/sites\/default\/files\/2020-05\/008-crane%402x.png","total_jobs":5937},{"tid":"21","name":"Financial activities","image_url":"https:\/\/dev-corp2corp.pantheonsite.io\/sites\/default\/files\/2020-05\/009-cash-flow%402x.png","total_jobs":9642},{"tid":"22","name":"Transportation and utilities","image_url":"https:\/\/dev-corp2corp.pantheonsite.io\/sites\/default\/files\/2020-05\/010-airplane%402x.png","total_jobs":5363},{"tid":"23","name":"Other services","image_url":"https:\/\/dev-corp2corp.pantheonsite.io\/sites\/default\/files\/2020-05\/Group%20416%402x.png","total_jobs":1310}]};
  return (
    <div className={classes.categorySectionContainer}>
      {/*<p className={classes.header}>Browse by Category</p>
      <CategoryCarousel categories={data1.data} />*/}
      <p className={classes.header}>Recently Posted Jobs</p>
      <JobList />
      <div className={classes.viewAllButtonContainer}>
        <StyledLink to="/jobs">
          <Button variant="contained" className={classes.viewAllButton}>
            VIEW ALL
          </Button>
        </StyledLink>
      </div>
    </div>
  );
};

export default CategorySection;
