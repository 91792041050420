import React from "react";
import { Grid, Paper, Typography, Button, Box } from "@material-ui/core";
import { Formik, Form, ErrorMessage, Field, FastField } from "formik";
import * as Yup from "yup";
import { FormikTextInput } from "components/common/FormInputs/FormikInputs";
import "./ResetPassword.scss";

const SetPassword = (props) => {
 
    const handleFormSubmit = (values, actions) => {
      const  action="reset_password";
        props.onSubmit(values,action)
    }

    return (
      <>
        <Grid className="header-reset" xs={12}>
          <h2>Reset Password</h2>
        </Grid>

        <Grid className="content" xs={12}>
          <Formik
            initialValues={{
              password: "",
              confirmPassword:""
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().required(
                "Please enter your new password"                
              ),
              confirmPassword: Yup.string()
              .oneOf([Yup.ref('password'), null], 'Passwords must match')
            })}
            onSubmit={handleFormSubmit}
          >
            {({ errors, touched, handleSubmit, isSubmitting }) => {
              return (
                <>
                  <Form
                    name="reset-password"
                    method="post"
                    onSubmit={handleSubmit}
                  >
                    <FastField
                      style={{ marginBottom: "38px" }}
                      type="password"
                      name="password"
                      component={FormikTextInput}
                      fullWidth
                      id="password"
                      size="small"
                      labelText="Enter new password"
                      placeholder="Enter your new password"
                      variant="outlined"
                    />

                     <FastField
                      style={{ marginBottom: "90px" }}
                      type="password"
                      name="confirmPassword"
                      component={FormikTextInput}
                      fullWidth
                      id="confrimPassword"
                      size="small"
                      labelText="Re-enter password"
                      placeholder="Re-enter password"
                      variant="outlined"
                    />                    

                    <Button
                      type="submit"
                      fullWidth
                      //disabled={loading}
                      variant="contained"
                      color="primary"
                    >
                      SUBMIT
                    </Button>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Grid>
      </>
    );
  };

export default SetPassword;
