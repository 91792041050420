import React from "react";
import { MDBListGroup, MDBListGroupItem, MDBContainer } from "mdbreact";
import JobListItem from "./JobListItem";
import lodash from "lodash";
import List from "@material-ui/core/List";
import { JobListContainer } from "../JobListingPage.styled";
const JobSaved = ({ list, selected, onItemSelect }) => {
  return (
    <JobListContainer>
      {lodash.isArray(list) &&
        list.map(item => {
          return (
            <JobListItem
              item={item}
              isActive={lodash.isEqual(item, selected)}
              onClick={() => {
                onItemSelect && onItemSelect(item);
              }}
            />
          );
        })}
    </JobListContainer>
  );
};

export default JobSaved;
