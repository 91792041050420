import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainContainer from "components/common/MainContainer";
import PersonalDetails from "./PersonalDetails";
//import { Container, Grid } from "@material-ui/core";
import AdditionalInformation from "./AdditionalInformation";
import Certifications from "./Certifications";
import Skills from "./Skills";
import DoneTwoToneIcon from '@material-ui/icons/DoneTwoTone';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Typography, Grid,Hidden, Paper, Divider, Box } from "@material-ui/core";
import IndeedResume from "./IndeedResume";
import WorkExperience from "./WorkExperience";
import { useParams, useHistory } from "react-router";
import { useSteps, OnBoarding } from "./useSteps";
import { getSkillsAction, getIndustriesAction, getCandidateDetailsAction } from "services/endpoints/jobs/jobActions";
import StorageManager from "utils/StorageManager";
import useMasterList from "utils/useMasterList";
import { useLocation } from "react-router-dom";
import lodash from "lodash";
const OnBoardingPage = () => {
  const location = useLocation();
  const [isRecruiter, setIsRecruiter] = useState(lodash.includes(location.pathname, "add-candidate"));
  const uid = StorageManager.instance.getUid();
  const { step } = useParams();
  const history = useHistory();
  const steps = useSteps(step || 1, 4);
  const [state] = steps;
  console.log("Location", location.pathname);
  useEffect(() => {
    if (isRecruiter) {
      history.push(`/add-candidate/${state.step}`);
    } else {
      history.push(`/user/edit/onboarding/${state.step}`);
    }
  }, [state.step]);

  useEffect(() => {
    setIsRecruiter(lodash.includes(location.pathname, "add-candidate"));
  }, [location.pathname]);
  const dispatch = useDispatch();
  const { skills, industries } = useMasterList();
  const { data: candidateData } = useSelector(state => state.job.candidate);
  useEffect(() => {
    dispatch(getCandidateDetailsAction(uid));
  }, []);

  const getCurrentView = () => {
    switch (parseInt(step)) {
      case 1:
        return <PersonalDetails uid={uid} skills={skills} industries={industries} candidate={candidateData} isRecruiter={isRecruiter} />;
      case 2:
        return <IndeedResume candidate={candidateData} isRecruiter={isRecruiter} />;
      case 3:
        return <WorkExperience candidate={candidateData} isRecruiter={isRecruiter} />;
      // case 4:
      //   return <Skills candidate={candidateData} />;
      case 4:
        return <AdditionalInformation candidate={candidateData} isRecruiter={isRecruiter} />;
      // case 6:
      //   return <Certifications candidate={candidateData} />;
      default:
        return "Error";
    }
  };

  return (
    <OnBoarding.Provider value={steps}>
      <MainContainer>
      <Grid container spacing={1}>
          {getCurrentView()}
         </Grid>
      </MainContainer>
    </OnBoarding.Provider>
  );
};

export default OnBoardingPage;
