import React, { useState, useEffect } from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBIcon, MDBView, MDBBtn } from "mdbreact";
import MainContainer from "components/common/MainContainer";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import axios from 'axios';



const BlogPage = () => {
    const [repos, setRepos] = useState([]);
    const history = useHistory();
    useEffect(() => {
      const fetchData = async () => {
          const response = await axios.get('https://live-corp2corp.pantheonsite.io/api/blog');
          setRepos(response.data);
      }
      
      fetchData();
    }, []);
    
  return (
    <MainContainer>
          <Grid container justify="center">
                    <Grid item sm={12} md={8} xl={6}>

    <MDBCard className="my-5 px-5 pb-5">
        
      <MDBCardBody>
        <h2 className="h1-responsive font-weight-bold text-center my-5">
          Recent Blogs
        </h2>
        <p className="text-center w-responsive mx-auto mb-5">
          {/*Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
          cupidatat non proident, sunt in culpa qui officia deserunt mollit
          anim id est laborum.*/}
        </p>


      {repos.map(repo =>
      <div>
   
   <MDBRow>
          <MDBCol lg="5" xl="4">
            <MDBView hover className="rounded z-depth-1-half mb-lg-0 mb-4">
              <img
                className="img-fluid"
                src={"https://live-corp2corp.pantheonsite.io/"+repo.field_image}
                alt=""
              />
              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>
          </MDBCol>
          <MDBCol lg="7" xl="8">
            <h3 className="font-weight-bold mb-3 p-0">
      <strong>{repo.title}</strong>
            </h3>
            <p className="dark-grey-text">
             {repo.body}
            </p>
            <p>
      by <a href="#!" className="font-weight-bold">{repo.uid}</a>, {repo.created}
            </p>
            <MDBBtn color="primary" size="md" onClick={event => history.push(repo.view_node)}>
              Read More
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <hr className="my-5" />
  
      </div>
      )}

     
  
    
      
           </MDBCardBody>
    </MDBCard>
    </Grid></Grid>
    </MainContainer>
  );
}

export default BlogPage;