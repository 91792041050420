import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import edit from "assets/images/edit.png";
import "./jobPosting.scss";

//import assets from "assets";

import Hidden from '@material-ui/core/Hidden';

import TagList from "components/common/TagList/TagList";
import lodash from "lodash";

import IconButton from "@material-ui/core/IconButton";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';


//import { Grid, Hidden, Paper, Container, Typography, TextField, FormControl, InputAdornment, Icon, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import assets from "../../../assets";
import { Image, Root, VerticalCenter, ImageGrid, LoginCard } from "../login/LoginPage.styled";
import MyAppBar from "../../common/myappbar/MyAppBar";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { TextInput } from "../../common/FormInputs";
import LockIcon from "@material-ui/icons/Lock";
import TopNavigation from 'components/pages/employer/header/topNavigation';
import SideNavigation from 'components/pages/employer/header/sideNavigation';
import Footer from 'components/pages/employer/header/Footer';
import SvgIcon from "@material-ui/core/SvgIcon";
import { ImageIcon, StyledLink } from "components/common/Misc";
import { authenticateAction, registerAction, generateOtpAction, verifyOtpAction,socialLoginAction } from "services/endpoints/usermanagement/userManagementActions";
import CKEditor from "react-ckeditor-component";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import ReactTags from 'react-tag-autocomplete'
import CurrencyFormat from 'react-currency-format';
import SocialLogin from "components/common/SocialLogin/SocialLogin";
import UserManager from "utils/UserManager";
import StorageManager from "utils/StorageManager";
import MainContainer from "../../common/MainContainer";
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import AlgoliaPlaces from 'algolia-places-react';
import Select from 'react-select';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader';
import { colourOptions } from './data';
import { withStyles } from "@material-ui/core/styles";
import EmployerContainer from "./employerContainer/EmployerContainer";
import './jobPosting.scss';

const styles = theme => ({
  skills:{
    backgroundColor:"white"
  },
  header1: {
    fontWeight: "bold",
    fontFamily: "'Poppins', sans-serif",
    margin: "0px",
    overflow: "hidden",
    fontSize: "0.85em"
  },
  sshare:{
padding:"33px !important"
  },
  jobApplyButton1: {
   
    fontSize: "0.7em",
    marginTop: "0px",
    fontFamily: "'Poppins', sans-serif",
    textTransform: "none",
    padding: "0px",
  },
  jobApplyButton: {
  backgroundColor: "#13C1D6",
  color: "white",
  },
  keyskill:{
    color: "#BABABA",
    display: "inline-block",
    fontSize: "0.8em",
    lineHeight: "1em",
    padding: "2px 8px",
    outline: "none",
    marginTop: "2px",
    cursor: "pointer",
    marginRight: "2px",
    borderRadius: "10px",
    border: "1px solid #BABABA",
    fontFamily: "'Poppins', sans-serif",
    "&:hover": {
      borderColor: `${theme.palette.primary.main}`,
      color: `${theme.palette.primary.main}`
    },
 
  },
});

import {  
  
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBModalFooter,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBView,
  MDBContainer,
  MDBFormInline,
  MDBCard,
  MDBCardBody,
  MDBModalBody,
  MDBModalHeader,
 
  MDBModal,
  location,
  MDBInput,
   MDBIcon } from "mdbreact";





import {Helmet} from "react-helmet";
import {
  EmailIcon,
  FacebookIcon,
  FacebookShareCount,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  FacebookShareButton,
  WorkplaceIcon,
} from "react-share";
//import { Grid } from "@material-ui/core";
import { Grid, Paper, Typography, Button, Divider } from "@material-ui/core";

const options = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10+', label: '10+' },
  { value: '15+', label: '15+' },
  { value: '20+', label: '20+' },

];

const Salaryoptions = [
  { value: 'Monthly', label: 'Monthly' },
  { value: 'Yearly', label: 'Yearly' },
  { value: 'Hourly', label: 'Hourly' },

];
const visa = [
  { value: '551', label: 'Cpt/opt' },
  { value: '551', label: 'Cpt/opt' },
  { value: '552', label: 'H1b' },
  { value: '553', label: 'H4 EAD' },
  { value: '554', label: 'L2 EAD' },
  { value: '555', label: 'GC' },
  { value: '556', label: 'USC' },
  { value: '557', label: 'TN' },
  { value: '558', label: 'Other' },

];
const jobType =[
  {
    label: "Full-time",
    value: "Full-time"
  },
  {
    label: "Part-time",
    value: "Part-time"
  },
  {
    label: "Contract",
    value: "Contract"
  },
  {
    label: "Internship",
    value: "Internship"
  },
  {
    label:"Fresher",
  value:"Fresher"
  },
  {
    label:"Volunteer",
    value:"Volunteer"
    },
    {
      label:"Walk-in",
      value:"Walk-in"
      }
];
const duration =[
  {
    label: "3-Months",
    value: "3-Months"
  },
  {
    label: "6-Months",
    value: "6-Months"
  },
  {
    label: "12-Months",
    value: "12-Months"
  },
  {
    label: "Long term ",
    value: "Long term"
  }
];
const edu =[
  
    {
      label: "High school or equivalent",
      value: "High school or equivalent"
    },
    {
      label: "Associate",
      value: "Associate"
    },
    {
      label: "Bachelor's",
      value: "Bachelor's"
    },
    {
      label: "Master's",
      value: "Master's"
    },
    {
      label: "Doctorate",
      value: "Doctorate"
    }
  
]
class JobPosting extends React.Component {
  constructor(props) {
    super(props);
  this.state = {

    company: "",
    radio:"Yearly",
    jobtitle: {
      value: "",
      valid: false
    },
    country: [] ,
    minsalary:"",
    maxsalary:"",
    modal14: false,
    selectedOption: null,
    selectedJobtype:null,
    selectedJobduration:null,
    selectedVaccancy:null,
    selectedtype:"Yearly",
    visaOption: null,
    PrievewData:"",
    preview:false,
    prieviewButton:false,
    selectedEdu:null,
    mincount:"",
    minxcount:"",
    async_test: [],
    job_type:[],
    duration:[],
    selectedEdu:[],
    content:'',
    email: {
      value: "",
      valid: false
    },
    skillValue:"",
    askillValue:"",
    salary: {
      value: "",
      valid: false
    },
    maxErrorS:false,
    maxErrorE:false,
    vaccno: {
      value: "",
      valid: false
    },
    maxexp: {
      value: "",
      valid: false
    },
    minexp: {
      value: "",
      valid: false
    },
    city: {
      value: "",
      valid: false
    },
    designation: {
      value: "",
      valid: false
    },
    state: {
      value: "",
      valid: false
    },
    checked:false,
    //preview:true,
    location: "",
    
    additonalSkills: {
      value: "",
      valid: false
    },
    fid: {
      value: "",
      valid: false
    },
    tags: [
      
    ],
    suggestions: colourOptions
  };
  this.handleCheck=this.handleCheck.bind(this)
}


  onClick = nr => () => {
    this.setState({
      selectedtype: nr
    });

    console.log(`Option selected for salary:`, this.state.selectedtype)
  };
  changeHandler = event => {
    if(event.target.name == "minexp"){
      
        this.setState({minxcount:event.target.value})
    
    }
    if(event.target.name == "maxexp"){
      if (event.target.value <= this.state.minexp.value){
        this.setState({maxErrorE:true})
      //console.log('event.target.value',event.target.value)
      }else{
        this.setState({maxErrorE:false})
      }
    }
    this.setState({ [event.target.name]: { value: event.target.value, valid: !!event.target.value } });
  };
 
Country() {  
    return (this.state.country.map(data => ({ label: data.label, value: data.value })))  
}  

onChange=evt=>{
  console.log("onChange fired with event info: ", evt);
  const newContent = evt.editor.getData();
  this.setState({
    content: newContent
  })
}
handleCheck(){
  this.setState({
      checked:!this.state.checked
  })
}
handleChangeVisa = visaOption => {
  console.log("d",visaOption.value);
  this.setState(
    { visaOption },
    () => console.log(`Option selected:`, this.state.visaOption)
  );
};
onChangeHandler=event=>{
  console.log(event.target.files[0]);

this.setState({ selectedFile:event.target.files[0] });
const token = StorageManager.instance.getAccessToken();
const csrf = StorageManager.instance.getXCSRFToken();
const uid = StorageManager.instance.getUid();
console.log(event.target.files[0].name);
const fileName = event.target.files[0].name;

console.log(fileName);
const headers = {
  'Content-Type': 'application/octet-stream',
  'Authorization': 'basic aGFuaXNoQHdpbm5vcHJvLmNvbTox',
  'X-CSRF-Token':csrf,
  'Content-Disposition':`file;filename="${fileName}"`
}
console.log(headers);
axios.post(`https://live-corp2corp.pantheonsite.io/file/upload/node/candidate_details/field_resume?_format=hal_json`, event.target.files[0] ,{
  headers: headers
})
.then(res => {
  localStorage.setItem('fid', res.data.fid[0].value);
  console.log("File Upload",res.data.fid[0].value);
})


}
handleChange1 = async_test => {
  this.setState(
    { async_test },
    () => console.log(`Option selected:`, this.state.async_test)
  );
};
  handleChange = selectedOption => {
    console.log("d",selectedOption.value);
    this.setState(
      { selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  handleDelete (i) {
    const tags = this.state.tags.slice(0)
    tags.splice(i, 1)
    this.setState({ tags })
  }
 
  handleAddition (tag) {
    const tags = [].concat(this.state.tags, tag)
    this.setState({ tags })
    console.log("tags",tags);
    let values = tags.map(function(skill){
      return skill.id;
    });
    let previewValue = tags.map(function(skill){
      return skill.name;
    });
    const final = previewValue.join();
    console.log("PrievewData",final);
    this.setState({PrievewData:final});


    //const sdata = Array.prototype.map.call(tags, function(item) { return item.id; }).join('","');
console.log("dtata",values);
this.setState({skillValue:values})
this.setState({askillValue:final})

  }
  handleChange2 = selectedJobtype => {
    console.log("d",selectedJobtype.value);
    this.setState(
      { selectedJobtype },
      () => console.log(`Option selected:`, this.state.selectedJobtype)
    );
  };
  handleChange3 = selectedVaccancy => {
    console.log("d",selectedVaccancy.value);
    this.setState(
      { selectedVaccancy },
      () => console.log(`Option selected:`, this.state.selectedVaccancy)
    );
  };
  // handleChangeType = selectedtype => {
  //   console.log("d",selectedtype.value);
  //   this.setState(
  //     { selectedtype },
  //     () => console.log(`Option selected:`, this.state.selectedtype)
  //   );
  // };
  handleChange4 = selectedJobduration => {
    console.log("d",selectedJobduration.value);
    this.setState(
      { selectedJobduration },
      () => console.log(`Option selected:`, this.state.selectedJobduration)
    );
  };
  handleChange5 = selectedEdu => {
    console.log("d",selectedEdu.value);
    this.setState(
      { selectedEdu },
      () => console.log(`Option selected:`, this.state.selectedEdu)
    );
  };
  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
    if(this.state.company.value == ""){
      toast.error("Please enter company name", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if(!this.state.content){
      toast.error("Please enter Job Description", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    /*if(!this.state.selectedtype || !this.state.selectedJobtype || !this.state.visaOption || !this.state.minsalary || !this.state.maxsalary || this.state.selectedJobduration){
      toast.error("Please enter missing fileds", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }*/
    const selectedJobtype1 = this.state.selectedJobtype?this.state.selectedJobtype.value:"";
    const selectedtype1 = this.state.selectedtype?this.state.selectedtype:"";
    const visaOption = this.state.visaOption?this.state.visaOption.value:"";
   const jobDuration = this.state.selectedJobduration?this.state.selectedJobduration.value:"";
    const vacancy = this.state.vaccno?this.state.vaccno.value:"";
    const minsalary = this.state.minsalary?this.state.minsalary:"";
    const maxsalary = this.state.maxsalary?this.state.maxsalary:"";
    const minexp = this.state.minexp?this.state.minexp.value:"";
    const maxexp = this.state.maxexp?this.state.maxexp.value:"";
    const educaction= this.state.selectedEdu?this.state.selectedEdu.value:"";
    const remote= this.state.checked;
    const company = this.state.company?this.state.company.value:"";
    const jobtitle = this.state.jobtitle?this.state.jobtitle.value:"";
    const additional_skills = event.target.additonalSkills?event.target.additonalSkills.value:"";
    const keyskills2 = this.state.skillValue?this.state.skillValue:"";
    const akeyskills2 = this.state.askillValue?this.state.askillValue:"";
    const content = this.state.content;
    const locationRow = this.state.location;
    console.log(locationRow);
    const location1 = !locationRow ? "empty" : locationRow;
    //const location = location1 =="empty" ? "":locationRow.suggestion.name+','+locationRow.suggestion.administrative+','+locationRow.suggestion.country;
    const location = this.state.location ? this.state.location.suggestion.name+','+this.state.location.suggestion.administrative+','+this.state.location.suggestion.country:"";
    const maxvals = maxsalary.replace(/[^\w\s]/gi, '');
    const minvals = minsalary.replace(/[^\w\s]/gi, '');
    console.log(minvals);
    console.log(maxvals);
    if (location === "") {
      toast.error("Please enter Job Location", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
  }
      /*  if(minvals > maxvals){
      toast.error("Maxmium salary should be greater than minmum salary", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }*/
    /*if(minexp > maxexp){
      toast.error("Maxmium experiance should be greater than minmum experiance", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }*/
    //return;
    //const salary = this.state.salary.value;
    console.log("company",company);
    console.log("jobtitle",jobtitle);
    console.log("remote",remote);
    console.log("type",selectedJobtype1);
    console.log("vacancy1",vacancy);
    console.log("jobDuration1",jobDuration);
    console.log("additional_skills",additional_skills);
    console.log("keyskills2",keyskills2);
    console.log("educaction1",educaction);
    console.log("selectedtype1",selectedtype1);
    console.log("minsalary",minsalary);
    console.log("maxsalary",maxsalary);
    console.log("minexp",minexp);
    console.log("maxexp",maxexp);
    console.log("content1",content);
    console.log("visaOption",visaOption);
    console.log("location",location);
    if(!keyskills2){
      toast.error("Please select valid skills from suggested list.", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

if(!company || !jobtitle || !selectedJobtype1 || !minexp || !educaction ){
      toast.error("Please enter missing fileds", {
        position: toast.POSITION.TOP_RIGHT
      });
    }else{
      //return;
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const basic = StorageManager.instance.getBasicToken();
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+basic,
        'X-CSRF-Token':csrf
      }
      
      const data = {
        "uid": uid,
        "company_name": company,
        "job_title": jobtitle,
        
        "employment_type": selectedJobtype1,
        "remote": remote,

        "selectedVaccancy":vacancy,
        "location": location,

        "salary_method": selectedtype1,
        "minsalary": minsalary,
        "maxsalary": maxsalary,
        
        "duration":jobDuration,
        "minimum_experience": minexp,
        "maximum_experience": maxexp,

        "skills":keyskills2,

        "permit": visaOption,
        "additional_skills":akeyskills2,
        "qualifications": educaction,
        //"job_id":this.props.match.params.id,
        "description":content
       
  
      };
      console.log(data);
      axios.post(`https://live-corp2corp.pantheonsite.io/services/jobs/create`, data,{
        headers: headers
      })
      .then(res => {
        toast.success("Successfully Submitted !", {
          position: toast.POSITION.TOP_RIGHT
        });
      
        console.log(res);
        console.log(res.data);
        this.props.history.push('/thank-you');
       // this.state.location("");
      
      })
    }
  return;
   /* return;

    if(this.state.company.value == ""){
      toast.error("Please enter company name", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if(!this.state.content){
      toast.error("Please enter Job Description", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if(this.state.async_test == ""){
      toast.error("Please enter skills", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if(!this.state.selectedOption){
      toast.error("Please enter Experiance", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if(!this.state.selectedVaccancy){
      toast.error("Please enter Vaccancy", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if(!this.state.selectedJobduration){
      toast.error("Please enter Duration", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if(!this.state.selectedJobtype){
      toast.error("Please enter Job Type", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if(!this.state.salary.value){
      toast.error("Please enter Salary", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if(!this.state.location){
      toast.error("Please enter Job Location", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    const exp = this.state.selectedOption.value;
    const skills = this.state.async_test;
    const keyskills1 = this.state.skillValue;
    const selectedJobtype = this.state.selectedJobtype.value;
    const jobDuration = this.state.selectedJobduration.value;
    const vacancy = this.state.selectedVaccancy.value;
    const educaction= this.state.selectedEdu.value;
    const company = this.state.company.value;
    const jobtitle = this.state.jobtitle.value;
    const additional_skills = event.target.additonalSkills.value;
    const salary = this.state.salary.value;
    const content = this.state.content;
    //const lname = this.state.lname;
    //const designation = this.state.designation.value;
   
    const fid = this.state.fid;
    const radio = this.state.radio;
    let values = skills.map(function(skill){
      return skill.value;
    });
   
    //const phone =  this.state.phone;
    const locationRow = this.state.location;
    console.log(locationRow);
    const location1 = !locationRow ? "empty" : locationRow;
    const location = location1 =="empty" ? "":locationRow.suggestion.name+','+locationRow.suggestion.administrative+','+locationRow.suggestion.country;
    console.log("location",location);
    if (company==="" && location==="" && skills==="" && exp==="" && jobtitle==="" && content==="") {
      toast.error("Required Fields..!", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }else{
      const token = StorageManager.instance.getAccessToken();
      const csrf = StorageManager.instance.getXCSRFToken();
      const uid = StorageManager.instance.getUid();
      const basic = StorageManager.instance.getBasicToken();
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'bearer '+basic,
          'X-CSRF-Token':csrf
        }
        
        const data = {
          "uid": uid,
          "company_name": company,
          "job_title": jobtitle,
          "description": content,
          "location": location,
          "country": "United states",
          "country_code": " ",
          "mobile_number": " ",
          "company_size": " ",
          "employment_type": selectedJobtype,
          "salary_curreny": "USD",
          "salary_range": salary,
          "salary_method": "",
          "minimum_experience": exp,
          "maximum_experience": exp,
          "qualifications": educaction,
          "skills":values,
          "duration":jobDuration,
          "selectedVaccancy":vacancy,
          "additional_skills":additional_skills,
          "company_logo":fid
    
        };
        console.log("fid",localStorage.getItem('fid'));
        console.log(data);
        axios.post(`https://live-corp2corp.pantheonsite.io/services/jobs/create`, data,{
          headers: headers
        })
        .then(res => {
          toast.success("Successfully Submitted !", {
            position: toast.POSITION.TOP_RIGHT
          });
        
          console.log(res);
          console.log(res.data);
          this.props.history.push('/thank-you');
         // this.state.location("");
         /* this.setState({
            
            company: "",
        title: "",
        location: "",
      
        
      
          })*/
          //window.location.reload();
       // })
   
    //}
    /*console.log("selectedJobtype",selectedJobtype);
    console.log("selectedJobtype",selectedJobtype);
    console.log("skills",skills);
    console.log("exp",exp);
    console.log("duration",duration);
    //console.log("selectedVaccancy",selectedVaccancy);
    console.log("fid",fid);
    console.log("educaction",educaction);
    console.log("location",location);
    console.log("additional_skills",additional_skills);
    console.log("salary",salary);
    console.log("content",content);
    console.log("radio1",radio);
    return;
  */
    //https://live-corp2corp.pantheonsite.io/services/register-candidate
    //alert(values);
    //alert("sas");
    /*const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const logout = StorageManager.instance.getLogoutToken();
    //const dispatch = useDispatch();


    UserManager.logout1();
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token':csrf
    }
    
    const data = {
      "first_name": fname,
      "last_name": lname,
      "email": email,
      "location": location,
      "designation": designation,
      "experience":exp,
      "mobile_number": phone,
      "password": password,
      "skills":values,
      "resume_fid": fid

    };
    
    console.log(data);
 
    axios.post(`https://live-corp2corp.pantheonsite.io/services/register-candidate`, data,{
      headers: headers
    })
    .then(response => { 
      console.log('succ',response)
      if(response.data.status === 0){
        toast.error("Email id already in use!", {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      toast.success("Successfully Submitted !", {
        position: toast.POSITION.TOP_RIGHT
      });
      
      const data = {
        "name": email,
        "pass":password,
        withCredentials: true,
  
      };
      axios.post(`https://live-corp2corp.pantheonsite.io/user/login?_format=json`, data,{
        headers: headers,
       withCredentials: true
      })
      .then(res => {
        console.log("dsaasd",res);
     
        // StorageManager.instance.setAccessToken(result.access_token);
        //StorageManager.instance.setBasicToken('aGFuaXNoQHdpbm5vcHJvLmNvbTox');
        StorageManager.instance.setBasicToken(btoa(`${email}:${password}`));
        StorageManager.instance.setUid(res.data.current_user.uid);
        StorageManager.instance.setXCSRFToken(res.data.csrf_token);
        StorageManager.instance.setLogoutToken(res.data.logout_token);
        StorageManager.instance.setAccessToken(res.data.access_token);
         //Not in the array
        console.log("Success1employer",res.data);

          //Not in the array
          console.log("Success1employer",res.data.current_user.roles);
          window.location.replace("onboarding/1");
        //console.log(res.data);
       // window.location.reload();
      })
      {/*const data = {"flag_id":"recruiter" ,"entity_type":"user", "entity_id":response.data.uid,"uid":uid};
      axios.post(`https://live-corp2corp.pantheonsite.io/api/flag?_format=json`, data,{
          headers: headers
      })
      .then(res => {
          console.log(res.data);
          return <Redirect to='/my-candidatesList'/>;
         
        })
        *///}
       // const username = event.target.email.value;
        //const password = event.target.password.value
        //const loginAction = (username, password) => {
         
       // };
    //})
  }

  toggle = nr => () => {
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
  }
  render() {
    const { classes } = this.props;
    const { selectedOption,selectedJobtype,selectedVaccancy,selectedJobduration,selectedtype,visaOption } = this.state;
    const {isApplied} = true;
   
 
    
      const getUploadParams = (file) => {
      console.log("sasas",file);
      return { url: 'https://httpbin.org/post' }
    }
  
    const handleChangeStatus = ({ file,meta, remove }, status) => {
      if (status === 'headers_received') {
        console.log(meta)
        const token = StorageManager.instance.getAccessToken();
        const csrf = StorageManager.instance.getXCSRFToken();
        const uid = StorageManager.instance.getUid();
        const basic = StorageManager.instance.getBasicToken();
        console.log(meta.name);
        const fileName = file.name;
        const headers = {
          'Content-Type': 'application/octet-stream',
          'Authorization': 'basic '+basic,
          'X-CSRF-Token':csrf,
          'Content-Disposition':`file;filename="${fileName}"`
        }
        console.log(headers);
        axios.post(`https://live-corp2corp.pantheonsite.io/file/upload/node/jobs/field_company_logo?_format=hal_json`, file ,{
          headers: headers
        })
        .then(res => {
          localStorage.setItem('fid', res.data.fid[0].value);
          this.setState({fid:res.data.fid[0].value});
          console.log("File Upload",res.data.fid[0].value);
        })
        //remove()
      } else if (status === 'aborted') {
        console.log(`${meta.name}, upload failed...`)
      }
    }

  return (
    
    <EmployerContainer>
    <div className="flexible-content">
      
    <MDBContainer>
 {!this.state.preview && 
      <MDBCard>
            <MDBCardBody>
              <h4 className="text-center font-weight-bold ">
                <strong>Post a job</strong>
              </h4>
              <h6 className="text-center pb-4">Let's get started</h6>
              <div>
       
              <form
          className="needs-validation job-posting"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>
            <MDBCol md="6" className="mb-3">
              <label
                htmlFor="defaultFormRegisterNameEx"
                className="black-text"
              >
                Company name for this job  <span style={{color:"red",fontWeight:800}}>*</span>
              </label>
              <input
                //value={this.state.company.value}
                defaultValue={this.state.company.value?this.state.company.value:""}
                className={this.state.company.valid ? "form-control grey-text" : "form-control grey-text"}
                name="company"
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterNameEx"
                placeholder="Enter company name"
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
            <MDBCol md="6" className="mb-3">
              <label
                htmlFor="defaultFormRegisterEmailEx2"
                className="black-text"
              >
                Job Title  <span style={{color:"red",fontWeight:800}}>*</span>
              </label>
              <input
                value={this.state.jobtitle.value}
                className={this.state.jobtitle.valid ? "form-control grey-text" : "form-control grey-text"}
                name="jobtitle"
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterEmailEx2"
                placeholder="Enter job title here"
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
            </MDBRow>
            <MDBRow>
            <MDBCol md="6" className="mb-3">
            <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="black-text"
              >
                Location  <span style={{color:"red",fontWeight:800}}>*</span>
              </label>
              <AlgoliaPlaces
                           placeholder='Location'
                              variant="outlined"
                             //value={this.state.location}
                      className={this.state.location.valid ? "form-control is-valid grey-text" : "form-control is-invalid grey-text"}
                              fullWidth
                              icon="user"
                              className="form-control grey-text"
                              required
                             // value= {this.state.location ? this.state.location.suggestion.name+','+this.state.location.suggestion.administrative+','+this.state.location.suggestion.country:""}
                              options={{
                                appId: 'plE79E3YU95N',
                                apiKey: 'ab6d2643563c402f95aaf5dca19afa95',
                                language: 'en',
                                countries: ['us'],
                                type: 'city',
                                // Other options from https://community.algolia.com/places/documentation.html#options
                              }}
                              onChange={ location => this.setState({location}) }
                            
                            
                        
                              onSuggestions={({ rawAnswer, query, suggestions }) => 
                                console.log('Fired when dropdown receives suggestions. You will receive the array of suggestions that are displayed.')}
                        
                              onCursorChanged={({ rawAnswer, query, suggestion, suggestonIndex }) => 
                                console.log('Fired when arrows keys are used to navigate suggestions.')}
                        
                              onClear={() => 
                                console.log('Fired when the input is cleared.')}
                        
                              onLimit={({ message }) => 
                                console.log('Fired when you reached your current rate limit.')}
                        
                              onError={({ message }) => 
                                console.log('Fired when we could not make the request to Algolia Places servers for any reason but reaching your rate limit.')}
                    />
                 <div className="invalid-feedback">
                   Please provide a valid location.
                 </div>
                 <div className="valid-feedback">Looks good!</div>
        
              </MDBCol>
              <MDBCol md="6" className="mb-3">
              <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="black-text"
              >
                Employee Type <span style={{color:"red",fontWeight:800}}>*</span>
              </label>
              <Select
                  value={this.state.selectedJobtype}
                  onChange={this.handleChange2}
                  options={jobType}
                  required
                />
               <div className="invalid-feedback">
                Please provide a valid designation.
              </div>
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>  
            
            </MDBRow>
            <MDBRow>
            <MDBCol md="6" className="mb-3">
            <div className="custom-control custom-checkbox pl-3" style={{paddingTop:"39px"}}>
              <input
                className="custom-control-input"
                type="checkbox"
                value=""
                id="invalidCheck"
                onChange={this.handleCheck}
                defaultChecked={this.state.checked}
                
              />
              <label className="custom-control-label" htmlFor="invalidCheck">
               Remote/Work From Home Only
              </label>
              <div className="invalid-feedback">
                
              </div>
            </div>
          </MDBCol> 
            </MDBRow>
           
           
          <MDBRow>
          <MDBCol md="6" className="mb-3">
            <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="black-text"
              >
               Salary 
              </label>

         <MDBFormInline>
        <MDBInput
          onClick={this.onClick("Yearly")}
          checked={this.state.selectedtype === "Yearly" ? true : false}
          label='Annual'
          color="red"
          type='radio'
          id='radio1'
          containerClass='mr-5'
        />
        <MDBInput
          onClick={this.onClick("Hourly")}
          checked={this.state.selectedtype === "Hourly" ? true : false}
          label='Hourly'
          type='radio'
          id='radio2'
          containerClass='mr-5'
        />
      </MDBFormInline>
               <div className="invalid-feedback">
                Please provide a valid designation.
              </div>
              <div className="valid-feedback">Looks good!</div>
              </MDBCol>
              </MDBRow>
          <MDBRow>
          <MDBCol md="6" className="mb-3">
            <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="black-text"
              >
              Minimum Salary 
              </label>
           
             <CurrencyFormat placeholder="Minimum salary" required className="form-control grey-text" value={this.state.minsalary?this.state.minsalary:""} thousandSeparator={true} prefix={'$'} onValueChange={(values) => {
                    const {formattedValue, value} = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    console.log("2223",value);
                    this.setState({mincount:value});
                    this.setState({minsalary: formattedValue})
                  }}/>

              </MDBCol>
              <MDBCol md="6" className="mb-3">
            <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className=" black-text"
              >
              Maximum
              </label>
           
             <CurrencyFormat placeholder="Maximum salary" required className="form-control grey-text" value={this.state.maxsalary?this.state.maxsalary:""} thousandSeparator={true} prefix={'$'} onValueChange={(values) => {
    const {formattedValue, value} = values;
    // formattedValue = $2,223
    // value ie, 2223
    console.log("2223",this.state.mincount+"tttt"+value.length);
    if(value <= this.state.mincount){
      this.setState({maxErrorS:true});
    }else{
      this.setState({maxErrorS:false});
     
    }
    this.setState({maxsalary: formattedValue})
    
  }}/>
    {this.state.maxErrorS &&(
               <div style={{color:'red'}}>Maximum salary should be greater than minimum salary</div>
               )}

              </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6" className="mb-3">
            <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="black-text"
              >
                Experiance Minimum  <span style={{color:"red",fontWeight:800}}>*</span>
              </label>
             
                   <input
                value={this.state.minexp.value}
                className={this.state.minexp.valid ? "form-control grey-text" : "form-control grey-text"}
                name="minexp"
                onChange={this.changeHandler}
                type="number"
                id="defaultFormRegisterEmailEx2"
                placeholder="Min Experiance"
                required
              />
               
              <div className="valid-feedback">Looks good!</div>
              </MDBCol>
              <MDBCol md="6" className="mb-3">
            <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="black-text"
              >
               Experiance Maximum  <span style={{color:"red",fontWeight:800}}>*</span>
              </label>
             
                   <input
                value={this.state.maxexp.value}
                className={this.state.maxexp.valid ? "form-control grey-text" : "form-control grey-text"}
                name="maxexp"
                onChange={this.changeHandler}
                type="number"
                id="defaultFormRegisterEmailEx2"
                placeholder="Max Experiance"
                required
              />
           {this.state.maxErrorE &&(
               <div style={{color:'red'}}>Maximum experiance should be greater than minimum experiance</div>
               )}
              <div className="valid-feedback">Looks good!</div>
              </MDBCol>
              </MDBRow>
          {/* <MDBRow>
             
          </MDBRow> */}
          
          <MDBRow> <MDBCol md="12" className="mb-3">
              <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="black-text"
              >
                Key Skills  <span style={{color:"red",fontWeight:800}}>*</span>
              </label>

               <ReactTags
        tags={this.state.tags}
        suggestions={this.state.suggestions}
        placeholder="Add key skills"
        className={classes.skills }
        handleDelete={this.handleDelete.bind(this)}
        handleAddition={this.handleAddition.bind(this)} 
        required
        />
              <div className="invalid-feedback">
                Please provide a valid city.
              </div>
              <div className="valid-feedback">Looks good!</div>
             
            </MDBCol>
     </MDBRow>
      
            <MDBRow>
            <MDBCol md="6">
            <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="black-text"
              >
                Qualification  <span style={{color:"red",fontWeight:800}}>*</span>
              </label>
              <Select
                  value={this.state.selectedEdu}
                  onChange={this.handleChange5}
                  options={edu}
                  required
                  //defaultValue = {{label:6,valaue:6}}
                />
            </MDBCol>  
            <MDBCol md="6" className="mb-3">
              <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="black-text"
              >Work Permit
                 
              </label>
              <Select
                value={visaOption}
                onChange={this.handleChangeVisa}
                options={visa}
                required
              />
              </MDBCol>
            
            <MDBCol md="12" className="mb-3">
            <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="black-text"
              >
               Job Description  <span style={{color:"red",fontWeight:800}}>*</span>
              </label>
            
            
         
             <CKEditor 
              activeClass="p10" 
              content={this.state.content} 
              events={{
                "change": this.onChange
              }}
             />
            
            
            
                </MDBCol>
          </MDBRow>
         
           <div className="form-btn" style={{float:"right"}}> 
           <Button 
           variant="contained"
           onClick={() => this.setState({preview:true})}>
           Preview
           </Button>
          <Button 
           variant="contained"
           color="primary"          
          type="submit" 
          disabled={!this.state.fid}
          style={{
            fontFamily: "'Poppins', sans-serif",
            marginLeft:"1em",
            color: "white",
            borderRadius: "10px"
          }}>
            Submit
          </Button>
          
           </div> 
                
        </form>
      
             </div>
           
        </MDBCardBody>
          </MDBCard>  

 
 }
    {this.state.preview  &&    
               
    
                  
              
                    <Grid item >
                    <MDBCard>
            <MDBCardBody>
            <h4 className="text-center"> 
                Post a job
              </h4>
              <h3 className="text-center" style={{paddingBottom:"40px"}}>
                Preview Job Post
              </h3>

              <form
               className="needs-validation"
               onSubmit={this.submitHandler}
               noValidate
             > 
              <div style={{backgroundColor:"#F8F8F8",paddingTop:"30px",paddingBottom:"30px",paddingRight:"50px",paddingLeft:"50px"}}> 
                 
              <Paper>
                        <Box p={3} xs={12}>

                          <Grid container justify="space-between">
                            

                            <Grid  xs={12}>
                              <span variant="caption">{this.state.company.value?this.state.company.value:"Company Name"}</span>
                              <img src={edit} alt="edit" style={{paddingLeft:"700px"}}/>
                              <span className="edit-button" style={{cursor:"pointer"}}onClick={() => this.setState({preview:false})}>
                              Edit
                             </span>
                              <div style={{marginLeft:"-9px"}}>
                              <Typography variant="caption" display="block" style={{padding:"3px"}}>
                                  <LocationOnIcon color="secondary" fontSize="small" style={{fontSize:"12px",color:"#1A20AF"}}/>
                                  <span style={{paddingLeft:"4px"}}>{

                                  this.state.location ? this.state.location.suggestion.name+','+this.state.location.suggestion.administrative+','+this.state.location.suggestion.country:"Job Location "

                                  }</span>
                                  
                              </Typography>

                              </div>
                            </Grid>
                        
                        

                          </Grid>
                        </Box>
                        <Divider />

                        {/* <Box p={3}>
                          <Grid container>

                            
                            <Grid item xs={12}>
                              <Box mt={2}>
                              <Grid item xs={12}>
                                <p style={{ fontFamily: "'Poppins', sans-serif" }} dangerouslySetInnerHTML={{ __html: this.state.content?this.state.content:"Job Description" }}></p>
                                </Grid>
                                <Grid item xs={12} style={{paddingBottom:"10px"}}>
                                <span >job types: {this.state.selectedJobtype?this.state.selectedJobtype.value:"job type"}</span>
                                </Grid>
                                <Grid item xs={12} style={{paddingBottom:"10px"}}>
                                <span>Salary: {this.state.selectedJobtype?this.state.selectedJobtype.value:"job type"}</span>
                                </Grid>
                                <Grid item xs={12} style={{paddingBottom:"20px"}}>
                                <span>Experiance</span>
                                </Grid>
                                <Grid item xs={12} style={{paddingBottom:"10px"}}>
                                <span>Total Work: {this.state.selectedJobtype?this.state.selectedJobtype.value:"job type"}</span>
                                </Grid>
                                <Grid item xs={12} style={{paddingBottom:"10px"}}>
                                <span>Work: {this.state.selectedJobtype?this.state.selectedJobtype.value:"job type"}</span>
                                </Grid>
                              
                               
                      </Box>

                          
                          </Grid>
                         
                      </Grid>
                      </Box> */}
 <Box p={3}>
                          <Grid container>
                        
                            
                            <Grid item xs={6}>
                              <TagList title="Qualifications" list={this.state.selectedEdu?this.state.selectedEdu.value:"Qualifications"} />
                            </Grid>
                            <Grid item xs={6}>
                              <TagList title="Skills" list={this.state.PrievewData?this.state.PrievewData:"Java,.net"} />
                            </Grid>
                            
                            <Grid item xs={12}>
                              <Box mt={2}>
                                <p style={{ fontFamily: "'Poppins', sans-serif" }} dangerouslySetInnerHTML={{ __html: this.state.content?this.state.content:"Job Description" }}></p>
                              
                                <Grid item xs={12}>
                            {/*
                              <Button
                                disabled={isApplied}
                                style={{ width: "124px",
                                height: "44px",
                              
                                background: "#13C1D6 0% 0% no-repeat padding-box",
                                borderRadius: "10px",
                                opacity: "1"}}
                                className={classes.jobApplyButton}
                                variant={isApplied ? "contained" : "outlined"}
                                color={isApplied ? "primary" : "secondary"}
                                
                              >
                                {isApplied ? "Applied" : "Apply Now"}
                            </Button>*/}
                          <span style={{position:"relative",float:"right"}}>

                              <FacebookShareButton
                                  url={"#"}
                                  quote={"#"}
                                  className="sshare"
                                  style={{padding:"2px !important"}}
                              >
                                  <FacebookIcon size={32} square />
                              </FacebookShareButton>
                              
                              <TwitterShareButton
                                  url={"#"}
                                  title={"#"}
                                  className="sshare"
                                  style={{padding:"2px !important"}}
                              >
                              <TwitterIcon size={32} square />
                              </TwitterShareButton>
                              <LinkedinShareButton
                                  url={"#"}
                                  style={{padding:"2px !important"}}
                                  className="sshare"
                              >
                              <LinkedinIcon size={32} square />
                              </LinkedinShareButton>
                          </span>
                      </Grid>
                          
                      </Box>

                          
                          </Grid>
                      </Grid>
                      </Box>                      
                      </Paper>
                      </div> 
                      <div className="form-btn" style={{float:"right",marginTop:"50px"}}> 
                      <Button 
           variant="contained"
           color="primary"          
          type="submit" 
          disabled={!this.state.fid}
          style={{
            fontFamily: "'Poppins', sans-serif",
            color: "white",
            borderRadius: "10px"
          }}>
            Submit
          </Button>
          <Button 
           variant="contained"  
                
          style={{
            fontFamily: "'Poppins', sans-serif",
            marginLeft:"1em",
            borderRadius: "10px"
          }}
          onClick={() => this.setState({preview:false})}  
          >
            Back
          </Button>
          </div>
          </form>
                      </MDBCardBody>
                      </MDBCard>
                  </Grid>
              
                  
                                
    }

          </MDBContainer>
         
        </div>   
        </EmployerContainer>
           
  );
  };
}

export default withStyles(styles)(JobPosting);