import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Typography, Button, Box } from "@material-ui/core";
import MainContainer from "../../../../common/MainContainer";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Formik, Form, ErrorMessage, Field, FastField, yupToFormErrors } from "formik";
import * as Yup from "yup";
import { FormikTextInput } from "components/common/FormInputs/FormikInputs";
import "./ResetPassword.scss";
import { toast } from "react-toastify";


const Forgot = (props) => {

  const history = useHistory();

    const handleFormSubmit = (values, actions) => {
      const  action="request_verification_code";
        props.onSubmit(values,action)
    }

   // let isEmail=values.userName.includes('@');
    let validationSchema ={};
    
 
       validationSchema = Yup.object().shape({
        UserName:Yup.string()
       //  .email("Enter a valid email")
        .required("Email/Phone Number is required")
        .test('test-name', 'Enter a valid email/phone number', 
          function(value) {
          const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

          const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
          let isValidEmail = emailRegex.test(value);
          let isValidPhone = phoneRegex.test(value);
          if (!isValidEmail && !isValidPhone ){
            return false;
          }
          return true;
        }) 

      })
    
    
    let initialValus = {
      userName: ""
    }

    return (
      <>
        <Grid className="header" xs={12}>
          <IconButton onClick={()=>history.push('/jobseekers/login')} aria-label="back">
            <ArrowBackIcon  fontSize="large" />
          </IconButton>
          <h2>Forgot Password</h2>
        </Grid>
        <Grid xs={12}>
          <Box className="otp-message">
            <div>Enter the email address or phone number associated with your Corp2Corp account.</div>
            <div>We will email/text you a link to create new password.</div>
          </Box>
        </Grid>

        <Grid className="content" xs={12}>
          <Formik
            initialValues={initialValus}
            validationSchema={validationSchema}
          
            onSubmit={handleFormSubmit}
          >
            {({ errors, touched, handleSubmit, isSubmitting }) => {
              return (
                <>
                  <Form
                    name="reset-password"
                    method="post"
                    onSubmit={handleSubmit}
                  >
                    <FastField
                      style={{ marginBottom: "65px" }}
                      name="UserName"
                      component={FormikTextInput}
                      fullWidth
                      id="email"
                      size="small"
                      labelText="Enter email id or mobile number"
                      placeholder="Enter your Email id or phone number"
                      variant="outlined"
                    />

                    <Button
                      type="submit"
                      fullWidth
                      //disabled={loading}
                      variant="contained"
                      color="primary"
                    >
                      PROCEED
                    </Button>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Grid>
      </>
    );
  };

export default Forgot;