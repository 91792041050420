import React from 'react';

import axios from 'axios';
import { MDBTable, MDBTableBody, MDBTableHead,MDBBtn, MDBIcon, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBContainer } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import TopNavigation from 'components/pages/employer/header/topNavigation';
import SideNavigation from 'components/pages/employer/header/sideNavigation';
import Footer from 'components/pages/employer/header/Footer';
import StorageManager from "utils/StorageManager";
import { toast } from "react-toastify";
import MainContainer from "components/common/MainContainer";
import Link from '@material-ui/core/Link';


export default class MyCandidates extends React.Component {
  state = {
    candidates: []
  }
 
  
  async componentDidMount() {
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+token,
        'X-CSRF-Token':csrf
      }
      await axios.get('https://live-corp2corp.pantheonsite.io/api/'+uid+'/recruiter_candidates',{
        headers: headers
      })
      .then(res => {
        const candidates = res.data;
        this.setState({ candidates });
      })
  }
  toggleEditing(value1, value2, event){
    event.preventDefault();
    console.log(value2,value1);
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const basic = StorageManager.instance.getBasicToken();
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'basic '+basic,
      //'X-CSRF-Token':csrf
    }
    
    const data = {
      "eid": value2,
      "uid": uid
    };
    
    console.log(data);
 
    axios.post(`https://live-corp2corp.pantheonsite.io/services/delete-candidate`, data,{
      headers: headers
    })
    .then(response => { 
      
      toast.success("Successfully Submitted !", {
        position: toast.POSITION.TOP_RIGHT
      })
      window.location.reload();
      this.props.history.push('/employer/my-candidatesList');
       
        
    })
}
  handleSort = (e, param,param1) => {
    e.preventDefault();
    console.log('Sorting by: ' + param);
    return;
    const token = StorageManager.instance.getAccessToken();
      const csrf = StorageManager.instance.getXCSRFToken();
      const uid = StorageManager.instance.getUid();
      const basic = StorageManager.instance.getBasicToken();
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'basic '+basic,
          //'X-CSRF-Token':csrf
        }
        axios.delete('https://live-corp2corp.pantheonsite.io/node/'+param+'?_format=hal_json',{
          headers: headers
        })
        .then(res => {
          toast.success("Successfully Deleted !", {
            position: toast.POSITION.TOP_RIGHT
          });
          this.props.history.push('/employer-dashboard');
        })
  }
  
  render() {

    return (
      <MainContainer>
        <div className="flexible-content">
      
        
          <SideNavigation />
          <main id="content" className="p-1">
          <MDBContainer>
          <MDBRow className="mb-4">
          <MDBCol md="12">
              <MDBCard>
                  <MDBCardBody>
                    <MDBTable>
                      <MDBTableHead color="cyan" textWhite>
                        <tr>
                          <th>#</th>
                          <th>Employee Name</th>
                          <th>Email Id</th>
                          <th>Phone Number</th>
                          <th>Location</th>
                          <th className="text-center">Actions</th>
                       
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                      
                      { this.state.candidates.map(person => <tr><td>{person.counter}</td><td>{person.name}</td><td>{person.email}</td><td>{person.mobile}</td><td>{person.location}</td><td>       <Link  href={"/candidate-edit/"+person.uid}><MDBIcon far icon="edit" /></Link>
      <Link  onClick={this.toggleEditing.bind(this, person.nid,person.uid) } href="#" style={{paddingLeft:"20px"}}><MDBIcon icon="calendar-times" /></Link></td></tr>)}
                        
                      </MDBTableBody>
                    </MDBTable>
                  </MDBCardBody>  
                </MDBCard>       
              </MDBCol>
      </MDBRow>

             </MDBContainer>
          </main>
        
          </div> 
          </MainContainer>
     )
  }
}