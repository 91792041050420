import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { textAlign } from "@material-ui/system";
import { makeStyles, TextField, Button } from "@material-ui/core";
import GooglePlaces from "../GooglePlaces";
import { StyledLink } from "../Misc";
import { TextInput } from "../FormInputs";
import { searchActionAction } from "services/endpoints/jobs/jobActions";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
// import { GoogleComponent } from "react-google-location";
const useStyles = makeStyles(theme => ({
  searchTextField: {
    width: "100%",
    // borderRadius: '10px',
    backgroundColor: "white"
  },
  inputStyle: {
    outline: "none",
    padding: "10px"
  },
  InputStyle: {
    background: "white",
    padding: "0px",
    outline: "none"
  },
  searchButton: {
    color: "white",
    backgroundColor: "#13C1D6",
    fontFamily: "'Poppins', sans-serif",
    padding: "10px 30px",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  }
}));

const SearchForm = ({ breakButton = true }) => {
  const history = useHistory();
  var url_string = window.location.href; //window.location.href
  var url = new URL(url_string);
  var c = url.searchParams.get("title");
  const [title, setTitle] = useState(c);
  const [location, setLocation] = useState("");
  const ref = React.createRef();

  const dispatch = useDispatch();
  const { loading, data, error } = useSelector(state => state.job.search);

  const performSearch = () => {
    console.log("Perform" + title + location);
    history.push(`jobs?location=${location}&title=${title}`);
  };

  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} sm={breakButton ? "7" : "4"}>
        <TextInput
          hideLabel
          fullWidth
          value={title}
          onChange={e => {
            setTitle(e.target.value);
          }}
          
          placeholder="Job Title or Keyword"
          inputProps={{ className: classes.inputStyle }}
          InputProps={{ className: classes.InputStyle }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={breakButton ? "7" : "4"}>
        <GooglePlaces
          fullWidth
      
          onPlaceSelected={place => {
            if (place && place.name) {
              setLocation(place.name+","+place.administrative+","+place.country);
            }
          }}
        />
      </Grid>
      {breakButton && <Grid item xs={12} sm={2}></Grid>}
      <Grid item xs={12} sm={4}>
        <Button
          variant="contained"
          color="primary"
          className={classes.searchButton}
          onClick={() => {
            performSearch();
          }}
        >
          SEARCH
        </Button>
      </Grid>
    </>
  );
};

export default SearchForm;
