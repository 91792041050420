import React,{Component} from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput,MDBCard, MDBCardBody } from 'mdbreact';
import MainContainer from "components/common/MainContainer";
import { toast } from "react-toastify";
import axios from 'axios';
import {Helmet} from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Typography, Box, Button, Divider,makeStyles } from "@material-ui/core";





class ContactPage extends Component {
  state = {
    name: "",
    email: "",
    subject: "",
    msg: "",
    submited:true
  };

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
    const name = this.state.name;
    const email =this.state.email;
    const subject = this.state.subject;
    const msg =  this.state.msg;
    console.log(name+email+msg);
    const headers = {
      'Content-Type': 'application/json'
    }
    const data = {
    
      "contact_form":[{"target_id":"feedback"}],
      "name":[{"value":name}],
      "mail":[{"value":email}],
      "subject":[{"value":subject}],
      "message":[{"value":msg}]
    };
    
    axios.post(`https://live-corp2corp.pantheonsite.io/entity/contact_message`, data,{
        headers: headers
      })
    .then(res => {
      const items = res.data;
      console.log(items);
      toast.success("Successfully Submitted");
      this.setState({
        submited:false
      });

    })
   

  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

     
      
  render() {
        
         const colStyle = { maxWidth: "22rem" };
         const { classes } = this.props;
       
            return (
                <MainContainer>
                    <Grid container justify="center">
                    <Grid item sm={12} md={8} xl={6}>
                    <MDBCard className="my-5 px-5 pb-5">
                       <MDBCardBody>
                       <div>
                         {this.state.submited ?
          <form
          className='needs-validation'
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>
            <MDBCol md='12'>
              <MDBInput
                icon='user'
                value={this.state.name}
                name='name'
                onChange={this.changeHandler}
                type='text'
                id='materialFormRegisterNameEx'
                label='Your Name'
                required
              >
                 <div className='invalid-feedback ml-4 pl-3'>
                  Please provide name.
                </div>
                <div className='valid-feedback ml-4 pl-3'>Looks good!</div>
              </MDBInput>
            </MDBCol>
            <MDBCol md='12'>
              <MDBInput
                icon='envelope-open'
                value={this.state.email}
                onChange={this.changeHandler}
                type='email'
                id='materialFormRegisterConfirmEx3'
                name='email'
                label='Your Email address'
                required
              >
               <div className='invalid-feedback ml-4 pl-3'>
                  Please provide a valid email.
                </div>
                <div className='valid-feedback ml-4 pl-3'>Looks good!</div>
              </MDBInput>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md='12'>
              <MDBInput
                icon='tag'
                value={this.state.subject}
                onChange={this.changeHandler}
                type='text'
                id='materialFormRegisterPasswordEx4'
                name='subject'
                label='Subject'
                required
              >
                <div className='invalid-feedback ml-4 pl-3'>
                  Please provide subject.
                </div>
                <div className='valid-feedback ml-4 pl-3'>Looks good!</div>
              </MDBInput>
            </MDBCol>
            <MDBCol md='12'>
              <MDBInput
                icon="pencil-alt"
                value={this.state.msg}
                onChange={this.changeHandler}
                type='textarea'
                id='materialFormRegisterPasswordEx4'
                name='msg'
                label='Message'
                required
              >
                <div className='invalid-feedback ml-4 pl-3'>
                  Please enter your message.
                </div>
                <div className='valid-feedback ml-4 pl-3'>Looks good!</div>
              </MDBInput>
            </MDBCol>
           
          </MDBRow>
       
          <MDBBtn color='primary' type='submit'>
            Submit Form
          </MDBBtn>
        </form>
        :
       <h2 style={{textAlign:"center",padding:"64px"}}>Successfully Submitted</h2>
         }
      </div>
              </MDBCardBody>
              </MDBCard>
       </Grid>
                    </Grid>
                     </MainContainer>
              
              
                )
        }
      }
      
      export default ContactPage;