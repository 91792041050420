import React from "react";
import { Box,LinearProgress } from "@material-ui/core";

const Overlay = (props) => {

  const messages = props.messages.map(message => <p>{message}</p>)

  return (
    <Box id="overlay" style={{ display: (props.show ? "block" : "none") }}>
      <Box className="content">
        <p> Please wait while we complete the registration process.</p>
        {messages}
        <LinearProgress></LinearProgress>
      </Box>
    </Box>
  );
};

export default Overlay;