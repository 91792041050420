import {
  GET_JOB_LIST,
  GET_JOB_APPLIED,
  GET_JOB_SAVED,
  GET_JOB_DETAIL,
  GET_SKILLS,
  GET_INDUSTRIES,
  UPDATE_BASIC_INFO,
  GET_CANDIDATE_DETAILS,
  SEARCH,
  FLAG_JOB
} from "constants/actionTypes";
import createApiReducer from "services/createAPIReducer";
const initialState = {
  jobList: {},
  jobApplied:{},
  jobSaved:{},
  jobDetail: {},
  skills: {},
  industries: {},
  basicInfo: {},
  candidate: {},
  search: {},
  flagJob: {}
};

const job = createApiReducer(initialState, [
  GET_JOB_LIST,
  GET_JOB_APPLIED,
  GET_JOB_SAVED,
  GET_JOB_DETAIL,
  GET_SKILLS,
  GET_INDUSTRIES,
  UPDATE_BASIC_INFO,
  GET_CANDIDATE_DETAILS,
  SEARCH,
  FLAG_JOB
]);

export default job;
