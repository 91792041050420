import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { useHistory } from "react-router-dom";
import RolesPopup from "components/pages/rolespopup";
import { LinkedIn } from "react-linkedin-login-oauth2";
import StorageManager from "utils/StorageManager";
import axios from 'axios';
import UserManager from "utils/UserManager";
import { MDBSpinner } from 'mdbreact';
import { GoogleLogout } from 'react-google-login';

const SocialLogin = ({ onLoginSuccess,onLoginFailed,buttonText }) => {
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [openSpinner, setSpinner] = useState(false);
  const responseRef = useRef(null);
  const history = useHistory();

  return (
    <Grid container style={{textAlign:"center"}}>
      <Grid item xs={12}>
   {!openSpinner ?
   <div>
        <GoogleLogin
          className="googleButton"
          clientId="808237848485-2fid2u88q52skos4neuujgtksmdi00o0.apps.googleusercontent.com"
              buttonText={buttonText || "GOOGLE"}
              style={{}}
          // buttonStyle={{ width: "80%", height: "50px", borderRadius:"10px" }}
          // style={{ width: "80%", height: "50px", borderRadius:"10px" }}
          onSuccess={response => {
            //setSpinner(true);
            console.log(response.accessToken);
            // onGoogleSuccess && onGoogleSuccess(response);
            //responseRef.current = { response };
            responseRef.current = { response };
           
            //UserManager.logout1();
            const headers = {
              'Content-Type': 'application/json',
              //"X-CSRF-Token": localStorage.getItem("anonymous_toke")
            }
            
            const data = {
              "access_token": response.accessToken,
              //withCredentials: true,
        
            };
            axios.post(`https://live-corp2corp.pantheonsite.io/user/login/google?_format=json`, data,{
              headers: headers,
            // withCredentials: true
            })
              .then(res => {
              console.log(res);
             // localStorage.setItem("google_login","GoogleLogin");
              if(res.data.status === 1){
                localStorage.setItem("google_login","GoogleLogin");
                localStorage.setItem("temp_xcsrf_token",res.data.csrf_token);
                //localStorage.setItem("telogout_token",res.data.logout_token);
                localStorage.setItem("temp_uid",res.data.current_user.uid);
                StorageManager.instance.setBasicToken('aGFuaXNoQHdpbm5vcHJvLmNvbTox');
                StorageManager.instance.setUid(res.data.current_user.uid);
                StorageManager.instance.setXCSRFToken(res.data.csrf_token);
                StorageManager.instance.setLogoutToken(res.data.logout_token);
                StorageManager.instance.setAccessToken(res.data.access_token);
                window.location.href="/saved-jobs";
              }
             //StorageManager.instance.setUid(res.data.current_user.uid);
             //StorageManager.instance.setXCSRFToken(res.data.csrf_token);
             //StorageManager.instance.setLogoutToken(res.data.logout_token);
             //UserManager.logout1();
             //UserManager.logout();
            //   //In the array!
            //   //console.log("Success1candidates",res.data.current_user.roles);
      
                onLoginSuccess({
                  email: response.profileObj.email,
                  name: response.profileObj.name,
                  uid: res.data.current_user.uid
                })
            //   console.log("dsaasd",res);
            //   localStorage.setItem("google_login","GoogleLogin");
            //   localStorage.setItem("temp_xcsrf_token",res.data.csrf_token);
            //   //localStorage.setItem("telogout_token",res.data.logout_token);
            //   localStorage.setItem("temp_uid",res.data.current_user.uid);
            //   StorageManager.instance.setBasicToken('aGFuaXNoQHdpbm5vcHJvLmNvbTox');
            //   StorageManager.instance.setUid(res.data.current_user.uid);
            //   StorageManager.instance.setXCSRFToken(res.data.csrf_token);
            //   StorageManager.instance.setLogoutToken(res.data.logout_token);
            //   //In the array!
            //   //console.log("Success1candidates",res.data.current_user.roles);
            
          
        
            //   //localStorage.setItem("basic_token","aGFuaXNoQHdpbm5vcHJvLmNvbTox");
            //   const headers_logout = {
            //     'Content-Type': 'application/json',
            //     //'Authorization': 'bearer '+token,
            //     'X-CSRF-Token':res.data.csrf_token
            //   }
            //   axios.get('https://live-corp2corp.pantheonsite.io/jwt/token',{withCredentials: true},{
            //     headers: headers_logout
            //   })
            //   .then(res => {
            //     console.log("sdds",res);
            //     StorageManager.instance.setAccessToken(res.data.token);
            //     const headers1 = {
            //       'Content-Type': 'application/json',
            //       "X-CSRF-Token": res.data.csrf_token
            //     }
                
            //     const data1 = {
            //     };
            //     console.log(data1);
            //     axios.post(`https://live-corp2corp.pantheonsite.io/jwt/token?_format=json`,data1,{
            //      headers: headers1,
            //      withCredentials: true
            //     })
            //     .then(response => {
            //       StorageManager.instance.setAccessToken(response.data.token);
            //       console.log("response1",response);
            //       window.location.href="/onboarding/1";
            //       //history.push("/employer-dashboard");
            //     });
                
              
            //    //;
            //     //window.location.href="/onboarding/1";
            //     const candidates = res.data;
            
            //   })
            //   //setOpenRoleModal(true);
            
            //   //console.log(res.data);
            //  // window.location.reload();
            })
           
          }}
          onFailure={response => {
            console.log(response);
            onLoginFailed("Google");
          }}
         
        />
        </div>
        :
        <MDBSpinner />
}
      </Grid>
        {/*<Grid item xs={12}>
        <FacebookLogin
          buttonStyle={{ width: "80%", height: "50px" }}
          appId="306857327024172"
          autoLoad={false}
          fields="name,email,picture"
          //  onClick={componentClicked} callback={responseFacebook}
        />
        
      </Grid>
    <Grid item xs={12}>
        <LinkedIn
          clientId="86y33ojoz4xkii"
          onFailure={response => {
            console.log(response);
          }
        }
          onSuccess={response => {
            console.log(response);
          }
        }
          redirectUri="http://localhost:3000/user/register"
        >
          Log in with Linked In
        </LinkedIn>
      </Grid>  */}
      <RolesPopup
        open={openRoleModal}
        onSubmit={role => {
          onGoogleSuccess({ response: responseRef.current.response, role });
        }}
      />
    </Grid>
  );
};

export default SocialLogin;
