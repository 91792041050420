import React from "react";
import { Grid, Box, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const Success = () => {
  const history = useHistory();

  const gotoLogin = () => {
    history.push('/employer/login');
  }
  return (
    <Box className="success">
      <Grid className="header" xs={12}>
        <h2>Thanks</h2>
      </Grid>

      <Grid xs={12}>
        <IconButton aria-label="back">
          <CheckCircleIcon fontSize="large" />
        </IconButton>
      </Grid>

      <Grid xs={12}>
        <Box className="message">
          Your password has been updated successfully.
          </Box>
      </Grid>

      <Grid className="action-selector" xs={12}>
        <Button variant="contained" color="primary" fullWidth onClick={gotoLogin}>
          Login
        </Button>
      </Grid>
    </Box>
  );
};

export default Success;
