import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Box } from "@material-ui/core";
import MainContainer from "components/common/MainContainer";
import JobList from "./joblists/JobList";
import JobDetail from "./joblists/JobDetail";
import { useRouteMatch } from "react-router";
import { getJobDetailAction } from "services/endpoints/jobs/jobActions";
import Skeleton from "@material-ui/lab/Skeleton";
import { MDBContainer, MDBAlert } from "mdbreact";
import StorageManager from "utils/StorageManager";
const JobDetailPage = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { loading, data, error } = useSelector(state => state.job.jobDetail);
  const getJobDetail = id => {
    dispatch(getJobDetailAction(id, StorageManager.instance.getUid()));
  };
  useEffect(() => {
    getJobDetail(match.params.id);
  }, [match.params.id]);

  return (
    <MainContainer>
      <Grid container>
        <Grid item xs={12}>
          <Box p={2}>
            {loading ? <Skeleton width="100%" height={200} /> : data ? <JobDetail item={data} /> : <MDBAlert color="warning">Job Not Found</MDBAlert>}
          </Box>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default JobDetailPage;
