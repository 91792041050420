import React, { useEffect } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import assets from "../../../assets";
import JobSearch from "./categories/JobSearch";
import { useDispatch, useSelector } from "react-redux";
import { getJobListAction } from "services/endpoints/jobs/jobActions";
import StorageManager from "utils/StorageManager";
import MainContainer from "components/common/MainContainer";
const useStyles = makeStyles(theme => ({
  jobListContainer: {
    padding: "28px 13vw",
    width: "100%"
  }
}));
const JobSearchList = () => {
  const { loading, data, error } = useSelector(state => state.job.jobList);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getJobListAction(StorageManager.instance.getUid()));
  }, []);

  const classes = useStyles();
  return (
    <MainContainer>
    <Grid container alignItems="stretch" spacing={2} className={classes.jobListContainer}>
      {data &&
        data.map((item, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
            <JobSearch item={item} />
          </Grid>
        ))}
    </Grid>
    </MainContainer>
  );
};

export default JobSearchList;
