import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import assets from "../../../assets";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import UserManager from "utils/UserManager";
import logo from "assets/images/ccf.svg";
import { MDBIcon, MDBContainer, MDBBtn } from 'mdbreact';


const useStyles = makeStyles(theme => ({
  mainContainer: {
    backgroundColor: "#091E67",
    minHeight: "75px"
  },
  subContainer2: {
    color: "white",
    paddingLeft: "140px",
  },
  logo: {
   // height: "28px",
   width:"97%",

    marginTop: "8px"
  },
  links: {
    textAlign: "center",
    padding: "22px"
  },
  footerMenuItem: {
    color: "white",
    textAlign:"left",
    fontFamily: "'Poppins', sans-serif",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const FooterComponent = () => {
  const classes = useStyles();
  const isLoggedIn = UserManager.isLoggedIn();
  return (
    <Grid container className={classes.mainContainer} alignItems="center" justify="center">
     
      <Grid item xs={6} sm={2}>
      <Grid container className={classes.links} justify="center">
          <img src={logo} className={classes.logo} />
        </Grid>
        </Grid>
        <Grid item xs={6} sm={2}>
        <Grid  className={classes.links} justify="center">
        <p className={classes.footerMenuItem}><Link to="/about">About Us</Link></p>
            <p className={classes.footerMenuItem}><Link to="/contact">Conatct Us</Link></p>
            <p className={classes.footerMenuItem}><Link to="/terms-use">Terms & Conditions</Link></p>
            <p className={classes.footerMenuItem}><Link to="/privacy-policy">Privacy Policy</Link></p>
            {/*<p className={classes.footerMenuItem}><Link to="/terms-use">Refund Policy</Link></p>*/}
        </Grid>
        </Grid>
        <Grid item xs={6} sm={2}>
        <Grid  className={classes.links} justify="center">
          <div></div>
        {!isLoggedIn && (
            <p className={classes.footerMenuItem}><Link to="/jobseekers/register">Job Seekers Registraion</Link></p>
            )
          }
          {!isLoggedIn && (
            <p className={classes.footerMenuItem}><Link to="/jobseekers/login">Job Seekers Login</Link></p>
          )}
            <p className={classes.footerMenuItem}><Link to="/jobs">Jobs by Skills</Link></p>
            <p className={classes.footerMenuItem}><Link to="/jobs">Jobs by Location</Link></p>
            <p className={classes.footerMenuItem}><Link to="/jobs">Jobs by Title</Link></p>
        </Grid>
        </Grid>
        <Grid item xs={6} sm={2}>
   
        {!isLoggedIn ?
        <Grid  className={classes.links} >
        <p className={classes.footerMenuItem}><Link to="/employer/register">Post Job</Link></p>
            <p className={classes.footerMenuItem}><Link to="/employer/register">Recruitment Solutions</Link></p>
            <p className={classes.footerMenuItem}><Link to="/employer/register">Recruiters Registration</Link></p>
        </Grid>
        :
        <Grid  className={classes.links} >
        <p className={classes.footerMenuItem}><Link to="/saved-jobs">Saved Jobs</Link></p>
            <p className={classes.footerMenuItem}><Link to="/applied-jobs">Applied Jobs</Link></p>
            <p className={classes.footerMenuItem}><Link to="/resume-edit/1">My Resume</Link></p>
        </Grid>
          }
        </Grid>
        <Grid item xs={6} sm={2}>
        <Grid container alignContent="center" justify="center" spacing={3}>
          <Grid item xs={2} container alignContent="center" justify="center">
          <MDBContainer>
          <MDBBtn size="sm" href="https://www.facebook.com/corp2corpjobs" target="_blank" tag="a" floating social="fb">
             <MDBIcon fab icon="facebook-f" />
          </MDBBtn>
          </MDBContainer>
          </Grid>
          <Grid item xs={2} container alignContent="center" justify="center">
          <MDBContainer>
          <MDBBtn size="sm" href="https://www.linkedin.com/company/corp2corp-jobs/" target="_blank" tag="a" floating social="li">
           <MDBIcon fab icon="linkedin-in" />
          </MDBBtn>
          </MDBContainer>
          </Grid>
         
          <Grid item xs={2} container alignContent="center" justify="center">
          <MDBContainer>
          <MDBBtn size="sm" href="https://twitter.com/corp2corpjobs" target="_blank" tag="a" floating social="tw">
             <MDBIcon fab icon="twitter" />
         </MDBBtn>
         </MDBContainer>
          
          </Grid>
        </Grid>
      
        </Grid>
        <Grid item xs={12} sm={12} md={3} justify="center">
      <Grid item xs={12} className={classes.subContainer2}>
    <p className={classes.footerMenuItem}>Copyright © Corp2Corp 2020 </p>
  </Grid>
  </Grid>
    </Grid>
    
   
    // <div>
    //     <div>
    //         <img src ={assets.images.cclogo} className={classes.logo}/>
    //     </div>
    //     <div className={classes.subContainer2}>
    //         <p>Jobs</p>
    //         <p>Employeers</p>
    //         <p>Job Seekers</p>
    //         <p>Contact Us</p>
    //     </div>
    //     <div>
    //         <img src ={assets.images.facebook}/>
    //         <img src ={assets.images.instagram}/>
    //         <img src ={assets.images.pinterest}/>
    //         <img src ={assets.images.twitter}/>
    //     </div>
    // </div>
  );
};

export default FooterComponent;
