import React from "react";
import { MDBContainer,MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn } from "mdbreact";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import TopNavigation from 'components/pages/employer/header/topNavigation';
import SideNavigation from 'components/pages/employer/header/sideNavigation';
import Footer from 'components/pages/employer/header/Footer';
import StorageManager from "utils/StorageManager";
import "./ecommerce.css";
import MainContainer from "components/common/MainContainer";
import UserManager from "utils/UserManager";


const EcommercePage = () => {
  const isLoggedIn = UserManager.isLoggedIn();
  return (
    <MainContainer>
    <div className="flexible-content">
      
 
    <main>
    <MDBContainer>

    <section className="text-center my-5">
      <h2 className="h1-responsive font-weight-bold text-center my-5">
     Subscription Packages
      </h2>
      <p className="black-text text-center w-responsive mx-auto mb-5">
     
      We as Corp2Corp.jobs providing you free subscription for 60 days in this critical time. Unlimited Job Posts, Unlimited Resume Searches and Downloads   </p>
      <MDBRow center>
        <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
        <MDBCard
            className="card-image"
            style={{
              backgroundImage:
                'url("https://mdbootstrap.com/img/Photos/Others/pricing-table%20(6).jpg")'
            }}
          >
            <div className="text-white text-center pricing-card d-flex align-items-center rgba-indigo-strong py-3 px-3 rounded">
            <MDBCardBody>
              <h5 className="font-weight-bold mt-3">Free (COVID-19 PLAN)</h5>
              <div className="price priceFree pt-0">
                <h2 className="number red-text mb-0">0</h2>
              </div>
              <ul className="striped mb-1">
                <li>
                  <p>
                    <strong></strong> Unlimited Job Posting
                  </p>
                </li>
                <li>
                  <p>
                    <strong></strong> Unlimited Resume Download
                  </p>
                </li>
                <li>
                  <p>
                    <strong></strong> Wide Rage of Jobs
                  </p>
                </li>
                <li>
                  <p>
                    <strong>1</strong> User
                  </p>
                </li>
                
              </ul>
              <MDBBtn href="/trial" rounded color="danger" className="mb-4">
               Subscribe
              </MDBBtn>
            </MDBCardBody>
            </div>
          </MDBCard>
        </MDBCol>
        <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
        <MDBCard
            className="card-image"
         
          >
            <div className="text-black text-center pricing-card d-flex align-items-center white py-3 px-3 rounded">
            <MDBCardBody>
              <h5 className="font-weight-bold mt-3">Pro</h5>
              <div className="price price-paid pt-0">
                <h2 className="number mb-0">900</h2>
              </div>
              <ul className="striped mb-1">
                <li>
                  <p>
                    <strong></strong> Unlimited Job Posting
                  </p>
                </li>
                <li>
                  <p>
                    <strong></strong> Unlimited Resume Download
                  </p>
                </li>
                <li>
                  <p>
                    <strong></strong> Wide Rage of Jobs
                  </p>
                </li>
                <li>
                  <p>
                    <strong>1</strong> User
                  </p>
                </li>
                
              </ul>
              {!isLoggedIn &&(
              <MDBBtn href="/employer/register" onClick={() => { localStorage.setItem("Project_id","999") }} rounded color="danger" className="mb-4">
               Subscribe
              </MDBBtn>
              )}
                 {isLoggedIn &&(
              <MDBBtn href="/checkout/999" onClick={() => { localStorage.setItem("Project_id","999") }} rounded color="danger" className="mb-4">
               Subscribe
              </MDBBtn>
                 )}
            </MDBCardBody>
            </div>
          </MDBCard>
        </MDBCol>
               </MDBRow>
    </section>
    </MDBContainer>
          </main>
          
          </div> 
          </MainContainer>
  );
}

export default EcommercePage;