import React from 'react';
import Search from "./Search";

class SearchPage extends React.Component {
	render() {
		return (
			<div>
				<Search/>
			</div>
		);
	}
}

export default SearchPage;