
import React, { useState, useEffect } from "react";
import { Box,CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactGoogleLogin from "react-google-login";
import UserManager from "utils/UserManager";
import { LOGIN_GOOGLE_CLIENT_ID } from "constants/generalConstants";
import { loginWithSocialAction } from "services/endpoints/usermanagement/userManagementActions";
import { handleSocialLoginSuccess,handleSocialLoginFailure } from "./socialLoginHelper"


const GoogleLogin = ({ onLoginSuccess,onLoginFailed,isRegister }) => {
  const [isLoading, setLoading] = useState(false);
  const { data: loginResponse, error } = useSelector(
    state => state.userManagement.loginWithGoogle
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (loginResponse) {
      const redirect = handleSocialLoginSuccess(loginResponse, isRegister, onLoginSuccess,'google',dispatch);
      if (redirect) {
        history.push(redirect);
      }
      setLoading(false);
    }

    if (error) {
      handleSocialLoginFailure("google");
    }
  }, [loginResponse, error]);
  
  const onSuccess = (response) => {
    UserManager.logout1();
    setLoading(true);
    dispatch(loginWithSocialAction(response.accessToken,'google'));
  }

  const onFailure = (error) => {
    handleSocialLoginFailure("google");
    setLoading(false);
  }

  return (
    <Box className="social-login-item">
      {!isLoading ?
        <ReactGoogleLogin
          className="social-btn btn-google"
          clientId={LOGIN_GOOGLE_CLIENT_ID}
          buttonText="Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
          // render={renderProps => (
          //   <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
          // )}
        /> :
        <CircularProgress />
      }
    </Box>
  );
};

export default GoogleLogin;
