import React, { useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import OtpInput from "react-otp-input";
import { Grid, Box } from "@material-ui/core";
import { TextInput } from "components/common/FormInputs";
import { Formik, Form, ErrorMessage, Field, FastField } from "formik";
import { FormikTextInput, FormikChipInput } from "components/common/FormInputs/FormikInputs";
import * as Yup from "yup";
const ResetPassword = ({ open, handleClose, onSubmit }) => {
  const formRef = useRef();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Forgot Password</DialogTitle>
      <DialogContent>
        <DialogContentText>Please enter new password</DialogContentText>
      </DialogContent>
      <Box marginX={12} marginBottom={4}>
        <Grid container justify="center">
          <Formik
            enableReinitialize={true}
            initialValues={{
              password: "",
              confirmPassword: ""
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().required("This field is required"),
              confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match")
            })}
            onSubmit={(values, actions) => {
              onSubmit(values.password);
            }}
          >
            {({ errors, touched, handleSubmit, isSubmitting, submitForm }) => {
              formRef.current = submitForm;
              return (
                <Form name="resetPass" method="post" onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FastField
                        name="password"
                        type="password"
                        component={FormikTextInput}
                        fullWidth
                        labelText="Password"
                        size="small"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FastField
                        name="confirmPassword"
                        component={FormikTextInput}
                        fullWidth
                        type="password"
                        id="last_name"
                        labelText="Confirm Password"
                        size="small"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Box>
      <DialogActions>
        {/* <Button onClick={handleClose}>Cancel</Button> */}
        <Button
          onClick={() => {
            formRef.current();
          }}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPassword;
