import React, { useState } from "react";
import { Grid, Typography, FormGroup, FormControlLabel, Checkbox, FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { CardContainer, ContentGrid } from "../Register.styled";
import RegisterHeader from "../header/RegisterHeader";
import { Formik, Form, ErrorMessage, Field, FastField, FieldArray } from "formik";
import * as Yup from "yup";
import { FormikTextInput, FormikCheckBoxGroup, FormikCheckBox } from "components/common/FormInputs/FormikInputs";
import NextPrevious from "../header/NextPrevious";
import StorageManager from "utils/StorageManager";
import { JobService } from "services/endpoints/jobs/JobService";
import lodash from "lodash";
import { toast } from "react-toastify";
import AppButton from "components/common/Buttons";

const IndeedResume = ({ candidate }) => {
  return (
    <React.Fragment>
      <CardContainer>
        
        <ContentGrid style={{ marginTop: "5vw" }}>
          <IndeedForm candidate={candidate} />
        </ContentGrid>
      </CardContainer>
    </React.Fragment>
  );
};

const IndeedForm = ({ candidate }) => {
  //   "{
  // 	""uid"": 5,
  // 	""education"": [{
  // 		""eid"": 1,
  // 		""degree"": ""B.Tech"",
  // 		""college"": ""MEC"",
  // 		""city"": ""Namakkal"",
  // 		""field_of_study"": ""Computer Science"",
  // 		""currently_here"": 0,
  // 		""start_date"": ""01/12/2019"",
  // 		""end_date"": ""31/12/2019""
  // 	}]
  // }"

  let education = lodash.get(candidate, "education", null);
  if (!education) {
    education = [
      {
        eid: undefined,
        degree: "",
        college: "",
        city: "",
        field_of_study: "",
        currently_here: 0,
        start_date: "",
        end_date: ""
      }
    ];
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        uid: lodash.get(candidate, "uid", StorageManager.instance.getUid()),
        education: education
      }}
      validationSchema={Yup.object().shape({
        education: Yup.array()
          .of(
            Yup.object().shape({
              degree: Yup.string().required("This field is required"),
              college: Yup.string().required("This field is required"),
              city: Yup.string().required("This field is required"),
              field_of_study: Yup.string().required("This field is required"),
              start_date: Yup.string().required("This field is required")
            })
          )
          .required()
      })}
      onSubmit={(values, actions) => {
        console.log("Data", values);
        return JobService.updateEducation(values).then(response => {
          console.log("response", response);
          if (response.status === 1) {
            toast.success("Successfully saved");
            return Promise.resolve(response);
          } else {
            toast.error("Error saving");
          }
          return Promise.reject(response);
        });
      }}
    >
      {({ errors, touched, handleSubmit, isSubmitting, submitForm, values }) => {
        return (
          <>
            <Form name="login" method="post" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <FieldArray
                  name="education"
                  render={arrayHelpers => (
                    <React.Fragment>
                      {values.education &&
                        values.education.map((education, index) => (
                          <React.Fragment>
                            <Grid item sm={6} xs={12}>
                              <FastField
                                name={`education[${index}].degree`}
                                component={FormikTextInput}
                                fullWidth
                                id="degree"
                                labelText="Degree"
                                size="small"
                                placeholder="e.g BSc, BA, PhD"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <FastField
                                name={`education[${index}].college`}
                                component={FormikTextInput}
                                fullWidth
                                id="college"
                                labelText="Collage or University"
                                size="small"
                                placeholder="Enter university name"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <FastField
                                style={{ marginBottom: "2em" }}
                                name={`education[${index}].field_of_study`}
                                component={FormikTextInput}
                                fullWidth
                                id="field_of_study"
                                labelText="Field of study"
                                size="small"
                                placeholder="e.g Biology, Psychology"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <FastField
                                style={{ marginBottom: "2em" }}
                                name={`education[${index}].city`}
                                component={FormikTextInput}
                                fullWidth
                                id="city"
                                labelText="City"
                                size="small"
                                placeholder="Enter city name"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>Time Period</Typography>
                            </Grid>
                            <Grid container sm={10} xs={12} alignItems="center" spacing={1}>
                              <Grid item xs={12}>
                                <FastField
                                  name={`education[${index}].currently_here`}
                                  component={FormikCheckBox}
                                  fullWidth
                                  id="currently_here"
                                  labelText="I currently study here"
                                  size="small"
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item sm={6} xs={12} md={5}>
                                <FastField
                                  name={`education[${index}].start_date`}
                                  component={FormikTextInput}
                                  type="date"
                                  fullWidth
                                  id="start_date"
                                  labelText="Start Date"
                                  size="small"
                                  variant="outlined"
                                />
                              </Grid>
                              {!values["currently_here"] && (
                                <React.Fragment>
                                  {/* <Grid item sm={12} xs={12} md={2}>
                        <Typography align="center">To</Typography>
                      </Grid> */}
                                  <Grid item sm={6} xs={12} md={5}>
                                    <FastField
                                      name={`education[${index}].end_date`}
                                      component={FormikTextInput}
                                      type="date"
                                      fullWidth
                                      id="end_date"
                                      labelText="End Date"
                                      size="small"
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid item xs={12} alignContent="flex-end" justify="flex-end">
                                    <AppButton primary style={{ background: "red" }} onClick={() => arrayHelpers.remove(index)} type="Small">
                                      - Remove Education
                                    </AppButton>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <hr />
                                  </Grid>
                                </React.Fragment>
                              )}
                            </Grid>
                          </React.Fragment>
                        ))}
                      <AppButton
                        style={{ marginTop: "2vw" }}
                        primary
                        selected
                        onClick={() =>
                          arrayHelpers.push({
                            eid: undefined,
                            degree: "",
                            college: "",
                            city: "",
                            field_of_study: "",
                            currently_here: 0,
                            start_date: "",
                            end_date: ""
                          })
                        }
                      >
                        + Add Education
                      </AppButton>
                    </React.Fragment>
                  )}
                />
              </Grid>
            </Form>

            <NextPrevious
              style={{ marginTop: "8vw" }}
              validateForm={increment => {
                submitForm().then(response => {
                  console.log("submit value", response);
                  increment();
                });
              }}
            />
            {/* <pre>
              <strong>Errors:</strong> {JSON.stringify(errors, null, 2)}
              <strong>Touched:</strong> {JSON.stringify(touched, null, 2)}
              {formValues && <strong>Submitted values:</strong>}
              {JSON.stringify(formValues, null, 2)}
            </pre> */}
          </>
        );
      }}
    </Formik>
  );
};

export default IndeedResume;
