import React, { useEffect, useState, useRef } from "react";
import { Redirect, Route } from "react-router-dom";
import FullScreenLoader from "components/common/FullScreenLoader/FullScreenLoader";
import UserManager from "./UserManager";
import { toast } from "react-toastify";
import lodash from "lodash";
const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const [loggedIn, setLoggedIn] = useState({ login: false, loading: true });
  const loadingDiv = useRef(<FullScreenLoader />);

  useEffect(() => {
    const loggedIn = UserManager.isLoggedIn();
    if (!loggedIn) {
      toast.warn("Please login");
    }
    setLoggedIn({ login: loggedIn, loading: false });
  }, []);

  return (
    <Route
      {...rest}
      render={props => {
        return loggedIn.loading ? (
          loadingDiv.current
        ) : loggedIn.login ? (
          typeof roles === "undefined" || lodash.difference(roles, UserManager.getRoles()).length === 0 ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/404",
                state: { from: props.location }
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/jobseekers/login",
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
