import React from "react";
import { Link } from "react-router-dom";
import { Grid, Hidden, Paper, Container, Typography, TextField, FormControl, InputAdornment, Icon, Button } from "@material-ui/core";

import MainContainer from "../components/common/MainContainer";

const NotFoundPage = () => {
  return (
    <MainContainer>
    <Container style={{ backgroundColor: "#F7F7F7", padding: "8vw 0px" }}>
      <Grid container component="main" justify="center">
    <div>
      <h4>404 Page Not Found</h4>
      <Link to="/"> Go back to homepage </Link>
    </div>
    </Grid>
    </Container>

    </MainContainer>
  );
};

export default NotFoundPage;
