import React from "react";
import { Grid, Typography, FormGroup, FormControlLabel, Checkbox, FormControl, Select, MenuItem, InputLabel, Box, Button } from "@material-ui/core";
import { CardContainer, ContentGrid } from "../Register.styled";
import RegisterHeader from "../header/RegisterHeader";
import { Formik, Form, ErrorMessage, Field, FastField, FieldArray } from "formik";
import * as Yup from "yup";
import { FormikTextInput, FormikCheckBox } from "components/common/FormInputs/FormikInputs";
import NextPrevious from "../header/NextPrevious";
import InfoIcon from "@material-ui/icons/Info";
import AppButton from "components/common/Buttons";
import { JobService } from "services/endpoints/jobs/JobService";
import StorageManager from "utils/StorageManager";
import { toast } from "react-toastify";
import lodash from "lodash";
const WorkExperience = ({ candidate }) => {
  return (
    <React.Fragment>
      <CardContainer>
        <RegisterHeader title="Work Experience" totalSteps={6} currentStep={2} />
        <Box style={{ background: "#F0FDFC", padding: "10px", marginTop: "2vw", display: "flex", alignItems: "center" }} justifyContent="center">
          <InfoIcon align="center" style={{ marginTop: "2px" }} />

          <Typography align="center"> Filling out 2 or more work experiences will double your chances of being contacted by an employer.</Typography>
        </Box>
        <ContentGrid style={{ marginTop: "5vw" }}>
          <WorkExperienceForm candidate={candidate} />
        </ContentGrid>
      </CardContainer>
    </React.Fragment>
  );
};

const WorkExperienceForm = ({ candidate }) => {
  //   {
  //         "uid": 5,
  //         "work_experience": [{
  //                 "wid":"3",
  //                 "job_title": "Senior Software Engineer",
  //                 "company_name": "Manipal",
  //                 "city": "Bangalore",
  //                 "currently_here": 1,
  //                 "start_date": "01/12/2019",
  //                 "end_date": "31/12/2019"
  //         }]
  // }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        uid: StorageManager.instance.getUid(),
        // work_experience: [
        //   {
        //     job_title: "",
        //     company_name: "",
        //     city: "",
        //     currently_here: false,
        //     start_date: "",
        //     end_date: "",
        //     description: ""
        //   }
        // ]
        work_experience: lodash.get(candidate, "work_experience", [])
      }}
      validationSchema={Yup.array().of(
        Yup.object().shape({
          job_title: Yup.string().required("This field is required"),
          company_name: Yup.string().required("This field is required"),
          city: Yup.string().required("This field is required"),
          start_date: Yup.string().required("This field is required")
        })
      )}
      onSubmit={(values, actions) => {
        console.log("onSubmit", values);
        return JobService.updateWorkHistory(values).then(response => {
          console.log("response", response);
          if (response.status === 1) {
            toast.success("Successfully saved");
            return Promise.resolve(response);
          } else {
            toast.error("Error saving");
          }
          return Promise.reject(response);
        });
      }}
    >
      {({ errors, touched, handleSubmit, isSubmitting, values, submitForm }) => {
        return (
          <>
            <Form name="login" method="post" onSubmit={handleSubmit}>
              <FieldArray
                name="work_experience"
                render={arrayHelpers => (
                  <React.Fragment>
                    {values.work_experience &&
                      values.work_experience.map((experience, index) => (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography>{`${index + 1}. ${experience.job_title} - ${experience.company_name}`}</Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <FastField
                              name={`work_experience[${index}].job_title`}
                              component={FormikTextInput}
                              fullWidth
                              labelText="Job Title"
                              size="small"
                              placeholder="Software engineer, IT Analyst etc.,"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <FastField
                              name={`work_experience[${index}].company_name`}
                              component={FormikTextInput}
                              fullWidth
                              labelText="Company name"
                              size="small"
                              placeholder="Enter company name"
                              variant="outlined"
                            />
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <FastField
                              style={{ marginBottom: "2em" }}
                              name={`work_experience[${index}].city`}
                              component={FormikTextInput}
                              fullWidth
                              labelText="City"
                              size="small"
                              placeholder="Enter city name"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>Time Period</Typography>
                          </Grid>
                          <Grid container sm={12} spacing={1} item>
                            <Grid xs={12}>
                              <FastField
                                name={`work_experience[${index}].currently_here`}
                                component={FormikCheckBox}
                                fullWidth
                                labelText="I currently work here"
                                size="small"
                                variant="outlined"
                              />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                              <FastField
                                name={`work_experience[${index}].start_date`}
                                component={FormikTextInput}
                                type="date"
                                fullWidth
                                id="start_date"
                                labelText="Start Date"
                                size="small"
                                variant="outlined"
                              />
                            </Grid>

                            {!experience.currently_here && (
                              <Grid item sm={6} xs={12}>
                                <FastField
                                  name={`work_experience[${index}].end_date`}
                                  component={FormikTextInput}
                                  type="date"
                                  fullWidth
                                  labelText="End Date"
                                  size="small"
                                  variant="outlined"
                                />
                              </Grid>
                            )}

                            <Grid item sm={12} style={{ marginTop: "2em" }}>
                              <FastField
                                name={`work_experience[${index}].description`}
                                component={FormikTextInput}
                                fullWidth
                                multiline
                                labelText="Description"
                                size="small"
                                placeholder="Describe your position and any significant accomplishments"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} alignContent="flex-end" justify="flex-end">
                            <AppButton primary style={{ background: "red" }} onClick={() => arrayHelpers.remove(index)}>
                              - Remove Experience
                            </AppButton>
                          </Grid>
                          <Grid item xs={12}>
                            <hr />
                          </Grid>
                        </Grid>
                      ))}
                    <AppButton
                      primary
                      selected
                      onClick={() =>
                        arrayHelpers.push({
                          job_title: "",
                          company_name: "",
                          city: "",
                          currently_here: 0,
                          start_date: "",
                          end_date: "",
                          description: ""
                        })
                      }
                    >
                      + Add Experience
                    </AppButton>
                  </React.Fragment>
                )}
              />
              <Grid xs={12} style={{ marginTop: "2em" }}>
                <FormControlLabel control={<Checkbox value="noExpire" />} label="I don’t have any work experience yet" />
              </Grid>
            </Form>
            <NextPrevious
              style={{ marginTop: "8vw" }}
              validateForm={increment => {
                submitForm().then(response => {
                  console.log("submit value", response);
                  increment();
                });
              }}
            />
            {/* <pre>
              <strong>Errors:</strong> {JSON.stringify(errors, null, 2)}
              <strong>Touched:</strong> {JSON.stringify(touched, null, 2)}
              {formValues && <strong>Submitted values:</strong>}
              {JSON.stringify(formValues, null, 2)}
            </pre> */}
          </>
        );
      }}
    </Formik>
  );
};

const AdditionalInformations = () => {
  return (
    <div>
      <ContentGrid>
        <Formik
          initialValues={{
            UserName: "",
            Password: ""
          }}
          validationSchema={Yup.object().shape({
            UserName: Yup.string().required("Please enter your email/mobile"),
            Password: Yup.string().required("Please enter your password"),
            salary: Yup.number().required("Please enter salary")
          })}
          onSubmit={(values, actions) => {
            actions.resetForm();
            if (loginAction) {
              loginAction(values.UserName, values.Password);
            }
          }}
        >
          {({ errors, touched, handleSubmit, isSubmitting }) => {
            return (
              <Form name="login" method="post" onSubmit={handleSubmit}>
                <Typography>Additional Informations</Typography>
                <FastField
                  style={{ marginBottom: "2em" }}
                  name="title"
                  component={FormikTextInput}
                  fullWidth
                  multiline
                  id="title"
                  size="small"
                  placeholder="Describe your position and any significant accomplishments"
                  variant="outlined"
                />
              </Form>
            );
          }}
        </Formik>
        <NextPrevious />
      </ContentGrid>
    </div>
  );
};

export default WorkExperience;
