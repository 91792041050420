import { AUTHENTICATE, REGISTER, VERIFY_OTP, GENERATE_OTP, RESET_PASSWORD, SOCIAL_LOGIN,REGISTER_WITH_OTP,EMAIL_REGISTER,UPADTE_RESUME_REF,LOGIN_WITH_SOCIAL,LINKEDIN_TOKEN,LOGIN_STATITICS,EMPLOYER_DETAILS,EMPLOYER_PROFILE_EDIT,EMPLOYER_JOB_ACTIVATE,EMPLOYER_CANDIDATE_DETAILS,EMPLOYER_JOB_COUNT } from "constants/actionTypes";
import { UserManagementService } from "services/endpoints/usermanagement/UserManagementService";

export const authenticateAction = (username, password) => {
  return {
    actionType: AUTHENTICATE,
    callAPI: () => UserManagementService.authenticate(username, password),
    stateObject: "authentication",
    isTransient: true
  };
};

export const registerAction = (email, password, role = "candidate", mobile, fname, mobile1) => {
  return {
    actionType: REGISTER,
    callAPI: () => UserManagementService.register(email, password, role, mobile, fname, mobile1),
    stateObject: "register",
    isTransient: true
  };
};

export const generateOtpAction = (username, type) => {
  return {
    actionType: GENERATE_OTP,
    callAPI: () => UserManagementService.generateOtp(username, type),
    stateObject: "generateOtp",
    isTransient: true
  };
};

export const socialRegisterAction = (password, role, mobile, fname) => {
  return {
    actionType: GENERATE_OTP,
    callAPI: () => UserManagementService.socialRegister(password, role, mobile, fname),
    stateObject: "socialRegister",
    isTransient: true
  };
};


export const verifyOtpAction = (otp, username, type) => {
  return {
    actionType: VERIFY_OTP,
    callAPI: () => UserManagementService.verifyOtp(otp, username, type),
    stateObject: "verifyOtp",
    isTransient: true
  };
};

// export const resetPasswordAction = (username, password) => {
//   return {
//     actionType: RESET_PASSWORD,
//     callAPI: () => UserManagementService.resetPassword(username, password),
//     stateObject: "resetPassword",
//     isTransient: true
//   };
// };

export const socialLoginAction = (uid,role) => {
  return {
    actionType: SOCIAL_LOGIN,
    callAPI: () => UserManagementService.socialLogin(uid,role),
    stateObject: "socialLogin",
    isTransient: true
  };
};

export const loginWithSocialAction = (access_token, provider) => {
  const stateObject = provider === "google" ? "loginWithGoogle" :
    provider === "facebook" ? "loginWithFacebook" :
    provider === "linkedin" ? "loginWithLinkedIn":"" ;
  return {
    actionType: LOGIN_WITH_SOCIAL,
    callAPI: () => UserManagementService.loginWithSocial(access_token,provider),
    stateObject: stateObject,
    isTransient: true
  };
};

export const linkedInTokenAction = (request) => {
  return {
    actionType: LINKEDIN_TOKEN,
    callAPI: () => UserManagementService.linkedInToken(request),
    stateObject: "linkedInToken",
    isTransient: true
  };
};

export const registerWithOtpAction = (request) => {
  return {
    actionType: REGISTER_WITH_OTP,
    callAPI: () => UserManagementService.registerWithOtp(request),
    stateObject: "registerWithOtp",
    isTransient: true
  };
};

export const emailRegisterAction = (request) => {
  return {
    actionType: EMAIL_REGISTER,
    callAPI: () => UserManagementService.emailRegister(request),
    stateObject: "registerWithOtp",
    isTransient: false
  };
};

export const updateResumeRefAction = (request) => {
  return {
    actionType: UPADTE_RESUME_REF,
    callAPI: () => UserManagementService.updateResumeRef(request),
    stateObject: "uploadResume",
    isTransient: true
  };
};

// export const editMobileNumberAction = () => {
//   return {
//     actionType: EDIT_MOBILE_NUMBER
//   };
// };
  
// export const registrationCompleteAction = () => {
//   return {
//     actionType: REGISTRATION_COMPLETE
//   };
// };

export const resetPasswordAction = (request) => {
  return {
    actionType: RESET_PASSWORD,
    callAPI: () => UserManagementService.resetPassword(request),
    stateObject: "resetPassword",
    isTransient: false
  };
};

export const loginStatiticsAction = (request,authorizationTocken) => {
  return {
    actionType: LOGIN_STATITICS,
    callAPI: () => UserManagementService.loginStatitics(request,authorizationTocken),
    stateObject: "loginStatitics",
    isTransient: true
  };
};

export const employerDetailsAction = (uid) => {
  return {
    actionType: EMPLOYER_DETAILS,
    callAPI: () => UserManagementService.employerDetails(uid),
    stateObject: "employerDetails",
    isTransient: false
  };
};

export const employerProfileEditAction = (data) => {
  return {
    actionType: EMPLOYER_PROFILE_EDIT,
    callAPI: () => UserManagementService.employerProfileEdit(data),
    stateObject: "employerProfileEdit",
    isTransient: false
  };
};

export const employerJobActivateAction = (data) => {
  return {
    actionType: EMPLOYER_JOB_ACTIVATE,
    callAPI: () => UserManagementService.updateJobState(data),
    stateObject: "employerJobActivate",
    isTransient: false
  };
};

export const getCandidateDetailsAction = (data) => {
  return {
    actionType: EMPLOYER_CANDIDATE_DETAILS,
    callAPI: () => UserManagementService.getCandidateDetails(data),
    stateObject: "candidateDetails",
    isTransient: false
  };
};

export const getEmployerJobCountAction = (data) => {
  return {
    actionType: EMPLOYER_JOB_COUNT,
    callAPI: () => UserManagementService.getEmployerJobCount(data),
    stateObject: "employerJobCount",
    isTransient: false
  };
};