import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import { Grid, Hidden, Paper, Container, Typography, TextField, FormControl, InputAdornment, Icon, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import assets from "../../../../assets";
import { Image, Root, VerticalCenter, ImageGrid, LoginCard } from "../../login/LoginPage.styled";
import MyAppBar from "../../../common/myappbar/MyAppBar";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { TextInput } from "../../../common/FormInputs";
import LockIcon from "@material-ui/icons/Lock";
import MainContainer from "../../../common/MainContainer";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ImageIcon, StyledLink } from "components/common/Misc";
import { authenticateAction, registerAction, generateOtpAction, verifyOtpAction,socialLoginAction } from "services/endpoints/usermanagement/userManagementActions";
import RegisterForm from "./RegisterForm";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import OtpVerification from "./otpverification/OtpVerification";
import SocialLogin from "components/common/SocialLoginRecruiter/SocialLogin";
import UserManager from "utils/UserManager";
import StorageManager from "utils/StorageManager";
import axios from 'axios';
import {Helmet} from "react-helmet";
const RegistrationPage = () => {
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const userInfo = useRef({});
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, data, error } = useSelector(state => state.userManagement.register);
  const { loginLoading, data: loginData, error: loginError } = useSelector(state => state.userManagement.authentication);
  const { data: generateOtpData, error: generateOtpError } = useSelector(state => state.userManagement.generateOtp);
  const { data: verifyOtpData, error: verifyOtpError } = useSelector(state => state.userManagement.verifyOtp);
  const { data: socialLoginData, error: socialLoginError } = useSelector(state => state.userManagement.socialLogin);


  useEffect(() => {
    if (error) {
      toast.error("Error, try again later");
    } else if (data) {
      login(userInfo.current.email, userInfo.current.password);
    } else if (generateOtpData && generateOtpData.status === 1) {
      toast.success("Please enter the OTP you received on your email to complete the registration process.");
      setOpenOtpModal(true);
    } else if (generateOtpData && generateOtpData.status === 0) {
      toast.error(generateOtpData.message);
    } else if (verifyOtpData && verifyOtpData.status === 1) {
      register(userInfo.current.email, userInfo.current.password, userInfo.current.role);
    } else if (verifyOtpData && verifyOtpData.status === 0) {
      toast.error("Error, Invalid otp");
    } else if (loginData) {
      toast.success("Successfully Registered. Please complete your onboarding process.");
      if (UserManager.isCandidate()) {
        history.push("/onboarding");
      } else if (UserManager.isEmployer()) {
        history.push("/employer-dashboard");
      } else {
        //Recruiter
        history.push("/recruiter-dashboard");
      }
    } else if (socialLoginData) {
      const access_token =   localStorage.getItem("google_token");
      const headers = {
        'Content-Type': 'application/json',
        //"X-CSRF-Token": localStorage.getItem("anonymous_toke")
      }
      
      const data = {
        "access_token": access_token
  
      };
      axios.post(`https://live-corp2corp.pantheonsite.io/user/login/google?_format=json`, data,{
        headers: headers,
       withCredentials: true
      })
      .then(res => {
        console.log("dsaasd",res);
        if ((res.data.current_user.roles).indexOf("candidate") > -1) {
        
          //StorageManager.instance.setAccessToken(result.access_token);
        
 
          StorageManager.instance.setUid(res.data.current_user.uid);
          StorageManager.instance.setXCSRFToken(res.data.csrf_token);
          StorageManager.instance.setLogoutToken(res.data.logout_token);
          //In the array!
          console.log("Success1candidates",res.data.current_user.roles);
          const headers1 = {
            'Content-Type': 'application/json',
            "X-CSRF-Token": res.data.csrf_token
          }
          
          const data1 = {
          };
          console.log(data1);
          axios.post(`https://live-corp2corp.pantheonsite.io/jwt/token?_format=json`,data1,{
           headers: headers1,
           withCredentials: true
          })
          .then(response => {
            StorageManager.instance.setAccessToken(response.data.token);
            console.log("response1",response);
            history.push("/onboarding");
          });
         // const dispatch = useDispatch();
         // dispatch(socialLoginAction(access_token));
      } else if ((res.data.current_user.roles).indexOf("employer") > -1) {

       // StorageManager.instance.setAccessToken(result.access_token);
        localStorage.setItem("xcsrf_token",res.data.csrf_token);
        localStorage.setItem("logout_token",res.data.logout_token);
        localStorage.setItem("uid",res.data.current_user.uid);
        localStorage.setItem("basic_token","aGFuaXNoQHdpbm5vcHJvLmNvbTox");
          //Not in the array
          console.log("Success1employer",res.data.current_user.roles);
          const headers1 = {
            'Content-Type': 'application/json',
            "X-CSRF-Token": res.data.csrf_token
          }
          
          const data1 = {
          };
          console.log(data1);
          axios.post(`https://live-corp2corp.pantheonsite.io/jwt/token?_format=json`,data1,{
           headers: headers1,
           withCredentials: true
          })
          .then(response => {
            StorageManager.instance.setAccessToken(response.data.token);
            console.log("response1",response);
            history.push("/employer/package");
          });
      }else if ((res.data.current_user.roles).indexOf("recruiter") > -1) {

        // StorageManager.instance.setAccessToken(result.access_token);
         localStorage.setItem("xcsrf_token",res.data.csrf_token);
         localStorage.setItem("logout_token",res.data.logout_token);
         localStorage.setItem("uid",res.data.current_user.uid);
         localStorage.setItem("basic_token","aGFuaXNoQHdpbm5vcHJvLmNvbTox");
           //Not in the array
           console.log("Success1employer",res.data.current_user.roles);
           const headers1 = {
             'Content-Type': 'application/json',
             "X-CSRF-Token": res.data.csrf_token
           }
           
           const data1 = {
           };
           console.log(data1);
           axios.post(`https://live-corp2corp.pantheonsite.io/jwt/token?_format=json`,data1,{
            headers: headers1,
            withCredentials: true
           })
           .then(response => {
             StorageManager.instance.setAccessToken(response.data.token);
             console.log("response1",response);
             history.push("/recruiter/package");
           });
       }else{
        localStorage.setItem("temp_xcsrf_token",res.data.csrf_token);
        //localStorage.setItem("telogout_token",res.data.logout_token);
        localStorage.setItem("temp_uid",res.data.current_user.uid);
        //localStorage.setItem("basic_token","aGFuaXNoQHdpbm5vcHJvLmNvbTox");
        const headers_logout = {
          'Content-Type': 'application/json',
          //'Authorization': 'bearer '+token,
          //'X-CSRF-Token':csrf
        }
        axios.get('https://live-corp2corp.pantheonsite.io/user/logout?_format=json&token='+res.data.logout_token,{withCredentials: true},{
          headers: headers_logout
        })
        .then(res => {
          const candidates = res.data;
      
        })
        //setOpenRoleModal(true);
      }
        //console.log(res.data);
       // window.location.reload();
      })
      //dispatch(socialLoginGoogleAction(access_token));
      
     
     /* UserManagementService.refreshToken()
        .then(result => {
          toast.success("Successfully logged in.");
          if (UserManager.isCandidate()) {
            history.push("/onboarding");
          } else if (UserManager.isEmployer()) {
            history.push("/");
          } else {
            //Recruiter
            history.push("/add-candidate");
          }
        })
        .catch(err => {});*/
    } else if (socialLoginError) {
      toast.error("Error, Logging in");
    }
  }, [data, error, loginData, loginError, generateOtpData, generateOtpError, verifyOtpData, verifyOtpError,socialLoginData]);

  const handleOtpClose = () => {
    setOpenOtpModal(false);
  };

  const login = (email, password) => {
    dispatch(authenticateAction(email, password));
  };

  const register = (email, password, role) => {
    dispatch(registerAction(email, password, "recruiter"));
  };

  const generateOtp = (email, password, role) => {
    userInfo.current = { email, password, role };
    dispatch(generateOtpAction(email, "Register"));
  };

  const verifyOtp = otp => {
    dispatch(verifyOtpAction(otp, userInfo.current.email, "Register"));
  };
  const onGoogleSuccess = response => {
    console.log("hey how are ytou",response.response);
  
    console.log("hey how are ytouq1",localStorage.getItem("temp_uid"));
    console.log(response.response.accessToken);
    //dispatch(socialLoginGoogleAction(access_token));
    dispatch(socialLoginAction(localStorage.getItem("temp_uid"), "employer"));
    localStorage.setItem("google_token",response.response.accessToken);
   
  };

  return (
    <MainContainer>
        <Helmet>
    <meta charSet="utf-8" />
    <title>Register - Corp2Corp Jobs</title>
    <meta name="keywords" content="job, Register,login,job search, jobs, work, employment, career, resumes, job vacancies,corp2corp,corp2corp.jobs,USA Jobs"></meta>
    <link rel="canonical" href="http://corp2corp.jobs/register" />
    <meta name="description" content="Corp2Corp is job portal to search and apply for jobs in US. Companies can find and hire candidates with work permits in United States."></meta>
       </Helmet>
      <Container style={{ backgroundColor: "#F7F7F7", padding: "8vw 0px" }}>
        <Grid container component="main" justify="center">
          <ImageGrid item sm={5} md={5}>
            <Hidden xsDown>
              <VerticalCenter style={{ padding: "8vw 0px" }}>
                <Typography variant="h6" align="center" style={{ fontFamily: "'Poppins', sans-serif" }}>
                  One Search
                </Typography>
                <Typography variant="h6" align="center" style={{ fontFamily: "'Poppins', sans-serif" }}>
                  {" "}
                  Million Opportunities
                </Typography>
                <Image src={assets.images.loginImg} />
              </VerticalCenter>
            </Hidden>
          </ImageGrid>

          <ImageGrid item xs={12} sm={6} md={6}>
            <VerticalCenter>
              <LoginCard elevation={12}>
                <Typography align="center" variant="h6" style={{ fontFamily: "'Poppins', sans-serif" }}>
                Recruiter Register
                </Typography>
                <Typography align="center" variant="caption" display="block" style={{ fontFamily: "'Poppins', sans-serif", paddingTop: "10px" }}>
                Register now and get the access of wide range of Skillset and Resources.
                </Typography>
                <Grid container item justify="center">
                  <FormControl style={{ marginTop: "2em", padding: "0px 10%" }} fullWidth>
                    <RegisterForm registerAction={generateOtp} loading={loading || loginLoading} />
                  </FormControl>
                  <Box align="center" style={{ marginTop: "1em", width: "100%" }} maxWidth="sm">
                    <hr />
                    <SocialLogin onGoogleSuccess={onGoogleSuccess} />
                    <hr />
                    <StyledLink to="/recruiter/login">
                      <Button variant="outlined" fullWidth display="block" style={{ marginTop: "1em", marginBottom: "1em", width: "80%" }}>
                        <Typography variant="caption" style={{ fontFamily: "'Poppins', sans-serif", padding: "6px" }} display="inline">
                          Login
                        </Typography>
                      </Button>
                    </StyledLink>
                  </Box>
                </Grid>
              </LoginCard>
            </VerticalCenter>
          </ImageGrid>

          {/* <Grid md={1} item>
          <Hidden smDown>{""}</Hidden>
        </Grid> */}
        </Grid>
      </Container>
      <OtpVerification
        open={openOtpModal}
        handleClose={handleOtpClose}
        onSubmit={otp => {
          verifyOtp(otp);
        }}
      />
    </MainContainer>
  );
};

export default RegistrationPage;
