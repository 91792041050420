import React, { useContext, useEffect, useState, useRef } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { Grid, Container, Typography, FormControl, InputAdornment, Icon, Button, FormGroup, LinearProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import MainContainer from "../../../common/MainContainer";
import { TextInput } from "../../../common/FormInputs";
import { CardContainer, HeaderGrid, ContentGrid, FormGrid } from "../Register.styled";
import assets from "assets";
import ImageIcon, { StyledLink } from "../../../common/Misc";
import PublishIcon from "@material-ui/icons/Publish";
import RegisterHeader from "../header/RegisterHeader";
import { Formik, Form, ErrorMessage, Field, FastField } from "formik";
import { FormikTextInput, FormikChipInput,FormikPlacesInput } from "components/common/FormInputs/FormikInputs";
import * as Yup from "yup";
import NextPrevious from "../header/NextPrevious";
import { OnBoarding } from "./useSteps";
import { updateBasicInfoAction } from "services/endpoints/jobs/jobActions";
import { toast } from "react-toastify";
import StorageManager from "utils/StorageManager";
import lodash from "lodash";
import { DropzoneArea } from "material-ui-dropzone";
import { JobService } from "services/endpoints/jobs/JobService";
import AlgoliaPlaces from "algolia-places-react";
const PersonalDetails = ({ skills, industries, candidate, uid, isRecruiter }) => {
  return (
    <CardContainer>
      
      <ContentGrid item md={12}>
        <PersonalDetailsForm skills={skills} industries={industries} candidate={candidate} uid={uid} isRecruiter={isRecruiter} />
      </ContentGrid>
    </CardContainer>
  );
};

const PersonalDetailsForm = ({ skills, industries, candidate, uid, isRecruiter }) => {
  const resumeRef = useRef({});
  const [state, increment, decrement] = useContext(OnBoarding);
  const dispatch = useDispatch();
  const { loading, data, error } = useSelector(state => state.job.basicInfo);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const updateBasicInfo = values => {
    dispatch(updateBasicInfoAction(values));
  };

  useEffect(() => {
    resumeRef.current = { fid: lodash.get(candidate, "resume_fid", ""), path: lodash.get(candidate, "resume_url", "") };
  }, [candidate]);

  useEffect(() => {
    if (error) {
      toast.error("Error, try again later.");
    }
    if (data) {
      toast.success("Updated successfully.");
      increment();
    }
  }, [data, error]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        resume_fid: lodash.get(candidate, "resume_fid", ""),
        first_name: lodash.get(candidate, "first_name", ""),
        last_name: lodash.get(candidate, "last_name", ""),
        email: lodash.get(candidate, "email", ""),
        mobile_number: lodash.get(candidate, "mobile_number", ""),
        location: lodash.get(candidate, "location", ""),
        total_experience: lodash.get(candidate, "total_experience", ""),
        additional_skills: lodash.get(candidate, "additional_skills", ""),
        key_skills: lodash.get(candidate, "key_skills", []),
        prefer_industry: lodash.get(candidate, "prefer_industry", [])
      }}
      validationSchema={Yup.object().shape({
        resume_fid: Yup.string()
          .required("This field is required")
          .nullable(),
        first_name: Yup.string()
          .required("This field is required")
          .nullable(),
        last_name: Yup.string()
          .required("This field is required")
          .nullable(),
        email: Yup.string()
          .required("This field is required")
          .nullable(),

        mobile_number: Yup.string()
          .required("This field is required")
          .nullable(),
        location: Yup.string()
          .required("This field is required")
          .nullable(),
        total_experience: Yup.number()
          .required("This field is required")
          .nullable(),
        key_skills: Yup.array()
          .required("This field is required")
          .nullable(),
        prefer_industry: Yup.array()
          .required("This field is required")
          .nullable()
      })}
      onSubmit={(values, actions) => {
        values["uid"] = StorageManager.instance.getUid();
        updateBasicInfo(values);
      }}
    >
      {({ errors, touched, handleSubmit, isSubmitting, values, setFieldValue }) => {
        return (
          <Form name="login" method="post" onSubmit={handleSubmit} autocomplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FastField
                  name="first_name"
                  component={FormikTextInput}
                  fullWidth
                  id="first_name"
                  labelText="First Name"
                  size="small"
                  placeholder="Enter your First Name"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FastField
                  name="last_name"
                  component={FormikTextInput}
                  fullWidth
                  id="last_name"
                  labelText="Last Name"
                  size="small"
                  placeholder="Enter your Last Name"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FastField
                  name="email"
                  component={FormikTextInput}
                  fullWidth
                  id="email"
                  disabled={!isRecruiter}
                  labelText="Email"
                  size="small"
                  placeholder="Email Id"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FastField
                  name="mobile_number"
                  component={FormikTextInput}
                  fullWidth
                  //type="number"
                  id="mobile_number"
                  size="small"
                  placeholder="Your primary mobile number"
                  labelText="Mobile Number"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated" for="current_experience">Current Location</label>
              <AlgoliaPlaces
      
        style={{ borderRadius: "10px" }}
       // value={form.values[field.name]}
      
        options={{
          appId: "plE79E3YU95N",
          apiKey: "ab6d2643563c402f95aaf5dca19afa95",
          language: "en",
          countries: ["us","in"],
          type: "city"
        }}
        id="location"
        name={'location'}
       
        onChange={e => setFieldValue('location', e.suggestion.name+','+e.suggestion.administrative+','+e.suggestion.country)}
        
        onSuggestions={({ rawAnswer, query, suggestions }) => {
          console.log("Query", query);
          console.log("rawAnswer", rawAnswer);
          console.log("suggestions", suggestions);
          //form.setFieldValue("location", query);
        }}
      />
               
              </Grid>
              <Grid item xs={12} sm={6}>
                <FastField
                  name="total_experience"
                  component={FormikTextInput}
                  fullWidth
                  type="number"
                  id="total_experience"
                  size="small"
                  placeholder="Total experience"
                  labelText="Total experience"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="key_skills"
                  component={FormikChipInput}
                  fullWidth
                  dataSource={skills}
                  dataSourceConfig={{ text: "name", value: "id" }}
                  id="key_skills"
                  placeholder="Enter your skills"
                  labelText="Key skills"
                  variant="outlined"
                  onKeyPress={e => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FastField
                  name="prefer_industry"
                  component={FormikChipInput}
                  fullWidth
                  dataSource={industries}
                  dataSourceConfig={{ text: "name", value: "id" }}
                  id="prefer_industry"
                  size="small"
                  placeholder="Prefer Industry"
                  labelText="Prefer Industry"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FastField
                  name="additional_skills"
                  component={FormikTextInput}
                  fullWidth
                  id="additional_skills"
                  size="small"
                  placeholder="Comma seperated Eg: PHP,C#,Java"
                  labelText="Addiotional Skills"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Resume :</Typography>
                {values["resume_fid"] ? (
                  <React.Fragment>
                    <a target="_blank" href={lodash.get(candidate, "resume_url", "")}>
                      {lodash.get(candidate, "resume_url", "")
                        ? lodash.get(candidate, "resume_url", "").substring(lodash.get(candidate, "resume_url", "").lastIndexOf("/") + 1)
                        : "resume.pdf"}
                    </a>
                    <DeleteIcon
                      color="action"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        // setResume({});
                        setFieldValue("resume_fid", "");
                        resumeRef.current = {};
                      }}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <DropzoneArea
                      acceptedFiles={[
                        "application/pdf",
                        "application/msword",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        ['docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
                      ]}
                      showAlerts={false}
                      onDrop={file => {
                        const reader = new FileReader();
                        reader.onload = () => {
                          // Do whatever you want with the file contents
                          const binaryStr = file;
                          console.log(binaryStr);

                          JobService.uploadResume(binaryStr, progressEvent => {
                            const percent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                            setFileUploadProgress(percent);
                            if (percent == 100) {
                              toast.success("Resume uploaded successfully.");
                            }
                            console.log("percent", percent);
                          }).then(result => {
                            const fid = lodash.get(result, "fid.0.value");
                            console.log("Fid", fid);
                            // setResume({ fid: fid });
                            resumeRef.current = { fid: fid };
                            setFieldValue("resume_fid", fid);
                          });
                        };
                        reader.readAsBinaryString(file);
                      }}
                      filesLimit={1}
                      showFileNames="true"
                      dropzoneText={"Drop your resume here (in pdf/word)"}
                    />
                    <LinearProgress variant="determinate" value={fileUploadProgress} />
                    <Typography color="error">{errors["resume_fid"] && errors["resume_fid"]}</Typography>
                  </React.Fragment>
                )}

                {/* <Button >
                <label htmlFor="fileUpload">Upload resume(Optional)</label>
                <input id="fileUpload" type="file" />
              </Button> */}
                {/* <TextInput id="resume" placeholder="Choose Resume" labelText="" variant="outlined" /> */}
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}>
                <Button type="submit" variant="contained" color="primary" style={{ marginTop: "1em", color: "white", width: "100%" }}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PersonalDetails;
