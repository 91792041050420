import axiosInstance from "./axios";
import endpoints from "./endpoints";
import StorageManager from "utils/StorageManager";

export default class API {
  static execute = async (
    url,
    httpMethod = "get",
    attrs = {
      body: {},
      params: {},
      data: {},
      headers: {}
    },
    hasAuth = false,
   // enableCookie = false,
    customToken
  ) => {
    console.log("Request attrs: ", attrs);
    const uid=StorageManager.instance.getUid();
    if (hasAuth) {
      const token = StorageManager.instance.getBasicToken();
      console.log("Enthado nannavathe",token)

      if (customToken) {
        //attrs.headers["Authorization"] = "basic " + customToken;
      } else if (token) {
        //attrs.headers["Authorization"] = "basic " + token;
      }
    }

    /*if (enableCookie) {
      attrs["withCredentials"] = true;
    }*/
    //TODO:: remove once API is ready
    // if (url == "v2/services/otp") {
    //   return new Promise((resolve, reject) => {
    //     if (attrs.data.action == "request_verification_code") {
    //       resolve({
    //         status:"verification_code_sent",
    //         message: "code sent successfully",
    //       });
    //     }else if (attrs.data.action == "verify_code") {
    //       resolve({
    //         uid:"1234",
    //         status:"verified",
    //         message: "code verified successfully",
    //       });
    //     }else if (attrs.data.action == "resend_verification_code") {
    //       resolve({
    //         status:"verification_code_sent",
    //         message: "code sent successfully",
    //       });
    //     }else if (attrs.data.action == "resend_verification_code_through_voice_call") {
    //       resolve({
    //         status:"verification_code_sent",
    //         message: "code sent successfully",
    //       });
    //     }else if(attrs.data.action == "reset_password") {
    //       resolve({
    //         status:"verification_code_sent",
    //         message: "code sent successfully",
    //       });
    //     }
    //   })
    // }
    
      // if (url == "v2/services/otp") {
      //   return new Promise((resolve, reject) => {
      //     if (attrs.data.action == "reset_password") {
      //    resolve({
      //       status:"verification_code_sent",
      //       message: "code sent successfully",
      //     });
      //   }else if (attrs.data.action == "reset_password_verify") {
      //     resolve({
      //    //   uid:"1234",
      //       status:"verified",
      //       message: "code verified successfully",
      //     });
      //   }
      //     });
      //   } 
        
    //     if (url.indexOf("user/login") !== -1) {
    //       return new Promise((resolve, reject) => {
    //         resolve({
    //           "current_user": {
    //             "uid": "1974",
    //             "name": "alexmjohn",
    //             "email": "alexmjohn01@gmail.com"
    //           },
    //           "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1OTcwNTA3MzMsImV4cCI6MTU5NzA1NDMzMywidWlkIjoiMTk3NCIsInJvbGVzIjpbImF1dGhlbnRpY2F0ZWQiLCJjYW5kaWRhdGUiXX0.JhgQwKn5GdVn_hJflqydEa53bSfXyNSf4wilSL86bpM",
    //           "status": 0,
    //           "csrf_token": "21-0UCKn8T8ug6IhWTcFi6ItgBNJqqz7TOGNO7pSTOA",
    //           "logout_token": "JNzYnnbdWQZiqnqhPtZuJ1LFK4VzriC3DY0fYYgSprI"
    //         });
    //         });
    //       }  
    // if (url == "services/linkedin") {
    //       return new Promise((resolve, reject) => {
    //           resolve(
    //             {"access_token":"AQW1FtVPaFuuxL_oCwyqWZVQg6w5A3LVehmowYOsaq0Zg873olatrdjobwWPzcgkb2ZY5jrDl6z1DTpk1pBfw1iqdbPfpVhTMsA5BFfTzuFffPs13p0CjJhz2bN7gmmtW8oQCHda4dlUoJoKDKLLzTTyUuAPPkmSkxjpGf48OimefrnJTlDfeJDL2tIcwzAvMaFICRmSP7-hUI9ifkzHv9qbv1zzNFISdiyoBWItt4YbiQ0s3mXozKW38mzrzfxR-LVgk9Tq0DZWVdzO6kkGEGVJrzCRxZHRnPCXZ4msWbBsx1N8fNCA0dBaPNEVXKL3srAMzo7kIvF8cDWj5PUpuKoYfPhS5A","expires_in":5184000}
    //           );
    //         });
    //       }  
        
    return await axiosInstance({
      method: httpMethod,
      url,
      ...attrs
    })
      .then(
        response => {
          console.log("BaseService", response);
          let code = response && response.status;
          let data = response && response.data && response.data.data;

          //overriding because api response format is not uniform across the app
          if (
            url === endpoints.login ||
            url === endpoints.register ||
            url === endpoints.generateOtp ||
            url === endpoints.verifyOtp ||
            url === endpoints.updateCandidateBasicInfo ||
            url === endpoints.updateEducation ||
            url === endpoints.updateWorkHistory ||
            url === endpoints.updateAdditionalInfo ||
            url === endpoints.flagJob ||
            url === endpoints.resetPassword ||
            url === endpoints.uploadResume ||
            url === endpoints.socialLogin ||
            url === endpoints.refreshToken ||
            url === endpoints.registerWithOtp ||
            url === endpoints.emailRegister ||
            url === endpoints.updateResumeRef ||
            url.indexOf(endpoints.loginWithSocial) !==-1  ||
            url === endpoints.linkedInToken ||
            url === endpoints.loginStatitics||
            url === endpoints.employerDetails+`${uid}/employer`||
            url === endpoints.employerProfileEdit||
            url === endpoints.EmployerJobActivate ||
            url === endpoints.employerJobCount ||
            url.indexOf("candidate-details") !==-1
          ) {
            data = response.data;
          } else if (url === endpoints.getXcsrfToken) {
            data = response.data;
          }

          if (code === 1 || code === 200 || code === 201) {
            return Promise.resolve(data);
          } else {
            console.log((response.data && response.data.message) || "Something went wrong while fetching!");

            return Promise.reject({
              response: code,
              data: data,
              message: response && response.data && response.data.message
            });
          }
        },
        error => {
          console.error("BaseService reject", error);
          return Promise.reject(error);
        }
      )
      .catch(err => {
        console.log("Err", err);
        const { status, statusText, data } = err.response;
        return Promise.reject(err);
      });
  };
}
