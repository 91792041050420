import React from "react";
import SocialLogin from "react-social-login";
import { Button } from "@material-ui/core";
import { Facebook } from "@material-ui/icons";
import {  MDBIcon } from "mdbreact";

const FacebookButton = (props) => {
  return (
    <Button
      variant="outlined"
      onClick={props.triggerLogin}
          color="primary"
          className="social-btn btn-facebook"
      startIcon={<MDBIcon fab icon="facebook" />}
      {...props}
    >
      Facebook
    </Button>
  );
};

export default SocialLogin(FacebookButton);
