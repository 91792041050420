import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainContainer from "../../common/MainContainer";
import FilterResults from "./filterresults/FilterResults";
import { Grid, Hidden } from "@material-ui/core";
import JobList from "./joblists/JobList";
import { getJobListAction, searchActionAction, getJobDetailAction } from "services/endpoints/jobs/jobActions";
import { JobListHolder } from "./JobListingPage.styled";
import List from "@material-ui/core/List";
import JobDetail from "./joblists/JobDetail";
import lodash from "lodash";
import Skeleton from "@material-ui/lab/Skeleton";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { MDBAlert } from "mdbreact";
import StorageManager from "utils/StorageManager";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Pagination from "material-ui-flat-pagination";


const JobListingPage = () => {
  const [item, setItem] = useState({});
  const [filter, setFilter] = useState({});
  
  const [offset, setOffset] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation();
  const { loading, data, error } = useSelector(state => state.job.jobList);

  const { location: jobLocation, title, category } = queryString.parse(location.search);
  console.log("location1", category);
  //console.log("location2", title);
  
  const { loading: jobDetailLoading, data: jobDetailData } = useSelector(state => state.job.jobDetail);
  const getJobDetail = id => {
    dispatch(getJobDetailAction(id, StorageManager.instance.getUid()));
  };
  const getJobList = (offset) => {
    console.log("offset1", offset)
    dispatch(getJobListAction(1,offset));
  };
  const handleClick = (offset) => {
    console.log("offset",title);
    setOffset(offset);
    if (jobLocation || title ) {
      searchJobList(jobLocation, title, offset);
    } else {
      getJobList(offset);
    }
    
  };
  const searchJobList = (location, title, offset, skills, job_type, uid) => {
    dispatch(searchActionAction(title, location, offset, skills, job_type, StorageManager.instance.getUid()));
  };

  useEffect(() => {
    searchJobList(jobLocation, title, offset, filter.skills, filter.job_type);
  }, [filter]);

  useEffect(() => {
    if (jobLocation || title || category) {
      searchJobList(jobLocation, title, offset, category);
    } else {
      getJobList();
    }
  }, [jobLocation, title]);

  const theme = useTheme();
  const matchesXsDown = useMediaQuery(theme.breakpoints.down("xs"));
 
  useEffect(() => {
    if (lodash.isArray(data) && data.length > 0) {
  
      if (!matchesXsDown) {
        setItem(data[0]);
        onItemSelect(data[0]);
      }
    } else if (loading) {
      setItem(null);
    }
  }, [data, error, loading]);

  const history = useHistory();
  const onItemSelect = item => {
    setItem(item);
    if (matchesXsDown) {
      history.push(`/job/${item.job_id}`);
    } else {
      getJobDetail(item.job_id);
    }
  };

  return (
    <MainContainer style={{ background: "#f4f4f4" }}>
      <FilterResults
        currentPage={1}
        totalPages={10790}
        onFilterChange={values => {
          if (values.value === "all") {
            setFilter({ ...filter, [values.type]: undefined });
          } else {
            setFilter({ ...filter, [values.type]: values.value });
          }
        }}
      />

      <JobListHolder>
        <Grid container spacing={1}>
          {(data && data.length > 0) || loading ? (
            <React.Fragment>
              <Grid item sm={4} xs={12}>
                {loading ? (
                  <Skeleton width="100%" height={200} />
                ) : (
                  data && data.length > 0 && <JobList list={data} onItemSelect={onItemSelect} selected={item} />
                )}
                {console.log("you",item)}
                 <Pagination
          limit={4}
          offset={offset}
          total={localStorage.getItem("count")?localStorage.getItem("count"):""}
          onClick={(e, offset) => handleClick(offset)}
        />
              </Grid>
              <Hidden xsDown>
                <Grid item sm={8}>
                  {jobDetailLoading || loading ? (
                    <Skeleton width="100%" height={200} />
                  ) : (
                    jobDetailData && <JobDetail item={jobDetailData} fullWidth />
                  )}
                </Grid>
              </Hidden>
            </React.Fragment>
          ) : (
            <Grid item xs={12}>
              <MDBAlert color="warning">Jobs Not Found</MDBAlert>
            </Grid>
          )}
        </Grid>
      </JobListHolder>
    </MainContainer>
  );
};
export default JobListingPage;
