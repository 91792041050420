import React from "react";
import { Grid, FormControl } from "@material-ui/core";
import LoginForm from "./LoginForm";
import GoogleLogin from "./../social/GoogleLogin";
import FacebookLogin from "./../social/FacebookLogin";
import LinkedInLogin from "./../social/LinkedInLogin";
import "./../social/SocialLogin.scss";

import "./Login.scss";

const Login = () => {
  return (
    <>
      <Grid className="message" xs={12}>
        You are just a step away from your Dream job
      </Grid>

      <Grid className="register-form" xs={12}>
        <FormControl fullWidth>
        {/* loginAction={loginAction} data={data} loading={loading} error={error} */}
           <LoginForm  />
        </FormControl>
      </Grid>
 
      <Grid container spacing={2} className="social-login-wrapper">
        <div className="hr-line">
          <span>Or Log in with</span>
        </div>
        <Grid item xs={4}>
          <LinkedInLogin />
        </Grid>
        <Grid item xs={4}>
          <GoogleLogin />
        </Grid>
        <Grid item xs={4}>
          <FacebookLogin/>
        </Grid>
      </Grid> 
    </>
  );
};

export default Login;