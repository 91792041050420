import React from "react";
import { useSelector } from "react-redux";
import { Grid, Paper, Hidden } from "@material-ui/core";
import MainContainer from "./../../../common/MainContainer";
import { Switch, NavLink, Route, useRouteMatch } from "react-router-dom";
import { MDBView, MDBAnimation } from "mdbreact";
import assets from "../../../../assets";
import { Image } from "../../login/LoginPage.styled";
import RegisterForm from "./register/RegisterForm";
import Login from "./login/Login";
import ThankYou from "./register/ThankYou";
import "./Register.scss";
import "./../register.css";

const CandidateLogin = () => {
  const registrationComplete  = useSelector(state => state.userManagement.registrationComplete);
  const match = useRouteMatch();
  const showThankyouPage = registrationComplete === true;

  return (
    <div className="register-with-otp">
      <MainContainer>
        <MDBView>
          <MDBAnimation type="fadeInRight" delay=".3s">
            <Grid container>
              <Hidden xsDown>
                <Grid xs={5} md={6}>
                  <Grid xs={12} className="main-message">
                    One search <br />
                    Million Opportunities
                  </Grid>
                  <Grid className="register-image">
                    <Image src={assets.images.loginImg} />
                  </Grid>
                </Grid>
              </Hidden>
              <Grid xs={12} sm={7} md={6}>
                <Paper elevation={5} className="login-with-otp">
                  {showThankyouPage ? (
                    <ThankYou></ThankYou>
                  ) : (
                    <>
                      <Grid className="action-selector" xs={12}>
                        <NavLink
                          to={`${match.url}/register`}
                          activeClassName="active-page"
                        >
                          Register
                        </NavLink>
                        <NavLink
                          to={`${match.url}/login`}
                          activeClassName="active-page"
                        >
                          Login
                        </NavLink>
                      </Grid>
                      <Switch>
                        <Route
                          path={`${match.path}/login`}
                          component={Login}
                        ></Route>
                        <Route 
                          path={`${match.path}/register/:page?`}
                          component={RegisterForm}
                        ></Route>
                      </Switch>
                    </>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </MDBAnimation>
        </MDBView>
      </MainContainer>
    </div>
  );
};

export default CandidateLogin;