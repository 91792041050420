import React from 'react';
import { MDBRow } from 'mdbreact';
import AdminCardSections from './sections/AdminCardSections';
import AdminCardSection2 from './sections/AdminCardSection2';
import TableSection from './sections/TableSection';
import BreadcrumSection from './sections/BreadcrumSection';
import ChartSection1 from './sections/ChartSection1';
import ChartSection2 from './sections/ChartSection2';
import ModalSection from './sections/ModalSection';
import TopNavigation from 'components/pages/recruiter/header/topNavigation';
import SideNavigation from 'components/pages/recruiter/header/sideNavigation';
import Footer from 'components/pages/recruiter/header/Footer';

const DashboardPage =  () => {
  return (
    <React.Fragment>
         <div className="flexible-content">
         <TopNavigation />
      <SideNavigation />
        <main id="content" className="p-5">
     
      <BreadcrumSection />
      <AdminCardSections />
      
      <TableSection />
      
      </main>
    </div>
    </React.Fragment>
    
  )
}

export default DashboardPage;