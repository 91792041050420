import React, { useEffect, useState,Component } from "react";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MainContainer from "components/common/MainContainer";
import { withStyles } from "@material-ui/core/styles";
import StorageManager from "utils/StorageManager";
import Link from '@material-ui/core/Link';

import { MDBCol, MDBRow, MDBCard, MDBCardUp, MDBCardBody, MDBAvatar, MDBRotatingCard, MDBIcon,MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem,MDBBtn, MDBNavLink } from
"mdbreact";
import axios from 'axios';

const styles = theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
 
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
});

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];
class CardExample extends Component {
  
    state = {
        flipped: false,
        items: [],
        jobs: [],
        savedJobs:[],
        activeItemJustified: "1"
      }
      toggleJustified = tab => e => {
        if (this.state.activeItemJustified !== tab) {
          this.setState({
            activeItemJustified: tab
          });
        }
      };
      componentDidMount() {
        const token = StorageManager.instance.getAccessToken();
        const csrf = StorageManager.instance.getXCSRFToken();
        const uid = StorageManager.instance.getUid();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'bearer '+token,
            'X-CSRF-Token':csrf
          }
           console.log("uid",uid);
        axios.get('https://live-corp2corp.pantheonsite.io/api/'+uid+'/candidate-details?_format=json')
          .then(res => {
            const items = res.data;
            console.log("Hanish",items);
            this.setState({ items });
          })
          axios.get('https://live-corp2corp.pantheonsite.io/api/'+uid+'/my-profile',{
            headers: headers
          })
          .then(res => {
            const jobs = res.data;
            console.log("Hanish",jobs);
            this.setState({ jobs });
          })
          axios.get('https://live-corp2corp.pantheonsite.io/api/'+uid+'/saved-jobs',{
            headers: headers
          })
          .then(res => {
            const savedJobs = res.data;
            console.log("Hanish",savedJobs);
            this.setState({ savedJobs });
          })
      }
    
      handleFlipping = () => {
        this.setState({ flipped: !this.state.flipped });
      }
      
  render() {
         const colStyle = { maxWidth: "22rem" };
         const { classes } = this.props;
        
        
      
            return (
                <React.Fragment>
      <CssBaseline />
    
      <main>
        {/* Hero unit */}
        <MainContainer>
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
           
          <MDBAvatar
                tag="img"
                src="https://secure.gravatar.com/avatar/36eacde7acefbfc6b5fe7ae550f250c3?s=96&amp;d=mm&amp;r=g"
                className="rounded-circle z-depth-1 img-fluid"
                alt="Sample avatar"
                
              />
      
            { this.state.items.map(item =>
            <div>
            
             {
item.fname ?  <h5 className="font-weight-bold mt-4 mb-3">{item.fname} {item.lname}</h5> : <h5 className="font-weight-bold mt-4 mb-3">{item.name}</h5>
      }
             <h3 className="text-uppercase blue-text">{item.jpreferance}</h3>
             </div>
            )}
            </Typography>
            {/*<Typography variant="h5" align="center" color="textSecondary" paragraph>
              Something short and leading about the collection below—its contents, the creator, etc.
              Make it short and sweet, but not too short so folks don&apos;t simply skip over it
              entirely.
            </Typography>*/}
          
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
        
    <MDBContainer>
        <MDBNav tabs className="nav-justified" color='indigo'>
          <MDBNavItem>
            <MDBNavLink link to="#" active={this.state.activeItemJustified === "1"} onClick={this.toggleJustified("1")} role="tab" >
              <MDBIcon icon="th-list" /> My Jobs
            
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink link to="#" active={this.state.activeItemJustified === "2"} onClick={this.toggleJustified("2")} role="tab" >
              <MDBIcon icon="heart" /> My Saved Jobs
            </MDBNavLink>
          </MDBNavItem>
         
        </MDBNav>
        <MDBTabContent
          className="card"
          activeItem={this.state.activeItemJustified}
        >
          <MDBTabPane tabId="1" role="tabpanel">
          <MDBTable>
      <MDBTableHead>
        <tr>
          <th>#</th>
          <th>Title</th>
          <th>Date</th>
          <th>Status</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
      { this.state.jobs.map(job => <tr><td>{job.counter}</td><td><h5>{job.title}</h5><h4 className="text-uppercase blue-text">{job.field_company_name}</h4><p>{job.field_job_location}</p></td><td>Applied {job.applied} ago</td><td><p className="green-text">Active</p></td></tr>)}
                                
      </MDBTableBody>
    </MDBTable>
          </MDBTabPane>
          <MDBTabPane tabId="2" role="tabpanel">
          <MDBTable>
      <MDBTableHead>
        <tr>
          <th>#</th>
          <th>Title</th>
          <th>Action</th>
          <th>Status</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
      { this.state.savedJobs.map(savedJob => <tr><td>{savedJob.counter}</td><td><h5>{savedJob.title}</h5><h4 className="text-uppercase blue-text">{savedJob.field_company_name}</h4><p>{savedJob.field_job_location}</p></td><td> <MDBBtn size="sm" social="comm" href={savedJob.view_node} >Apply</MDBBtn> </td><td><p className="green-text">Active</p></td></tr>)}
                                
      </MDBTableBody>
    </MDBTable>
          </MDBTabPane>
         
        </MDBTabContent>
      </MDBContainer>
          {/* End hero unit 
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://source.unsplash.com/random"
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Heading
                    </Typography>
                    <Typography>
                      This is a media card. You can use this section to describe the content.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" color="primary">
                      View
                    </Button>
                    <Button size="small" color="primary">
                      Edit
                    </Button>
                  </CardActions>
                </Card>
            </Grid>
            ))}
            </Grid>*/}
        </Container>
        </MainContainer>
      </main>
      {/* Footer */}
      
      {/* End footer */}
    </React.Fragment>
            )
        }
      }
      
      export default withStyles(styles)(CardExample);