

import React from 'react';

import axios from 'axios';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBIcon, MDBView, MDBBtn,MDBAvatar,MDBBadge,MDBContainer } from "mdbreact";

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import TopNavigation from 'components/pages/recruiter/header/topNavigation';
import SideNavigation from 'components/pages/recruiter/header/sideNavigation';
import Footer from 'components/pages/recruiter/header/Footer';
import Education from './sections/Education';
import Experiance from './sections/Experiance';
import logo from 'assets/images/default.png';
import StorageManager from "utils/StorageManager";




export default class EmployerCandidateDetails extends React.Component {
  
  constructor() {
    super();
    this.state = {
      liked: false,
      items: []
    };
    this.handleClick = this.acceptItem.bind(this);
  } 
  acceptItem = item => {
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+token,
        'X-CSRF-Token':csrf
      }
        this.setState({ item: item });
        const data = {"flag_id":"short_list" ,"entity_type":"user", "entity_id":item.uid,"uid":uid};
        axios.post(`https://live-corp2corp.pantheonsite.io/api/flag?_format=json`, data,{
            headers: headers
          })
          .then(res => {
            
            console.log(res.data);
            window.location.reload(); 
          })
    this.setState({
      liked: !this.state.liked
    });
  }
  componentDidMount() {
    const contentId = this.props.match.params.id;  
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+token,
        'X-CSRF-Token':csrf
      }  
    axios.get('https://live-corp2corp.pantheonsite.io/api/'+contentId+'/candidate-details?_format=json',{headers})
      .then(res => {
        const items = res.data;
        console.log("Hanish",items);
        this.setState({ items });
      })
  }

  render() {
    const label = this.state.liked ? 'Shortlist' : 'Remove'
   
    return (
        <div className="flexible-content">
      
          <TopNavigation />
          <SideNavigation />
          <main id="content" className="p-5">
          <MDBContainer>
          <MDBCard className="my-5 px-5 pb-5">
       
          { this.state.items.map(item =>
        <MDBCardBody cascade className="text-center">
                <MDBAvatar className="mx-auto white" circle>
                        <img src={logo} alt="" className="rounded-circle" />
                </MDBAvatar>
  <h4 className="font-weight-bold mb-3">{item.fname} {item.flagged}{item.lname}</h4>
          <p className="font-weight-bold blue-text">{item.designation} {item.jpreferance}</p>
                   <div class="text-center py-4">
                        <span id="footer-link-newsletter" class="border rounded p-2 px-3 mr-4 d-none d-md-inline-block"> <i class="fas fa-envelope black-text mr-2"> </i> 
                          {item.email}
                        </span>
                        <span id="footer-link-affiliate"  class="border rounded p-2 px-3 mr-4 d-none d-md-inline-block"><MDBIcon  icon="phone-volume mr-2" />
                         {item.mobile}
                        </span>
                        <span id="footer-link-contact"  class="border rounded p-2 px-3 mr-4 d-none d-md-inline-block"> <MDBIcon icon="search-location mr-2" /> 
                        {item.location}
                        </span>
                        <span id="footer-link-contact"  class="border rounded p-2 px-3 mr-4 d-none d-md-inline-block"><MDBIcon icon="comment-dollar mr-2" /> 
                        {item.salary}/Year
                        </span>
                     </div>
                    {/* <MDBBtn className="btn-gplus waves-light" onClick={e => this.acceptItem(item)}>
         
        
         <MDBIcon icon="plus-square" className="pr-2" />
         {label}
          </MDBBtn>*/}
                  {item.cv ? 
                  <MDBBtn color="default" className="waves-light">
                    <MDBIcon icon="cloud-download-alt" className="pr-2" />
                    <a href={item.cv} target=""_blank>Download Resume</a>
                   
                       
                  </MDBBtn>
                  :""}
                  <div>
    
      </div>
      {item.body ? 
    <hr className="my-5" />
    :""}
    {item.body ? 
    <div>
                     <h2 className="h1-responsive font-weight-bold text-center ">
                          About Me
                      </h2>
                     
                      <p className="text-center w-responsive mx-auto mb-5" dangerouslySetInnerHTML={{ __html: item.body }}></p>
   
    </div>
    :""}
     <hr className="my-5" />
                       <h2 className="h1-responsive font-weight-bold text-center">
                         SKILLS
                       </h2>
    <div> <MDBBtn color="primary" size="lg">{item.skills} {item.askill}</MDBBtn>
                            
                        
                        </div>
          
          <hr className="my-5" />
          
        </MDBCardBody>
          )}
                        <section>
                        <h2 className="h1-responsive font-weight-bold text-center my-5">
                           Education
                        </h2>
                        <Education />
                       </section>
    <hr className="my-5" />
    <section className="my-5">
        <h2 className="h1-responsive font-weight-bold text-center">
          Experiance
          </h2>
          <Experiance />
</section>
      </MDBCard>
   
        </MDBContainer>
          </main>
          <Footer />
          </div> 
     )
  }
}


