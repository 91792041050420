import React from "react";
import { Formik, Form, ErrorMessage, Field, FastField } from "formik";
import * as Yup from "yup";
import { FormikTextInput } from "components/common/FormInputs/FormikInputs";
import LockIcon from "@material-ui/icons/Lock";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { Button, LinearProgress } from "@material-ui/core";

const LoginForm = ({ loginAction, loading, data, error }) => {
  return (
    <Formik
      initialValues={{
        UserName: "",
        Password: ""
      }}
      validationSchema={Yup.object().shape({
        UserName: Yup.string().required("Please enter your email"),
        Password: Yup.string().required("Please enter your password")
      })}
      onSubmit={(values, actions) => {
        actions.resetForm();
        if (loginAction) {
          loginAction(values.UserName, values.Password);
        }
      }}
    >
      {({ errors, touched, handleSubmit, isSubmitting }) => {
        return (
          <>
            <Form name="login" method="post" onSubmit={handleSubmit}>
              <FastField
                style={{ marginTop: "1em" }}
                name="UserName"
                component={FormikTextInput}
                fullWidth
                id="email"
                size="small"
                placeholder="Email"
                variant="outlined"
                Icon={MailOutlineIcon}
              />

              <FastField
                style={{ marginTop: "1em" }}
                name="Password"
                component={FormikTextInput}
                type="password"
                fullWidth
                id="password"
                size="small"
                placeholder="Password"
                variant="outlined"
                Icon={LockIcon}
              />

              <Button
                type="submit"
                fullWidth
                disabled={loading}
                variant="contained"
                color="primary"
                style={{ fontFamily: "'Poppins', sans-serif", marginTop: "1em", color: "white" }}
              >
                Login
              </Button>
              {loading && <LinearProgress />}
            </Form>
            {/* <pre>
              <strong>Errors:</strong> {JSON.stringify(errors, null, 2)}
              <strong>Touched:</strong> {JSON.stringify(touched, null, 2)}
              {formValues && <strong>Submitted values:</strong>}
              {JSON.stringify(formValues, null, 2)}
            </pre> */}
          </>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
