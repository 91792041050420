import React,{useState} from "react";
import { makeStyles, Button, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import assets from "assets";
import AppButton from "components/common/Buttons";
import { StyledLink } from "components/common/Misc";
import lodash from "lodash";
import WorkIcon from '@material-ui/icons/Work';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SchoolIcon from '@material-ui/icons/School';
import useMasterList from "utils/useMasterList";
import axios from 'axios';
import { toast } from "react-toastify";
import StorageManager from "utils/StorageManager";
import UserManager from "utils/UserManager";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(theme => ({
  logo: {
    objectFit: "contain",
    objectPosition: "left",
    height: "36px",
    width: "100%"
  },
  logoContainer: {
    [theme.breakpoints.only("sm")]: {
      textAlign: "center"
    }
  },
  headerContainer: {
    textAlign: "right",
    [theme.breakpoints.only("sm")]: {
      paddingTop: "0px !important"
    }
  },
  header1: {
    fontWeight: "bold",
    fontFamily: "'Poppins', sans-serif",
    margin: "0px",
    overflow: "hidden",
    fontSize: "0.85em"
  },
  header2: {
    margin: "0px",
    overflow: "hidden",
    fontSize: "0.61em",
    marginTop: "-5px",
    lineHeight:"1",
    paddingTop:"10px"
  },
  categoryBoxHeaderContainer: {
    padding: "0px 12px",
    fontFamily: "'Poppins', sans-serif"
  },
  categoryJobBoxContainer: {
    backgroundColor: "white",
    marginTop: "16px",
    minHeight:"100%"
  },
  jobRole: {
    color: "#707070",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "0.9em",
    padding: "2px 10px",
    marginBottom: "2px",
    lineHeight: "1.2em"
  },
  jobSalary: {
    fontFamily: "'Poppins', sans-serif",
    marginTop: "0px",
    fontSize: "0.8em",
    padding: "0px 10px"
  },
  skillsContainer: {
    padding: "0px 10px"
  },
  jobSkill: {
    color: "#BABABA",
    display: "inline-block",
    fontSize: "0.8em",
    lineHeight: "1em",
    padding: "2px 8px",
    outline: "none",
    marginTop: "2px",
    cursor: "pointer",
    marginRight: "2px",
    borderRadius: "10px",
    border: "1px solid #BABABA",
    fontFamily: "'Poppins', sans-serif",
    "&:hover": {
      borderColor: `${theme.palette.primary.main}`,
      color: `${theme.palette.primary.main}`
    }
  },
  keyskill:{
    color: "#BABABA",
    display: "inline-block",
    fontSize: "0.8em",
    lineHeight: "1em",
    padding: "2px 8px",
    outline: "none",
    marginTop: "2px",
    cursor: "pointer",
    marginRight: "2px",
    borderRadius: "10px",
    border: "1px solid #BABABA",
    fontFamily: "'Poppins', sans-serif",
    "&:hover": {
      borderColor: `${theme.palette.primary.main}`,
      color: `${theme.palette.primary.main}`
    },
 
  },
  jobPosted: {
    fontSize: "0.7em",
    fontFamily: "'Poppins', sans-serif",
    marginTop: "0px"
  },
  jobApplyButton: {
    backgroundColor: "#13C1D6",
    color: "white",
 
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "bold",
    fontSize: "0.7em",
    padding: "1px 10px",
    borderRadius: "10px"
  },
  jobApplyButton1: {
    fontSize: "0.7em",
    marginTop: "0px",
    fontFamily: "'Poppins', sans-serif",
    textTransform: "none",
    padding: "0px",
  },
  jobFooterContainer: {
    padding: "30px 25px 0px 15px"
  },
  jobApplyButtonContainer: {
    textAlign: "right"
  }
}));

const CategoryJobBox = ({ item }) => {
  const { skills } = useMasterList();
  const classes = useStyles();
  const items9 = item.key_skills;
  const [isSaved, setIsSaved] = useState(item.saved);
  const history = useHistory();
  const isLoggedIn = UserManager.isLoggedIn();
  console.log(items9); // => "2012-10-01"
  const flagJobSaved = jobId => {
  const token = StorageManager.instance.getAccessToken();
  const csrf = StorageManager.instance.getXCSRFToken();
  const uid = StorageManager.instance.getUid();
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'bearer '+token,
      'X-CSRF-Token':csrf
    }

   

  const data = {"flag_id":"save_job" ,"entity_type":"node", "entity_id":jobId,"uid":uid};
  axios.post(`https://live-corp2corp.pantheonsite.io/api/flag?_format=json`, data,{
      headers: headers
  })
  .then(res => {
      console.log(res.data);
      setIsSaved(true);
     
    })
}
  return (
    <div className={classes.categoryJobBoxContainer}>
       
      <div>
     
        <Grid className={classes.categoryBoxHeaderContainer} container spacing={2}>
        
        <Grid item xs={6} className="MuiGrid-root makeStyles-logoContainer-383 MuiGrid-item MuiGrid-grid-xs-6">
          {
item.logo_url ?  <img src={lodash.get(item, "logo_url", assets.images.logo)} className={classes.logo} /> : <Typography className={classes.header1}>{item.company_name}</Typography>
      }
           
          </Grid>
          <Grid item xs={12} sm={6} className={classes.headerContainer}>
            <h3 className={classes.header1}>{item.title}</h3>
            <p className={classes.header2}>{item.job_location}</p>
          </Grid>
        </Grid>
       
        <div className="makeStyles-jobRole-389">
          <b>
          <WorkIcon style={{color:"#1259A1",size:"10px",marginRight:"3px",fontSize:"13px"}}/>{item.qualifications}
            <br />
            <SchoolIcon style={{color:"#1259A1",size:"10px",marginRight:"0px",fontSize:"13px"}}/> {item.minimum_experience} Years
          </b>
        </div>
        <p className="makeStyles-jobSalary-390">{item.summary}</p>
        <div className="makeStyles-skillsContainer-391">
         {items9}
         
        </div>

        <Grid container spacing={2} className={classes.jobFooterContainer}>
          <Grid item xs={6}>
            <p className="makeStyles-jobPosted-798">Posted date: {item.created}</p>
          
          
          </Grid>
          <Grid item xs={3}> 
          
          {/* <span
                  disabled={isSaved}
                  style={{cursor:"pointer"}}
                  className={classes.jobApplyButton1}
                  onClick={() => {
                    if (UserManager.isLoggedIn()) flagJobSaved(item.job_id);
                    else {
                      toast.warn("Please login to apply!");
                      history.push("/login");
                    }
                  }}
                >
                  {isSaved ? <FavoriteIcon style={{color:"red"}} fontSize="small" /> : <FavoriteBorderIcon fontSize="small" style={{color:"red"}} /> } 
                </span>
                */}
                </Grid>
            
          <Grid item xs={3} className={classes.jobApplyButtonContainer}>
          {isLoggedIn && (
            <StyledLink to={`${item.clean_url}`}>
              <Button
          variant="contained"
          className={classes.searchButton} variant="outlined" color={lodash.get(item, "job_applied", 0) == 1 ? "primary" : "secondary"} className={classes.jobApplyButton}>
                {lodash.get(item, "job_applied", 0) == 1 ? "APPLIED" : "APPLY"}
              </Button>
             
            </StyledLink>
          )}
            {!isLoggedIn && (
            
              <Button variant="outlined"  onClick={() => { 

                toast.warn("Please login to apply!");
                history.push("/jobseekers/register");
               }} color={lodash.get(item, "job_applied", 0) == 1 ? "primary" : "secondary"} className={classes.jobApplyButton}>
                {lodash.get(item, "job_applied", 0) == 1 ? "APPLIED" : "APPLY"}
              </Button>
             
         
          )}
        
          </Grid>
        </Grid>
      </div>
 
    </div>
  );
};

export default CategoryJobBox;
