import React from "react";
import { styled } from "@material-ui/styles";
import { Link } from "react-router-dom";
export const ImageIcon = styled(({ src }) => <img src={src} />)({
  width: "30px",
  height: "30px"
});

export const StyledLink = styled(Link)({
  textDecoration: "none"
});
