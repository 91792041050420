import React from "react";
import { TextField, InputAdornment, FormControlLabel, FormLabel, InputLabel } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import ChipInput from "material-ui-chip-input";
export const TextInput = styled(({ Icon,EndIcon, name, labelText, labelStyle, hideLabel, ...others }) => (
  <>
    {!hideLabel && (
      <InputLabel htmlFor={name} style={{ ...labelStyle }}>
        {labelText}
      </InputLabel>
    )}
    <TextField
      name={name}
      {...others}
      InputLabelProps={{
        shrink: true
      }}
      // FormHelperTextProps={{
      //   marginBottom: "2px"
      // }}
      inputProps={{
        style: {
          padding: "10px",
          outline: "none",
          borderRadius: "10px"
        }
      }}
      InputProps={{
        startAdornment: Icon && <InputAdornment position="start">{<Icon style={{ color: "#C8C8C8" }} />}</InputAdornment>,
        endAdornment: EndIcon && <InputAdornment position="start">{<EndIcon style={{ color: "#C8C8C8" }} />}</InputAdornment>,
        ...others.InputProps
      }}
    />
  </>
))({
  "& fieldset": {
    borderRadius: "10px",
    outline: "none"
  }
});

export const FormControlLabelInput = ({ labelText, ...props }) => (
  <FormLabel htmlFor={name} control={<TextInput {...props} />} label={labelText} labelPlacement="top|left">
    {labelText}
  </FormLabel>
);

export const TextChipInput = styled(({ Icon, name, labelText, labelStyle, value, ...others }) => {
  console.log("Chop Props", others);
  return (
    <>
      <InputLabel htmlFor={name} style={{ ...labelStyle }}>
        {labelText}
      </InputLabel>

      <ChipInput
        value={value}
        name={name}
        {...others}
        InputLabelProps={{
          shrink: true
        }}
        // FormHelperTextProps={{
        //   marginBottom: "2px"
        // }}
        inputProps={{
          style: {
            padding: "10px",
            outline: "none",
            borderRadius: "10px"
          }
        }}
        InputProps={{
          startAdornment: Icon && <InputAdornment position="start">{<Icon style={{ color: "#C8C8C8" }} />}</InputAdornment>
        }}
      />
    </>
  );
})({
  "& fieldset": {
    borderRadius: "10px",
    outline: "none"
  }
});
