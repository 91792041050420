import React from "react";
import { Button } from "@material-ui/core";
import { styled } from "@material-ui/styles";

const AppButton = styled(({ selected, variant = "outlined", style, ...other }) => {
  return <Button color={selected ? "primary" : "default"} {...other} variant={variant} />;
})(({ theme, selected, style, ...props }) => {
  return {
    backgroundColor: `${selected ? theme.palette.primary.main : "white"}`,
    color: `${!selected ? "black" : "white"}`,
    fontSize: "0.85em",
    paddingLeft: "30px",
    paddingRight: "30px",
    borderRadius: "100px",
    "&:hover": {
      backgroundColor: `${selected ? theme.palette.primary.main : "white"}`
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px"
    },
    ...style
  };
});

export default AppButton;
