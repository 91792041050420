import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import axios from 'axios';
import { toast } from "react-toastify";
import DisplayTile from "./DisplayTile";
import EmployerContainer from "./../EmployerContainer/EmployerContainer"; 
import "./profile.scss";
import { employerDetailsAction, employerProfileEditAction } from "services/endpoints/usermanagement/userManagementActions";
import StorageManager from "utils/StorageManager";

const Profile = () => {
  //Get the value from store using useSelector
  const { loading, data: employerDetails, error } = useSelector(
    state => state.userManagement.employerDetails
  );
  const { load, data: employerProfileEdit, error1 } = useSelector(
    state => state.userManagement.employerProfileEdit
  );
  
  useEffect(() => {
    if(employerProfileEdit){
  toast.success(employerProfileEdit.message, {
    position: toast.POSITION.TOP_RIGHT
  });
  dispatch(employerDetailsAction(uid))
}
}, [employerProfileEdit]);
   

    var employeProfile = {
      fname: employerDetails?employerDetails[0].fname:"",
      lname: employerDetails?employerDetails[0].lname:"",
      mobile: employerDetails?employerDetails[0].mobile:"",
      email: employerDetails?employerDetails[0].email:"",
      company: employerDetails?employerDetails[0].company:"",
      address: employerDetails?employerDetails[0].address.replaceAll("<br />",",").replaceAll(",,",","):"",
      pass: ""
    
   }
  const uid= StorageManager.instance.getUid();
   const dispatch = useDispatch();
   useEffect(() => {
   dispatch(employerDetailsAction(uid))
  }, []);
   
   

    const handleEdit = updatedValues => {
        updatedValues.uid = uid;
        dispatch(employerProfileEditAction(updatedValues));
    };

    return (
        <EmployerContainer heading="My Profile">
            <Box className="employer-profile">
                <DisplayTile
                    field="name"
                    label="Employee Name"
                    value={employeProfile.fname + " "+employeProfile.lname}
                    onEdit={handleEdit}
                    profile={employeProfile}
                />
                <DisplayTile
                    field="company"
                    label="Company Name"
                    value={employeProfile.company}
                    onEdit={handleEdit}
                />
                <div className="email-profile">
                <DisplayTile
                    field="email"
                    label="Email"
                    //className="email-profile"
                    value={employeProfile.email}
                    //onEdit={handleEdit}
                />
                </div>
                <DisplayTile
                    field="pass"
                    label="Password"
                    value={employeProfile.pass}
                    onEdit={handleEdit}
                />
                <DisplayTile
                    field="mobile"
                    label="Phone Number"
                    value={employeProfile.mobile}
                    onEdit={handleEdit}
                />
                <DisplayTile
                    field="address"
                    label="Address"
                    value={employeProfile.address}
                    onEdit={handleEdit}
                />
                {/* <DisplayTile
                  field="logout"
                  label="logout"
                  value={employeProfile.email}
                  onEdit={handleEdit}
                />*/}
                </Box>
            </EmployerContainer>
    );
};
export default Profile;
