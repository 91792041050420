import React from 'react';

import axios from 'axios';
import { MDBTable, MDBTableBody, MDBTableHead,MDBBtn, MDBIcon, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBContainer } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import TopNavigation from 'components/pages/employer/header/topNavigation';
import SideNavigation from 'components/pages/employer/header/sideNavigation';
import Footer from 'components/pages/employer/header/Footer';


export default class PersonList extends React.Component {
  state = {
    persons: []
  }
 
  
  componentDidMount() {
    axios.get(`https://live-corp2corp.pantheonsite.io/api/my-jobs?_format=json`)
      .then(res => {
        const persons = res.data;
        this.setState({ persons });
      })
  }

  render() {

    return (
        <div className="flexible-content">
      
          <TopNavigation />
          <SideNavigation />
          <main id="content" className="p-5">
          <MDBContainer>
                <MDBRow className="mb-4">
                        <MDBCol>
                            <MDBCard>
                                <MDBCardBody>
                                    <MDBTable>
                                    <MDBTableHead color="primary-color" textWhite>
                                        <tr>
                                        <th>Title</th>
                                        <th>Location</th>
                                        <th>Number Of Application</th>
                                        <th>Ceated</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                    
                                    { this.state.persons.map(person => <tr><td>{person.title}</td><td>{person.field_job_location}</td><td>{person.count}</td><td>{person.created}</td><td>{person.status}</td><td><MDBBtn tag="a" size="sm" floating color="red"> <MDBIcon icon="times" /> </MDBBtn><MDBBtn tag="a" size="sm" floating color="green"> <MDBIcon icon="check" /> </MDBBtn></td></tr>)}
                                        
                                    </MDBTableBody>
                                    </MDBTable>
                                </MDBCardBody>  
                                </MDBCard>       
                            </MDBCol>
                    </MDBRow>
          </MDBContainer>
          </main>
          <Footer />
          </div> 
     )
  }
}