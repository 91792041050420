import React,{useState} from "react";
import { MDBListGroup, MDBListGroupItem, MDBContainer } from "mdbreact";
import { makeStyles,Typography, Grid, Paper, Divider, Box, Button } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import lodash from "lodash";


import assets from "assets";
import AppButton from "components/common/Buttons";
import { StyledLink } from "components/common/Misc";

import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import useMasterList from "utils/useMasterList";
import axios from 'axios';
import { toast } from "react-toastify";
import StorageManager from "utils/StorageManager";
import UserManager from "utils/UserManager";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(theme => ({
  logo: {
    objectFit: "contain",
    objectPosition: "left",
    height: "36px",
    width: "100%"
  },
  logoContainer: {
    [theme.breakpoints.only("sm")]: {
      textAlign: "center"
    }
  },
  headerContainer: {
    textAlign: "right",
    [theme.breakpoints.only("sm")]: {
      paddingTop: "0px !important"
    }
  },
  header1: {
    fontWeight: "bold",
    fontFamily: "'Poppins', sans-serif",
    margin: "0px",
    overflow: "hidden",
    fontSize: "0.85em"
  },
  header2: {
    margin: "0px",
    overflow: "hidden",
    fontSize: "0.61em",
    marginTop: "-5px"
  },
  categoryBoxHeaderContainer: {
    padding: "0px 12px",
    fontFamily: "'Poppins', sans-serif"
  },
  categoryJobBoxContainer: {
    backgroundColor: "white",
    marginTop: "16px"
  },
  jobRole: {
    color: "#707070",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "0.9em",
    padding: "2px 10px",
    marginBottom: "2px",
    lineHeight: "1.2em"
  },
  jobSalary: {
    fontFamily: "'Poppins', sans-serif",
    marginTop: "0px",
    fontSize: "0.8em",
    padding: "0px 10px"
  },
  skillsContainer: {
    padding: "0px 10px"
  },
  jobSkill: {
    color: "#BABABA",
    display: "inline-block",
    fontSize: "0.8em",
    lineHeight: "1em",
    padding: "2px 8px",
    outline: "none",
    marginTop: "2px",
    cursor: "pointer",
    marginRight: "2px",
    borderRadius: "10px",
    border: "1px solid #BABABA",
    fontFamily: "'Poppins', sans-serif",
    "&:hover": {
      borderColor: `${theme.palette.primary.main}`,
      color: `${theme.palette.primary.main}`
    }
  },
  keyskill:{
    color: "#BABABA",
    display: "inline-block",
    fontSize: "0.8em",
    lineHeight: "1em",
    padding: "2px 8px",
    outline: "none",
    marginTop: "2px",
    cursor: "pointer",
    marginRight: "2px",
    borderRadius: "10px",
    border: "1px solid #BABABA",
    fontFamily: "'Poppins', sans-serif",
    "&:hover": {
      borderColor: `${theme.palette.primary.main}`,
      color: `${theme.palette.primary.main}`
    },
 
  },
  jobPosted: {
    fontSize: "0.7em",
    fontFamily: "'Poppins', sans-serif",
    marginTop: "0px"
  },
  jobApplyButton: {
    backgroundColor: "#13C1D6",
    color: "white",
 
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "bold",
    fontSize: "0.7em",
    padding: "1px 10px",
    borderRadius: "10px"
  },
  jobApplyButton1: {
    fontSize: "0.7em",
    marginTop: "0px",
    fontFamily: "'Poppins', sans-serif",
    textTransform: "none",
    padding: "0px",
  },
  jobFooterContainer: {
    padding: "30px 25px 0px 15px"
  },
  jobApplyButtonContainer: {
    textAlign: "right"
  }
}));
const JobListItem = ({ item, isActive = false, onClick }) => {
  const theme = useTheme();
  const { skills } = useMasterList();
  const classes = useStyles();
  const items9 = item.key_skills;
  const [isSaved, setIsSaved] = useState(item.saved);
  
  const history = useHistory();
  const isLoggedIn = UserManager.isLoggedIn();
  console.log(items9); // => "2012-10-01"
  const flagJobSaved = jobId => {
  const token = StorageManager.instance.getAccessToken();
  const csrf = StorageManager.instance.getXCSRFToken();
  const uid = StorageManager.instance.getUid();
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'bearer '+token,
      'X-CSRF-Token':csrf
    }

   

  const data = {"flag_id":"save_job" ,"entity_type":"node", "entity_id":jobId,"uid":uid};
  axios.post(`https://live-corp2corp.pantheonsite.io/api/flag?_format=json`, data,{
      headers: headers
  })
  .then(res => {
      //console.log(res.data);
      setIsSaved(true);
     
    })
}
  return (
    <Paper style={{ background: `${isActive ? theme.palette.primary.main : "white"}`, marginBottom: "10px" }} fullWidth onClick={onClick}>
      
      
      <Grid style={{ background: "#fff", marginLeft: "4px" }} fullWidth>
        <Box p={3}>
          <Grid>
            <Divider orientation="vertical" />
          </Grid>
          <div>
   
   <Grid className={classes.categoryBoxHeaderContainer} container spacing={2}>
   
   <Grid item xs={6} className={classes.logoContainer}>
     {
item.logo_url ?  <img src={lodash.get(item, "logo_url", assets.images.logo)} className={classes.logo} /> : <Typography className={classes.header1}>{item.company_name}</Typography>
 }
      
     </Grid>
     <Grid item xs={6} sm={6} className={classes.headerContainer}>
       <h3 className={classes.header1}>{item.title}</h3>
       <p className={classes.header2}>{item.job_location}</p>
     </Grid>
   </Grid>
  
   <p className={classes.jobRole}>
     <b>
       {item.qualifications}
       <br />
       {item.minimum_experience} Years
     </b>
   </p>
   <p className={classes.jobSalary}>{item.summary}</p>
   <div className={classes.skillsContainer}>
    {items9}
    
   </div>

   <Grid container spacing={2} className={classes.jobFooterContainer}>
     <Grid item xs={6}>
       <p className={classes.jobPosted}>Posted date: {item.created}</p>
     
     
     </Grid>
     <Grid item xs={6} style={{textAlign:"right"}}>  <span
             disabled={isSaved}
             style={{cursor:"pointer",textAlign:"center"}}
             className={classes.jobApplyButton1}
             onClick={() => {
               if (UserManager.isLoggedIn()) flagJobSaved(item.job_id);
               else {
                 toast.warn("Please login to apply!");
                 history.push("/login");
               }
             }}
           >
             {/*{isSaved ? <FavoriteIcon style={{color:"red"}} fontSize="small" /> : <FavoriteBorderIcon fontSize="small" style={{color:"red"}} /> } */}
           </span></Grid>
       
     {/*<Grid item xs={3} className={classes.jobApplyButtonContainer}>
     {isLoggedIn && (
      
         <Button
     variant="contained"
     className={classes.searchButton} variant="outlined" color={lodash.get(item, "job_applied", 0) == 1 ? "primary" : "secondary"} className={classes.jobApplyButton}>
           {lodash.get(item, "job_applied", 0) == 1 ? "APPLIED" : "APPLY"}
         </Button>
        
    
     )}
       {!isLoggedIn && (
       
         <Button variant="outlined"  onClick={() => { 

           toast.warn("Please login to apply!");
           history.push("/register");
          }} color={lodash.get(item, "job_applied", 0) == 1 ? "primary" : "secondary"} className={classes.jobApplyButton}>
           {lodash.get(item, "job_applied", 0) == 1 ? "APPLIED" : "APPLY"}
         </Button>
        
    
     )}
   
     </Grid>
        */}
   </Grid>
 </div>

       
          
        </Box>
      </Grid>
    </Paper>
  );
};

export default JobListItem;
