import React, { Component } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import { Grid, Hidden, Paper, Container, Typography, TextField, FormControl, InputAdornment, Icon, Button, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import assets from "../../../assets";
import MyAppBar from "../../common/myappbar/MyAppBar";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { TextInput } from "../../common/FormInputs";
import LockIcon from "@material-ui/icons/Lock";
import MainContainer from "../../common/MainContainer";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ImageIcon, StyledLink } from "components/common/Misc";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import TopNavigation from 'components/pages/recruiter/header/topNavigation';
import SideNavigation from 'components/pages/recruiter/header/sideNavigation';
import Footer from 'components/pages/recruiter/header/Footer';
import StorageManager from "utils/StorageManager";
import CKEditor from "react-ckeditor-component";
import { authenticateAction } from "services/endpoints/usermanagement/userManagementActions";
import { toast } from "react-toastify";
import { useHistory ,Redirect} from "react-router-dom";
import { Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBStepper, MDBStep, MDBBtn, MDBCard, MDBCardBody, MDBInput,MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption, MDBInputGroup,MDBDropdown,MDBDropdownToggle,MDBIcon,MDBDropdownMenu,MDBDropdownItem,MDBFileInput} from "mdbreact";
import AlgoliaPlaces from 'algolia-places-react';
import {DropzoneDialog,DropzoneArea} from 'material-ui-dropzone'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

import axios from 'axios';



class CandidateCreate extends React.Component {
  constructor(props) {
    super(props);
    this.updateContent = this.updateContent.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = {
        content: '',
        company: "",
    title: "",
    open: false,
    files: [],
    location: "",
    selectedFile: null,
    skills: "",
    salary: "",
    fid:"",
    designation:"",
    location:"",
    mobile:"",
    amount: "",
    education:"",
    items:"",
    experiance:"",
    Jobtype:"",
    slary:"",
    value: 'Choose your option',
    salarytype:"",
    edu:"",
    exp:"",
    sk: [
      {
        text: "USA",
        value: "1"
      },
      {
        text: "Germany",
        value: "2"
      },
      {
        text: "France",
        value: "3"
      },
      {
        text: "Poland",
        value: "4"
      },
      {
        text: "Japan",
        value: "5"
      }
    ],
    options: [
      {
        text: "Full-time",
        value: "1"
      },
      {
        text: "Part-time",
        value: "2"
      },
      {
        text: "Contract",
        value: "3"
      },
      {
        text: "Internship",
        value: "4"
      },
      {
        text:"Fresher",
        value:"6"
        },
        {
          text:"Volunteer",
          value:"7"
          }
    ],
    salary: [
      {
        text: "Yearly",
        value: "1"
      },
      {
        text: "Monthly",
        value: "2"
      },
      {
        text: "Hourly",
        value: "3"
      }
    ],
    exp: [
      {
        text: "1",
        value: "1"
      },
      {
        text: "2",
        value: "2"
      },
      {
        text: "3",
        value: "3"
      },
      
      {
        text: "4",
        value: ""
      },
      {
        text: "5",
        value: "5"
      },
      
      {
        text: "6",
        value: "6"
      },
      {
        text: "7",
        value: "7"
      },
      {
        text: "8",
        value: "8"
      },
      {
        text: "9",
        value: "9"
      },
      {
        text: "10",
        value: "10"
      }
      
    ],
    edu: [
      {
        text: "High school or equivalent",
        value: "1"
      },
      {
        text: "Associate",
        value: "2"
      },
      {
        text: "Bachelor's",
        value: "3"
      },
      {
        text: "Master's",
        value: "4"
      },
      {
        text: "Doctorate",
        value: "3"
      }
    ]
    }
}

  getValueOfSelect = (Jobtype) => {
    this.setState({ Jobtype });
  };
  onChangeHandler=event=>{
    console.log(event.target.files[0]);

  this.setState({ selectedFile:event.target.files[0] });
  const token = StorageManager.instance.getAccessToken();
  const csrf = StorageManager.instance.getXCSRFToken();
  const uid = StorageManager.instance.getUid();
  console.log(event.target.files[0].name);
  const fileName = event.target.files[0].name;

console.log(fileName);
  const headers = {
    'Content-Type': 'application/octet-stream',
    'Authorization': 'basic aGFuaXNoQHdpbm5vcHJvLmNvbTox',
    'X-CSRF-Token':csrf,
    'Content-Disposition':`file;filename="${fileName}"`
  }
  console.log(headers);
  axios.post(`https://live-corp2corp.pantheonsite.io/file/upload/node/candidate_details/field_resume?_format=hal_json`, event.target.files[0] ,{
    headers: headers
  })
  .then(res => {
    localStorage.setItem('fid', res.data.fid[0].value);
    console.log("File Upload",res.data.fid[0].value);
  })
  

}
  getValueSalary = (salarytype) => {
    this.setState({ salarytype });
  };
  getValueExperiance = (experiance) => {
    this.setState({ experiance });
  };
  getValueEducation = (education) => {
    this.setState({ education });
  };
  getValueEducationSkill = (skills) => {
    this.setState({ skills });
  };
  updateContent(newContent) {
    this.setState({
        content: newContent
    })
}

onChange(evt){
  console.log("onChange fired with event info: ", evt);
  var newContent = evt.editor.getData();
  this.setState({
    content: newContent
  })
}

onBlur(evt){
  console.log("onBlur event called with event info: ", evt);
}

afterPaste(evt){
  console.log("afterPaste event called with event info: ", evt);
}
  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
    console.log(this.state.selectedFile);
  
   
  
  
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const fname = event.target.fname.value;
    const lname = event.target.lname.value;
    const email = event.target.email.value;
    const mobile = event.target.mobile.value;
    const locationRow = this.state.location;
    const location = locationRow.suggestion.name+','+locationRow.suggestion.administrative+','+locationRow.suggestion.country;
   console.log(location);
    const amount = event.target.amount.value;
     const designation = event.target.designation.value;
    //const skills = event.target.skills.value;
    const Jobtype = this.state.Jobtype;
    const salarytype = this.state.salarytype;
    const experiance = this.state.experiance;
    const education = this.state.education;
    const skills = this.state.skills;
    const content = this.state.content;
    console.log("fid",this.state.fid);
    const fid =this.state.fid;
   console.log("weq",experiance,fname,lname,amount,location,content,skills,Jobtype,fid,email,location,designation);
    if(fname && lname && amount && location && content && skills && Jobtype && fid){
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+token,
        'X-CSRF-Token':csrf
      }
      
      const data = {
        "first_name": fname,
        "last_name": lname,
        "email": email,
        "location": location,
        "designation": designation,
        "mobile_number": mobile,
        "job_type": Jobtype,
        "salary": amount,
        "salary_type": salarytype,
        "experience": experiance,
        "resume_fid": fid,
        "education": education,
        "skills":skills
  
      };
      
      console.log(data);
      axios.post(`https://live-corp2corp.pantheonsite.io/services/create-candidate`, data,{
        headers: headers
      })
      .then(response => { 
        console.log('succ',response.data.uid)
        toast.success("Successfully Submitted !", {
          position: toast.POSITION.TOP_RIGHT
        })
        const data = {"flag_id":"recruiter" ,"entity_type":"user", "entity_id":response.data.uid,"uid":uid};
        axios.post(`https://live-corp2corp.pantheonsite.io/api/flag?_format=json`, data,{
            headers: headers
        })
        .then(res => {
            console.log(res.data);
            return <Redirect to='/my-candidatesList'/>;
           
          })
          
          this.setState({
            fname: '',
            lname:'',
            email:'',
            location:'',
            designation:'',
            content:'',
            skills:'',
      
          })
      })
      .catch(error => {
        if(error.status === 400){
        toast.error("User already exists.!", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
      
          console.log('err',error.response)
      });
 
     {/*.then(res => {
        console.log("user_details",res)
        toast.success("Successfully Submitted !", {
          position: toast.POSITION.TOP_RIGHT
        })
        const data = {"flag_id":"apply_job" ,"entity_type":"node", "entity_id":nid,"uid":value};
        axios.post(`https://live-corp2corp.pantheonsite.io/api/flag?_format=json`, data,{
            headers: headers
        })
        .then(res => {
            console.log(res.data);
          })
  
        console.log(res);
        console.log(res.data);
        this.setState({
          fname: '',
          lname:'',
          email:'',
          location:'',
          designation:'',
          content:'',
          skills:'',
    
        })
       // window.location.reload();
      })*/}
      
    }else{
      toast.error("Please enter job details !", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    
  
    return;
    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'bearer '+token,
       Accept: 'application/json',
      'X-CSRF-Token':csrf
    }
 fetch('https://live-corp2corp.pantheonsite.io/entity/node?_format=hal_json', {
   method: 'post',
    withCredentials: true,
   headers: {
     'Content-Type':'application/json',
     
      'Authorization': 'bearer '+token,
       Accept: 'application/json',
      'X-CSRF-Token':csrf
     },
   body: data
  });
  return;
      axios.post(`https://live-corp2corp.pantheonsite.io/entity/node?_format=hal_json`,{ withCredentials: true,
      headers: headers
    },data)
    .then(res => {
     
    
      console.log(res);
      console.log(res.data);
    })
    return;
    if(company && title && amount && location && content && skills && Jobtype){
    
    
    const data = {
      "uid": uid,
      "company_name": company,
      "job_title": title,
      "description": content,
      "location": location,
      "country": "United states",
      "country_code": " ",
      "mobile_number": " ",
      "company_size": " ",
      "employment_type": Jobtype,
      "salary_curreny": "USD",
      "salary_range": amount,
      "salary_method": salarytype,
      "minimum_experience": experiance,
      "maximum_experience": experiance,
      "qualifications": education,
      "skills":skills

    };

    axios.post(`https://live-corp2corp.pantheonsite.io/services/jobs/create`, data,{
      headers: headers
    })
    .then(res => {
      toast.success("Successfully Submitted !", {
        position: toast.POSITION.TOP_RIGHT
      });
    
      console.log(res);
      console.log(res.data);
    })
  }else{
    toast.error("Please enter job details !", {
      position: toast.POSITION.TOP_RIGHT
    });
  }
    // do stuff
    console.log('company:', company);
    console.log('title:', title);
     console.log("location",location);
    console.log("salary:",amount);
     console.log("salarytype:",salarytype);
     console.log("JOB TYPE:",Jobtype);
     console.log("experiance:",experiance);
     console.log("education:",education);
     console.log("skills:",skills);
     console.log("content:",content);
  };


  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
    
  };
  componentDidMount() {
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+token,
        'X-CSRF-Token':csrf
      }
    axios.get('https://live-corp2corp.pantheonsite.io/api/skills',{
        headers: headers
      })
      .then(res => {
        const items = res.data;
        console.log("Hanish",items);
        this.setState({ items });
      })
  }
  handleClose() {
    this.setState({
        open: false
    });
}

handleSave(files) {
    //Saving files to state for further use and closing Modal.
    this.setState({
        files: files, 
        open: false
    });
}

handleOpen() {
    this.setState({
        open: true,
    });
}
render() {
  const getUploadParams = (file) => {
    console.log("sasas",file);
    return { url: 'https://httpbin.org/post' }
  }

  const handleChangeStatus = ({ meta, remove }, status) => {
    if (status === 'headers_received') {
      console.log(meta)
      const token = StorageManager.instance.getAccessToken();
      const csrf = StorageManager.instance.getXCSRFToken();
      const uid = StorageManager.instance.getUid();
      console.log(meta.name);
      const fileName = meta.name;
      const headers = {
        'Content-Type': 'application/octet-stream',
        'Authorization': 'basic aGFuaXNoQHdpbm5vcHJvLmNvbTox',
        'X-CSRF-Token':csrf,
        'Content-Disposition':`file;filename="${fileName}"`
      }
      console.log(headers);
      axios.post(`https://live-corp2corp.pantheonsite.io/file/upload/node/candidate_details/field_resume?_format=hal_json`, meta ,{
        headers: headers
      })
      .then(res => {
        localStorage.setItem('fid', res.data.fid[0].value);
        this.setState({fid:res.data.fid[0].value});
        console.log("File Upload",res.data.fid[0].value);
      })
      //remove()
    } else if (status === 'aborted') {
      console.log(`${meta.name}, upload failed...`)
    }
  }
  return (
    
     
    <div className="flexible-content">
      
    <TopNavigation />
    <SideNavigation />
    <main id="content" className="p-5">
    <MDBContainer>
      <MDBCard>
            <MDBCardBody>
              <h2 className="text-center font-weight-bold pt-4 pb-5">
                <strong>Create Candidate</strong>
              </h2>
              <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>
            <MDBCol md="4">
          
              <MDBInput
                value={this.state.fname}
                name="fname"
                onChange={this.changeHandler}
                type="text"
                id="materialFormRegisterNameEx"
                label="First name"
                required
              />
           
            </MDBCol>
            <MDBCol md="4">
              <MDBInput
                value={this.state.lname}
                name="lname"
                onChange={this.changeHandler}
                type="text"
                id="materialFormRegisterEmailEx2"
                label="Last name"
                required
              />
            </MDBCol>
            <MDBCol md="4">
              <MDBInput
                value={this.state.email}
                onChange={this.changeHandler}
                type="email"
                id="materialFormRegisterConfirmEx3"
                name="email"
                label="Your Email address"
              />
            </MDBCol>
          </MDBRow>
            <MDBRow>
            <MDBCol md="4">
         
              <MDBInput
                value={this.state.mobile}
                name="mobile"
                onChange={this.changeHandler}
                type="number"
                id="materialFormRegisterNameEx"
                label="Mobile Number"
                required
              >
                <div className="valid-feedback">Looks good!</div>
              </MDBInput>
            </MDBCol>
            <MDBCol md="4">
              <MDBInput
                value={this.state.designation}
                name="designation"
                onChange={this.changeHandler}
                type="text"
                id="materialFormRegisterEmailEx2"
                label="Designation"
                required
              >
                <div className="valid-feedback">Looks good!</div>
              </MDBInput>
            </MDBCol>
            <MDBCol md="4">
            
                    <div className="md-form">
                  <AlgoliaPlaces
                              placeholder='Job Location'
                              variant="outlined"
                              required
                              fullWidth
                              icon="user"
                              className="form-control"
                              required
                              options={{
                                appId: 'plE79E3YU95N',
                                apiKey: 'ab6d2643563c402f95aaf5dca19afa95',
                                language: 'en',
                                countries: ['us'],
                                type: 'city',
                                // Other options from https://community.algolia.com/places/documentation.html#options
                              }}
                              onChange={ location => this.setState({location}) }
                            
                            
                        
                              onSuggestions={({ rawAnswer, query, suggestions }) => 
                                console.log('Fired when dropdown receives suggestions. You will receive the array of suggestions that are displayed.')}
                        
                              onCursorChanged={({ rawAnswer, query, suggestion, suggestonIndex }) => 
                                console.log('Fired when arrows keys are used to navigate suggestions.')}
                        
                              onClear={() => 
                                console.log('Fired when the input is cleared.')}
                        
                              onLimit={({ message }) => 
                                console.log('Fired when you reached your current rate limit.')}
                        
                              onError={({ message }) => 
                                console.log('Fired when we could not make the request to Algolia Places servers for any reason but reaching your rate limit.')}
                        />
                 <div className="invalid-feedback">
                   Please provide a valid location.
                 </div>
                 <div className="valid-feedback">Looks good!</div>
                 </div>
             
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="4">
              <MDBSelect
                getValue={this.getValueOfSelect} getTextContent={this.getValueOfSelect}
              
                options={this.state.options}
                selected="Choose your option"
                label="Job Type"
              />
            </MDBCol>
            <MDBCol md="4">
             <MDBInput 
             label="$ Salary" 
            
             value={this.state.amount}
             onChange={this.changeHandler}
             type="number"
             id="materialFormRegisterPasswordEx6"
             name="amount"
             required
               >
                 <div className="invalid-feedback">
                   Please provide a valid Salary.
                 </div>
                 <div className="valid-feedback">Looks good!</div>
                 </MDBInput>
           </MDBCol>
          <MDBCol md="4">
            <MDBSelect
              getValue={this.getValueSalary} getTextContent={this.getValueSalary}
              options={this.state.salary}
              selected="Choose Per Year/Month"
              label="Salary Type"
            />
          </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="4">
            <MDBSelect
              getValue={this.getValueExperiance} getTextContent={this.getValueExperiance}
              options={this.state.exp}
              selected="Choose Experiance"
              label="Experiance"
            />
            </MDBCol>
            <MDBCol md="4">
            <MDBSelect
              getValue={this.getValueEducation} getTextContent={this.getValueEducation}
              options={this.state.edu}
              selected="Choose Education"
              label="Education"
            />
            </MDBCol>
            <MDBCol md="4">
            
              <MDBSelect
              getValue={this.getValueEducationSkill} 
          multiple
          search
          options={this.state.items}
          selected="Please Choose Skills"
          selectAll
        />
      
            </MDBCol>
          </MDBRow>
  
   
          <MDBRow>
            <MDBCol>
            <h5>Upload Resume</h5>
            <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        maxFiles={1}
        multiple={false}
        canCancel={false}
        inputContent="Drop A File"
           accept=".pdf,.doc,.docx"
      inputContent={(files, extra) => (extra.reject ? 'Image, audio and video files only' : 'Drag Files')}
        styles={{
          dropzone: { width: 400, height: 200 },
          dropzoneActive: { borderColor: 'green' },
        }}
      />  
            </MDBCol>
          </MDBRow>
          <br/>
          <MDBRow>
           <MDBCol>
             <h5>Candidate Cover Letter</h5>
             <CKEditor 
              activeClass="p10" 
              content={this.state.content} 
              events={{
                "blur": this.onBlur,
                "afterPaste": this.afterPaste,
                "change": this.onChange
              }}
             />
            </MDBCol>
          </MDBRow>
            <MDBRow>
            <MDBInput
              type="checkbox"
              value="conditions"
              id="materialInvalidCheck"
              required
              label="Agree to terms and conditions"
            >
              <div className="invalid-feedback">
                You must agree before submitting.
              </div>
            </MDBInput>
          </MDBRow>
          <MDBBtn color="primary" type="submit">
            Submit Details
          </MDBBtn>
        </form>
      </div>
        </MDBCardBody>
          </MDBCard>  
          </MDBContainer>
          </main>
             <Footer/>
        </div>    
  );
  };
}

export default CandidateCreate;