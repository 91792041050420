import React from "react";
import { MDBContainer,MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn } from "mdbreact";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import TopNavigation from 'components/pages/employer/header/topNavigation';
import SideNavigation from 'components/pages/employer/header/sideNavigation';
import Footer from 'components/pages/employer/header/Footer';
import StorageManager from "utils/StorageManager";


const EcommercePage = () => {
  return (
    <div className="flexible-content">
      
 
    <main id="content" className="p-5">
    <MDBContainer>

    <section className="text-center my-5">
      <h2 className="h1-responsive font-weight-bold text-center my-5">
      We Care For Your Career.
      </h2>
      <p className="black-text text-center w-responsive mx-auto mb-5">
     

We as Corp2Corp.Jobs providing you free subscription in the time of this critical crisis. Unlimited Job Searches, Job Posts and Resume Downloads.
      </p>
      <MDBRow>
        <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
        <MDBCard
            className="card-image"
            style={{
              backgroundImage:
                'url("https://mdbootstrap.com/img/Photos/Others/pricing-table%20(6).jpg")'
            }}
          >
            <div className="text-white text-center pricing-card d-flex align-items-center rgba-indigo-strong py-3 px-3 rounded">
            <MDBCardBody>
              <h5 className="font-weight-bold mt-3">Free(Covid-19 Crisis Plan)</h5>
              <div className="price pt-0">
                <h2 className="number red-text mb-0">0</h2>
              </div>
              <ul className="striped mb-1">
                <li>
                  <p>
                    <strong></strong> Unlimited Job Posting
                  </p>
                </li>
                <li>
                  <p>
                    <strong></strong> Unlimited Resume Download
                  </p>
                </li>
                <li>
                  <p>
                    <strong></strong> Wide Rage of Jobs
                  </p>
                </li>
                
              </ul>
              <MDBBtn href="/employer-dashboard" rounded color="danger" className="mb-4">
               Subscribe
              </MDBBtn>
            </MDBCardBody>
            </div>
          </MDBCard>
        </MDBCol>
        <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
          <MDBCard
            className="card-image"
            style={{
              backgroundImage:
                'url("https://mdbootstrap.com/img/Photos/Others/pricing-table%20(6).jpg")'
            }}
          >
            <div className="text-white text-center pricing-card d-flex align-items-center rgba-indigo-strong py-3 px-3 rounded">
              <MDBCardBody>
                <h5 className="font-weight-bold mt-2">Pro</h5>
                <div className="price pt-0">
                  <h2 className="number mb-0">20</h2>
                </div>
                <ul className="striped mb-0">
                  <li>
                    <p>
                      <strong>200</strong> Resume Download
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>10</strong> Job Posting
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>1</strong> User
                    </p>
                  </li>
                  
                  
                </ul>
                <MDBBtn href="/checkout/3" outline color="white">
                  Buy now
                </MDBBtn>
              </MDBCardBody>
            </div>
          </MDBCard>
        </MDBCol>
        <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
          <MDBCard pricing>
            <MDBCardBody>
              <h5 className="font-weight-bold mt-3">Enterprise</h5>
              <div className="price pt-0">
                <h2 className="number mb-0">30</h2>
              </div>
              <ul className="striped mb-1">
              <li>
                    <p>
                      <strong>300</strong> Resume Download
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>50</strong> Job Posting
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>1</strong> User
                    </p>
                  </li>
              </ul>
              <MDBBtn href="/checkout/3" rounded color="danger" className="mb-4">
                Buy now
              </MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </section>
    </MDBContainer>
          </main>
          
          </div> 
  );
}

export default EcommercePage;