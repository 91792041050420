import StorageManager from "utils/StorageManager";
import API from "services/API";
import endpoints from "services/endpoints";
import lodash from "lodash";
import UserManager from "utils/UserManager";
export class UserManagementService {
  static async authenticate(username, password) {
    const body = { name: username, pass: password };
    const params = { _format: "json" };
    return API.execute(endpoints.login, "post", { data: body, params }, false, true).then(result => {
      StorageManager.instance.setAccessToken(result.access_token);
      console.log("hey token",result.access_token);
      StorageManager.instance.setBasicToken(btoa(`${username}:${password}`));
      StorageManager.instance.setUid(lodash.get(result, "current_user.uid"));
      StorageManager.instance.setXCSRFToken(lodash.get(result, "csrf_token"));
      StorageManager.instance.setLogoutToken(lodash.get(result, "logout_token"));
      return result;
    });
  }

  static async register(email, password, role = "candidate", mobile, fname, mobile1) {
    const data = { email, password, role, mobile, fname, mobile1 };
    return API.execute(endpoints.register, "post", { data }, false, false);
  }

  static async verifyOtp(otp, username, type) {
    const data = { otp, type, username };
    return API.execute(endpoints.verifyOtp, "post", { data }, false, false);
  }

  static async generateOtp(username, type) {
    const data = { username, type };
    return API.execute(endpoints.generateOtp, "post", { data });
  }

  static async logout(uid) {
    const data = { uid };
    const params = { _format: "json" };
    return API.execute(endpoints.logout, "get", { params, data });
  }

  static async resetPassword(username, password) {
    const data = { username, password };
    return API.execute(endpoints.resetPassword, "put", { data });
  }

  static async socialLogin(uid, role) {
    const data = { uid, role };
    return API.execute(endpoints.socialLogin, "post", { data }, false, false).then(response => {
      //StorageManager.instance.setXCSRFToken(lodash.get(response, "csrf_token"));
      return response;
    });
  }

  static async loginWithSocial(access_token,provider) {
    const data = {access_token:access_token};
    const params = { _format: "json" };
    //
    const headers = { 'Content-Type': 'application/json'}
    return API.execute(endpoints.loginWithSocial+provider, "post", {withCredentials: true, data, params, headers }, false, true);
  }

  static async linkedInToken(data) {
    const headers = { 'Content-Type': 'application/json'}
    return API.execute(endpoints.linkedInToken, "post", { withCredentials: true, data, headers }, false, true);
  }
  
  static async refreshToken() {
    const params = { _format: "json" };
    return API.execute(endpoints.refreshToken, "post", { params }, false, true).then(response => {
      StorageManager.instance.setAccessToken(response.token);
      StorageManager.instance.setUid(UserManager.getUid());
      return response;
    });
  }

  static async registerWithOtp(data) {
    return API.execute(endpoints.registerWithOtp, "post", { data }, false, false);
  }

  static async emailRegister(data) {
    return API.execute(endpoints.emailRegister, "post", { data }, false, false);
  }

  static async uploadResume(file, authorizationTocken, onUploadProgress) {
    const data = file;
    const params = {
      _format: "hal_json"
    };
    const headers = {
      "Content-Disposition": `file;filename="${file.name}"`,
      "Content-Type": "application/octet-stream",
      "Authorization":"basic " +authorizationTocken
    };
    return API.execute(endpoints.uploadResume, "post", { data, headers, params, onUploadProgress }, true, true, authorizationTocken);
  }

  static async updateResumeRef(data,authorizationTocken) {
    const headers = { 
      "Authorization":"basic " +authorizationTocken
    };
    return API.execute(endpoints.updateResumeRef, "post", { data,headers }, false, false,authorizationTocken);
  }

  static async resetPassword(data) {
    return API.execute(endpoints.resetPassword, "post", { data }, false, false);
  }

  static async loginStatitics(data,authorizationTocken) {
    const headers = { 
      "Authorization": "basic " + authorizationTocken
    };
    return API.execute(endpoints.loginStatitics, "post", { data,headers }, false, false,authorizationTocken);
  } 



static async employerDetails(uid) {
  const headers = { 
    "Content-Type": "application/json",
  };
  const url=`${uid}/employer`
  return API.execute(endpoints.employerDetails+url, "get", {headers }, false, false);
} 
static async employerProfileEdit(data) {
  const headers = { 
    "Content-Type": "application/json",
  };
  return API.execute(endpoints.employerProfileEdit, "post", {data,headers }, false, false);
} 

static async updateJobState(data) {
  return API.execute(endpoints.EmployerJobActivate, "post", {data}, false, false);
  } 
  
static async getCandidateDetails(uid) {
  const headers = { 
    "Content-Type": "application/json",
  };
  const url=`api/${uid}/candidate-details`
  return API.execute(url, "get", {headers }, false, false);
  } 
      
static async getEmployerJobCount(data) {
  const headers = { 
    "Content-Type": "application/json",
  };
  return API.execute(endpoints.employerJobCount, "post", {data,headers }, false, false);
} 



}



