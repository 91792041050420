import { useDispatch, useSelector } from "react-redux";
import { changeUserType } from "actions/appActions";
export const useUserType = () => {
  const dispatch = useDispatch();
  const userType = useSelector(state => state.app.userType);

  const changeType = type => {
    dispatch(changeUserType(type));
  };

  return [userType, changeType];
};
