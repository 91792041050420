import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import SocialButton from './socialButton'
import UserCard from './userCard'
import StorageManager from "utils/StorageManager";
import axios from 'axios';
import UserManager from "utils/UserManager";
import {  MDBIcon } from "mdbreact";
import { Grid } from "@material-ui/core";
import FacebookIcon from '@material-ui/icons/Facebook';
import SocialLogin from "components/common/SocialLogin/SocialLogin";
import "./social.css"
import { MDBSpinner } from 'mdbreact';
export default class Facebook extends Component {
  constructor (props) {
    super(props)

    this.state = {
      logged: false,
      user: {},
      currentProvider: '',
      loader:false
    }
    this.nodes = {}

    this.onLoginSuccess = this.onLoginSuccess.bind(this)
    this.onLoginFailure = this.onLoginFailure.bind(this)
    this.onLogoutSuccess = this.onLogoutSuccess.bind(this)
    this.onLogoutFailure = this.onLogoutFailure.bind(this)
    this.logout = this.logout.bind(this)
  }

  setNodeRef (provider, node) {
    if (node) {
      this.nodes[ provider ] = node
    }
  }

  onLoginSuccess (user) {
    console.log("hello  ilan",user._token.accessToken);
    this.setState({loader:true});
    console.log(user);
    //return;
  
      //setSpinner(true);
      //console.log(response.accessToken);
      // onGoogleSuccess && onGoogleSuccess(response);
      //responseRef.current = { response };
      //responseRef.current = { response };
     
      //UserManager.logout1();
      const headers = {
        'Content-Type': 'application/json',
        //"X-CSRF-Token": localStorage.getItem("anonymous_toke")
      }
      
      const data = {
        "access_token": user._token.accessToken,
        withCredentials: true,
  
      };
      axios.post(`https://live-corp2corp.pantheonsite.io/user/login/facebook?_format=json`, data,{
        headers: headers,
       withCredentials: true
      })
        .then(res => {
          if(res.data.status === 1){
            localStorage.setItem("facebook","facebook");
            localStorage.setItem("temp_xcsrf_token",res.data.csrf_token);
            //localStorage.setItem("telogout_token",res.data.logout_token);
            localStorage.setItem("temp_uid",res.data.current_user.uid);
            StorageManager.instance.setBasicToken('aGFuaXNoQHdpbm5vcHJvLmNvbTox');
            StorageManager.instance.setUid(res.data.current_user.uid);
            StorageManager.instance.setXCSRFToken(res.data.csrf_token);
            StorageManager.instance.setLogoutToken(res.data.logout_token);
            StorageManager.instance.setAccessToken(res.data.access_token);
            window.location.href="/saved-jobs";
            return;
          }
          if (this.props.isRegister) {
            this.props.onLoginSuccess({
              email: user._profile.email,
              name: user._profile.name,
              uid: res.data.current_user.uid
            });
            UserManager.logout1();
            
          } 
          return;
          /*else {
            console.log("dsaasd",res);
        localStorage.setItem("facebook","facebook");
        localStorage.setItem("temp_xcsrf_token",res.data.csrf_token);
        //localStorage.setItem("telogout_token",res.data.logout_token);
        localStorage.setItem("temp_uid",res.data.current_user.uid);
        StorageManager.instance.setBasicToken('aGFuaXNoQHdpbm5vcHJvLmNvbTox');
        StorageManager.instance.setUid(res.data.current_user.uid);
        StorageManager.instance.setXCSRFToken(res.data.csrf_token);
        StorageManager.instance.setLogoutToken(res.data.logout_token);
        //In the array!
        //console.log("Success1candidates",res.data.current_user.roles);
      
        StorageManager.instance.setAccessToken("eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1OTY4NjEzNTksImV4cCI6MTU5Njg2NDk1OSwidWlkIjoiMjA1NiIsInJvbGVzIjpbImF1dGhlbnRpY2F0ZWQiLCJjYW5kaWRhdGUiXX0.nCe4zmEvQ7qv0ThSdh7RiMRilGajB1HI4sQV_4_5Ltc");
        //console.log("response1",response);
        window.location.href="/saved-jobs";
  
        //localStorage.setItem("basic_token","aGFuaXNoQHdpbm5vcHJvLmNvbTox");
        const headers_logout = {
          'Content-Type': 'application/json',
          //'Authorization': 'bearer '+token,
          'X-CSRF-Token':res.data.csrf_token
        }
        axios.get('https://live-corp2corp.pantheonsite.io/jwt/token',{withCredentials: true},{
          headers: headers_logout
        })
        .then(res => {
          console.log("sdds",res);
          StorageManager.instance.setAccessToken(res.data.token);
          const headers1 = {
            'Content-Type': 'application/json',
            "X-CSRF-Token": res.data.csrf_token
          }
          
          const data1 = {
          };
          console.log(data1);
          axios.post(`https://live-corp2corp.pantheonsite.io/jwt/token?_format=json`,data1,{
           headers: headers1,
           withCredentials: true
          })
          .then(response => {
            StorageManager.instance.setAccessToken(response.data.token);
            console.log("response1",response);
            window.location.href="/onboarding/1";
            //history.push("/employer-dashboard");
          });
          
        
         //;
          //window.location.href="/onboarding/1";
          const candidates = res.data;
      
        })
      //   setOpenRoleModal(true);
      
      //   console.log(res.data);
      //  window.location.reload();
          }*/
      })
     
    

    this.setState({
      logged: true,
    currentProvider: user._provider,
      user
    })
  }

  onLoginFailure (err) {
    console.error(err)
    this.props.onLoginFailed("Facebook");
    this.setState({
      logged: false,
      currentProvider: '',
      user: {}
    })
  }

  onLogoutSuccess () {
    this.setState({
      logged: false,
      currentProvider: '',
      user: {}
    })
  }

  onLogoutFailure (err) {
    console.error(err)
  }

  logout () {
    const { logged, currentProvider } = this.state

    if (logged && currentProvider) {
      this.nodes[currentProvider].props.triggerLogout()
    }
  }

  render () {
    let children
   
    if (this.state.loader) {
      children = <Grid container  style={{justifyContent:"center"}}><MDBSpinner /></Grid>
      //children = <UserCard user={this.state.user} logout={this.logout} />
    } else {
      children = [
        <Grid container  style={{textAlign:"center"}}>
       
        <SocialButton
          provider='facebook'
          size="lg"
          appId={'597472400859811'}
         style={this.props.style || {borderRadius:"10px",width:"80%",fontSize:"14px"}}
          onLoginSuccess={this.onLoginSuccess}
          onLoginFailure={this.onLoginFailure}
          social="fb"
          onLogoutSuccess={this.onLogoutSuccess}
          getInstance={this.setNodeRef.bind(this, 'facebook')}
          key={'facebook'}
        >
          <MDBIcon fab icon="facebook-f" className="pr-1" /> <span style={this.props.btnStyle ||{fontWeight: "900"}}>Facebook</span>
        </SocialButton>,
        {/*<SocialButton
          provider='google'
          appId='292145916511-qvrt784vbqoe136r3bpuenni1ftmu9ng.apps.googleusercontent.com'
          onLoginSuccess={this.onLoginSuccess}
          onLoginFailure={this.onLoginFailure}
          onLogoutSuccess={this.onLogoutSuccess}
          social="google"
          className="google"
          style={{borderRadius:"10px",backgroundColor:"rgb(59, 89, 152) !important",color:"rgba(0, 0, 0, 0.54)"}}
          onLogoutFailure={this.onLogoutFailure}
          getInstance={this.setNodeRef.bind(this, 'google')}
          key={'google'}
        >
         <div ><svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="evenodd"><path d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z" fill="#EA4335"></path><path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4"></path><path d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z" fill="#FBBC05"></path><path d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z" fill="#34A853"></path><path fill="none" d="M0 0h18v18H0z"></path></g></svg><span style={{padding: "10px 10px 10px 10px",fontWeight: "900"}}>Login with Google</span></div>
        </SocialButton>,
          <SocialButton
          provider='linkedin'
          appId='86y33ojoz4xkii'
          social="li"
          size="lg"
          style={{borderRadius:"10px"}}
          onLoginSuccess={this.onLoginSuccess}
          onLoginFailure={this.onLoginFailure}
          onLogoutSuccess={this.onLogoutSuccess}
          getInstance={this.setNodeRef.bind(this, 'linkedin')}
          key={'linkedin'}
        >
          <MDBIcon fab icon="linkedin-in" className="pr-1" /><span style={{fontWeight: "900"}}>Login with LinkedIn</span>
        </SocialButton>*/}
        </Grid>
     
      ]
{/*
      // Amazon only supports HTTPS
      if (window.location.protocol === 'https:') {
        children.push(
          <SocialButton
            provider='amazon'
            appId='amzn1.application-oa2-client.26aaf63624854cbcaa084735a0fc47ed'
            onLoginSuccess={this.onLoginSuccess}
            onLoginFailure={this.onLoginFailure}
            onLogoutSuccess={this.onLogoutSuccess}
            getInstance={this.setNodeRef.bind(this, 'amazon')}
            key={'amazon'}
          >
            Login with Amazon
          </SocialButton>
        )
      } else {
        // We donâ€™t use HTTPS because of Gatekeeper, but it can be enabled if Gatekeeper is served over HTTPS
        children.push(
          <SocialButton
            autoCleanUri
            provider='github'
            gatekeeper='http://localhost:9999'
            appId='8a7c2edb2e602d969839'
            redirect='http://localhost:8080'
            onLoginSuccess={this.onLoginSuccess}
            onLoginFailure={this.onLoginFailure}
            onLogoutSuccess={this.onLogoutSuccess}
            getInstance={this.setNodeRef.bind(this, 'github')}
            key={'github'}
          >
            Login with GitHub OAuth
          </SocialButton>
        )
      }*/}
    }

    return children
  }
}