import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import serviceReducers from "services/serviceReducers";
import app from "reducers/appReducer";
const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    ...serviceReducers,
    app
  });

export default rootReducer;
