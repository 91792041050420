import axios from "axios";
import StorageManager from "utils/StorageManager";
import endpoints from "./endpoints";

const axiosInstance = axios.create({
  baseURL: endpoints.baseUrl
  // proxy: {
  //   host: "dev-corp2corp.pantheonsite.io"
  // }
});

const storage = StorageManager.instance;

axiosInstance.interceptors.request.use(
  config => {
    const xcsrf = storage.getXCSRFToken();
    console.log("XSCRF", xcsrf);
    if (xcsrf) {
      config.headers["X-CSRF-Token"] = xcsrf;
    }
    // config.headers["Content-Type"] = "application/json";
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// axiosInstance.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     const originalRequest = error.config;
//     if (error.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       return axiosInstance
//         .post("/auth/token", {
//           refresh_token: storage.getRefreshToken()
//         })
//         .then(res => {
//           if (res.status === 201) {
//             // 1) put token to LocalStorage
//             storage.setAccessToken(res.data);
//             storage.setRefreshToken(res.data);

//             // 2) Change Authorization header
//             axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + storage.getAccessToken();

//             // 3) return originalRequest object with Axios.
//             return axiosInstance(originalRequest);
//           }
//         });
//     }

//     // return Error object with Promise
//     return Promise.reject(error);
//   }
// );

export default axiosInstance;
