import {
  GET_JOB_LIST,
  GET_JOB_APPLIED,
  GET_JOB_SAVED,
  GET_JOB_DETAIL,
  GET_SKILLS,
  GET_INDUSTRIES,
  UPDATE_BASIC_INFO,
  GET_CANDIDATE_DETAILS,
  SEARCH,
  FLAG_JOB
} from "constants/actionTypes";
import { JobService } from "./JobService";

export const getJobListAction = (uid, offset) => {
  return {
    actionType: GET_JOB_LIST,
    callAPI: () => JobService.getJobList(uid, offset),
    stateObject: "jobList"
  };
};
export const getJobAppliedAction = uid => {
  return {
    actionType: GET_JOB_APPLIED,
    callAPI: () => JobService.getJobApplied(uid),
    stateObject: "jobApplied"
  };
};
export const getJobSavedAction = uid => {
  return {
    actionType: GET_JOB_APPLIED,
    callAPI: () => JobService.getJobSaved(uid),
    stateObject: "jobSaved"
  };
};

export const getJobDetailAction = (jobId, uid) => {
  return {
    actionType: GET_JOB_DETAIL,
    callAPI: () => JobService.getJobDetail(jobId, uid),
    stateObject: "jobDetail"
  };
};

export const getSkillsAction = () => {
  return {
    actionType: GET_SKILLS,
    callAPI: () => JobService.getMasterList("skills"),
    stateObject: "skills"
  };
};

export const getIndustriesAction = () => {
  return {
    actionType: GET_INDUSTRIES,
    callAPI: () => JobService.getMasterList("categories"),
    stateObject: "industries"
  };
};

export const updateBasicInfoAction = info => {
  return {
    actionType: UPDATE_BASIC_INFO,
    callAPI: () => JobService.updateBasicInfo(info),
    stateObject: "basicInfo",
    isTransient: true
  };
};

export const getCandidateDetailsAction = uid => {
  return {
    actionType: GET_CANDIDATE_DETAILS,
    callAPI: () => JobService.getCandidateDetails(uid),
    stateObject: "candidate"
  };
};

export const searchActionAction = (title, location, categories, skills, job_type, uid) => {
  return {
    actionType: SEARCH,
    callAPI: () => JobService.search(title, location, categories, skills, job_type, uid),
    stateObject: "jobList"
  };
};

export const flagJobAction = jobId => {
  return {
    actionType: FLAG_JOB,
    callAPI: () => JobService.flagJob(jobId),
    stateObject: "flagJob",
    isTransient: true
  };
};
