import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import assets from "../../../assets";
import { Grid } from "@material-ui/core";


const useStyles = makeStyles (theme => ({
    jobComponentContainer:{
        backgroundColor: 'white',
        boxShadow: '0px 0px 6px #00000029',
        borderRadius: '5px',
        padding:'0px 10px'
    },
    logo:{
        maxWidth:'60px'
    },
    applyButtonContainer:{
        textAlign:'right'
    },
    jobApplyButton:{
        fontSize:'0.68em',
        borderRadius:'11px',
        padding:'1px 0px'
    },
    jobDesc1:{
        fontFamily: "'Poppins', sans-serif",
        fontSize: '0.86em',
        marginTop:'2px',
        marginBottom: '0px'
    },
    jobDesc2:{
        fontFamily: "'Poppins', sans-serif",
        marginTop:'2px',
        lineHeight:'1.3em',
        fontSize: '0.8em',
        color: '#707070',

    },
    jobDesc3:{
        fontFamily: "'Poppins', sans-serif",
        fontSize: '0.86em',
        color: '#707070',
        
    }
}));

const JobComponent = () =>{
    const classes = useStyles();
    return(
        <div className={classes.jobComponentContainer}>
            <Grid container spacing ={2}>
                <Grid item xs={4}>
                    <img src ={assets.images.companyLogo} className={classes.logo}/>
                </Grid>
                <Grid item xs={8} className={classes.applyButtonContainer}>
                        <Button variant="outlined" color="secondary" className={classes.jobApplyButton}>Apply</Button>
                </Grid>
            </Grid>
            <p className={classes.jobDesc1}>UI/UX Specalist, 7 years Exp.</p>
            <p className={classes.jobDesc2}>Wipro Technologies,<br/>Bengaluru Karnataka, India</p>    
            <p className={classes.jobDesc3}>3 Days ago</p>
        </div>    
    );

}
export default JobComponent;