import React,{useEffect,useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink,useHistory } from "react-router-dom";
import { Formik, Form, ErrorMessage, Field, FastField } from "formik";
import * as Yup from "yup";
import lodash from "lodash";
import { FormikTextInput } from "components/common/FormInputs/FormikInputs";
import {authenticateAction,loginStatiticsAction} from "services/endpoints/usermanagement/userManagementActions";
import LockIcon from "@material-ui/icons/Lock";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { Button, LinearProgress } from "@material-ui/core";
import { toast } from "react-toastify";
import { COUNTRY_CODE } from "constants/generalConstants";
import UserManager from "utils/UserManager";


const LoginForm = ({ }) => {

  const { loading, data: loginDetails, error } = useSelector(state => state.userManagement.authentication);
  const [username, setUsername] = useState("");
  const dispatch = useDispatch(); 
  const history = useHistory();

  useEffect(() => {
    if (error) {
      console.log("error",error);
     toast.error("Error, wrong email id/Password");
    } else if (loginDetails) {
      toast.success("Successfully logged in.");
      if (UserManager.isCandidate()) {
        history.push("/saved-jobs");
      } else if (UserManager.isEmployer()) {
        const pro = localStorage.getItem("Project_id");
        if(pro){
          history.push("/checkout/999");
        }else{
          history.push("/employer-dashboard");
        }
      } else {
        //Recruiter
        history.push("/recruiter-dashboard");
      }
      let type = isFinite(username) ? "mobile" : "email";
      let reqest = {type,uid:lodash.get(loginDetails,"current_user.uid")}
      dispatch(loginStatiticsAction(reqest,loginDetails.access_token));
    } 
  }, [loginDetails, error]);

  const handleFormSubmit = (values, actions) => {
    let userName = values.UserName;
    if (isFinite(userName) && userName.length === 10) {
      userName = COUNTRY_CODE + userName;
    }
    setUsername(userName);
    dispatch(authenticateAction(userName, values.Password));
  }
  return (
    <Formik
      initialValues={{
        UserName: "",
        Password: ""
      }}
      validationSchema={Yup.object().shape({
        UserName: Yup.string().required("Please enter your email"),
        Password: Yup.string().required("Please enter your password")
      })}
      onSubmit={handleFormSubmit}
      // {(values, actions) => {
      //   actions.resetForm();
      //   if (loginAction) {
      //     loginAction(values.UserName, values.Password);
      //   }
      // }}
    >
      {({ errors, touched, handleSubmit, isSubmitting }) => {
        return (
          <>
            <Form name="login" method="post" onSubmit={handleSubmit}>
              <FastField
                style={{ marginBottom: "34px", }}
                name="UserName"
                component={FormikTextInput}
                fullWidth
                id="email"
                size="small"
                labelText="Enter Email Address/ Mobile Number"
                placeholder="Enter your Email Address/ Mobile Number"
                variant="outlined"
                Icon={MailOutlineIcon}
              />

              <FastField
                style={{ marginBottom: "29px" }}
                name="Password"
                component={FormikTextInput}
                type="password"
                fullWidth
                id="password"
                size="small"
                labelText="Enter Your Password"
                placeholder="Enter Your Password"
                variant="outlined"
                Icon={LockIcon}
              />

              <NavLink exact to="/forgot" className="reset-password-link"style={{paddingBottom:"54px"  }}> Forgot Password </NavLink>

              <Button
                type="submit"
                fullWidth
                disabled={loading}
                variant="contained"
                color="primary"
                style={{ fontFamily: "'Poppins', sans-serif", marginTop: "1em", color: "white", borderRadius:"10px" }}
              >
                LOG IN
              </Button>
              {loading && <LinearProgress />}
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
