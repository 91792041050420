import React from "react";
import { Formik, Form, ErrorMessage, Field, FastField } from "formik";
import * as Yup from "yup";
import { FormikTextInput } from "components/common/FormInputs/FormikInputs";
import LockIcon from "@material-ui/icons/Lock";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { Button, Select, MenuItem, InputLabel, FormControl, LinearProgress, Grid } from "@material-ui/core";
import { StyledLink } from "components/common/Misc";


import PhoneInput from 'react-phone-input-2';



import "./register.css";
const RegisterForm = ({ registerAction, loading, data, error }) => {
  const [mobile, setPhone] = React.useState("");
  const [mobile1, setmobile1] = React.useState("");
  const roles = [
    { name: "Candidate", value: "candidate" },
    { name: "Recruiter", value: "recruiter" },
    { name: "Employer", value: "employer" }
   
  ];
 
  return (
    
    <Formik
      initialValues={{ 
        email: "",
        mobile:"",
        fname: "",
        password: "",
        role: "candidate"
      }}
      validationSchema={Yup.object().shape({
       
        //email: Yup.string().required("Please enter your email"),
        email: Yup.string()
        .email('Invalid email')
        .required('Please enter your email'),
        //mobile: Yup.string().required("Please enter your mobile number"),
        fname: Yup.string().required("Please enter your name"),
        password: Yup.string().required("Please enter your password").min(6, 'Seems a bit short...'),
        
        role: Yup.string().required("Please select your role")
      })}
      onSubmit={(values, actions) => {
        console.log("Values:", mobile1);
        actions.resetForm();
        if (registerAction) {
          registerAction( values.email, values.password, values.role, mobile1, values.fname);
        }
      }}
    >
      {({ errors, touched, handleSubmit, isSubmitting }) => {
        return (
          <>
            <Form name="register" method="post" onSubmit={handleSubmit}>
              <Grid container>
                <div className="social-register">
              
                <FastField
                  style={{ marginTop: "1em",transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out", color: "#495057",borderRadius:".6rem" }}
                  name="fname"
                  component={FormikTextInput}
                  fullWidth
                  id="fname"
                  size="small"
                  placeholder="Name"
                  variant="outlined"
                  Icon={PersonOutlineOutlinedIcon}
                />
             
                <FastField
                  style={{ marginTop: "1em",transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out", color: "#495057",borderRadius:".6rem" }}
                  name="email"
                  component={FormikTextInput}
                  fullWidth
                  id="email"
                  size="small"
                  placeholder="Email"
                  variant="outlined"
                  Icon={MailOutlineIcon}
                />
             
          
             <PhoneInput
                   style={{ marginTop: "1em",transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out", color: "#495057",borderRadius:"10px" }}
               
                  //country={'us'}
                  required
                  
                  inputProps={{
                    name: 'mobile',
                    required: true,
                    //autoFocus: true
                  }}
                  placeholder	="Mobile Number"
                  inputStyle={{
                    width:"100%",
                    height:"40px"
                  }

                  }
                  value={mobile1}
                  onChange={phone => setmobile1(phone)} 
               />
              {/*}
              <FastField
                  style={{ display:"none",marginTop: "1em",transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out", color: "#495057",borderRadius:".6rem" }}
                  name="mobile"
                  component={FormikTextInput}
                  fullWidth
                  id="mobile"
                  size="small"
                  type="hidden"
                  value={mobile}
                  placeholder="Mobile Number"
                  variant="outlined"
                  Icon={CallOutlinedIcon}
                />
                */}
             
                <FastField
                  style={{ marginTop: "1em",  transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out", color: "#495057",borderRadius:".6rem" }}
                  name="password"
                  component={FormikTextInput}
                  type="password"
                 
                  fullWidth
                  id="password"
                  size="small"
                  placeholder="Password"
                  variant="outlined"
                  Icon={LockIcon}
                />
              
                </div>
                <Grid item xs={12}>
                  {/*<FormControl fullWidth style={{ marginTop: "1em" }}>
                    <FastField
                      name="role"
                      component={FormikTextInput}
                      select
                      shrink={true}
                      fullWidth
                      selectItems={roles}
                      size="small"
                      variant="outlined"
                    />
        </FormControl>*/}
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  style={{ fontFamily: "'Poppins', sans-serif", marginTop: "1em", color: "white",borderRadius: "10px" }}
                >
                  Register
                </Button>
                {loading && <LinearProgress />}
              </Grid>
            </Form>
            {/* <pre>
              <strong>Errors:</strong> {JSON.stringify(errors, null, 2)}
              <strong>Touched:</strong> {JSON.stringify(touched, null, 2)}
              {formValues && <strong>Submitted values:</strong>}
              {JSON.stringify(formValues, null, 2)}
            </pre> */}
          </>
        );
      }}
    </Formik>
  );
};

export default RegisterForm;
