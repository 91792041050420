import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Typography, Button, Box } from "@material-ui/core";
import MainContainer from "../../../../common/MainContainer";
import { Switch, NavLink, Route, useRouteMatch } from "react-router-dom";
import {registerWithOtpAction} from "services/endpoints/usermanagement/userManagementActions";
import IconButton from "@material-ui/core/IconButton";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Formik, Form, ErrorMessage, Field, FastField } from "formik";
import * as Yup from "yup";
import { FormikTextInput } from "components/common/FormInputs/FormikInputs";
import "./ResetPassword.scss";
import { toast } from "react-toastify";

const Verify = (props) => {
    const { loading, data: registerWithOtp, error } = useSelector(state => state.userManagement.registerWithOtp);
    const dispatch = useDispatch();

    const resendCode = () => {
        dispatch(
          registerWithOtpAction({
            action: "reset_password",
            username: props.userName
          })
        );
      };
    
      const resendCodeThroughVoiceCall = () => {
        dispatch(
          registerWithOtpAction({
            action: "reset_password_voice",
            username: props.userName
          })
        );
      };
 
    const handleFormSubmit = (values, actions) => {
      const  action="verify_verification_code";
        props.onSubmit(values,action) 
    }

    return (
      <>
        <Grid className="heading" xs={12}>
          <h2>Verify</h2>
        </Grid>
        <Grid xs={12}>
          <Box className="message">
            Enter 3 digit verification code sent on {props.userName}.
          </Box>
        </Grid>

        <Grid className="content" xs={12}>
          <Formik
            initialValues={{
              verificationCode: ""
            }}
            validationSchema={Yup.object().shape({
              verificationCode: Yup.string().required(
                "Please enter the verification code"
              )
            })}
            onSubmit={handleFormSubmit}
          >
            {({ errors, touched, handleSubmit, isSubmitting }) => {
              return (
                <>
                  <Form
                    name="reset-password"
                    method="post"
                    onSubmit={handleSubmit}
                  >
                    <FastField
                      style={{ marginBottom: "20px" }}
                      name="verificationCode"
                      component={FormikTextInput}
                      fullWidth
                      id="verificationCode"
                      size="small"
                      labelText="Enter verification code"
                      placeholder="Enter your verifcation code here"
                      variant="outlined"
                    />
                    <p>Did not get a verification code?</p>
 
                    <Box className="resend-options">
                        <a onClick={resendCode}>Resend Code</a>
                        <span>|</span>
                        <a onClick={resendCodeThroughVoiceCall}>
                          Resend code through voice call
                        </a>
                      </Box> 

                    <Button
                      type="submit"
                      fullWidth
                      //disabled={loading}
                      variant="contained"
                      color="primary"
                    >
                      CONTINUE
                    </Button>
                  </Form>
                </>
              );
            }}
          </Formik>

        </Grid>
      </>
    );
  };

export default Verify;