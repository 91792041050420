import React from "react";
import { hydrate,render } from "react-dom";
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { AppContainer } from "react-hot-loader";
import configureStore, { history } from "./store/configureStore";
import Root from "./components/Root";
import "./styles/styles.scss"; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
require("./favicon.ico"); // Tell webpack to load favicon.ico
const store = configureStore();
const rootElement = document.getElementById("app");
if (rootElement.hasChildNodes()) {
  hydrate(<AppContainer>
    <Root store={store} history={history} />
  </AppContainer>, rootElement);
} else {
  render(<AppContainer>
    <Root store={store} history={history} />
  </AppContainer>, rootElement);
}


if (module.hot) {
  module.hot.accept("./components/Root", () => {
    const NewRoot = require("./components/Root").default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById("app")
    );
  });
}
