import { EDIT_MOBILE_NUMBER, REGISTRATION_COMPLETE } from './../constants/actionTypes';

const logAction = action => {
  let statusColor = "orange";
  if (action.payload.status === "success") {
    statusColor = "green";
  } else if (action.payload.status === "failure") {
    statusColor = "red";
  } else if (action.payload.status === "clear") {
    statusColor = "purple";
  }
  console.log(`%c ${action.type} - ${action.payload.status}`, `color: ${statusColor}; font-weight:bold; font-size:14px;`, action.payload);
};

const createApiReducer = (initialState, actionTypes = []) => {
  return function reducer(state = initialState, action) {

    //TODO:: Need to remove from here
    if (action.type == EDIT_MOBILE_NUMBER) {
      return {
        ...state,
        registerWithOtp: {
          ...state.registerWithOtp,
            data: { "status": "request_verification_code" }
        }
      }
    }else if (action.type == REGISTRATION_COMPLETE) {
      return {
        ...state,
        registrationComplete :true
      }
    }

    if (actionTypes.includes(action.type)) {
      logAction(action);
      const { payload, type } = action;
      const { status, ...data } = payload;

      const newState = {
        ...state,
        ...data
      };

      console.log("New state:", newState);

      return newState;
    } else {
      return state;
    }
  };
};

export default createApiReducer;
