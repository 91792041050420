const endpoints = {
   baseUrl: "https://live-corp2corp.pantheonsite.io",
 
   register: "services/registration",
   login: "user/login",
   logout: "services/logout",
   EmployerJobActivate: "v2/services/moderate",
   //resetPassword: "services/forgot-password",
   employerDetails:"v2/services/",
   employerProfileEdit:"v2/services/employer/edit",
   resetPassword:"v2/services/password",
   socialLoginGoogle: "user/login/google?_format=json",
   socialLogin: "services/social-login",
   refreshToken: "jwt/token",
   loginStatitics: "v2/services/statistics",
   employerJobCount:"v2/services/employer/count",
 
   loginWithSocial: "user/login/",
   linkedInToken: "services/linkedin",
 
   registerWithOtp: "v2/services/otp",
   emailRegister: "v2/services/register",
   updateResumeRef: "v2/services/resume",
   
   verifyOtp: "services/verify-otp",
   getCategories: "services/categories/info",
   getJobInfo: "services/jobs/info",
   getXcsrfToken: "session/token",
   getJobList: "services/jobs/list",
   getJobApplied: "services/jobs/applied",
   getJobSaved: "services/jobs/saved",
   getJobDetail: "services/jobs/details",
   getMasterList: "services/master-list",
   createJob: "services/jobs/create",
   flagJob: "entity/flagging",
 
   updateCandidateBasicInfo: "services/profile/candidate/basic-info",
   updateEducation: "services/profile/candidate/education-details",
   updateWorkHistory: "services/profile/candidate/experience-details",
   updateAdditionalInfo: "services/profile/candidate/additional-info",
   uploadResume: "file/upload/node/candidate_details/field_resume",
   getCandidateDetails: "services/profile/candidate",
   search: "services/search-filter",
   generateOtp: "services/generate-otp",
   socialRegister: "services/social-register"
 };
 
 export default endpoints;
 