import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  PhoneAndroidOutlined,
  LockOutlined,
  PersonOutlineOutlined,
  MailOutline,
  Lock
} from "@material-ui/icons";
import { Button, Box, LinearProgress, Grid } from "@material-ui/core";
import { Formik, Form, FastField } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { FormikTextInput } from "components/common/FormInputs/FormikInputs";
import {
  registerWithOtpAction,
  emailRegisterAction,
  authenticateAction
} from "services/endpoints/usermanagement/userManagementActions";
import {
  EDIT_MOBILE_NUMBER,
  REGISTRATION_COMPLETE
} from "constants/actionTypes";
import { COUNTRY_CODE } from "constants/generalConstants";

const VerifyMobile = ({ onSubmitForm, registerData, loading }) => {
  const dispatch = useDispatch();

  const handleFormSubmit = values => {
    onSubmitForm(values);
  };

  const editMobileNumber = () => {
    dispatch({ type: EDIT_MOBILE_NUMBER });
  };

  const resendCode = () => {
    dispatch(
      registerWithOtpAction({
        action: "resend_verification_code",
        mobile: COUNTRY_CODE + registerData.mobile
      })
    );
  };

  const resendCodeThroughVoiceCall = () => {
    dispatch(
      registerWithOtpAction({
        action: "resend_verification_code_through_voice_call",
        mobile: COUNTRY_CODE + registerData.mobile
      })
    );
  };

  const EditButton = () => {
    return (
      <div className="edit-button" onClick={editMobileNumber}>
        Edit
      </div>
    );
  };

  let validationSchema = {
    verificationCode: Yup.string().required(
      "Please enter your verification code"
    )
  };

  return (
    <Formik
      initialValues={{
        mobile: registerData.mobile
      }}
      validationSchema={Yup.object().shape(validationSchema)}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit }) => {
        return (
          <>
            <Form name="register" method="post" onSubmit={handleSubmit}>
              <Grid container>
                <div className="social-register">
                  <div className="mobile-number-wrapper">
                    <FastField
                      style={{
                        marginBottom: "20px",
                        transition:
                          "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                        color: "#495057",
                        borderRadius: ".6rem"
                      }}
                      name="mobile"
                      component={FormikTextInput}
                      fullWidth
                      type="number"
                      id="mobile"
                      size="small"
                      labelText="Enter Mobile Number"
                      placeholder="Enter your Mobile Number"
                      variant="outlined"
                      Icon={PhoneAndroidOutlined}
                      disabled
                      EndIcon={EditButton}
                    />
                  </div>

                  <FastField
                    style={{
                      transition:
                        "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                      color: "#495057",
                      borderRadius: ".6rem"
                    }}
                    name="verificationCode"
                    component={FormikTextInput}
                    fullWidth
                    id="verificationCode"
                    size="small"
                    labelText="Enter Verification Code"
                    placeholder="Enter your Verification Code"
                    variant="outlined"
                    Icon={LockOutlined}
                  />
                  <Box className="resend-options">
                    <a onClick={resendCode}>Resend Code</a>
                    <span>|</span>
                    <a onClick={resendCodeThroughVoiceCall}>
                      Resend code through voice call
                    </a>
                  </Box>
                </div>
              </Grid>
              <Grid container>
                <Grid xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                      marginTop: "1em",
                      color: "white",
                      borderRadius: "10px"
                    }}
                  >
                    Register
                  </Button>
                  {loading && <LinearProgress />}
                </Grid>
              </Grid>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default VerifyMobile;
