

import React from 'react';

import axios from 'axios';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBIcon, MDBView, MDBBtn,MDBAvatar,MDBBadge,MDBContainer } from "mdbreact";

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import TopNavigation from 'components/pages/employer/header/topNavigation';
import SideNavigation from 'components/pages/employer/header/sideNavigation';
import Footer from 'components/pages/employer/header/Footer';
import Education from './sections/Education';
import Experiance from './sections/Experiance';
import logo from 'assets/images/default.png';
import StorageManager from "utils/StorageManager";
import MainContainer from "components/common/MainContainer";
import Link from '@material-ui/core/Link';



export default class EmployerCandidateDetails extends React.Component {
  
  constructor() {
    super();
    this.state = {
      liked: false,
      resume:'',
      items: []
    };
    this.handleClick = this.acceptItem.bind(this);
  } 
  acceptItem = item => {
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+token,
        'X-CSRF-Token':csrf
      }
        this.setState({ item: item });
        const data = {"flag_id":"short_list" ,"entity_type":"user", "entity_id":item.uid,"uid":uid};
        axios.post(`https://live-corp2corp.pantheonsite.io/api/flag?_format=json`, data,{
            headers: headers
          })
          .then(res => {
            
            console.log(res.data);
            window.location.reload(); 
          })
    this.setState({
      liked: !this.state.liked
    });
  }
  componentDidMount() {
    const contentId = this.props.match.params.id;  
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+token,
        'X-CSRF-Token':csrf
      }  
    axios.get('https://live-corp2corp.pantheonsite.io/api/'+contentId+'/candidate-details?_format=json',{headers})
      .then(res => {
        const items = res.data;
        console.log("Hanish",items);
        this.setState({ items });
      })
      const headers1 = {
        'Content-Type': 'application/json'
      }
      //const uid = StorageManager.instance.getUid();
       axios.get('https://live-corp2corp.pantheonsite.io/my-resume/'+contentId, {  headers: headers1 })
      .then(res => {
       console.log("kiki",res.data[0].field_description);
       const v1 = res.data[0].field_description;
       if(v1 == ""){
          axios.get('https://corp2corpfile.blob.core.windows.net/corp2corp/'+contentId+'.html')
          .then(res => {
          // console.log(res.data);
           this.setState({resume:res.data})
          })
       }else if(v1){
          this.setState({resume:res.data[0].field_description})
       }
      /* if(res.data.data[0].field_description == ""){
          axios.get(`https://corp2corpfile.blob.core.windows.net/corp2corp/Karthik%20Radhakrishnan%20Mechanical%20Project%20Engineer%20Resume.html`)
          .then(res => {
          // console.log(res.data);
           this.setState({resume:res.data})
          })
       
       }else{
          this.setState({resume:res.data[0].field_description})
       }*/
      })
  }

  render() {
    const label = this.state.liked ? 'Shortlist' : 'Remove'
   
    return (
      <MainContainer>
        <div className="flexible-content">
      
         
          <SideNavigation />
          <main id="content" className="p-1">
          <MDBContainer>
          <MDBCard className="my-5 px-5 pb-5">
       
          { this.state.items.map(item =>
        <MDBCardBody cascade className="text-center">
           <p className="text-center w-responsive mx-auto mb-5" dangerouslySetInnerHTML={{ __html: this.state.resume.replace(/\�/g,'&nbsp') }}></p>
        
    </MDBCardBody>
     )}
      </MDBCard>
   
        </MDBContainer>
          </main>
         
          </div> 
          </MainContainer>
     )
  }
}


