const assets = {
  images: {
    logo: require("./images/c2c-01.svg"),
    bodybg: require("./images/bodybg.jpg"),
    suitcaseIcon: require("./images/suitcase.svg"),
    companyLogo: require("./images/companyLogo.png"),
    googlelogo: require("./images/googlelogo.png"),
    ibmlogo: require("./images/ibmlogo.png"),
    kpmglogo: require("./images/kpmglogo.png"),
    microsoftlogo: require("./images/microsoftlogo.png"),
    nestlelogo: require("./images/nestlelogo.png"),
    siemenslogo: require("./images/siemenslogo.png"),
    pwclogo: require("./images/pwclogo.png"),
    unileverlogo: require("./images/unileverlogo.png"),
    eylogo: require("./images/eylogo.png"),
    gelogo: require("./images/gelogo.png"),
    boschlogo: require("./images/boschlogo.png"),
    bmwlogo: require("./images/bmwgrouplogo.png"),
    googleplay: require("./images/googleplay.png"),
    applestore: require("./images/appstore.png"),
    cclogo: require("./images/cclogo.svg"),
    cclogo1: require("./images/cclogo1.svg"),
    facebook: require("./images/facebook.svg"),
    instagram: require("./images/instagram.svg"),
    pinterest: require("./images/pinterest.svg"),
    twitter: require("./images/twitter.svg"),
    bannerBackground: require("./images/bannerbg.png"),
    registerBackground: require("./images/bg.png"),
    googleIcon: require("./images/googleIcon.svg"),
    linkedinIcon: require("./images/linkedinIcon.svg"),
    navLeftArrow: require("./images/navLeftArrow.svg"),
    navRightArrow: require("./images/navRightArrow.svg"),
    heroBg2: require("./images/hero-slice2.png"),
    heroBg1: require("./images/hero-slice1.png"),
    loginImg: require("./images/loginImage.png"),
    uploadIcon: require("./images/uploadIcon.svg")
  }
};
export default assets;
