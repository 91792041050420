import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterContainer, FilterType, FilterTabs, FilterTab } from "./FilterResults.styled";
import { Formik, Form, ErrorMessage, Field, FastField } from "formik";
import { FormikTextInput } from "components/common/FormInputs/FormikInputs";
import SearchIcon from "@material-ui/icons/Search";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Grid, Button, Typography, Box, TextField, Select, MenuItem } from "@material-ui/core";
import JobTypeFilter from "./JobTypeFilter";
import SearchForm1 from "components/common/searchForm/SearchForm1";
import { getCategoriesAction } from "services/endpoints/common/commonActions";
import { getSkillsAction } from "services/endpoints/jobs/jobActions";
import { TextInput } from "components/common/FormInputs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const FilterResults = ({ searchAction, onFilterChange }) => {
  const dispatch = useDispatch();
  const { data: catData } = useSelector(state => state.common.categories);
  const { data: skillsData } = useSelector(state => state.job.skills);
  const jobTypes = [
    { id: "Full-time", name: "Full-time" },
    { id: "Part-time", name: "Part-time" },
    { id: "Contract", name: "Contract" }
  ];
  useEffect(() => {
    dispatch(getCategoriesAction());
    dispatch(getSkillsAction());
  }, []);
  return (
    <FilterContainer>
      <Formik
        initialValues={{
          What: "hi",
          Where: "",
          JobType: ""
        }}
        // validationSchema={Yup.object().shape({
        //     UserName: Yup.string().required("Please enter your email/mobile"),
        //     Password: Yup.string().required("Please enter your password")
        // })}
        onSubmit={(values, actions) => {
          actions.resetForm();
          if (searchAction) {
            searchAction(values.What, values.Where);
          }
        }}
      >
        {({ errors, touched, handleSubmit, isSubmitting }) => {
          return (
            <>
              <Form name="search" method="post" onSubmit={handleSubmit}>
                <Grid container xs={12} spacing={1} alignItems="flex-start">
                  <SearchForm1 breakButton={false} />
                </Grid>
                <Grid continer style={{ marginTop: "1vh" }}>
                  <Grid item sm={10}>
                    {/* <Box>
                      <FilterTabs value={value} onChange={handleChangeFilterTab}>
                        <FilterTab label="JOB TYPE" />
                        <FilterTab label="SORT BY" />
                        <FilterTab label="DATE ADDED" />
                      </FilterTabs>
                    </Box> */}
                    {/* <Box>
                      <TabPanel value={value} index={0}>
                        <JobTypeFilter />
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        Item Two
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        Item Three
                      </TabPanel>
                    </Box> */}
                  </Grid>
                  <Grid item sm={2}></Grid>

                  <Grid item xs={3}></Grid>
                </Grid>
              </Form>
            </>
          );
        }}
      </Formik>
{/*}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextInput
            select
            labelText="Category"
            variant="outlined"
            fullWidth
            onChange={e => {
              onFilterChange && onFilterChange({ type: "categories", value: e.target.value });
            }}
          >
            <MenuItem value="all">All</MenuItem>
            {catData &&
              catData.map(item => {
                return <MenuItem value={item.tid}>{item.name}</MenuItem>;
              })}
          </TextInput>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInput
            select
            labelText="Skills"
            variant="outlined"
            fullWidth
            onChange={e => {
              onFilterChange && onFilterChange({ type: "skills", value: e.target.value });
            }}
          >
            <MenuItem value="all">All</MenuItem>
            {skillsData &&
              skillsData.map(item => {
                return <MenuItem value={item.id}>{item.name}</MenuItem>;
              })}
          </TextInput>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInput
            select
            labelText="Job Type"
            variant="outlined"
            fullWidth
            onChange={e => {
              onFilterChange && onFilterChange({ type: "job_type", value: e.target.value });
            }}
          >
            <MenuItem value="all">All</MenuItem>
            {jobTypes &&
              jobTypes.map(item => {
                return <MenuItem value={item.id}>{item.name}</MenuItem>;
              })}
          </TextInput>
        </Grid>
            </Grid>*/}
    </FilterContainer>
  );
};

export default FilterResults;
