import React from 'react';
import logo from "assets/images/logo.svg";
import { MDBListGroup, MDBListGroupItem, MDBIcon } from 'mdbreact';
import { NavLink } from 'react-router-dom';
import 'components/pages/employer/index.css';
import UserManager from "utils/UserManager";


const TopNavigation = () => {
    return (
        <div className="sidebar-fixed position-fixed">
            <a href="/recruiter-dashboard" className="logo-wrapper waves-effect">
                <img alt="corp2corp" className="img-fluid" src={logo}/>
            </a>
            <MDBListGroup className="list-group-flush">
                <NavLink exact={true} to="/recruiter-dashboard" activeClassName="activeClass">
                    <MDBListGroupItem>
                        <MDBIcon icon="chart-pie" className="mr-3"/>
                        Dashboard
                    </MDBListGroupItem>
                </NavLink>
                
                <NavLink to="/recruiter/My-Jobs" activeClassName="activeClass">
                    <MDBListGroupItem>
                        <MDBIcon icon="list-ul" className="mr-3"/>
                        My Jobs
                    </MDBListGroupItem>
                </NavLink>
                <NavLink to="/recruiter/My-candidates" activeClassName="activeClass">
                    <MDBListGroupItem>
                        <MDBIcon icon="list-ul" className="mr-3"/>
                        Job Applicants
                    </MDBListGroupItem>
                </NavLink>
                <NavLink to="/recruiter/job-posting" activeClassName="activeClass">
                    <MDBListGroupItem>
                        <MDBIcon icon="plus" className="mr-3"/>
                       Post Jobs
                    </MDBListGroupItem>
                </NavLink>
                <NavLink to="/my-candidatesList" activeClassName="activeClass">
                    <MDBListGroupItem>
                        <MDBIcon icon="address-book" className="mr-3"/>
                        My Candidates
                    </MDBListGroupItem>
                </NavLink>
                <NavLink to="/candidate-create" activeClassName="activeClass">
                    <MDBListGroupItem>
                        <MDBIcon icon="plus" className="mr-3"/>
                       Create Candidate
                    </MDBListGroupItem>
                </NavLink>
                <NavLink to="/Candidate-Search" activeClassName="activeClass">
                    <MDBListGroupItem>
                        <MDBIcon icon="search" className="mr-3"/>
                          Job Search
                    </MDBListGroupItem>
                </NavLink>
                
                
            </MDBListGroup>
        </div>
    );
}

export default TopNavigation;