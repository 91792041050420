import { styled } from "@material-ui/core";

export const PlacesInput = styled("input")(({ theme }) => {
  return {
    paddingLeft: "10px",
    height: "35px",
    width: "95%",
    borderRadius: "8px",
    border: "1px solid white",
    [theme.breakpoints.down("xs")]: {
      width: "97%"
    }
  };
});
