import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import assets from "../../../assets";
import { Grid } from "@material-ui/core";
import JobComponent from "./JobComponent"

const useStyles = makeStyles (theme => ({
    urgentRequirmentsContainer:{
        backgroundColor: 'white',
        padding: '0px 10px'
    },
    header:{
        fontSize: '20px',
        color: '#707070',
        fontFamily: 'Helvetica',
        paddingTop:'25px',
        paddingBottom:'30px',
        textAlign:'center'
    },
    applyNowButton:{
        backgroundColor: '#13C1D6',
      color:'white',
      paddingLeft: '15px',
      paddingRight: '15px',
      borderRadius: '25px',
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 'bold'
    },
    applyNowButtonContainer:{
        textAlign:'center',
        padding:'6px 0px 27px 0px'
    }
}));
const UrgentRequirments = () =>{
    const classes=useStyles();
    return(
        <div className={classes.urgentRequirmentsContainer}>
            <p className={classes.header}>Urgent Requirments</p>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <JobComponent/>
                </Grid >
                <Grid item xs={12} sm={6}>
                    <JobComponent/>
                </Grid >
                <Grid item xs={12} sm={6}>
                    <JobComponent/>
                </Grid >
                <Grid item xs={12} sm={6}>
                    <JobComponent/>
                </Grid >
                <Grid item xs={12} sm={6}>
                    <JobComponent/>
                </Grid >
                <Grid item xs={12} sm={6}>
                    <JobComponent/>
                </Grid >
                
            </Grid>
            <div className={classes.applyNowButtonContainer}>
                <Button variant="contained" className={classes.applyNowButton}>APPLY NOW</Button>
            </div>
        </div>
    );
}

export default UrgentRequirments;