

import React from 'react';

import axios from 'axios';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBIcon, MDBView, MDBBtn,MDBAvatar,MDBBadge,MDBContainer } from "mdbreact";



export default class Experiance extends React.Component {
  state = {
    items: []
  }
 
  
  componentDidMount() {
    const pathArray = window.location.pathname.split('/');
    const candiateID = pathArray[2];
    axios.get('https://live-corp2corp.pantheonsite.io/api/'+candiateID+'/experience?_format=json')
      .then(res => {
        const items = res.data;
        console.log("Hanish",items);
        this.setState({ items });
      })
  }

  render() {

    return (
      
      
        <MDBRow>
            <MDBCol lg="7">
        { this.state.items.map(item => 
        
            <MDBRow className="mb-3">
                <MDBCol md="1" size="2">
                
                </MDBCol>
                <MDBCol md="11" size="10">
                    <h5 className="font-weight-bold mb-3">{item.company}</h5>
                  
                    <p className="grey-text">
                        {item.start} to {item.end}
                    </p>
                  
                    <p className="text-center w-responsive mx-auto mb-5" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                </MDBCol>
            </MDBRow>
        
        
        )}
      
        
         </MDBCol>
      </MDBRow>
   
     )
  }
}


