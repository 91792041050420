import React,{Component} from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBIcon, MDBView, MDBBtn } from "mdbreact";
import MainContainer from "components/common/MainContainer";
import { withStyles } from "@material-ui/core/styles";
import StorageManager from "utils/StorageManager";
import Link from '@material-ui/core/Link';
import axios from 'axios';
import {Helmet} from "react-helmet";
import { Grid } from "@material-ui/core";


class TermsPage extends Component {
    state = {
       
        items: [],
      
      }
      componentDidMount() {
   
        const headers = {
            'Content-Type': 'application/json'
          }
          
        axios.get('https://live-corp2corp.pantheonsite.io/node/3882?_format=json')
          .then(res => {
            const items = res.data;
            this.setState({ items });
            const title = res.data.title[0].value;
            const body = res.data.body[0].value;
           
            this.setState({ title });
            this.setState({body});
           

          })
      }

     
      
  render() {
        
         const colStyle = { maxWidth: "22rem" };
         const { classes } = this.props;
       
            return (
                <MainContainer>
                  <Grid container justify="center">
                    <Grid item sm={12} md={8} xl={6}>
                <MDBCard className="my-5 px-5 pb-5">
      <MDBCardBody>
        <h2 className="h1-responsive font-weight-bold text-center my-5">
         {this.state.title}
        </h2>
        <p className="w-responsive mx-auto mb-5" dangerouslySetInnerHTML={{ __html: this.state.body }}> 
      
        </p>
      
        </MDBCardBody></MDBCard>
        </Grid></Grid>
</MainContainer>
                 )
        }
      }
      
      export default TermsPage;