import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { useHistory } from "react-router-dom";
import "./SocialLogin.scss";
import RolesPopup from "components/pages/rolespopup";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { authenticateAction, registerAction, generateOtpAction, verifyOtpAction,socialLoginAction } from "services/endpoints/usermanagement/userManagementActions";

import StorageManager from "utils/StorageManager";
import axios from 'axios';

const SocialLogin = ({ onGoogleSuccess }) => {
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const dispatch = useDispatch();
  const responseRef = useRef(null);
  const history = useHistory();

  return (
    <Grid container>
      <Grid item xs={12}>
        <GoogleLogin
          className="googleButton"
          clientId="808237848485-2fid2u88q52skos4neuujgtksmdi00o0.apps.googleusercontent.com"
          buttonText="LOGIN WITH GOOGLE"
          buttonStyle={{ width: "80%", height: "50px" }}
          style={{ width: "80%", height: "50px" }}
          onSuccess={response => {
            console.log(response.accessToken);
            // onGoogleSuccess && onGoogleSuccess(response);
            //responseRef.current = { response };
            responseRef.current = { response };
           
            
            const headers = {
              'Content-Type': 'application/json',
              //"X-CSRF-Token": localStorage.getItem("anonymous_toke")
            }
            
            const data = {
              "access_token": response.accessToken,
              withCredentials: true,
        
            };
            axios.post(`https://live-corp2corp.pantheonsite.io/user/login/google?_format=json`, data,{
              headers: headers,
             withCredentials: true
            })
            .then(res => {
              console.log("dsaasd",res);
              if ((res.data.current_user.roles).indexOf("candidate") > -1) {
              
                //StorageManager.instance.setAccessToken(result.access_token);
              
                StorageManager.instance.setBasicToken('aGFuaXNoQHdpbm5vcHJvLmNvbTox');
                StorageManager.instance.setUid(res.data.current_user.uid);
                StorageManager.instance.setXCSRFToken(res.data.csrf_token);
                StorageManager.instance.setLogoutToken(res.data.logout_token);
                //In the array!
                console.log("Success1candidates",res.data.current_user.roles);
                const headers1 = {
                  'Content-Type': 'application/json',
                  "X-CSRF-Token": res.data.csrf_token
                }
                
                const data1 = {
                };
                console.log(data1);
                axios.post(`https://live-corp2corp.pantheonsite.io/jwt/token?_format=json`,data1,{
                 headers: headers1,
                 withCredentials: true
                })
                .then(response => {
                  StorageManager.instance.setAccessToken(response.data.token);
                  console.log("response1",response);
                  history.push("/onboarding");
                });
               // const dispatch = useDispatch();
               // dispatch(socialLoginAction(access_token));
            } else if ((res.data.current_user.roles).indexOf("employer") > -1) {
              // StorageManager.instance.setAccessToken(result.access_token);
              StorageManager.instance.setBasicToken('aGFuaXNoQHdpbm5vcHJvLmNvbTox');
              StorageManager.instance.setUid(res.data.current_user.uid);
              StorageManager.instance.setXCSRFToken(res.data.csrf_token);
              StorageManager.instance.setLogoutToken(res.data.logout_token);
          //Not in the array
          console.log("Success1employer",res.data.current_user.roles);
          const headers1 = {
            'Content-Type': 'application/json',
            "X-CSRF-Token": res.data.csrf_token
          }
          
          const data1 = {
          };
          console.log(data1);
          axios.post(`https://live-corp2corp.pantheonsite.io/jwt/token?_format=json`,data1,{
           headers: headers1,
           withCredentials: true
          })
          .then(response => {
            StorageManager.instance.setAccessToken(response.data.token);
            console.log("response1",response);
            history.push("/employer-dashboard");
          });
                //Not in the array
                console.log("Success1employer",res.data.current_user.roles);
            }else if ((res.data.current_user.roles).indexOf("recruiter") > -1) {

              // StorageManager.instance.setAccessToken(result.access_token);
              
              // StorageManager.instance.setAccessToken(result.access_token);
               StorageManager.instance.setBasicToken('aGFuaXNoQHdpbm5vcHJvLmNvbTox');
               StorageManager.instance.setUid(res.data.current_user.uid);
               StorageManager.instance.setXCSRFToken(res.data.csrf_token);
               StorageManager.instance.setLogoutToken(res.data.logout_token);
                 //Not in the array
                 console.log("Success1employer",res.data.current_user.roles);
                 const headers1 = {
                   'Content-Type': 'application/json',
                   "X-CSRF-Token": res.data.csrf_token
                 }
                 
                 const data1 = {
                 };
                 console.log(data1);
                 axios.post(`https://live-corp2corp.pantheonsite.io/jwt/token?_format=json`,data1,{
                  headers: headers1,
                  withCredentials: true
                 })
                 .then(response => {
                   StorageManager.instance.setAccessToken(response.data.token);
                   console.log("response1",response);
                   history.push("/recruiter-dashboard");
                 });
             }else{
               
              localStorage.setItem("temp_xcsrf_token",res.data.csrf_token);
              //localStorage.setItem("telogout_token",res.data.logout_token);
              localStorage.setItem("temp_uid",res.data.current_user.uid);
              //localStorage.setItem("basic_token","aGFuaXNoQHdpbm5vcHJvLmNvbTox");
              const headers_logout = {
                'Content-Type': 'application/json',
                //'Authorization': 'bearer '+token,
                //'X-CSRF-Token':csrf
              }
              axios.get('https://live-corp2corp.pantheonsite.io/user/logout?_format=json&token='+res.data.logout_token,{withCredentials: true},{
                headers: headers_logout
              })
              .then(res => {
                const candidates = res.data;
            
              })
              console.log("hey how are ytou",response);
  
              console.log("hey how are ytouq1",localStorage.getItem("temp_uid"));
              console.log(response.accessToken);
              //dispatch(socialLoginGoogleAction(access_token));
              dispatch(socialLoginAction(localStorage.getItem("temp_uid"), "recruiter"));
              localStorage.setItem("google_token",response.accessToken);
              //setOpenRoleModal(true);
            }
              //console.log(res.data);
             // window.location.reload();
            })
           
          }}
          onFailure={response => {
            console.log(response);
          }}
         
        />
      </Grid>
      {/* <Grid item xs={12}>
        <FacebookLogin
          buttonStyle={{ width: "80%", height: "50px" }}
          appId="1088597931155576"
          autoLoad={false}
          fields="name,email,picture"
          //  onClick={componentClicked} callback={responseFacebook}
        />
        ,
      </Grid> */}
      {/* <Grid item xs={12}>
        <LinkedIn
          clientId="81c8dd161up821"
          // onFailure={this.handleFailure}
          // onSuccess={this.handleSuccess}
          redirectUri="http://localhost:3000/login"
        >
          Log in with Linked In
        </LinkedIn>
      </Grid> */}
      <RolesPopup
        open={openRoleModal}
        onSubmit={role => {
          onGoogleSuccess({ response: responseRef.current.response, role });
        }}
      />
    </Grid>
  );
};

export default SocialLogin;
