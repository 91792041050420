import React, { useReducer } from "react";
const OnBoarding = React.createContext(null);
const useSteps = (initialStep = 1, totalSteps = 6) => {
  const initialState = { step: initialStep, total: totalSteps };
  const [state, dispatch] = useReducer(stepsReducer, initialState);
  const isPrevAvailable = state.step !== 1;
  const isNextAvailable = state.step !== totalSteps;

  const increment = () => {
    if (isNextAvailable) dispatch({ type: "increment" });
  };

  const decrement = () => {
    if (isPrevAvailable) dispatch({ type: "decrement" });
  };

  return [state, increment, decrement, isNextAvailable, isPrevAvailable];
};

const stepsReducer = (state, action) => {
  switch (action.type) {
    case "increment":
      return { ...state, step: parseInt(state.step) + 1 };
    case "decrement":
      return { ...state, step: parseInt(state.step) - 1 };
    default:
      throw state;
  }
};

export { useSteps, OnBoarding };
