import { GET_CATEGORIES, GET_JOB_INFO, XCRF_TOKEN } from "constants/actionTypes";
import createApiReducer from "services/createAPIReducer";
const initialState = {
  categories: {},
  jobInfo: {},
  xcsrf: {}
};

const common = createApiReducer(initialState, [GET_CATEGORIES, GET_JOB_INFO, XCRF_TOKEN]);

export default common;
