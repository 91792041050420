import React, { useState, useEffect } from "react";
import { Box, Paper,Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DialogBox from "./DialogBox";
import UserManager from "utils/UserManager";

const DisplayTile = ({ field, label, value, onEdit, ...rest }) => {
  const [isEditing, setEditing] = useState(false);
  const [editFields, setEditFields] = useState([]);
  const handleEdit = event => {
    let fieldEls = [];
    if (field === "name") {
      fieldEls = [
        { field: "fname", label: "First name", value: rest.profile.fname },
        { field: "lname", label: "Last name", value: rest.profile.lname }
      ];
    } else if (field === "address") {
      fieldEls = [{ field, label, value, type: "textarea" }];
    } else {
      fieldEls = [{ field, label, value }];
    }
    setEditFields(fieldEls);
    setEditing(true);
  };

  const handleSave = event => {
    setEditFields([]);
    setEditing(false);
    onEdit(event);
  };

  const handleCancelEdit = () => {
    setEditFields([]);
    setEditing(false);
  };

  return (
    <>
    {
      field=="logout"?(
      <Paper className="display-tile">
        <div className="label">{label}</div>
        <div className="value">{value}</div>
        <Button 
           className="back-button"
           variant="contained"  
           color="primary"                 
          style={{
            fontFamily: "'Poppins', sans-serif",
            marginLeft:"1em",
            borderRadius: "10px"
          }}
          onClick={() => {UserManager.logout();}}  
          >
            LOG OUT
          </Button>
      </Paper>
      ):(
        <Paper className="display-tile">
        <div className="label">{label}</div>
        <div className="value">{value}</div>
        <a className="edit-button" onClick={handleEdit}>
          <EditIcon />
          <span>Edit</span>
        </a>
      </Paper>  

      )}
      {isEditing ? (
        <DialogBox
          isOpen={isEditing}
          onSave={handleSave}
          onCancel={handleCancelEdit}
          fields={editFields}
        ></DialogBox>
      ) : (
        ""
      )}
    </>
  );
};
export default DisplayTile;
