import React from "react";
import MyAppBar from "../myappbar/MyAppBar";
import FooterComponent from "components/pages/home/FooterComponent";
import { withStyles, Slide } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { Container } from "./MainContainer.styled";
const styles = theme => ({
  toolbar: theme.mixins.toolbar
});
const MainContainer = ({ showHeader = true, showFooter = true, children, classes, ...rest }) => {
  return (
    <Container {...rest}>
      <MyAppBar />
      {/* <Slide direction="up" in={true}> */}
      <div style={{ flex: "1" }}>{children}</div>
      {/* </Slide> */}
      <FooterComponent />
    </Container>
  );
};

export default withStyles(styles)(MainContainer);
