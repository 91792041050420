import React from "react";
import { styled } from "@material-ui/styles";
import { Box, Grid, Container, TextField, Paper } from "@material-ui/core";
import assets from "../../../assets";

export const Root = styled(({ ...other }) => <Grid {...other} />)({
  backgroundColor: "#F7F7F7"
});

export const VerticalCenter = styled(Container)`
  display: "block",
  verticalAlign: "middle",
  flexDirection: "column",
  justifyContent: "center"
})`;

export const ImageGrid = styled(Grid)({
  height: "100%",
  display: "flex",
  verticalAlign: "middle",
  flexDirection: "column",
  justifyContent: "center",
  fontFamily: "'Poppins', sans-serif"
});

export const Image = styled(Box)({
  height: "23vw",
  width: "100%",
  backgroundImage: `url(${assets.images.loginImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "contain"
});

export const LoginCard = styled(Paper)(({ theme }) => ({
  padding: "3.5vh 0px",
  marginRight: "8vw",
  [theme.breakpoints.down("sm")]: {
    marginRight: "0px"
  }
}));

export const ImageIcon = styled(Box)({
  marginLeft: "6px"
});
