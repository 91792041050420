import React from "react";
import { Grid, Container, Typography, FormControl, InputAdornment, Icon, Button, FormGroup } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, ErrorMessage, Field, FastField } from "formik";
import { FormikTextInput, FormikChipInput, FormikEditor, FormikCheckBoxGroup } from "components/common/FormInputs/FormikInputs";
import * as Yup from "yup";
import { updateBasicInfoAction } from "services/endpoints/jobs/jobActions";
import { toast } from "react-toastify";
import StorageManager from "utils/StorageManager";
import lodash from "lodash";
import { DropzoneArea } from "material-ui-dropzone";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const JobPostingForm = () => {
  const employmentTypes = [
    { name: "Full time", value: "full_time" },
    { name: "Part time", value: "part_time" }
  ];

  const qualifications = [
    { name: "BTech", value: "btech" },
    { name: "BSC", value: "bsc" },
    { name: "Phd", value: "phd" }
  ];
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        company_name: "",
        job_title: "",
        location: "",
        employment_type: "",
        salary_range: "",
        description: "",
        application_deadline: "",
        apply_type: "",
        external_url: "",
        experience: "",
        qualification: [],
        designation: []
      }}
      validationSchema={Yup.object().shape({
        company_name: Yup.string().required("This field is required"),
        job_title: Yup.string().required("This field is required"),
        location: Yup.string().required("This field is required"),
        employment_type: Yup.string().required("This field is required"),
        salary_range: Yup.string().required("This field is required"),
        description: Yup.string().required("This field is required"),
        application_deadline: Yup.string().required("This field is required"),
        apply_type: Yup.string().required("This field is required"),
        external_url: Yup.string().required("This field is required"),
        experience: Yup.string().required("This field is required"),
        qualification: Yup.array().required("This field is required"),
        designation: Yup.array().required("This field is required")
      })}
      onSubmit={(values, actions) => {}}
    >
      {({ errors, touched, handleSubmit, isSubmitting }) => {
        return (
          <Form name="postJob" method="post" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FastField
                  name="company_name"
                  component={FormikTextInput}
                  fullWidth
                  id="company_name"
                  labelText="Company Name"
                  size="small"
                  placeholder="Enter Company Name"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FastField
                  name="location"
                  component={FormikTextInput}
                  fullWidth
                  id="location"
                  labelText="Company Location"
                  size="small"
                  placeholder="Enter Company Location"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FastField
                  name="employment_type"
                  component={FormikTextInput}
                  select
                  fullWidth
                  selectItems={employmentTypes}
                  id="employment_type"
                  labelText="Employment Type"
                  size="small"
                  placeholder="Enter Employment Type"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FastField
                  name="salary_range"
                  component={FormikTextInput}
                  select
                  fullWidth
                  selectItems={employmentTypes}
                  id="salary_range"
                  labelText="Salary Range"
                  size="small"
                  placeholder="Enter Salary range"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FastField
                  name="description"
                  component={FormikEditor}
                  fullWidth
                  id="salary_range"
                  labelText="Description"
                  size="small"
                  placeholder="Enter Description"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FastField
                  name="qualification"
                  component={FormikCheckBoxGroup}
                  fullWidth
                  items={qualifications}
                  id="salary_range"
                  labelText="Qualification"
                  size="small"
                  variant="outlined"
                />
              </Grid>

              <Button type="submit" variant="contained" color="primary" style={{ marginTop: "1em", color: "white", width: "100%" }}>
                Register Now
              </Button>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default JobPostingForm;
