import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBIcon, MDBView, MDBBtn } from "mdbreact";
import MainContainer from "components/common/MainContainer";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import axios from 'axios';
const BlogPage = () => {
    const [respo, setRespo] = useState([]);
    const history = useHistory();
    const contentId = window.location.href.split('/').reverse()[1];
    useEffect(() => {
      const fetchData = async () => {
          const response = await axios.get('https://live-corp2corp.pantheonsite.io/blogs/'+contentId);
          setRespo(response.data);
      }
      
      fetchData();
    }, []);
  return (
    <MainContainer>
    <Grid container justify="center">
              <Grid item sm={12} md={8} xl={6}>
    <MDBCard className="my-5 px-5 pb-5">
      <MDBCardBody>
      {respo.map(res =>
      <div>
        <MDBRow>
          <MDBCol md="12">
            <MDBCard reverse>
              <MDBView hover cascade waves>
                <img
                   src={"https://live-corp2corp.pantheonsite.io/"+res.field_image}
                  alt=""
                  className="img-fluid"
                />
                <MDBMask overlay="white-slight" className="waves-light" />
              </MDBView>
              <MDBCardBody cascade className="text-center">
                <h2 className="font-weight-bold">
      <a href="#!">{res.title}</a>
                </h2>
                <p>
                  Written by 
                  <a href="#!">
                    <strong> {res.uid}</strong>
                  </a>
                  , {res.created}
                </p>
                <MDBBtn href={"https://www.facebook.com/sharer.php?u=https://corp2corp.jobs"+res.view_node} className="btn-fb waves-light">
                  <MDBIcon fab icon="facebook-f" className="pr-2" />
                  Facebook
                </MDBBtn>
              
                <MDBBtn href={"https://twitter.com/intent/tweet?url=https://corp2corp.jobs"+res.view_node+"&text="+res.title+"&via={user_id}&hashtags=Corp2corp,US Jobs"} className="btn-tw waves-light">
                  <MDBIcon fab icon="twitter" className="pr-2" />
                  Twitter
                </MDBBtn>
           
                <MDBBtn href={"https://www.linkedin.com/shareArticle?mini=true&url=https://corp2corp.jobs"+res.view_node+"&title="+res.title+"&summary="+res.body} className="btn-li waves-light">
                  <MDBIcon fab icon="linkedin-in" className="pr-2" />
                  LinkedIn
                </MDBBtn>
                
             
              
              </MDBCardBody>
            </MDBCard>
            <MDBContainer className="mt-5">
            <p className=" w-responsive mx-auto mb-5" dangerouslySetInnerHTML={{ __html: res.body }}></p>
        
            </MDBContainer>
          </MDBCol>
        </MDBRow>
        <hr className="mb-5 mt-4" />
        </div>
      )}
    </MDBCardBody>
    </MDBCard>
    </Grid>
    </Grid>
    </MainContainer>
  );
}

export default BlogPage;