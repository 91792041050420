import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import OtpInput from "react-otp-input";
import { Grid, Box } from "@material-ui/core";
import { TextInput } from "components/common/FormInputs";
const ForgotPassword = ({ open, handleClose, onSubmit }) => {
  const [email, setEmail] = useState("");
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Forgot Password</DialogTitle>
      <DialogContent>
        <DialogContentText>Please enter your email id</DialogContentText>
      </DialogContent>
      <Box marginX={12} marginBottom={4}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <TextInput
              fullWidth
              placeholder="Email"
              variant="outlined"
              value={email}
              onChange={e => {
                setEmail(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <DialogActions>
        {/* <Button onClick={handleClose}>Cancel</Button> */}
        <Button
          onClick={() => {
            onSubmit && onSubmit(email);
          }}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ForgotPassword;
