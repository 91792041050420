import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Typography, Button, Box } from "@material-ui/core";
import MainContainer from "../../../../common/MainContainer";
import { resetPasswordAction, registerWithOtpAction } from "services/endpoints/usermanagement/userManagementActions";
import { COUNTRY_CODE } from "constants/generalConstants";
import "./ResetPassword.scss";
import Forgot from "./Forgot";
import Verify from "./Verify";
import SetPassword from "./SetPassword";
import Success from "./Success";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router";

// const initialState = {
//   status: "request_verification_code",
// };

const initialStatus = "request_verification_code";

const ResetPassword = () => {
  const [userName, setUserName] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState(initialStatus);
  const { loading, data: resetPassword, error } = useSelector(state => state.userManagement.registerWithOtp);
  const { loading1, data: resetPassword1, error1 } = useSelector(state => state.userManagement.resetPassword);
  const dispatch = useDispatch();
  const history = useHistory();

  //const verificationStatus = resetPassword ? resetPassword.status : "request_verification_code";
  //const resetStatus = resetPassword1 ? resetPassword1.status : "";

  useEffect(()=>{
    if(resetPassword){
      if(resetPassword.message){
        toast.success(resetPassword.message);        
      }else if (resetPassword.Error || resetPassword.error) {
        toast.error(resetPassword.Error || resetPassword.error);
      }
    

    if (resetPassword.status) {
      setStatus(resetPassword.status);
    }
  }
  }, [resetPassword]);

  useEffect(()=>{
    if(resetPassword1){
      if(resetPassword1.message){
        toast.success(resetPassword1.message);        
      }else if (resetPassword1.Error || resetPassword1.error) {
        toast.error(resetPassword1.Error || resetPassword1.error);
      }
    

    if (resetPassword1.status) {
      setStatus(resetPassword1.status);
    }
  }
  }, [resetPassword1]);

  console.log("status:",resetPassword)
  console.log(error)

  const formatUserName = (userName)=>{
    if (isFinite(userName) && userName.length === 10) {
      return  COUNTRY_CODE + userName;
    }
    return userName;
  }

  const handleSubmit = (data, action) => {
    if (action == "request_verification_code") {
      const req = {
        "action": "reset_password",
        "username": formatUserName(data.UserName)
      }
      setUserName(data.UserName);
      dispatch(registerWithOtpAction(req));
    } else if (action == "verify_verification_code") {
      const req = {
        "action": "reset_password_verify",
        "username": formatUserName(userName),
        "verificationCode": data.verificationCode
      }
      setVerificationCode(data.verificationCode);
      dispatch(registerWithOtpAction(req));
    } else if (action == "reset_password") {
      const req = {
        "action": "reset_password_verify",
        "username": formatUserName(userName),
        "verificationCode": verificationCode,
        "password": data.password
      }
     // setVerificationCode(data.verificationCode);
      dispatch(resetPasswordAction(req));
      toast.success("Successfully updated please login");
      history.push('/jobseekers/login')
    }

  }
  return (
    <MainContainer>
      <Paper elevation={10} className="reset-password-container">
        {status === "request_verification_code"?
          <Forgot onSubmit={handleSubmit}></Forgot>
          : ""
        }
        {status === "verification_code_sent" ?
          <Verify onSubmit={handleSubmit} userName={userName}></Verify>
          : ""
        }
        {status === "reset_password_verify"  ?
          <SetPassword onSubmit={handleSubmit}></SetPassword>
          : ""
        }
        {status === "reset-success" ?
          <Success></Success> : ""
        }
      </Paper>
    </MainContainer>

  );
};

export default ResetPassword;
