import React,{Component} from "react";
import { MDBRow, MDBInput, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBIcon, MDBView, MDBBtn,MDBContainer,MDBAutocomplete,MDBSelect } from "mdbreact";
import FilterResults from 'react-filter-search';
import TopNavigation from 'components/pages/recruiter/header/topNavigation';
import SideNavigation from 'components/pages/recruiter/header/sideNavigation';
import Footer from 'components/pages/recruiter/header/Footer';
import StorageManager from "utils/StorageManager";
import axios from 'axios';
import { toast } from "react-toastify";

class CandidateSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      value: '',
      nid:'',
      candidates:[]
    };
  }
  componentWillMount() {
       const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+token,
        'X-CSRF-Token':csrf
      }
      axios.get('https://live-corp2corp.pantheonsite.io/services/recruiter/'+uid+'/user-list',{
        headers: headers
      })
      .then(res => {
        const candidates = res.data;
        this.setState({ candidates });
      })
    fetch('https://live-corp2corp.pantheonsite.io/api/search-result')
      .then(response => response.json())
      .then(json => this.setState({ data: json }));
  }
  handleChange = event => {
    const { value } = event.target;
    this.setState({ value });
  };
  
   getValueCandidates = (candidate) => {
    this.setState({ candidate });
    console.log(candidate);
  };

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
    console.log(this.actionInput.value);
    console.log(this.state.candidate);
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+token,
        'X-CSRF-Token':csrf
      }
        
        
  
    const nid = this.actionInput.value;
    const elements = this.state.candidate;
    const items = [];

    for (const [index, value] of elements.entries()) {
      items.push(<li key={index}>{value}</li>)
      console.log(value);
      const data = {"flag_id":"apply_job" ,"entity_type":"node", "entity_id":nid,"uid":value};
        axios.post(`https://live-corp2corp.pantheonsite.io/api/flag?_format=json`, data,{
            headers: headers
          })
          .then(res => {
            
            console.log(res.data);
            this.setState({
              candidate: ''
        
            })
            toast.success("Successfully Submitted !", {
              position: toast.POSITION.TOP_RIGHT
            })
            return <Redirect to='/my-candidatesList'/>;
          })
  

    }
   
  }
    render() {
        const { data, value } = this.state;
        return (
              <div className="flexible-content">
      
    <TopNavigation />
    <SideNavigation />
    <main id="content" className="p-5">
    <MDBContainer>
            <MDBCard className="my-5 px-5 pb-5">
       
            <MDBCardBody>
                <h2 className="h1-responsive font-weight-bold text-center my-5">
                Job Search
                </h2>
               
                <MDBRow>
                     <MDBCol md="12">
        <div className="active-pink-3 active-pink-4 mb-4">
            <input className="form-control" type="text" placeholder="Search" aria-label="Search" value={value} onChange={this.handleChange} />
        </div>
         
       </MDBCol>
       
               
               
                </MDBRow>
                <hr className="my-5" />
    <FilterResults
          value={value}
          data={data}
          renderResults={results => (
            <div>
              {results.map(el => (
                  <div>
                   <MDBRow>
              
                <MDBCol lg="7" xl="8">
                    <h3 className="font-weight-bold mb-3 p-0">
         
                    <strong>{el.title}</strong>
                    </h3>
                     <h6 className="font-weight-bold mb-3">Location:{el.location}</h6>
          <h6 className="font-weight-bold mb-3">Experiance: {el.experiance}</h6>
             <h6 className="font-weight-bold mb-3">Skills: {el.skills}</h6>
                    <div className="dark-grey-text">
                    <p dangerouslySetInnerHTML={{ __html: el.body }}></p>
                  
                    </div>
                    <p>
                    by <a href="#!" className="font-weight-bold">{el.company}</a>, {el.date}
                    </p>
                    <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
            <input type="hidden" name="action" value={el.nid} 
                       ref={(input) => { this.actionInput = input }} />
           
           
            <MDBSelect
              getValue={this.getValueCandidates} 
          
          search
          options={this.state.candidates}
          selected="Please Select Candidate"
          
        />
                    <MDBBtn color="primary" size="md" type="submit">

                    Apply Now
                    </MDBBtn>
                    </form>
                </MDBCol>
            </MDBRow>
                <hr className="my-5" />
               </div>
              ))}
            </div>
          )}
        />
                </MDBCardBody>
            </MDBCard>
            </MDBContainer>
          </main>
             <Footer/>
        </div> 
      );
  }
}
export default CandidateSearch;