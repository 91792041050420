import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import assets from "../../../assets";
import { Grid } from "@material-ui/core";
import UrgentRequirment from "./UrgentRequirments";
import FeatureJobs from "./FeaturedJobs";

const useStyles = makeStyles (theme => ({
    featureSectionContainer:{
        padding: '95px 13vw',
        backgroundColor: '#0E2677'
    }
}));

const FeatureSection = () =>{
    const classes=useStyles();
    return(
        <div className={classes.featureSectionContainer}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>    
                <FeatureJobs/>
            </Grid>
            <Grid item  xs={12} sm={6}>    
                <UrgentRequirment/>
            </Grid>
        </Grid>    
        </div>     
    );
}

export default FeatureSection;