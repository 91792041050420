import React from 'react';

import axios from 'axios';
import { MDBTable, MDBTableBody, MDBTableHead, MDBBtn, MDBIcon, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBContainer } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import TopNavigation from 'components/pages/employer/header/topNavigation';
import SideNavigation from 'components/pages/employer/header/sideNavigation';
import Footer from 'components/pages/employer/header/Footer';
import StorageManager from "utils/StorageManager";
import MainContainer from "components/common/MainContainer";
import Link from '@material-ui/core/Link';
import { Paper, Grid } from '@material-ui/core';
import EmployerContainer from "./employerContainer/EmployerContainer";
import { UserManagementService } from "services/endpoints/usermanagement/UserManagementService";

import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import PersonIcon from '@material-ui/icons/Person';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './MyCandidates.scss';

const apiBaseUrl = "https://live-corp2corp.pantheonsite.io";


export default class PersonList extends React.Component {
  state = {
    items: [],
    candidateDetails: null,
    isCandidateDetailsView: false
  }


  componentDidMount() {
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('job_id');
    const job_id = myParam?"?nid="+myParam:"";
    console.log("job_id",job_id);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + token,
      'X-CSRF-Token': csrf
    }
    axios.get('https://live-corp2corp.pantheonsite.io/api/' + uid + '/my-candidates'+job_id, {
      headers: headers
    })
      .then(res => {
        const items = res.data;
       
        this.setState({ items });
      })
  }
  
  deleteItem = item => { // bound arrow function handler
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + token,
      'X-CSRF-Token': csrf
    }
    this.setState({ item: item });
    const data = { "flag_id": "apply_job", "entity_type": "node", "entity_id": item.nid, "uid": item.uid };
    axios.post(`https://live-corp2corp.pantheonsite.io/api/unflag?_format=json`, data, {
      headers: headers
    })
      .then(res => {

        console.log(res.data);
        window.location.reload();
      })
    console.log(item);
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('job_id');
    console.log("myParam",myParam);
    axios.get('https://live-corp2corp.pantheonsite.io/api/my-candidates', {
      headers: headers
    })
      .then(res => {
        const items = res.data;
        
        this.setState({ items });
      })
  }
  acceptItem = item => { // bound arrow function handler

    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + token,
      'X-CSRF-Token': csrf
    }
    this.setState({ item: item });
    const data = { "flag_id": "short_list", "entity_type": "user", "entity_id": item.uid, "uid": uid };
    axios.post(`https://live-corp2corp.pantheonsite.io/api/flag?_format=json`, data, {
      headers: headers
    })
      .then(res => {

        console.log(res.data);
        window.location.reload();
      })
  }

  handleViewProfile = (event) => {
    let uid = event.currentTarget.getAttribute("data-uid");
    this.setState({
      isCandidateDetailsView: true
    });
    UserManagementService.getCandidateDetails(uid).then((res) => {
      this.setState({
        candidateDetails: res[0]
      });
      console.log(res);
    })
  }
  handleGoBack = (event) => {
    this.setState({
      isCandidateDetailsView: false,
      candidateDetails: null
    });
  }

  render() {
    if (this.state.isCandidateDetailsView) {
      return (
        <EmployerContainer>
          <Grid xs={12}>
            <ArrowBackIcon className="back-to-list-button" onClick={this.handleGoBack} />
          </Grid>
          <Grid xs={12}>
            <Paper elevation={3} className="candidate-details">
              {this.state.candidateDetails ?
                <>
                  <Grid container className="main-details section">
                    <Grid item xs={10}>
                      <div className="field name">{this.state.candidateDetails.name},</div>
                      <div className="field">{this.state.candidateDetails.location}</div>
                      <div className="field job-type">{"Full-time | New-Grad"}</div>
                      <div className="field email">Email Address : {this.state.candidateDetails.email}</div>
                      <div className="field">Phone : {this.state.candidateDetails.mobile}</div>
                    </Grid>
                    <Grid item xs={2}>
                      {this.state.candidateDetails.resume ?
                        <div className="download-link">
                          <Link href={this.state.candidateDetails.cv + this.state.candidateDetails.resume} target="_blank"><GetAppIcon /><span>DOWNLOAD RESUME</span></Link>
                        </div> : ""}
                    </Grid>
                  </Grid>
                  <Grid container className="skills-and-education  section">
                    <Grid item xs={12} lg={6}>
                      <div>Education</div>
                      {this.state.candidateDetails.field_education ? this.state.candidateDetails.field_education.map(s => <span>{s}</span>) : <span>Not Available</span>}
                    </Grid>
                    <Grid item xs={6} lg={6}>
                      <div>Skills</div>
                      {this.state.candidateDetails.key_skills ? this.state.candidateDetails.key_skills.split(',').map(s => <span>{s}</span>) : <span>Not Available</span>}
                    </Grid>
                  </Grid>
                  <Grid container className="question-and-answers section">
                    <Grid xs={12}>
                      <div className="qa-item">
                        <label className="question">Are you citizen of the United States</label>
                        <label className="answer">{"Yes"}</label>
                      </div>
                      <div className="qa-item">
                        <label className="question">Key Skills</label>
                        <label className="answer">{this.state.candidateDetails.key_skills}</label>
                      </div>
                      <div className="qa-item">
                        <label className="question">Education Qualifications</label>
                        <label className="answer">{this.state.candidateDetails.field_education.join(",")}</label>
                      </div>
                      <div className="qa-item">
                        <label className="question">Current Salary</label>
                        <label className="answer">{this.state.candidateDetails.salary}</label>
                      </div>
                      <div className="qa-item">
                        <label className="question">Experience:</label>
                        <label className="answer">{this.state.candidateDetails.field_experience && this.state.candidateDetails.field_experience.map(ex => <div>{ex}</div>)}</label>
                      </div>
                      <div className="qa-item">
                        <label className="question">Education:</label>
                        <label className="answer">{this.state.candidateDetails.field_education.join(",")}</label>
                      </div>
                      <div className="qa-item">
                        <label className="question">Location:</label>
                        <label className="answer">{this.state.candidateDetails.location}</label>
                      </div>
                      <div className="qa-item">
                        <label className="question">Industry:</label>
                        <label className="answer">{this.state.candidateDetails.field_prefer_industry}</label>
                      </div>
                    </Grid>
                  </Grid>
                </>
                : "Loading...."}

            </Paper>
          </Grid>
        </EmployerContainer>
      )
    } else {
      return (
        <EmployerContainer heading="Applied Jobs">
          <div className="flexible-content">
            {this.state.items.map(item => {
              return <Paper elevation={3} className="job-tile">
                <Grid container>
                  <Grid item xs={12} md={7} lg={9}>
                    <div className="job-id">{item.name}</div>
                    <div className="job-title">{item.job_title}</div>
                    <div className="job-skills">
                      {item.field_key_skills ? item.field_key_skills.split(',').map(s => <span>{s}</span>) : <span>No Skills</span>}
                    </div>
                    <div className="job-location"><LocationOnIcon />{item.location}</div>
                    <div className="job-created-dt"><WatchLaterIcon />{item.created}</div>
                  </Grid>
                  <Grid item xs={12} md={5} lg={3} className="action-grid">
                    <div className="candidate-actions">
                      <Tooltip title="View Profile"><Link onClick={this.handleViewProfile} data-uid={item.uid}><VisibilityIcon /></Link></Tooltip>
                      <Tooltip title="Download"><Link href={apiBaseUrl+item.field_resume} target="_blank"><GetAppIcon /></Link></Tooltip>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            })
            }
          </div>
        </EmployerContainer>
      )
    }
  }
}