import StorageManager from "./StorageManager";
import jwtDecode from "jwt-decode";
import lodash from "lodash";
import { UserManagementService } from "services/endpoints/usermanagement/UserManagementService";
import axios from 'axios';
export default class UserManager {
  static isLoggedIn = () => {
    return StorageManager.instance.getAccessToken();
  };

  static logout = () => {
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const logout = StorageManager.instance.getLogoutToken();
    console.log(logout);
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+token,
        'X-CSRF-Token':csrf
      }
      axios.post('https://live-corp2corp.pantheonsite.io/user/logout?_format=json&token='+logout,{},{
        headers: headers,
        withCredentials:true
      })
      .then(res => {
        
      })

      StorageManager.instance.setAccessToken("");
      StorageManager.instance.setUid("");
      StorageManager.instance.setXCSRFToken("");
      localStorage.setItem("google_login","");
      window.location.replace("/");
    
  };

  static logout1 = () => {
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const logout = StorageManager.instance.getLogoutToken();
    console.log(logout);
    const headers = { 
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+token,
        'X-CSRF-Token':csrf
      }
      axios.post('https://live-corp2corp.pantheonsite.io/user/logout?_format=json&token='+logout,{},{
        headers: headers,
        withCredentials: true
      })
      .then(res => {
        const candidates = res.data;
    
      })
    StorageManager.instance.setAccessToken("");
    StorageManager.instance.setUid("");
    StorageManager.instance.setXCSRFToken("");
   

    //window.location.replace("/");
  };

  static decode = () => {
    return jwtDecode(StorageManager.instance.getAccessToken());
  };

  static getRoles = () => {
    console.log("Roles", lodash.get(UserManager.decode(), "roles", []));
    return lodash.get(UserManager.decode(), "roles", []);
  };

  static getUid = () => {
    return lodash.get(UserManager.decode(), "uid", "");
  };

  static isCandidate = () => {
    return lodash.includes(UserManager.getRoles(), "candidate");
  };

  static isRecruiter = () => {
    return lodash.includes(UserManager.getRoles(), "recruiter");
  };

  static isEmployer = () => {
    return lodash.includes(UserManager.getRoles(), "employer");
  };
  static isPro = () => {
    return lodash.includes(UserManager.getRoles(), "pro");
  };
}
