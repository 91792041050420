import React, { Component } from 'react';
import qs from 'qs'
import { LinkedIn } from 'react-linkedin-login-oauth2';
import StorageManager from "utils/StorageManager";
import axios from 'axios';
import UserManager from "utils/UserManager";
import { MDBSpinner } from 'mdbreact';
import { MDBIcon, MDBBtn } from "mdbreact";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";

import { withRouter } from "react-router-dom";
class LinkedInPage extends React.Component {
  state = {
    code: '',
    errorMessage: '',
    loader:false,
  };


  handleSuccess = (data) => {
    console.log(data);
    this.setState({loader:true});
    const headers = {
      'Content-Type': 'application/json',
      //"X-CSRF-Token": localStorage.getItem("anonymous_toke")
    }
    
    const data1 = {
      "code": data.code,
     withCredentials: true,

    };
    axios.post(`https://live-corp2corp.pantheonsite.io/services/linkedin`, data1,{
      headers: headers,
     withCredentials: true
    })
    .then(res => {
      console.log("res",res.data.access_token);
      UserManager.logout1();
      const headers = {
        'Content-Type': 'application/json',
        //"X-CSRF-Token": localStorage.getItem("anonymous_toke")
      }
      
      const data1 = {
        "access_token": res.data.access_token,
        withCredentials: true,
  
      };
      axios.post(`https://live-corp2corp.pantheonsite.io/user/login/linkedin?_format=json`, data1,{
        headers: headers,
       withCredentials: true
      })
  
      .then(res => {
        if(res.data.status === 1){
          //localStorage.setItem("google_login","GoogleLogin");
          localStorage.setItem("temp_xcsrf_token",res.data.csrf_token);
          //localStorage.setItem("telogout_token",res.data.logout_token);
          localStorage.setItem("temp_uid",res.data.current_user.uid);
          StorageManager.instance.setBasicToken('aGFuaXNoQHdpbm5vcHJvLmNvbTox');
          StorageManager.instance.setUid(res.data.current_user.uid);
          StorageManager.instance.setXCSRFToken(res.data.csrf_token);
          StorageManager.instance.setLogoutToken(res.data.logout_token);
          StorageManager.instance.setAccessToken(res.data.access_token);
          window.location.href="/saved-jobs";
        }else{
          toast.warn("Please register to continue");
          //const history = useHistory();
          //history.push("/jobseekers/register");
          this.props.history.push("/jobseekers/register");
        }
       return;
        //localStorage.setItem("basic_token","aGFuaXNoQHdpbm5vcHJvLmNvbTox");
        const headers_logout = {
          'Content-Type': 'application/json',
          //'Authorization': 'bearer '+token,
          'X-CSRF-Token':res.data.csrf_token
        }
        axios.get('https://live-corp2corp.pantheonsite.io/jwt/token',{withCredentials: true},{
          headers: headers_logout
        })
        .then(res => {
          console.log("sdds",res);
          StorageManager.instance.setAccessToken(res.data.token);
          const headers1 = {
            'Content-Type': 'application/json',
            "X-CSRF-Token": res.data.csrf_token
          }
          
          const data1 = {
          };
          console.log(data1);
          axios.post(`https://live-corp2corp.pantheonsite.io/jwt/token?_format=json`,data1,{
           headers: headers1,
           withCredentials: true
          })
          .then(response => {
            StorageManager.instance.setAccessToken(response.data.token);
            console.log("response1",response);
            window.location.href="/saved-jobs";
            //history.push("/employer-dashboard");
          });
          
        
         //;
          //window.location.href="/onboarding/1";
          const candidates = res.data;
      
        })
        //setOpenRoleModal(true);
      
        //console.log(res.data);
       // window.location.reload();
      })
     
    })
    return;
    
    
    this.setState({
      code: data.code,
      errorMessage: '',
    });
  }

  handleFailure = (error) => {
    this.setState({
      code: '',
      errorMessage: error.errorMessage,
    });
  }
  
  render() {
    const { code, errorMessage } = this.state;
    return (
      <Grid container style={{textAlign:"center"}}>
      <Grid item xs={12}>
         <div>
           {!this.state.loader ?
        <LinkedIn
          clientId="86y33ojoz4xkii"
          redirectUri={`${window.location.origin}/linkedin`}
          scope="r_emailaddress r_liteprofile"
          state="34232423"
          style={{backgroundColor:"white"}}
          onFailure={this.handleFailure}
          onSuccess={this.handleSuccess}
          supportIE
          redirectPath='/linkedin'
        >
     <span style={{padding: "10px 10px 10px 0px"}}>  <MDBIcon style={{fontSize:"14px"}} fab icon="linkedin-in" className="pr-1" /> Linkedin</span>
         
      
        </LinkedIn>
       :
       <div>
       <MDBSpinner />
       </div>
  }
      </div>
      </Grid></Grid>
       
    );
  }
}

export default withRouter(LinkedInPage);