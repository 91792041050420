import { styled, Typography } from "@material-ui/core";

export const JobListHolder = styled("div")(({ theme }) => {
  return {
    margin: "2vh 6vw",
    minHeight: "100%",
    [theme.breakpoints.down("xs")]: {
      margin: "0px"
    }
  };
});

export const Logo = styled("img")({
  width: "85px",
  height: "auto"
});

export const JobSectionHeading = styled(Typography)({
  marginTop: "4vw"
});

export const JobListContainer = styled("div")({
  //height: "60vh",
  overflow: "overlay"
});
