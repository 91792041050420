import React from 'react';
import MainContainer from "../../../common/MainContainer/MainContainer";
import './landing001.scss';
import { Grid } from '@material-ui/core';
import bannerImg from "/assets/images/landing001/banner.png";
import discoverabilityImg from "/assets/images/landing001/discoverability.png";
import integratedAIImg from "/assets/images/landing001/integratedAI.png";
import verifiedImg from "/assets/images/landing001/verified.png";
import profileRecencyImg from "/assets/images/landing001/profileRecency.png";
import googleLogo from "/assets/images/landing001/google.png";
import ibmLogo from "/assets/images/landing001/ibm.png";
import microsoftLogo from "/assets/images/landing001/microsoft.png";
import siemensLogo from "/assets/images/landing001/siemens.png";
import bmwLogo from "/assets/images/landing001/bmw.png";
import eyLogo from "/assets/images/landing001/ey.png";



export default function Landing001() {
    return (
        <MainContainer>
            <div className="landing-001">
                <div className="first">
                    <div className="image">
                        <img src={bannerImg} />
                    </div>
                    <div className="main-content">
                        <p className="main-heading">
                            A True Recruiter Friendly Job Board.
                        </p>
                        <p className="description">
                            Especial tailor made platform best for recruiters making every
                            hire great with improved productivity at every crucial factor like
                            talent hire, job postings, organizing profiles, intuitive control
                            and top of all cutting down the cost per candidate.
                        </p>
                    </div>
                </div>
                <div className="second">
                    <Grid container>
                        <Grid item xs={6} lg={3}>
                            <h1>150+</h1>
                            <p>JOB APPLICATIONS</p>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <h1>140M+</h1>
                            <p>RESUME & SOCIAL PROFILE</p>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <h1>70+</h1>
                            <p>RESUME & SOCIAL PROFILE</p>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <h1>30M+</h1>
                            <p>JOB POSTED ANNUALLY</p>
                        </Grid>
                    </Grid>
                </div>
                <div className="third">
                    <Grid container>
                        <Grid item xs={12}>
                            <p className="heading">
                                How we are making recruiters job more efficient
                    </p>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} lg={6}>
                                <Grid container>
                                    <Grid item xs={12} className="sub-heading">
                                        <div className="triangle">
                                            <img className="image" src={profileRecencyImg} />
                                        </div>
                                        <h3>Profile Recency:</h3>
                                    </Grid>
                                    <Grid item xs={12} className="content">
                                        Time spent on any outdated profile is a waste of energies
                                        and costs which we don’t want our recruiters to bother on.
                                        Which is why we are much focused on realtime sourcing
                                        based on the need and demand of the market and keeping
                                        open only the fresh profiles in our database.
                        </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Grid container>
                                    <Grid item xs={12} className="sub-heading">
                                        <div className="triangle">
                                            <img className="image" src={verifiedImg} />
                                        </div>
                                        <h3>100% Verified Profiles:</h3>
                                    </Grid>
                                    <Grid item xs={12} className="content">
                                        The most annoying case of recruiters is putting all their
                                        efforts on Junk/Spam Profiles resulting in no positive
                                        result. Our engineering is well designed to find and
                                        delete any junk/spam profile in our job board.
                        </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} lg={6}>
                                <Grid container>
                                    <Grid item xs={12} className="sub-heading">
                                        <div className="triangle">
                                            <img className="image" src={discoverabilityImg} />
                                        </div>
                                        <h3>Smart Jobs Discoverability:</h3>
                                    </Grid>
                                    <Grid item xs={12} className="content">
                                        Multiple searches for relevant profiles is literally a
                                        waste of time. Our smart algorithms keep suggesting you
                                        the profiles based on your relevance score and keep
                                        suggesting your desired profiles
                        </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Grid container>
                                    <Grid item xs={12} className="sub-heading">
                                        <div className="triangle">
                                            <img className="image" src={integratedAIImg} />
                                        </div>
                                        <h3>Integrated AI Algorithms:</h3>
                                    </Grid>
                                    <Grid item xs={12} className="content">
                                        Whilst we are working on every aspect of making the
                                        recruiter task simplified, even our advanced AI Algorithms
                                        are simplifying every step since from sourcing the profile
                                        to recruiter shortlisting making a quick connection to the
                                        need.
                        </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className="fourth">
                    <h1 className="fourth-heading">CUSTOMERS</h1>
                    <p className="fourth-subheading">Trusted by the brands you trust</p>

                    <div className="fourth-body">
                        <div className="logo-container">
                            <img src={googleLogo} />
                        </div>
                        <div className="logo-container">
                            <img src={ibmLogo} />
                        </div>
                        <div className="logo-container">
                            <img src={siemensLogo} />
                        </div>
                        <div className="logo-container">
                            <img src={microsoftLogo} />
                        </div>
                        <div className="logo-container">
                            <img src={bmwLogo} />
                        </div>
                        <div className="logo-container">
                            <img src={eyLogo} />
                        </div>
                    </div>
                </div>
                <div className="fifth">
                    <p className="fifth-heading">
                        Sign Up for the Latest Industry News, Tips and Advice
                </p>
                    <div className="subscribe">
                        <input type="text" placeholder="Enter your email address"></input>
                        <button>SUBSCRIBE</button>
                    </div>
                </div>
            </div>
        </MainContainer>
    );
}