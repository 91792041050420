import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import { Grid, Hidden, Paper, Container, Typography, TextField, FormControl, InputAdornment, Icon, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import badge01 from "../../../../assets/images/badge01.svg";
import { Image, Root, VerticalCenter, ImageGrid, LoginCard } from "../../login/LoginPage.styled";
import MyAppBar from "../../../common/myappbar/MyAppBar";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { TextInput } from "../../../common/FormInputs";
import LockIcon from "@material-ui/icons/Lock";
import MainContainer from "../../../common/MainContainer";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ImageIcon, StyledLink } from "components/common/Misc";
import { authenticateAction, registerAction, generateOtpAction, verifyOtpAction,socialLoginAction } from "services/endpoints/usermanagement/userManagementActions";
import RegisterForm from "./RegisterForm";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import OtpVerification from "./otpverification/OtpVerification";
import SocialLogin from "components/common/SocialLoginEmployer/SocialLogin";
import UserManager from "utils/UserManager";
import StorageManager from "utils/StorageManager";
import axios from 'axios';
import AlgoliaPlaces from 'algolia-places-react';
import {Helmet} from "react-helmet";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';

import './custom.css';


import { MDBBtn,MDBBadge, MDBCard,MDBModalFooter, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBRow, MDBCol, MDBIcon } from
'mdbreact';


class FormsPage extends React.Component {
  state = {
    fname: {
      value: "",
      valid: false
    },
    lname: {
      value: "",
      valid: false
    },
    address: {
      value: "",
      valid: false
    },
    email: {
      value: "",
      valid: false
    },
    mobile: {
      value: "",
      valid: false
    },
    location: {
      value: "",
      valid: false
    },
    company: {
      value: "",
      valid: false
    },
    password: {
      value: "",
      valid: false
    }
  };
  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  
    const fname = event.target.fname.value;
    const lname = event.target.lname.value;
    const email = event.target.email.value;
    const company = event.target.company.value;
    const password = event.target.password.value;
    const locationRow = this.state.location;
   // const location = locationRow.suggestion.name+','+locationRow.suggestion.administrative+','+locationRow.suggestion.country;
   const address = event.target.address.value;
    const phone = this.state.phone;
    console.log(fname+lname+email+company+location+phone+"passowrd"+password);
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const logout = StorageManager.instance.getLogoutToken();
    //const dispatch = useDispatch();


    UserManager.logout1();
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token':csrf
    }
    
    const data = {
      "fname": fname,
      "lname": lname,
      "email": email,
      "address": address,
      "company": company,
      "phone": phone,
      "password": password,
      "role":"employer"

    };
    
    console.log(data);
 
    axios.post(`https://live-corp2corp.pantheonsite.io/services/employer/registration`, data,{
      headers: headers
    })
    .then(response => { 
      console.log('succ',response)
      if(response.data.status === 0){
        toast.error("Email id already in use!", {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      toast.success("Successfully Submitted !", {
        position: toast.POSITION.TOP_RIGHT
      });
      
      const data = {
        "name": email,
        "pass":password,
        withCredentials: true,
  
      };
      axios.post(`https://live-corp2corp.pantheonsite.io/user/login?_format=json`, data,{
        headers: headers,
       withCredentials: true
      })
      .then(res => {
        console.log("dsaasd",res);
     
        // StorageManager.instance.setAccessToken(result.access_token);
        //StorageManager.instance.setBasicToken('aGFuaXNoQHdpbm5vcHJvLmNvbTox');
        StorageManager.instance.setBasicToken(btoa(`${email}:${password}`));
        StorageManager.instance.setUid(res.data.current_user.uid);
        StorageManager.instance.setXCSRFToken(res.data.csrf_token);
        StorageManager.instance.setLogoutToken(res.data.logout_token);
        StorageManager.instance.setAccessToken(res.data.access_token);
    //Not in the array
    console.log("Success1employer",res.data);

          //Not in the array
          console.log("Success1employer",res.data.current_user.roles);
          window.location.replace("/employer-dashboard");
        //console.log(res.data);
       // window.location.reload();
      })
      {/*const data = {"flag_id":"recruiter" ,"entity_type":"user", "entity_id":response.data.uid,"uid":uid};
      axios.post(`https://live-corp2corp.pantheonsite.io/api/flag?_format=json`, data,{
          headers: headers
      })
      .then(res => {
          console.log(res.data);
          return <Redirect to='/my-candidatesList'/>;
         
        })
        */}
       // const username = event.target.email.value;
        //const password = event.target.password.value
        //const loginAction = (username, password) => {
         
       // };
    })
      
    
    
  }
  changeHandler = event => {
    this.setState({ [event.target.name]: { value: event.target.value, valid: !!event.target.value } });
  };

  render() {
    return (
      <MainContainer>
      <Helmet>
  <meta charSet="utf-8" />
  <title>Employer Register - Corp2Corp Jobs</title>
  <meta name="keywords" content="job, Register,login,job search, jobs, work, employment, career, resumes, job vacancies,corp2corp,corp2corp.jobs,USA Jobs"></meta>
  <link rel="canonical" href="http://corp2corp.jobs/register" />
  <meta name="description" content="Corp2Corp is job portal to search and apply for jobs in US. Companies can find and hire candidates with work permits in United States."></meta>
     </Helmet>
     <Container className="con-register">
       
        <MDBCard>
         
          <MDBCardImage
            className='blue-gradient white-text d-flex justify-content-center  flex-column p-4 rounded'
            tag='div'
          > 
            
            <Typography align="center" variant="h6" style={{ fontFamily: "'Poppins', sans-serif" }}>
            FREE 60 DAYS TRIAL
                </Typography>
                <Typography align="center" variant="h7" display="block" style={{ fontFamily: "'Poppins', sans-serif", paddingTop: "10px" }}>
                Unlimited job postings
                </Typography>
                <Typography align="center" variant="h7" display="block" style={{ fontFamily: "'Poppins', sans-serif", paddingTop: "10px" }}>
                Unlimited resume searches
                </Typography>
          <img src={badge01} style={{ margin:"0", padding:"0",fontStyle:"italic",position: "absolute",top:"-6px",right: "-5px",width:"74px" }}/>
                 
          </MDBCardImage>
          <MDBCardBody cascade className='employer-register'>
      <div>
      <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>
            <MDBCol md="6" className="mb-3">
              <label
                htmlFor="defaultFormRegisterNameEx"
                className="grey-text"
              >
                First name
              </label>
              <input
                value={this.state.fname.value}
                className={this.state.fname.valid ? "form-control" : "form-control"}
                name="fname"
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterNameEx"
                placeholder="First name"
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
            <MDBCol md="6" className="mb-3">
              <label
                htmlFor="defaultFormRegisterEmailEx2"
                className="grey-text"
              >
                Last name
              </label>
              <input
                value={this.state.lname.value}
                className={this.state.lname.valid ? "form-control" : "form-control"}
                name="lname"
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterEmailEx2"
                placeholder="Last name"
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
            </MDBRow>
            <MDBRow>
            <MDBCol md="6" className="mb-3">
              <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="grey-text"
              >
               Corporate Email
              </label>
              <input
                value={this.state.email.value}
                className={this.state.email.valid ? "form-control" : "form-control"}
                onChange={this.changeHandler}
                type="email"
                id="defaultFormRegisterConfirmEx3"
                name="email"
                required
                placeholder="Your Email address"
              />
              <small id="emailHelp" className="form-text text-muted">
                We'll never share your email with anyone else.
              </small>
            </MDBCol>
            <MDBCol md="6" className="mb-3">
              <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                Company Name
              </label>
              <input
                value={this.state.company.value}
                className={this.state.company.valid ? "form-control" : "form-control"}
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterPasswordEx4"
                name="company"
                placeholder="Company Name"
                required
              />
              <div className="invalid-feedback">
                Please provide a valid.
              </div>
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
      
          </MDBRow>
          <MDBRow>
           {/* <MDBCol md="12" className="mb-3">
              <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                Location
              </label>
              <AlgoliaPlaces
                              placeholder='Location'
                              variant="outlined"
                             
                      className={this.state.location.valid ? "form-control is-valid" : "form-control is-invalid"}
                              fullWidth
                              icon="user"
                              className="form-control"
                              required
                              options={{
                                appId: 'plE79E3YU95N',
                                apiKey: 'ab6d2643563c402f95aaf5dca19afa95',
                                language: 'en',
                                countries: ['us'],
                                type: 'city',
                                // Other options from https://community.algolia.com/places/documentation.html#options
                              }}
                              onChange={ location => this.setState({location}) }
                            
                            
                        
                              onSuggestions={({ rawAnswer, query, suggestions }) => 
                                console.log('Fired when dropdown receives suggestions. You will receive the array of suggestions that are displayed.')}
                        
                              onCursorChanged={({ rawAnswer, query, suggestion, suggestonIndex }) => 
                                console.log('Fired when arrows keys are used to navigate suggestions.')}
                        
                              onClear={() => 
                                console.log('Fired when the input is cleared.')}
                        
                              onLimit={({ message }) => 
                                console.log('Fired when you reached your current rate limit.')}
                        
                              onError={({ message }) => 
                                console.log('Fired when we could not make the request to Algolia Places servers for any reason but reaching your rate limit.')}
                        />
                 <div className="invalid-feedback">
                   Please provide a valid location.
                 </div>
                 <div className="valid-feedback">Looks good!</div>
                
                              </MDBCol>*/}
                               <MDBCol md="12" className="mb-3">
            <label htmlFor="exampleFormControlTextarea1">
            Address
            </label>
            <textarea
            className="form-control"
            value={this.state.address.value}
           className={this.state.address.valid ? "form-control" : "form-control"}
           onChange={this.changeHandler}
            id="exampleFormControlTextarea1"
            name="address"
            rows="5"
            placeholder="Please enter your address"
            required
            />
                 <div className="invalid-feedback">
                Please provide address
              </div>
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
            </MDBRow>
            <MDBRow>
            <MDBCol md="6" className="mb-3">
              <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="grey-text"
              >
                Phone Number
              </label>
              <PhoneInput
                  country={'us'}
                  required
                  value={this.state.phone}
                  onChange={phone => this.setState({ phone })}
                  
               />
              <small id="mobileHelp" className="form-text text-muted">
                We'll never share your Phone number with anyone else.
              </small>
            </MDBCol>
   
               <MDBCol md="6" className="mb-3">
              <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                Password
              </label>
              <input
                value={this.state.password.value}
                className={this.state.password.valid ? "form-control is-valid" : "form-control is-invalid"}
                onChange={this.changeHandler}
                type="password"
                id="defaultFormRegisterPasswordEx4"
                className="form-control"
                name="password"
                placeholder="Password"
                required
              />
              <div className="invalid-feedback">
                Please provide a valid Pawword.
              </div>
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
          </MDBRow>
          
          <MDBCol md="12" className="mb-3">
            <div className="custom-control custom-checkbox pl-3">
              <input
                className="custom-control-input"
                type="checkbox"
                value=""
                id="invalidCheck"
                required
              />
              <label className="custom-control-label" htmlFor="invalidCheck">
                Agree to terms and conditions
              </label>
              <div className="invalid-feedback">
                You must agree before submitting.
              </div>
            </div>
          </MDBCol>
          <div className="white-text d-flex justify-content-center align-items-center flex-column">
          <MDBBtn color="cyan" type="submit" style={{borderRadius:"19px"}}>
            Try Free
          </MDBBtn>
          </div>
          <MDBModalFooter className="mx-5 pt-3 mb-1">
              <div className="font-small grey-text d-flex justify-content-end">
               
              <Box align="center" style={{ marginTop: "1em", width: "100%" }} maxWidth="sm">
                Already member?
                    
                    <StyledLink to="/employer/login">
                      <Button variant="outlined" fullWidth display="block" style={{ marginTop: "1em", marginBottom: "1em", width: "80%" }}>
                        <Typography variant="caption" style={{ fontFamily: "'Poppins', sans-serif", padding: "6px",borderRadius:"19px" }} display="inline">
                          Login
                        </Typography>
                      </Button>
                    </StyledLink>
                  </Box>
              </div>
            </MDBModalFooter>
          
        </form>
      </div>
      </MDBCardBody></MDBCard>
      </Container>
      </MainContainer>
    );
  }
}

export default FormsPage;
