import React, { useState, useEffect } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import assets from "../../../../assets";
import CategoryCarousel from "./CategoryCarousel";
import JobList from "../JobList";
import lodash from "lodash";
import { getCategoriesAction } from "services/endpoints/common/commonActions";
import { StyledLink } from "components/common/Misc";
import CategoryBrands from "./CategoryBrands";

import Ecommerce from "./ecommerce";

const useStyles = makeStyles(theme => ({
  header: {
    color: "#707070",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "30px",
    paddingTop: "80px",
    textAlign: "center",
    paddingBottom: "45px",
    marginTop: "0px",
    fontWeight: "500"
  },
  categorySectionContainer: {
    backgroundColor: "#EAF6FA"
  },
  viewAllButton: {
    backgroundColor: "#13C1D6",
    color: "white",
    paddingLeft: "50px",
    paddingRight: "50px",
    borderRadius: "25px",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "bold"
  },
  viewAllButtonContainer: {
    textAlign: "center",
    padding: "62px 0px 75px 0px"
  }
}));

const CategorySection = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, data, error } = useSelector(state => state.common.categories);
  useEffect(() => {
    dispatch(getCategoriesAction());
  }, []);
  return (
    <div className={classes.categorySectionContainer}>
   {/* <h2 className={classes.header}>Top Employers</h2>
    <CategoryBrands />
      <JobList />
      <div className={classes.viewAllButtonContainer}>
        <StyledLink to="/jobs">
          <Button variant="contained" className={classes.viewAllButton}>
            VIEW ALL
          </Button>
        </StyledLink>
        
      </div>*/}
    </div>
  );
};

export default CategorySection;
