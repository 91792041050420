
import React, { useState, useEffect } from "react";
import { Box,Button, CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { LinkedIn } from 'react-linkedin-login-oauth2';
import { useHistory } from "react-router-dom";
import UserManager from "utils/UserManager";
import { LOGIN_LINKEDIN_CLIENT_ID } from "constants/generalConstants";
import { loginWithSocialAction,linkedInTokenAction } from "services/endpoints/usermanagement/userManagementActions";
import { handleSocialLoginSuccess,handleSocialLoginFailure } from "./socialLoginHelper"
import {  MDBIcon } from "mdbreact";

const LinkedInLogin = ({ onLoginSuccess, onLoginFailed, isRegister }) => {
  const [isLoading, setLoading] = useState(false);
  const { data: loginResponse, error } = useSelector(
    state => state.userManagement.loginWithLinkedIn
  );
  const { data: linkedInToken, error:tokenError } = useSelector(
    state => state.userManagement.linkedInToken
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (loginResponse) {
      const redirect = handleSocialLoginSuccess(loginResponse, isRegister, onLoginSuccess,'linkedin',dispatch);
      if (redirect) {
        history.push(redirect);
      }
      setLoading(false);
    }
    if (error) {
      handleSocialLoginFailure("linkedIn");
    }
  }, [loginResponse, error]);

  useEffect(() => {
    if (linkedInToken) {
      UserManager.logout1();
      dispatch(loginWithSocialAction(linkedInToken.access_token,'linkedin'));
    }
  }, [linkedInToken]);
  
  const onSuccess = (response) => {
    setLoading(true);
    dispatch(linkedInTokenAction({ code: response.code}));
  }

  const onFailure = (error) => {
    handleSocialLoginFailure("linkedIn");
    setLoading(false);
  }

  return (
    <Box className="social-login-item">
      {!isLoading ?
        <LinkedIn
          clientId={LOGIN_LINKEDIN_CLIENT_ID}
          redirectUri={`${window.location.origin}/linkedin`}
          scope="r_emailaddress r_liteprofile"
          state="34232423"
          onFailure={onFailure}
          onSuccess={onSuccess}
          supportIE
          redirectPath='/linkedin'
        >
          <MDBIcon fab icon="linkedin-in"> LinkedIn</MDBIcon>
    
        </LinkedIn> :
        <CircularProgress />
      }
    </Box>
  );
};

export default LinkedInLogin;
