import React from "react";
import { MDBListGroup, MDBListGroupItem, MDBContainer } from "mdbreact";
import JobListItem from "./JobListItem";
import lodash from "lodash";
import List from "@material-ui/core/List";
import UserManager from "utils/UserManager";
import { JobListContainer } from "../JobListingPage.styled";
const JobList = ({ list, selected, onItemSelect }) => {
  const [counter, setCounter] = React.useState({});
  const isLoggedIn = UserManager.isLoggedIn();
  return (
    <JobListContainer>
      {console.log(setCounter,list[0].counter)}
      {localStorage.setItem("count",list[0].counter)}
      {lodash.isArray(list) &&
        list.map(item => {
          return (
            <JobListItem
              item={item}
              isActive={lodash.isEqual(item, selected)}
              onClick={() => {
                onItemSelect && onItemSelect(item);
              }}
            />
          );
        })}
    </JobListContainer>
  );
};

export default JobList;
