import React, { useState } from "react";
import { makeStyles, Button } from "@material-ui/core";
import assets from "../../../assets";
import { Grid } from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  switchContainer: {
    textAlign: "center",
    padding: "120px 0px 80px 0px"
  },
  roundedBackground: {
    backgroundColor: "#0E2677",
    padding: "11px",
    borderRadius: "47px",
    display: "inline-block"
  },
  roundedButton: {
    fontFamily: "'Poppins'",
    fontSize: "0.95em",
    borderRadius: "20px",
    color: "white",
    padding: "7px 20px",
    textTransform: "none",
    margin: "6px 15px",
    "&:hover": {
      color: "white"
    }
  },
  selected: {
    backgroundColor: "#13C1D6",
    color: "#0E2677"
  }
}));

const SwitchComponent = () => {
  const classes = useStyles();

  const [selected, setSelected] = useState("popular");
  const changeSelection = selection => {
    setSelected(selection);
  };

  return (
    <div className={classes.switchContainer}>
      {/* <Grid container spacing={2}className={classes.roundedBackground}>
                    <Grid item xs={6} className={classes.roundedItem}>
                        <Button className={classes.roundedButton+" "+classes.selected}>Popular Employers</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button className={classes.roundedButton}>Latest Employers</Button>
                    </Grid>
                </Grid> */}
      <div className={classes.roundedBackground}>
        <Button
          onClick={() => {
            setSelected("popular");
          }}
          className={classes.roundedButton + " " + (selected === "popular" ? classes.selected : "")}
        >
          Popular Employers
        </Button>

        <Button
          onClick={() => {
            setSelected("latest");
          }}
          className={classes.roundedButton + " " + (selected === "latest" ? classes.selected : "")}
        >
          Latest Employers
        </Button>
      </div>
    </div>
  );
};

export default SwitchComponent;
