import userManagement from "services/endpoints/usermanagement/userManagemnetReducer";
import common from "services/endpoints/common/commonReducer";
import job from "services/endpoints/jobs/jobReducer";
const serviceReducers = {
  userManagement,
  common,
  job
};

export default serviceReducers;
