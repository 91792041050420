import React, { useContext } from "react";
import { HeaderGrid } from "../Register.styled";
import { Typography, Grid } from "@material-ui/core";
import { OnBoarding } from "../onboarding/useSteps";

const RegisterHeader = ({ title, description }) => {
  const [state] = useContext(OnBoarding);
  return (
    <HeaderGrid item sm={12} md={12} style={{justifyContent:"center"}}>
     {/* <Typography align="center" style={{ paddingTop: "10px" }}>
        Edit
  </Typography>*/}
      <Grid item md={8}>
        <Typography align="center" style={{ fontFamily: "Poppins, sans-serif", fontSize: "2em", fontweight: "700" }}>
          {title}
          <span style={{ fontSize: "0.5em", display: "block", fontweight: "500" }}>{description}</span>
        </Typography>
      </Grid>
      {/*<Typography align="center" style={{ paddingTop: "10px" }}>
        Step <strong>{state.step}</strong> to {state.total}
</Typography>*/}
    </HeaderGrid>
  );
};

export default RegisterHeader;
