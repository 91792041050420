import React,{Component} from "react";
import { MDBRow, MDBInput, MDBCol, MDBCard, MDBCardBody,MDBCardImage,MDBCardTitle, MDBMask, MDBIcon, MDBView, MDBBtn,MDBContainer,MDBAutocomplete,MDBSelect } from "mdbreact";
import FilterResults from 'react-filter-search';
import TopNavigation from 'components/pages/employer/header/topNavigation';
import SideNavigation from 'components/pages/employer/header/sideNavigation';
import Footer from 'components/pages/employer/header/Footer';
import StorageManager from "utils/StorageManager";
import axios from 'axios';
import { toast } from "react-toastify";
import ReactTags from 'react-tag-autocomplete'
import MainContainer from "components/common/MainContainer";
import { Typography, withTheme } from "@material-ui/core";
import Link from '@material-ui/core/Link';
import TextTruncate from 'react-text-truncate';
import AlgoliaPlaces from 'algolia-places-react';
import Select from 'react-select';
import { colourOptions } from './data';
import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  skills:{
    backgroundColor:"white"
  }
});
const options = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10+', label: '10+' },
  { value: '15+', label: '15+' },
  { value: '20+', label: '20+' },

];
const visa = [
  { value: '551', label: 'Cpt/opt' },
  { value: '551', label: 'Cpt/opt' },
  { value: '552', label: 'H1b' },
  { value: '553', label: 'H4 EAD' },
  { value: '554', label: 'L2 EAD' },
  { value: '555', label: 'GC' },
  { value: '556', label: 'USC' },
  { value: '557', label: 'TN' },
  { value: '558', label: 'Other' },

];

class CandidateSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      keyword:'',
      country: [] ,
      async_test: [],
      location:'',
      selectedOption: null,
      visaOption: null,
      value: '',
      nid:'',
      tags: [
      
      ],
      suggestions: colourOptions,
      candidates:[]
    };
   // this.handleCheck=this.handleCheck.bind(this)
  }
  SearchResults = event => {
    event.preventDefault();
    event.target.className += " was-validated";
    const exp = this.state.selectedOption?this.state.selectedOption.value:"";
    const visa = this.state.visaOption?this.state.visaOption.value:"All";
    const keyskills = this.state.async_test;
    const keyword = this.state.keyword ? this.state.keyword.value:"";

    const locationRow = this.state.location;
    console.log("row",locationRow.value);
    const empty = locationRow.value;
    const location1 = empty === ""? "empty" : locationRow;
    //alert(location1);
    const location = location1 ? locationRow.suggestion.name+','+locationRow.suggestion.administrative+','+locationRow.suggestion.country:"";
   //alert(location);
    /*let values =keyskills.map(function(skill){
      return skill.value;
    });*/
    const keyskills2 = this.state.tags;
    //const content = this.state.content;
    let values = keyskills2.map(function(skill){
      return skill.id;
    });
    var newStr = values.toString().replace(/,/g, '&skills%5B%5D=');
    if(newStr){
    fetch('https://live-corp2corp.pantheonsite.io/api/candidate-search?skills%5B%5D='+newStr+'&exp='+exp+'&location='+location+'&combine='+keyword+'&visa='+visa)
      .then(response => response.json())
      .then(json => this.setState({ data: json }));
  
}else{
  fetch('https://live-corp2corp.pantheonsite.io/api/candidate-search?exp='+exp+'&location='+''+'&combine='+keyword+'&visa='+visa)
  .then(response => response.json())
  .then(json => this.setState({ data: json }));
}

}
  async componentWillMount() {
    await axios.get('https://live-corp2corp.pantheonsite.io/api/register/skills').then(response => {  
      console.log(response);  
      this.setState({  
              country: response.data  

      })  
})  
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
  
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+token,
        'X-CSRF-Token':csrf
      }
     await axios.get('https://live-corp2corp.pantheonsite.io/services/recruiter/'+uid+'/user-list',{
        headers: headers
      })
      .then(res => {
        const candidates = res.data;
        this.setState({ candidates });
      })
    await fetch('https://live-corp2corp.pantheonsite.io/api/candidate-search?exp=&location=&combine=&visa=All')
      .then(response => response.json())
      .then(json => this.setState({ data: json }));
  }
  Country() {  
    return (this.state.country.map(data => ({ label: data.label, value: data.value })))  
} 
handleChange2 = selectedOption => {
  console.log("d",selectedOption.value);
  this.setState(
    { selectedOption },
    () => console.log(`Option selected:`, this.state.selectedOption)
  );
};
handleChange3 = visaOption => {
  console.log("d",visaOption.value);
  this.setState(
    { visaOption },
    () => console.log(`Option selected:`, this.state.visaOption)
  );
};
handleDelete (i) {
  const tags = this.state.tags.slice(0)
  tags.splice(i, 1)
  this.setState({ tags })
}

handleAddition (tag) {
  const tags = [].concat(this.state.tags, tag)
  this.setState({ tags })
  console.log("tags",tags);
  let values = tags.map(function(skill){
    return skill.id;
  });
  //const sdata = Array.prototype.map.call(tags, function(item) { return item.id; }).join('","');
console.log("dtata",values);
this.setState({skillValue:values})

}
handleChange1 = async_test => {
  this.setState(
    { async_test },
    () => console.log(`Option selected:`, this.state.async_test)
  );
};
  handleChange = event => {
    const { value } = event.target;
    this.setState({ value });
  };
  changeHandler = event => {
    this.setState({ [event.target.name]: { value: event.target.value, valid: !!event.target.value } });
  };
   getValueCandidates = (candidate) => {
    this.setState({ candidate });
    console.log(candidate);
  };

 
    render() {
      const { classes } = this.props;
        const { data, value,selectedOption,visaOption } = this.state;
        return (
          <MainContainer>
              <div className="flexible-content">
      

    <SideNavigation />
    <main id="content" className="p-1">
    <MDBCard>
                         <MDBCardBody>
 
               
                <MDBRow>
                <MDBCol md="6">
                <label
                htmlFor="defaultFormRegisterkeywordEx"
                className="grey-text"
              >
                Keyword
              </label>
              <input
                value={this.state.keyword.value}
                className={this.state.keyword.valid ? "form-control" : "form-control"}
                name="keyword"
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterKeywordEx"
                placeholder="Keyword"
                required
              />
                 </MDBCol>
       
                 <MDBCol md="4">
                 <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="grey-text"
              >
                Visa Preferance
              </label>
              <Select
        value={visaOption}
        onChange={this.handleChange3}
        options={visa}
      />
                 </MDBCol>
               
                </MDBRow>
                <MDBRow>  
                  <MDBCol md="3">
                  <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="grey-text"
              >
                Experiance
              </label>
              <Select
        value={selectedOption}
        onChange={this.handleChange2}
        options={options}
      />
    </MDBCol>
     <MDBCol md="3">
    
     <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                Key Skills
              </label>
              <ReactTags
        tags={this.state.tags}
        suggestions={this.state.suggestions}
        placeholder="Select Skill"
        className={classes.skills}
        handleDelete={this.handleDelete.bind(this)}
        handleAddition={this.handleAddition.bind(this)} />
    </MDBCol>
 <MDBCol md="3">
 <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                Location
              </label>
              <AlgoliaPlaces
                              placeholder='Location'
                              variant="outlined"
                             
                      className={this.state.location.valid ? "form-control is-valid" : "form-control is-invalid"}
                              fullWidth
                              icon="user"
                              className="form-control"
                              required
                              options={{
                                appId: 'plE79E3YU95N',
                                apiKey: 'ab6d2643563c402f95aaf5dca19afa95',
                                language: 'en',
                                countries: ['us'],
                                type: 'city',
                                // Other options from https://community.algolia.com/places/documentation.html#options
                              }}
                              onChange={ location => this.setState({location}) }
                            
                            
                        
                              onSuggestions={({ rawAnswer, query, suggestions }) => 
                                console.log('Fired when dropdown receives suggestions. You will receive the array of suggestions that are displayed.')}
                        
                              onCursorChanged={({ rawAnswer, query, suggestion, suggestonIndex }) => 
                                console.log('Fired when arrows keys are used to navigate suggestions.')}
                        
                              onClear={() => 
                                console.log('Fired when the input is cleared.')}
                        
                              onLimit={({ message }) => 
                                console.log('Fired when you reached your current rate limit.')}
                        
                              onError={({ message }) => 
                                console.log('Fired when we could not make the request to Algolia Places servers for any reason but reaching your rate limit.')}
                        />
     
    </MDBCol>
    <MDBCol md="3">
    <MDBBtn color="cyan" rounded size="sm" type="submit" className="mr-auto mt-4"  onClick={this.SearchResults}>
          Search
        </MDBBtn>
    </MDBCol>
    </MDBRow> 
                <hr className="my-5" />
    <FilterResults
          value={value}
          data={data}
          renderResults={results => (
            <div>
                <MDBRow>
              {results.map(el => (
                  <div>
                 
                 <MDBCol lg="4" xl="12" className="mb-4">
                <MDBCard>
         
          <MDBCardBody>
           
            <MDBRow>
            
    <MDBCol md="2" className="text-center"> <img style={{maxWidth:"48%"}} src="./default.png" className="img-fluid z-depth-1 rounded-circle" alt="" /></MDBCol>
    <MDBCol md="7">
      <Typography style={{top: "601px",


font: "Bold 20px/22px Poppins",
letterSpacing: "0px",
color: "#13C1D6",
opacity: "1",
maxWidth:"150px"
}}>{el.fname} {el.lname}</Typography>
<div style={{
color:"white",
textAlign:"center",

background: "#13C1D6 0% 0% no-repeat padding-box",
borderRadius: "13px",
opacity: "1",
maxWidth:"150px",
}}>
   <TextTruncate
                    line={1}
                    element="p"
                    truncateText="…"
                    text={el.designation}
                    textTruncateChild={""}
                  />
  </div></MDBCol>
    <MDBCol md="3">{/*<Link href={el.uid}><MDBIcon far icon="eye" className="black-text pr-3" /></Link>*/}<Link href={el.resume}><MDBIcon icon="download"  style={{paddingLeft:"10px"}} className="black-text" /></Link></MDBCol>
  </MDBRow>
<div>
<Typography><MDBIcon icon="suitcase" /><span style={{textAlign: "left",
font: "Regular 16px/22px Poppins",
letterSpacing: "0px",
color: "#7C7C7C",paddingLeft:"10px"}}>Experiance:</span><span>{el.experiance}</span></Typography>
<Typography><MDBIcon icon="map-marker-alt" /><span style={{textAlign: "left",
font: "Regular 16px/22px Poppins",
letterSpacing: "0px",
color: "#7C7C7C",paddingLeft:"13px"}}>Location:</span><span style={{maxWidth:"150px"}}>{el.location}</span></Typography>
</div>
            <hr />
            <div>
              <Typography>Skills</Typography>
              <Typography style={{textAlign: "left",font: "Regular 14px/21px Poppins",letterSpacing: "0px",color: "#13C1D6",opacity: "1",maxWidth:"400px"}}>{el.skills}</Typography>
            </div>
            <hr />
            <div>
              <Typography>Additional Skills</Typography>
              <TextTruncate
                    line={4}
                    element="p"
                    truncateText="…"
                    text={el.askill}
                    textTruncateChild={""}
                    style={{textAlign: "left",font: "Regular 14px/21px Poppins",letterSpacing: "0px",color: "#13C1D6",opacity: "1",maxWidth:"400px"}}
                  />
              {/*<Typography style={{textAlign: "left",font: "Regular 14px/21px Poppins",letterSpacing: "0px",color: "#13C1D6",opacity: "1",maxWidth:"400px"}}> {el.askill}</Typography>*/}
            </div>
            <hr />
            <div>
              <Typography style={{font: "Regular 14px/22px Poppins"}}>Basic Destails</Typography>
              <Typography><MDBIcon icon="at" /><span style={{font: "Regular 16px/22px Poppins",letterSpacing: "0px",color: "#7C7C7C",paddingLeft:"5px"}}>Email:</span><span style={{font: "Regular 16px/22px Poppins",letterSpacing: "0px",color: "#13C1D6",paddingLeft:"5px"}} >{el.email}</span></Typography>
<Typography><MDBIcon icon="phone-square-alt" /><span style={{font: "Regular 16px/22px Poppins",letterSpacing: "0px",color: "#7C7C7C",paddingLeft:"5px"}}>Mobile:</span><span style={{font: "Regular 16px/22px Poppins",letterSpacing: "0px",color: "#13C1D6",paddingLeft:"5px"}}>{el.mobile}</span></Typography>
            </div>
            </MDBCardBody>
            </MDBCard>
                   
                  
                </MDBCol>
           
              
               </div>
              ))}
               </MDBRow>
            </div>
          )}
        />
            </MDBCardBody>
                       </MDBCard>
          </main>
             
        </div> 
        </MainContainer>
      );
  }
}
export default withStyles(styles)(CandidateSearch);