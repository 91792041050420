import { CHANGE_USER_TYPE } from "../constants/actionTypes";

const initialState = {
  userType: "employee"
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_USER_TYPE:
      return {
        ...state,
        userType: action.payload
      };
    default:
      return state;
  }
}