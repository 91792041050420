import React from "react";
import { styled, withStyles } from "@material-ui/styles";
import { Grid, Container, Button, Tabs, Tab, Paper } from "@material-ui/core";

export const FilterContainer = styled(Paper)({
  padding: "15px 9%",
  backgroundColor: "white"
});

export const FilterType = styled(({ name, selected, ...others }) => <Button>{name}</Button>)({});

const FilterTypeButton = props => {
  const { name, selected } = props;
  return <Button>{name}</Button>;
};

export const FilterTabs = withStyles({
  flexContainer: {
    paddingTop: "20px"
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      width: "80%",
      backgroundColor: "#13C1D6"
    }
  }
})(props => <Tabs indicatorColor="primary" textColor="primary" {...props} TabIndicatorProps={{ children: <div /> }} />);

export const FilterTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    minHeight: 0,
    fontWeight: theme.typography.fontWeightRegular,
    // marginRight: theme.spacing(4),
    fontFamily: ['"Helvetica Neue"', "sans-serif"].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium
    },
    "&:focus": {
      color: "#40a9ff"
    }
  },
  selected: {}
}))(props => <Tab disableRipple {...props} />);
