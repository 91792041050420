import React from 'react';

import axios from 'axios';
import { MDBTable, MDBTableBody, MDBTableHead,MDBBtn, MDBIcon, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBContainer } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import TopNavigation from 'components/pages/recruiter/header/topNavigation';
import SideNavigation from 'components/pages/recruiter/header/sideNavigation';
import Footer from 'components/pages/recruiter/header/Footer';
import StorageManager from "utils/StorageManager";


export default class MyCandidates extends React.Component {
  state = {
    candidates: []
  }
 
  
  componentDidMount() {
    const token = StorageManager.instance.getAccessToken();
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+token,
        'X-CSRF-Token':csrf
      }
      axios.get('https://live-corp2corp.pantheonsite.io/api/'+uid+'/recruiter_candidates',{
        headers: headers
      })
      .then(res => {
        const candidates = res.data;
        this.setState({ candidates });
      })
  }

  render() {

    return (
        <div className="flexible-content">
      
          <TopNavigation />
          <SideNavigation />
          <main id="content" className="p-5">
          <MDBContainer>
          <MDBRow className="mb-4">
          <MDBCol md="12">
              <MDBCard>
                  <MDBCardBody>
                    <MDBTable>
                      <MDBTableHead color="primary-color" textWhite>
                        <tr>
                          <th>#</th>
                          <th>Employee Name</th>
                          <th>Email Id</th>
                          <th>Phone Number</th>
                          <th>Location</th>
                          <th>Action</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                      
                      { this.state.candidates.map(person => <tr><td>{person.counter}</td><td>{person.name}</td><td>{person.email}</td><td>{person.mobile}</td><td>{person.location}</td>{/*<td><MDBBtn tag="a" size="sm" floating color="red"> <MDBIcon icon="times" /> </MDBBtn></td>*/}</tr>)}
                        
                      </MDBTableBody>
                    </MDBTable>
                  </MDBCardBody>  
                </MDBCard>       
              </MDBCol>
      </MDBRow>

             </MDBContainer>
          </main>
           <Footer/>
          </div> 
     )
  }
}