

import React, { useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import assets from "../../../assets";
import { Paper, Hidden } from "@material-ui/core";
import "./MyAppBar.scss";
import AssignmentIcon from '@material-ui/icons/Assignment';
import c12 from "assets/images/cclogo.svg";
import { Link,NavLink } from "react-router-dom";
import { useUserType } from "utils/hooks/common";
import AppButton from "../Buttons";
import UserManager from "utils/UserManager";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { UserManagementService } from "services/endpoints/usermanagement/UserManagementService";
import { GoogleLogout } from 'react-google-login';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,MDBLink } from "mdbreact";
import './appbar.css';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';

import List from '@material-ui/core/List';

import Divider from '@material-ui/core/Divider';


import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SearchIcon from '@material-ui/icons/Search';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DoneIcon from '@material-ui/icons/Done';
import SettingsIcon from '@material-ui/icons/Settings';


import PostAddIcon from '@material-ui/icons/PostAdd';


import MailIcon from '@material-ui/icons/Mail';
const drawerWidth = 240;


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  homeLogins: {
    backgroundColor:"#20B3D6" ,
    color: "white"
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    fontFamily: "'Poppins', sans-serif"
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolbar: {
    background: "#fff",

  },
  ebutton:{

  },
  jButton:{
    color: "white",
    fontSize: "0.85em",
    fontFamily: "Poppins, sans-serif",
    marginRight: "10px",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: "rgb(19, 193, 214)!important",
    borderRadius: "100px",
    minWidth: "135px"
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  logoContainer: {
    textAlign: "center"
  },
  logo: {
    //height: "72px",
    width:"96%",
  },
  headerButtonContainer: {
    [theme.breakpoints.up("sm")]: {
      textAlign: "left"
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginTop: "-20px"
    }
  },
  headerButton: {
    fontSize: "0.85em",
    paddingLeft: "30px",
    paddingRight: "30px",
    marginRight: "10px"
  }
}));

const MyAppBar = () => {
  const classes = useStyles();
  const isLoggedIn = UserManager.isLoggedIn();
  //const isEmployer = UserManager.isEmployer();
  //const isCandidate = UserManager.isCandidate();
  const history = useHistory();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const pathArray = window.location.pathname.split('/');
  const secondLevelLocation = pathArray[1];
  return (
    <AppBar position="sticky" className="appbarcss">
      <Toolbar className={classes.toolbar}>
        <Grid container  alignItems="center" justify="space-between">
          <Grid item xs={12} sm={4} className={classes.logoContainer} >
          <Toolbar className="leftLogo">
         {/*
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
         */}
         {isLoggedIn && (
           <div>
           {UserManager.isEmployer() ? (
            <Link to="/sales-and-support" style={{ textDecoration: "none" }}>
           
            <img src={assets.images.cclogo} className={classes.logo} alt="Corp2Corp Job Portal" title="Corp2Corp Job Portal"/>
                     </Link>
           ):(
            <Link to="/" style={{ textDecoration: "none" }}>
           
            <img src={assets.images.cclogo} className={classes.logo} alt="Corp2Corp Job Portal" title="Corp2Corp Job Portal"/>
                     </Link>
           )}
           </div>
         )}

{!isLoggedIn && (
          <div>
          <Link to="/" style={{ textDecoration: "none" }}>
           
          <img src={assets.images.cclogo} className={classes.logo} alt="Corp2Corp Job Portal" title="Corp2Corp Job Portal"/>
                   </Link>
                   </div>
   )}

         
                     {isLoggedIn && (
                     <div className="mobilemenu">
                       <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          onClick={handleDrawerOpen}
                          edge="start"
                          className={clsx(classes.menuButton, open && classes.hide)}
                        >
                          <MenuIcon />
                        </IconButton>
                     </div>
                     )}
                     </Toolbar>
                     <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
       
        <Divider />
        <List>
        {isLoggedIn && <Actions1 />}
      </List>
        <List>
  
      {!isLoggedIn && (
          <div>
        <ListItem button onClick={event =>  history.push('/jobseekers/register')}>
            <ListItemIcon> <FileCopyIcon /> </ListItemIcon>
              <ListItemText primary="Post Resume" />
        </ListItem>
        <ListItem button onClick={event =>  history.push('/jobseekers/login')}>
            <ListItemIcon> <LockOpenIcon /> </ListItemIcon>
              <ListItemText primary="Login" />
        </ListItem>
        <ListItem button onClick={event =>  history.push('/jobseekers/register')}>
            <ListItemIcon> <PersonAddIcon /> </ListItemIcon>
              <ListItemText primary="Register" />
        </ListItem>
        </div>
      )}

        {/*}  {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
        ))}*/}
        </List>
        <Divider />
        <List>
        {!isLoggedIn && (
          <div>
        <ListItem button  onClick={event =>  history.push('/employer/Pricing')}>
            <ListItemIcon> <FileCopyIcon /> </ListItemIcon>
              <ListItemText primary="Post Job" />
        </ListItem>
        <ListItem button onClick={event =>  history.push('/employer/Pricing')}>
            <ListItemIcon> <SearchIcon /> </ListItemIcon>
              <ListItemText primary="Search Resume" />
        </ListItem>
        <ListItem button onClick={event =>  history.push('/employer/login')}>
            <ListItemIcon> <AssignmentIndIcon /> </ListItemIcon>
              <ListItemText primary="Login" />
        </ListItem>
        <ListItem button onClick={event =>  history.push('/employer/Pricing')}>
            <ListItemIcon> <LocalOfferIcon /> </ListItemIcon>
              <ListItemText primary="Trial Free" />
        </ListItem>
        <ListItem button onClick={event =>  history.push('/employer/Pricing')}>
            <ListItemIcon> <LocalAtmIcon /> </ListItemIcon>
              <ListItemText primary="Pricing" />
        </ListItem>
        </div>
      )}
         {/*} {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
         ))}*/}
        </List>
      </Drawer>
    
          </Grid>
              {!isLoggedIn && (
            <React.Fragment>
              <Grid item xs={12} sm={4}>
                <Grid item xs={12} className={classes.headerButtonContainer}>
                  <UserTypeButton />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4} className={classes.headerButtonContainer}>
                
                <Hidden xsDown>
                
                {secondLevelLocation == "employer" && (
                  <div>
                  <Link to="/employer/sales-and-support" style={{ textDecoration: "none" }}>
                  <AppButton variant="text" size="small"  style={{ fontFamily: "'Poppins', sans-serif"}}>
                    Advertise
                  </AppButton>
                </Link>
                 <Link to="/contact" style={{ textDecoration: "none"}} >
                 <AppButton className="homeLogins" size="small" style={{ fontFamily: "'Poppins', sans-serif",border:"none" }}>Contact Us</AppButton>
               </Link>
               </div>

                )}

{secondLevelLocation != "employer" && (
                  <div>
                  <Link to="/jobseekers/register" style={{ textDecoration: "none" }}>
                  <AppButton variant="text" size="small"  style={{ fontFamily: "'Poppins', sans-serif"}}>
                    REGISTER
                  </AppButton>
                </Link>
                 <Link to="/jobseekers/login" style={{ textDecoration: "none"}} >
                 <AppButton className="homeLogins" size="small" style={{ fontFamily: "'Poppins', sans-serif",border:"none" }}>LOGIN</AppButton>
               </Link>
               </div>

                )}
                  
                  
                 
                 
              
                
                </Hidden>
              </Grid>
            </React.Fragment>
          )}

          {isLoggedIn && <Actions />}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

const Actions = () => {
  const theme = useTheme();
  const classes = useStyles();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const isLoggedIn = UserManager.isLoggedIn();
  const isEmployer = UserManager.isEmployer();
  const isCandidate = UserManager.isCandidate();
  const isGoogle = localStorage.getItem("google_login");
  const logout = () => {
    console.log('logout'); // eslint-disable-line
    handleClose();
    
    UserManager.logout();
  }
  const handleClose = () => {
 
 

    setAnchorEl(null);
  };
  
  return (
    <React.Fragment>
        <Hidden xsDown>
              <Grid item xs={12} sm={4}>
                <Grid item xs={12} className={classes.headerButtonContainer}>
                {!isLoggedIn && (
                  <UserTypeButton />
            )}
            {isEmployer && <div>
              {/* activeClassName="employer-link-active" */}
              <NavLink className="employer-links"  to="/employer-dashboard" style={{color: "#707070",fontSize: "16px"}}>Dashboard</NavLink>
              <MDBDropdown size="sm"  className="employer-links">
                <MDBDropdownToggle color="none" style={{ border: "none", backgroundColor: "white", color: "black", boxShadow: "unset", padding:"0", lineHeight: "16px", fontWeight: "300", margin:"0", marginTop: "-1px" }}>
                  <Typography style={{ textTransform:"none", color: "#707070", fontSize: "16px",fontWeight: "300" }}>Jobs<ArrowDropDownIcon/></Typography>
                </MDBDropdownToggle>
                <MDBDropdownMenu className="default accent-4" basic style={{ fontFamily: "'Poppins', sans-serif" }}>
                  <Link to="/job-posting"><MDBDropdownItem style={{ fontFamily: "'Poppins', sans-serif" }}>Post a Job</MDBDropdownItem></Link>
                  <Link to="/My-Jobs"><MDBDropdownItem style={{ fontFamily: "'Poppins', sans-serif" }} >Manage Jobs</MDBDropdownItem></Link>
                 {/* <Link to="/My-candidates"><MDBDropdownItem style={{ fontFamily: "'Poppins', sans-serif" }}>Applied Jobs</MDBDropdownItem></Link>*/}
                </MDBDropdownMenu>
              </MDBDropdown>  
              <NavLink className="employer-links" to="/employer/Candidate-Search" style={{color: "#707070",fontSize: "16px"}}>Resume Search</NavLink>
              <NavLink className="employer-links"  to="/sales-and-support" style={{color: "#707070",fontSize: "16px"}}>Support / Sales</NavLink>
          </div>}
          {isCandidate && <div>
              {/* activeClassName="employer-link-active" */}
              <NavLink className="employer-links"  to="/saved-jobs" style={{color: "#707070",fontSize: "16px"}}>Saved Jobs</NavLink>
              <NavLink className="employer-links"  to="/applied-jobs" style={{color: "#707070",fontSize: "16px"}}>Applied Jobs</NavLink>
              <NavLink className="employer-links"  to="/jobs" style={{color: "#707070",fontSize: "16px"}}>Job Search</NavLink>
              {/*<NavLink className="employer-links"  to="/resume-edit/1" style={{color: "#707070",fontSize: "16px"}}>My Resume</NavLink>*/}
             {/*
              <MDBDropdown size="sm"  className="employer-links">
                <MDBDropdownToggle color="none" style={{ border: "none", backgroundColor: "white", color: "black", boxShadow: "unset", padding:"0", lineHeight: "16px", fontWeight: "300", margin:"0", marginTop: "-1px" }}>
                  <Typography style={{ textTransform:"none", color: "#707070", fontSize: "16px",fontWeight: "300" }}>Jobs<ArrowDropDownIcon/></Typography>
                </MDBDropdownToggle>
                <MDBDropdownMenu className="default accent-4" basic style={{ fontFamily: "'Poppins', sans-serif" }}>
                  <Link to="/job-posting"><MDBDropdownItem style={{ fontFamily: "'Poppins', sans-serif" }}>Post a Job</MDBDropdownItem></Link>
                  <Link to="/My-Jobs"><MDBDropdownItem style={{ fontFamily: "'Poppins', sans-serif" }} >Manage Jobs</MDBDropdownItem></Link>
                 </MDBDropdownMenu>
              </MDBDropdown> 
              <NavLink className="employer-links" to="/employer/Candidate-Search" style={{color: "#707070",fontSize: "16px"}}>Resume Search</NavLink>
              <NavLink className="employer-links"  to="/sales-and-support" style={{color: "#707070",fontSize: "16px"}}>Support / Sales</NavLink>
           */}
              </div>}
             
                </Grid>
              </Grid>
      <Grid container xs={12} sm={2} justify={smallScreen ? "center" : "flex-end"} style={{marginRight:"168px"}}>
        
        {/*<IconButton aria-label="notifications">
          <NotificationsNoneOutlinedIcon />
        </IconButton>
        <IconButton aria-label="messges">
          <EmailOutlinedIcon />
                </IconButton>*/}
                   
              {/* 
            {isEmployer && (
          <Grid item xs={4}>
                
          
            <MDBDropdown size="sm">
              <MDBDropdownToggle rounded class="jButton">
              <Typography style={{ padding: "7px 2px" ,fontFamily: "'Poppins', sans-serif",fontSize:".96em"}}> My Dashboard
              
 
              </Typography>
              </MDBDropdownToggle>
              <MDBDropdownMenu color="default" basic>
                <Link to="/job-posting"><MDBDropdownItem >Post Job</MDBDropdownItem></Link>
                <Link to="/candidates"><MDBDropdownItem>Search Resume</MDBDropdownItem></Link>
                <Link to="/Pricing"><MDBDropdownItem>Pricing</MDBDropdownItem></Link>
                
                <Link to="/employer-dashboard"><MDBDropdownItem>My Dashboard</MDBDropdownItem></Link>
                <MDBDropdownItem  onClick={() => {
     handleClose();
    
     UserManager.logout();
   }}>Logout</MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          
  </Grid>
           )} */}
           
     <Grid item xs={4} justify={smallScreen ? "center" : "flex-end"}>
       
        <IconButton aria-label="profile" aria-haspopup="true" onClick={handleClick}>
          <PersonOutlineOutlinedIcon />
  </IconButton>
      </Grid>
      <Menu id="profile-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
   <div>
     
  

       
        <MenuItem
          onClick={() => {
            handleClose();
            if (UserManager.isCandidate()) {
              history.push("/saved-jobs");
            } else if (UserManager.isEmployer()) {
              {
                history.push("/employer/profile");
              }
            }
          }}
        >
          My Profile
        </MenuItem>
      
      </div>
    <div>
      
             </div> 
  {!isGoogle && (
   <MenuItem
   onClick={() => {
     handleClose();
    
     UserManager.logout();
   }}
  >
   Logout
  </MenuItem>
             )}

{isGoogle && (
  <GoogleLogout
  clientId="808237848485-2fid2u88q52skos4neuujgtksmdi00o0.apps.googleusercontent.com"
  buttonText="Logout"
  onLogoutSuccess={logout}
>
  Logout
</GoogleLogout>
             )}
     
    
      </Menu>
      </Grid>
      </Hidden>
    </React.Fragment>
  );
};

const Actions1 = () => {
  const theme = useTheme();
  const classes = useStyles();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const isLoggedIn = UserManager.isLoggedIn();
  const isEmployer = UserManager.isEmployer();
  const isCandidate = UserManager.isCandidate();
  const isGoogle = localStorage.getItem("google_login");
  const logout = () => {
    console.log('logout'); // eslint-disable-line
    localStorage.clear();

    handleClose();
    
    UserManager.logout();
  }
  const handleClose = () => {
 
 

    setAnchorEl(null);
  };
  
  return (
    <React.Fragment>
       {isEmployer && (
    <div>
 
  <ListItem button onClick={event =>  history.push('/employer-dashboard')}>
      <ListItemIcon> <DashboardIcon /> </ListItemIcon>
        <ListItemText primary="My Dashboard" />
  </ListItem>
  <ListItem button onClick={event =>  history.push('/job-posting')}>
      <ListItemIcon> <PostAddIcon /> </ListItemIcon>
        <ListItemText primary="Post Job" />
  </ListItem>

  <ListItem button onClick={event =>  history.push('/My-Jobs')}>
      <ListItemIcon> <PostAddIcon /> </ListItemIcon>
        <ListItemText primary="Manage Job" />
  </ListItem>

 {/* <ListItem button onClick={event =>  history.push('/My-candidates')}>
      <ListItemIcon> <PostAddIcon /> </ListItemIcon>
        <ListItemText primary="Applied Jobs" />
       </ListItem>*/}

  <ListItem button onClick={event =>  history.push('/employer/profile')}>
      <ListItemIcon> <PostAddIcon /> </ListItemIcon>
        <ListItemText primary="My Profile" />
  </ListItem>
  <ListItem button onClick={event =>  history.push('/employer/Candidate-Search')}>
      <ListItemIcon> <SearchIcon /> </ListItemIcon>
        <ListItemText primary="Resume Search" />
  </ListItem>
  {!isGoogle && (
  <ListItem button  onClick={() => {
     handleClose();
    
     UserManager.logout();
   }}>
      <ListItemIcon> <ExitToAppIcon /> </ListItemIcon>
        
        <ListItemText primary="Logout" />
  </ListItem>
  )}
   {isGoogle && (
 <GoogleLogout
 clientId="808237848485-2fid2u88q52skos4neuujgtksmdi00o0.apps.googleusercontent.com"
 buttonText="Logout"
 onLogoutSuccess={logout}
>
 <span style={{fontFamily: "'Poppins', sans-serif"}}>Logout</span>
</GoogleLogout>
   )}
   </div>
)}
 {isCandidate && (
    <div>
 
  <ListItem button onClick={event =>  history.push('/saved-jobs')}>
      <ListItemIcon> <FavoriteIcon /> </ListItemIcon>
        <ListItemText primary="Saved Jobs" />
  </ListItem>
  <ListItem button onClick={event =>  history.push('/applied-jobs')}>
      <ListItemIcon> <DoneIcon /> </ListItemIcon>
        <ListItemText primary="Applied Jobs" />
  </ListItem>
  <ListItem button onClick={event =>  history.push('/resume-edit/1')}>
      <ListItemIcon> <AssignmentIcon /> </ListItemIcon>
        <ListItemText primary="My Resume" />
  </ListItem>
  <ListItem button onClick={event =>  history.push('/jobs')}>
      <ListItemIcon> <SearchIcon /> </ListItemIcon>
        <ListItemText primary="Job Search" />
  </ListItem>
  <ListItem button onClick={event =>  history.push('/onboarding/1')}>
      <ListItemIcon> <SettingsIcon /> </ListItemIcon>
        <ListItemText primary="My Settings" />
  </ListItem>
  {!isGoogle && (
  <ListItem button  onClick={() => {
     handleClose();
    
     UserManager.logout();
   }}>
      <ListItemIcon> <ExitToAppIcon /> </ListItemIcon>
        <ListItemText primary="Logout" />
  </ListItem>
  )}
  
  {isGoogle && (
 <GoogleLogout
 clientId="808237848485-2fid2u88q52skos4neuujgtksmdi00o0.apps.googleusercontent.com"
 buttonText="Logout"
 onLogoutSuccess={logout}
>
 Logout
</GoogleLogout>
   )}
   </div>
)}
    </React.Fragment>
  );
};

const UserTypeButton = ({ className }) => {
  const history = useHistory();
  const [type, changeType] = useUserType();
  const pathArray = window.location.pathname.split('/');
  const secondLevelLocation = pathArray[1];
  const [anchorEl, setAnchorEl] = React.useState(null);
  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  function handleClick2(event) {
    if (anchorEl2 !== event.currentTarget) {
      setAnchorEl2(event.currentTarget);
    }
  }

  function handleClose2() {
    setAnchorEl2(null);
  }

  
  return (
    <React.Fragment>
     {/* <AppButton
        item
        selected={type === "employee"}
        style={{ marginRight: "10px" ,fontFamily: "'Poppins', sans-serif"}}
        onClick={() => {
          if (!UserManager.isLoggedIn()) {
            toast.warn("Please login to continue");
            history.push("/login");
          } else {
            changeType("employee");
          }
        }}
      >
        JOBSEEKERS
      </AppButton>
      <AppButton
        item
        style={{ fontFamily: "'Poppins', sans-serif" }}
        selected={type === "employer"}
        onClick={() => {
          if (!UserManager.isLoggedIn()) {
           // toast.warn("Please login to continue");
            history.push("/employer/register");
          } else {
            changeType("employer");
          }
        }}
      >
        EMPLOYERS
      </AppButton>*/}
      <Grid container spacing={1} className="topbutton">
            <Grid item xs={3.5} className="topbutton1">
      
      {secondLevelLocation != "employer" && (
      <Button
        aria-owns={anchorEl ? "simple-menu1" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        onMouseOver={handleClick}
        style={{border:"none",backgroundColor: "#16c1d6",color: "white", boxShadow: "unset",borderRadius:"19px"}}
      >
       JOBSEEKERS
      </Button>
      )}

{secondLevelLocation == "employer" && (
      <Button
        aria-owns={anchorEl ? "simple-menu1" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        onMouseOver={handleClick}
        style={{border:"none",backgroundColor: "white",color: "black", boxShadow: "unset"}}
      >
       JOBSEEKERS
      </Button>
      )}
      <Menu
        id="simple-menu1"
        style={{top:"30px"}}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
      >
         <MenuItem onClick={handleClose}><Link to="/jobseekers/login" style={{fontFamily: "'Poppins', sans-serif"}}>Post Resume</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to="/jobseekers/login" style={{fontFamily: "'Poppins', sans-serif"}}>Login</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to="/jobseekers/register" style={{fontFamily: "'Poppins', sans-serif"}}>My Register</Link></MenuItem>
       
      </Menu>

     {/*
              <MDBDropdown size="sm">
              {secondLevelLocation != "employer" && (
            
                  <MDBDropdownToggle  color="none" style={{border:"none",backgroundColor: "#16c1d6",color: "white", boxShadow: "unset",borderRadius:"19px"}}>
                  <Typography style={{ color:"white" ,fontFamily: "'Poppins', sans-serif",fontSize:"1.2em", background:"#16c1d6",borderRadius:"19px",fontWeight:"800"}}>JOBSEEKERS</Typography>
                  </MDBDropdownToggle>
               
              )}
               {secondLevelLocation == "employer" && (
                
                  <MDBDropdownToggle  color="none" style={{border:"none",backgroundColor: "white",color: "black", boxShadow: "unset"}}>
                  <Typography style={{color:"black" ,fontFamily: "'Poppins', sans-serif",fontSize:"1.2em"}}>JOBSEEKERS</Typography>
                  </MDBDropdownToggle>
               
              )}
                  <MDBDropdownMenu  basic style={{fontFamily: "'Poppins', sans-serif"}}>
                    <Link to="/jobseekers/login" style={{fontFamily: "'Poppins', sans-serif"}}><MDBDropdownItem style={{fontFamily: "'Poppins', sans-serif"}}>Post Resume</MDBDropdownItem></Link>
                    <Link to="/jobseekers/login"><MDBDropdownItem style={{fontFamily: "'Poppins', sans-serif"}}>Login</MDBDropdownItem></Link>
                    <Link to="/jobseekers/register"><MDBDropdownItem style={{fontFamily: "'Poppins', sans-serif"}}>Register</MDBDropdownItem></Link>
                  
                  </MDBDropdownMenu>
                </MDBDropdown>
               */}
            </Grid>
          <Grid item xs={3.5} className="topbutton1">
                
{/*
            <MDBDropdown size="sm">
            {secondLevelLocation == "employer" && (
              <MDBDropdownToggle color="none" style={{border:"none",backgroundColor: "#16c1d6",color: "white", boxShadow: "unset",borderRadius:"19px"}}>
                
                  <Typography style={{ color:"white" ,fontFamily: "'Poppins', sans-serif",fontSize:"1.2em", background:"#16c1d6",borderRadius:"19px",fontWeight:"800"}}> EMPLOYERS</Typography>
              </MDBDropdownToggle>
              )}
               {secondLevelLocation != "employer" && (
              <MDBDropdownToggle color="none" style={{border:"none",backgroundColor: "white",color: "black", boxShadow: "unset"}}>
                
                  <Typography style={{ color:"black" ,fontFamily: "'Poppins', sans-serif",fontSize:"1.2em"}}> EMPLOYERS</Typography>
              </MDBDropdownToggle>
               )}
              <MDBDropdownMenu  className="default accent-4" basic style={{fontFamily: "'Poppins', sans-serif"}}>
                <Link to="/employer/register"><MDBDropdownItem style={{fontFamily: "'Poppins', sans-serif"}}>Post Job</MDBDropdownItem></Link>
                <Link to="/employer/register"><MDBDropdownItem style={{fontFamily: "'Poppins', sans-serif"}} >Search Resume</MDBDropdownItem></Link>
                <Link to="/employer/login"><MDBDropdownItem style={{fontFamily: "'Poppins', sans-serif"}}>Login</MDBDropdownItem></Link>
                <Link to="/employer/trial"><MDBDropdownItem style={{fontFamily: "'Poppins', sans-serif"}}>Trial Free</MDBDropdownItem></Link>
                <Link to="/employer/Pricing"><MDBDropdownItem style={{fontFamily: "'Poppins', sans-serif"}}>Pricing</MDBDropdownItem></Link>
     
              </MDBDropdownMenu>
            </MDBDropdown>
               */}
                {secondLevelLocation != "employer" && (
      <Button
        aria-owns={anchorEl2 ? "simple-menu2" : undefined}
        aria-haspopup="true"
        onClick={handleClick2}
        onMouseOver={handleClick2}
        style={{border:"none",backgroundColor: "white",color: "black", boxShadow: "unset"}}
      >
        EMPLOYERS
      </Button>
      )}

{secondLevelLocation == "employer" && (
      <Button
        aria-owns={anchorEl ? "simple-menu1" : undefined}
        aria-haspopup="true"
        onClick={handleClick2}
        onMouseOver={handleClick2}
        style={{border:"none",backgroundColor: "#16c1d6",color: "white", boxShadow: "unset",borderRadius:"19px"}}
      >
        EMPLOYERS
      </Button>
      )}
      <Menu
        id="simple-menu1"
        style={{top:"30px"}}
        anchorEl={anchorEl2}
        open={Boolean(anchorEl2)}
        onClose={handleClose2}
        MenuListProps={{ onMouseLeave: handleClose2 }}
      >
         <MenuItem onClick={handleClose}><Link to="/employer/register" style={{fontFamily: "'Poppins', sans-serif"}}>Post Job</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to="/employer/login" style={{fontFamily: "'Poppins', sans-serif"}}>Login</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to="/employer/register" style={{fontFamily: "'Poppins', sans-serif"}}>Register</Link></MenuItem>
       
      </Menu>
          </Grid>
     </Grid>
     
    </React.Fragment>
  );
};

export default MyAppBar;
