import React, { useState, useRef, useEffect } from "react";
import { TextInput, TextChipInput } from "components/common/FormInputs";
import ReactTags from "react-tag-autocomplete";
import { InputLabel, Select, MenuItem, FormControl, FormControlLabel, Checkbox, FormGroup, FormHelperText, FormLabel, Grid } from "@material-ui/core";
import lodash from "lodash";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
export const FormikTextInput = ({ field, form, selectItems, ...props }) => {
  const error = Boolean(form.errors[field.name] && form.touched[field.name]);
  const touched = form.touched[field.name];
  const errorMessage = form.errors[field.name];
  return (
    <TextInput {...field} {...props} error={error} touched={touched ? "true" : "false"} helperText={error && errorMessage}>
      {selectItems &&
        selectItems.map(item => {
          return <MenuItem value={item.value}>{item.name}</MenuItem>;
        })}
    </TextInput>
  );
};

export const FormikChipInput = ({ field, form, dataSource, labelText, ...props }) => {
  const [tags, setTags] = useState([]);
  const error = Boolean(form.errors[field.name] && form.touched[field.name]);
  const touched = form.touched[field.name];
  const errorMessage = form.errors[field.name];

  useEffect(() => {
    const items = lodash.filter(dataSource, item => {
      return lodash.includes(form.values[field.name], item.id);
    });
    setTags(items);
  }, [form.values[field.name]]);

  return (
    <>
      <InputLabel>{labelText}</InputLabel>
      <ReactTags
        classNames={{ selected: "react-tags__selected_custom" }}
        {...field}
        {...props}
        tags={tags}
        minQueryLength={0}
        autoresize={false}
        suggestions={dataSource || []}
        handleAddition={tag => {
          if (lodash.includes(form.values[field.name], tag.id)) return;
          if (form.values[field.name]) form.setFieldValue(field.name, [...form.values[field.name], tag.id]);
          else {
            form.setFieldValue(field.name, [tag.id]);
          }
        }}
        handleDelete={index => {
          const items = [...form.values[field.name]];
          items.splice(index, 1);
          form.setFieldValue(field.name, items);
        }}
        error={error}
        selected={error}
        touched={touched ? "true" : "false"}
        handleFocus={() => {
          form.setFieldTouched(field.name, true);
        }}
        inputAttributes={{
          style: {
            borderColor: "red"
          }
        }}
        style={{ borderColor: "red" }}
      />
      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" style={{ color: "#f44336" }}>
        {error && errorMessage}
      </p>
    </>
  );
};

export const FormikEditor = ({ field, form, items, labelText, ...props }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  return (
    <>
      <InputLabel htmlFor={props.name}>{labelText}</InputLabel>
      <Editor
        editorStyle={{ minHeight: "200px", border: "1px solid #F1F1F1" }}
        initialEditorState={editorState}
        onEditorStateChange={state => {
          setEditorState(state);
          form.setFieldValue(field.name, draftToHtml(convertToRaw(state.getCurrentContent())));
        }}
      />
    </>
  );
};

export const FormikCheckBoxGroup = ({ field, form, items, labelText, ...props }) => {
  const [selected, setSelected] = useState([]);
  const error = selected.length == 0;
  const touched = form.touched[field.name];
  const errorMessage = form.errors[field.name];
  // useEffect(() => {
  //   form.setFieldValue(field.name, selected);
  // }, [selected]);

  useEffect(() => {
    const ids = lodash
      .filter(items, item => {
        return lodash.includes(form.values[field.name], item.value);
      })
      .map(item => item.value);
    console.log("Jobs", ids);
    setSelected(ids);
  }, [form.values[field.name]]);

  return (
    <FormControl error={error} component="fieldset">
      <FormLabel htmlFor={props.name}>{labelText}</FormLabel>
      <FormGroup>
        <Grid container>
          {items &&
            items.map((item, index) => {
              return (
                <Grid xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={lodash.includes(selected, item.value)}
                        onChange={e => {
                          if (e.target.checked) {
                            // if (lodash.includes(selected, item.value)) return;
                            // setSelected([...selected, item.value]);

                            if (lodash.includes(form.values[field.name], item.value)) return;
                            if (form.values[field.name]) form.setFieldValue(field.name, [...form.values[field.name], item.value]);
                            else {
                              form.setFieldValue(field.name, [item.value]);
                            }
                          } else {
                            // const sel = lodash.filter(selected, it => {
                            //   return it !== item.value;
                            // });
                            // console.log("removed", sel);
                            // setSelected(sel);

                            lodash.remove(selected, val => item.value == val);
                            form.setFieldValue(field.name, [...selected]);
                          }
                        }}
                        value={item.value}
                      />
                    }
                    label={item.name}
                  />
                </Grid>
              );
            })}
        </Grid>
      </FormGroup>
      <FormHelperText> {error && errorMessage}</FormHelperText>
    </FormControl>
  );
};

export const FormikCheckBox = ({ field, form, items, labelText, ...props }) => {
  console.log("Form", form.values);
  return (
    <FormControlLabel
      {...props}
      value={form.values[field.name]}
      control={<Checkbox />}
      label={labelText}
      onChange={e => {
        form.setFieldValue(field.name, e.target.checked);
      }}
    />
  );
};
