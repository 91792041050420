import React, {useContext, useEffect, useState, useRef} from 'react';

import JoditEditor from "jodit-react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import { Grid, Hidden, Paper, Container, Typography, TextField, FormControl, InputAdornment, Icon, Button, Divider,LinearProgress } from "@material-ui/core";
//import { Typography,Hidden, Paper, Divider, Box } from "@material-ui/core";
import DoneTwoToneIcon from '@material-ui/icons/DoneTwoTone';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import { Formik, Form, ErrorMessage, Field, FastField } from "formik";
import { FormikTextInput, FormikChipInput,FormikPlacesInput } from "components/common/FormInputs/FormikInputs";
import * as Yup from "yup";
import DeleteIcon from "@material-ui/icons/Delete";
//import { Grid, Container, Typography, FormControl, InputAdornment, Icon, Button, FormGroup, LinearProgress } from "@material-ui/core";
//import { useDispatch, useSelector } from "react-redux";
import FavoriteIcon from '@material-ui/icons/Favorite';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ArrowRightTwoToneIcon from '@material-ui/icons/ArrowRightTwoTone';
import { makeStyles } from "@material-ui/styles";
import assets from "../../../assets";
import { Image, Root, VerticalCenter, ImageGrid, LoginCard } from "../login/LoginPage.styled";
import MyAppBar from "../../common/myappbar/MyAppBar";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { TextInput } from "../../common/FormInputs";
import LockIcon from "@material-ui/icons/Lock";

import SvgIcon from "@material-ui/core/SvgIcon";
import { ImageIcon, StyledLink } from "components/common/Misc";
import { authenticateAction, registerAction, generateOtpAction, verifyOtpAction,socialLoginAction } from "services/endpoints/usermanagement/userManagementActions";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import SocialLogin from "components/common/SocialLogin/SocialLogin";
import UserManager from "utils/UserManager";
import StorageManager from "utils/StorageManager";
import MainContainer from "../../common/MainContainer";
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import AlgoliaPlaces from 'algolia-places-react';
import Select from 'react-select';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader';
import './resume.css';
import { updateBasicInfoAction } from "services/endpoints/jobs/jobActions";

import lodash from "lodash";
import { DropzoneArea } from "material-ui-dropzone";
import { JobService } from "services/endpoints/jobs/JobService";

import { getSkillsAction, getIndustriesAction, getCandidateDetailsAction } from "services/endpoints/jobs/jobActions";
 
const Example = ({candidate}) => {
  
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const { data: candidateData } = useSelector(state => state.job.candidate);
    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }
    
    return (
      
  

<Grid item sm={12} xs={12} lg={8}>

  <Box align="center">
  <PersonalDetailsForm candidate={candidateData} />

   </Box>


</Grid>

        );
}

const PersonalDetailsForm = ({   }) => {
  const resumeRef = useRef({});
  //const [state, increment, decrement] = useContext(OnBoarding);
  const dispatch = useDispatch();
  const { loading, data, error } = useSelector(state => state.job.basicInfo);
  const { data: candidateData } = useSelector(state => state.job.candidate);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [buttonText, setButonText] = useState('Update');
  const updateBasicInfo = values => {
    dispatch(updateBasicInfoAction(values));
  };
 useEffect(() => {
    dispatch(getCandidateDetailsAction(StorageManager.instance.getUid()));
  }, []);
  useEffect(() => {
    resumeRef.current = { fid: lodash.get(candidateData, "resume_fid", ""), path: lodash.get(candidateData, "resume_url", "") };
  }, [candidateData]);

  useEffect(() => {
    if (error) {
      toast.error("Error, try again later.");
    }
    if (data) {
      toast.success("Updated successfully.");
      const uid = StorageManager.instance.getUid();
      dispatch(getCandidateDetailsAction(uid));
    
      //increment();
    }
  }, [data, error]);

  return (
    <Formik
    enableReinitialize={true}
    initialValues={{
      resume_fid: lodash.get(candidateData, "resume_fid", ""),
      first_name: lodash.get(candidateData, "first_name", ""),
     
    }}
    validationSchema={Yup.object().shape({
      resume_fid: Yup.string()
        .required("This field is required")
        .nullable()
    })}
    onSubmit={(values, actions) => {
      values["uid"] = StorageManager.instance.getUid();

      console.log(values);
      const uid = StorageManager.instance.getUid();
      let user = {
          "uid":values.uid,
          "fid":values.resume_fid
    
      }
      const headers = {
          'Content-Type': 'application/json'
        }
      axios.post(`https://live-corp2corp.pantheonsite.io/v2/services/resume-edit`,user, {  headers: headers })
    .then(res => {
      console.log(res);
      window.location.href="/resume-edit/1";
    })
      return;
      updateBasicInfo(values);
      console.log(values);
      //return;
      window.location.href="/resume-edit/1";
    }}
  >
    
    {({ errors, touched, handleSubmit,props, isSubmitting, values, setFieldValue }) => {
       
     
      return (
       
        <Grid container style={{ backgroundColor: "#F7F7F7", justifyContent:"center" }}>
        <Form name="login" method="post" onSubmit={handleSubmit} autocomplete="off">
          <Grid container spacing={3}>
           
            <Grid item xs={12}>
              <Typography>Resume :</Typography>
             
                <React.Fragment>
                  <DropzoneArea
                    acceptedFiles={[
                      "application/pdf",
                      "application/msword",
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ['docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
                    ]}
                    showAlerts={false}
                    onDrop={file => {
                      const reader = new FileReader();
                      reader.onload = () => {
                        // Do whatever you want with the file contents
                        const binaryStr = file;
                        console.log(binaryStr);
                        setButonText("Pleas wait uploading resume..");
                        JobService.uploadResume(binaryStr, progressEvent => {
                          const percent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                          setFileUploadProgress(percent);
                          if (percent == 100) {
                            toast.success("Resume uploaded successfully.");
                          }
                          console.log("percent", percent);
                        }).then(result => {
                          const fid = lodash.get(result, "fid.0.value");
                          console.log("Fid", result.uri[0].url);
                          // setResume({ fid: fid });
                          resumeRef.current = { fid: fid };
                          setFieldValue("resume_fid", fid);

      let uid = StorageManager.instance.getUid();
                          let user = 
                          {
                            "tasks": {
                              "import-my-file": {
                                "operation": "import/url",
                                "url": "https://live-corp2corp.pantheonsite.io/"+result.uri[0].url
                              },
                              "convert-my-file": {
                                "operation": "convert",
                                "filename":uid+".html",
                                "input": "import-my-file",
                                "output_format": "html"
                              },
                              "export-my-file": {
                                      "operation": "export/azure/blob",
                                      "input": "convert-my-file",
                                       "storage_account": "corp2corpfile",
                                     // "storage_access_key": "+MSfvjxoYF/gxaTR7WLPqS0iZiFe2kjP8HHFe4dQ1kQkS5lLz/tPGP9hkfL3pw/TPGT1ZZLdZ2nj463SZj6IAg==",
                                     "storage_access_key": "WkmI+OO8NHUb5uVgZOu3M7elXJZoAxy0ijBL/votoabHnex5TMCo034LLPB4RlTe7dvjmvDu8Ois9vvM2Z3ELw==",
                                      
                                     "sas_token": "",
                                      "container": "corp2corpfile",
                                      "blob": ""
                              }
                            }
                          }
                        const headers = {
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6Ijg1YWVkYzQyZjhjMDZiMzIzMjgyMTkxZWJiNjg1ZjhmYzM2NTVkZjFhZDU2YzliNjZiYjRjNjBkZmU0NTcwNmE1NjZkZTg3YzMwM2Y4N2I1In0.eyJhdWQiOiIxIiwianRpIjoiODVhZWRjNDJmOGMwNmIzMjMyODIxOTFlYmI2ODVmOGZjMzY1NWRmMWFkNTZjOWI2NmJiNGM2MGRmZTQ1NzA2YTU2NmRlODdjMzAzZjg3YjUiLCJpYXQiOjE1ODc3MjQyOTksIm5iZiI6MTU4NzcyNDI5OSwiZXhwIjo0NzQzMzk3ODk4LCJzdWIiOiI0MTg2NzA0MSIsInNjb3BlcyI6WyJ1c2VyLnJlYWQiLCJ1c2VyLndyaXRlIiwidGFzay5yZWFkIiwidGFzay53cml0ZSIsIndlYmhvb2sucmVhZCIsIndlYmhvb2sud3JpdGUiLCJwcmVzZXQucmVhZCIsInByZXNldC53cml0ZSJdfQ.INq4egGGUaPQOPvZ8dKYqxvCSuSMzbxvJk1uznpc_EtpCFTMprFoRqhKnPqjJwm3opaik5CpT0zam9Zc3fi07wmF5iQwCeG0OsDZyMmcwJcaVSsnS2N47hvj1aPjGifRk-CwueE1wEPTTaMXYpy55BgkKva6AlBGW5LKzmDlUiMXRgWLbNMSCG8UQcu379ts3VzDx2_tYPlNQn1mZbf-9wvAJYnBLXFzw6Va4Rj2mLr4qACReXn39WV3rJBXg4Zb-T3FuFyMSW9Vi-BrghxsArlxSTs6jf_v8vRCoTY_WhKtgQD-M99NZc8SOONg-Ctkzllkl8jWqHvv77_GPlkMtPNae_FUAiOJjrIQ49xeXdc1kHifw6_kZCne5BkmJsFvigXzMR2WVMNcE4Ec4vZLxsxHFOI75snei8TO_-KDio8iDnB1Imco5cKwS_aUeJwNxJEg85r-zzPm5PzLTKTUGDA96vYsr3dWHuQkAGnq7cyYZ-mN8HAw9dK1BAg4oe55d4vQikESaFLTflH1ExP7FMApC7WTYrgcIn6P-lIUobohmScQzbuYWdkjKSmz052GYF2wx3VxYHPcjOyNUUEufk1lUlsOpHv5Z52YujOLwJ6QWMA8cuQM0PY8wgXMw8Qyiabq8zmF-wpW8R5ZxiI5hR4nS3zML6301eVlNRt3jC8'
                        }
                        
                        axios.post(`https://api.cloudconvert.com/v2/jobs`,user, {  headers: headers })
                        .then(res => {
                          console.log(res);
                          console.log(res.data);
                          setButonText("Update Now");
                          //return;
                          //window.location.href="/resume-edit/1";
                         //// window.location.replace("/onboarding/1");
                        })
                        });
                      };
                      reader.readAsBinaryString(file);
                    }}
                    filesLimit={1}
                    showFileNames="true"
                    dropzoneText={"Drop your resume here (in pdf/word)"}
                  />
                  <LinearProgress variant="determinate" value={fileUploadProgress} />
                  <Typography color="error">{errors["resume_fid"] && errors["resume_fid"]}</Typography>
                </React.Fragment>
              

              {/* <Button >
              <label htmlFor="fileUpload">Upload resume(Optional)</label>
              <input id="fileUpload" type="file" />
            </Button> */}
              {/* <TextInput id="resume" placeholder="Choose Resume" labelText="" variant="outlined" /> */}
            </Grid>
            <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justify="center"
  
>

  <Grid item xs={4}>
  <Button type="submit" variant="contained" color="primary" style={{ marginTop: "1em", color: "white", width: "100%" }}>
                {buttonText}
              </Button>
  </Grid>   

</Grid> 
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid iitem xs={3}>
            
            </Grid>
          </Grid>
        </Form>
        </Grid>
 
      );
    }}
  </Formik>

    );
};
export default Example;