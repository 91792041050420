import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
///import { colourOptions } from './data';
import ReactTags from 'react-tag-autocomplete'
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBView,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
 
  MDBAnimation
} from 'mdbreact';
//import './ClassicFormPage.css';

import Box from "@material-ui/core/Box";
import { Grid, Hidden, Paper, Container, Typography, TextField, FormControl, InputAdornment, Icon, Button } from "@material-ui/core";

//import Footer from "./home/FooterComponent1"


import { ImageIcon, StyledLink } from "components/common/Misc";

import { toast } from "react-toastify";

import UserManager from "utils/UserManager";
import StorageManager from "utils/StorageManager";
//import MainContainer from "../../common/MainContainer";
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import AlgoliaPlaces from 'algolia-places-react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader';

import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  skills:{
    backgroundColor:"white"
  }
});
const options = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10+', label: '10+' },
  { value: '15+', label: '15+' },
  { value: '20+', label: '20+' },

];
const visa = [
  { value: '551', label: 'Cpt/opt' },
  { value: '551', label: 'Cpt/opt' },
  { value: '552', label: 'H1b' },
  { value: '553', label: 'H4 EAD' },
  { value: '554', label: 'L2 EAD' },
  { value: '555', label: 'GC' },
  { value: '556', label: 'USC' },
  { value: '557', label: 'TN' },
  { value: '558', label: 'Other' },

];
class ClassicFormPage extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    collapseID: '',
    fname: {
      value: "",
      valid: false
    },
    lname: {
      value: "",
      valid: false
    },
   
    skillValue:"",
    selectedOption: null,
    visaOption: null,
    async_test: [],
    email: {
      value: "",
      valid: false
    },
    password: {
      value: "",
      valid: false
    },
    city: {
      value: "",
      valid: false
    },
    skills: {
      value: "",
      valid: false
    },
    designation: {
      value: "",
      valid: false
    },
    upload:false,
    buttonText:"Register",
    disabled : false,
    state: {
      value: "",
      valid: false
    },
    location: {
      value: "",
      valid: false
    },
    fid: {
      value: "",
      valid: false
    },
    tags: [
      
    ],
    suggestions: ""
  };
}
  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }));
    handleDelete (i) {
      const tags = this.state.tags.slice(0)
      tags.splice(i, 1)
      this.setState({ tags })
    }
   
    handleAddition (tag) {
      const tags = [].concat(this.state.tags, tag)
      this.setState({ tags })
      console.log("tags",tags);
      let values = tags.map(function(skill){
        return skill.id;
      });
      //const sdata = Array.prototype.map.call(tags, function(item) { return item.id; }).join('","');
console.log("dtata",values);
this.setState({skillValue:values})

    }



  changeHandler = event => {
    this.setState({ [event.target.name]: { value: event.target.value, valid: !!event.target.value } });
  };
 
 
onChangeHandler=event=>{
  console.log(event.target.files[0]);

this.setState({ selectedFile:event.target.files[0] });
const token = StorageManager.instance.getAccessToken();
const csrf = StorageManager.instance.getXCSRFToken();
const uid = StorageManager.instance.getUid();
console.log(event.target.files[0].name);
const fileName = event.target.files[0].name;

console.log(fileName);
const headers = {
  'Content-Type': 'application/octet-stream',
  'Authorization': 'basic aGFuaXNoQHdpbm5vcHJvLmNvbTox',
  'X-CSRF-Token':csrf,
  'Content-Disposition':`file;filename="${fileName}"`
}
console.log(headers);
axios.post(`https://live-corp2corp.pantheonsite.io/file/upload/node/candidate_details/field_resume?_format=hal_json`, event.target.files[0] ,{
  headers: headers
})
.then(res => {
  localStorage.setItem('fid', res.data.fid[0].value);
  console.log("File Upload",res.data.fid[0].value);
})


}
handleChange1 = async_test => {
  this.setState(
    { async_test },
    () => console.log(`Option selected:`, this.state.async_test)
  );
};
  handleChange = selectedOption => {
    console.log("d",selectedOption.value);
    this.setState(
      { selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  handleChange2 = visaOption => {
    console.log("d",visaOption.value);
    this.setState(
      { visaOption },
      () => console.log(`Option selected:`, this.state.visaOption)
    );
  };
  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
    if(this.state.password.value == ""){
      toast.error("Please enter password", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    /*if(this.state.skills.value == ""){
      toast.error("Please enter skills", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }*/
    if(this.state.email.value == ""){
      toast.error("Please enter email", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
if(this.state.skillValue == ""){
      toast.error("Please enter key skills", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    if(this.state.visaOption == ""){
      toast.error("Please enter Work authentication types", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if(this.state.selectedOption == ""){
      toast.error("Please enter Work experiance", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    const exp = this.state.selectedOption;
     
    const visa = this.state.visaOption?this.state.visaOption.value:"";
   
    const keyskills = this.state.tags;
    const keyskills1 = this.state.skillValue;
    const fname = this.state.fname.value;
    const skills = "";
    const lname = this.state.lname.value;
    const email = this.state.email.value;
    const password = this.state.password.value;
    //const lname = this.state.lname;
    const designation = this.state.designation.value;
   
    const fid = this.state.fid;
    
    /*if (this.state.fid.value==="") {
      toast.error("Please wait ..!", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }*/
    const phone =  this.state.phone;
   // const locationRow = this.state.location;
    //const location = locationRow.suggestion.name+','+locationRow.suggestion.administrative+','+locationRow.suggestion.country;
    const locationRow = this.state.location;
    console.log("row",locationRow.value);
    const empty = locationRow.value;
    if(empty === ""){
      toast.error("Wrong location format. Please select from the list!", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    const location1 = empty === ""? "empty" : locationRow;
    const location = location1 =="empty" ? "":locationRow.suggestion.name+','+locationRow.suggestion.administrative+','+locationRow.suggestion.country;
   
    if (password==="" && location===""  && exp==="" && email==="" && fname==="" && visa === "") {
      toast.error("Required Fields..!", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    console.log("fname",fname);
    console.log("lname",lname);
    console.log("skills",this.state.skillValue);
    console.log("exp",exp);
    console.log("selectedOption",exp);
    console.log("designation",designation);
    //console.log("fid",fid);
    console.log("phone",phone);
    console.log("location",location);
    console.log("email",email);
    console.log("pass",password);
    let values = keyskills.map(function(skill){
      return skill.value;
    });
    console.log("values",values);
    if(visa === ""){
      toast.error("Please enter Work authentication types", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    //https://live-corp2corp.pantheonsite.io/services/register-candidate
    //alert(values);
    //alert("sas");
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const logout = StorageManager.instance.getLogoutToken();
    //const dispatch = useDispatch();
   

    UserManager.logout1();
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token':csrf
    }
    
    const data = {
      "first_name": fname,
      "last_name": lname,
      "email": email,
      "location": location,
      "designation": designation,
      "experience":exp,
      "mobile_number": phone,
      "password": password,
      "skills":keyskills1,
      "resume_fid": fid,
      "additional_skills":"",
      "visa_type":visa

    };
    
    console.log(data);
    //return;
    this.setState({disabled: true});
    axios.post(`https://live-corp2corp.pantheonsite.io/services/register-candidate`, data,{
      headers: headers
    })
    .then(response => { 
      console.log('succ',response)
      if(response.data.status === 0){
        this.setState({disabled: false});
        toast.error("This email is already registered. Want to sign in or recover your password?", {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      toast.success("Successfully Submitted !", {
        position: toast.POSITION.TOP_RIGHT
      });
      
      const data = {
        "name": email,
        "pass":password,
        withCredentials: true,
  
      };
      axios.post(`https://live-corp2corp.pantheonsite.io/user/login?_format=json`, data,{
        headers: headers,
       withCredentials: true
      })
      .then(res => {
        console.log("dsaasd",res);
     
        // StorageManager.instance.setAccessToken(result.access_token);
        //StorageManager.instance.setBasicToken('aGFuaXNoQHdpbm5vcHJvLmNvbTox');
        StorageManager.instance.setBasicToken(btoa(`${email}:${password}`));
        StorageManager.instance.setUid(res.data.current_user.uid);
        StorageManager.instance.setXCSRFToken(res.data.csrf_token);
        StorageManager.instance.setLogoutToken(res.data.logout_token);
        StorageManager.instance.setAccessToken(res.data.access_token);
         //Not in the array
        console.log("Success1employer",res.data);
     
          //Not in the array
          console.log("Success1employer",res.data.current_user.roles);
          window.location.replace("/progress");
        //console.log(res.data);
       // window.location.reload();
      })
      {/*const data = {"flag_id":"recruiter" ,"entity_type":"user", "entity_id":response.data.uid,"uid":uid};
      axios.post(`https://live-corp2corp.pantheonsite.io/api/flag?_format=json`, data,{
          headers: headers
      })
      .then(res => {
          console.log(res.data);
          return <Redirect to='/my-candidatesList'/>;
         
        })
        */}
       // const username = event.target.email.value;
        //const password = event.target.password.value
        //const loginAction = (username, password) => {
         
       // };
    })
  }

  render() {
    const { selectedOption,collapseID,visaOption } = this.state;
    const { classes } = this.props;
    const getUploadParams = (file) => {
      console.log("sasas",file);
      this.setState({
        buttonText: "Wait Resume Uploading ..."
    })
      return { url: 'https://httpbin.org/post' }
    }
  
    const handleChangeStatus = ({file, meta, remove }, status) => {
      if (status === 'headers_received') {
        console.log(meta)
        const token = StorageManager.instance.getAccessToken();
        const csrf = StorageManager.instance.getXCSRFToken();
        const uid = StorageManager.instance.getUid();
        console.log(file.name);
        const fileName = file.name;
        const headers = {
          'Content-Type': 'application/octet-stream',
          'Authorization': 'basic aGFuaXNoQHdpbm5vcHJvLmNvbTox',
          'X-CSRF-Token':csrf,
          'Content-Disposition':`file;filename="${fileName}"`
        }
        console.log(headers);
        axios.post(`https://live-corp2corp.pantheonsite.io/file/upload/node/candidate_details/field_resume?_format=hal_json`, file ,{
          headers: headers
        })
        .then(res => {
          localStorage.setItem('fid', res.data.fid[0].value);
          this.setState({fid:res.data.fid[0].value});
          console.log("File Upload",res.data.fid[0].value);
          this.setState({
            upload: true
        })
        })
        //remove()
      } else if (status === 'aborted') {
        console.log(`${meta.name}, upload failed...`)
      }
    }
   
    const overlay = (
      <div
        id='sidenav-overlay'
        style={{ backgroundColor: 'transparent' }}
        onClick={this.toggleCollapse('navbarCollapse')}
      />
    );
    const animatedComponents = makeAnimated();
    return (
      <div id='classicformpage'>
        <Router>
          <div>
            <MDBNavbar
              dark
              expand='md'
              scrolling
              fixed='top'
              style={{ marginTop: '0' }}
            >
              <MDBContainer>
                <MDBNavbarBrand>
              
                <img onClick={event => this.props.history.push('/')} src={require('images/bg.png')} />
             
                </MDBNavbarBrand>
                
                     </MDBContainer>
            </MDBNavbar>
            {collapseID && overlay}
          </div>
        </Router>

        <MDBView>
          <MDBMask className='d-flex justify-content-center align-items-center gradient' />
          <MDBContainer
            style={{ height: '100%', width: '100%', paddingTop: '3rem' }}
            className='mt-5   justify-content-center align-items-center'
          >
            <MDBRow>
              <MDBAnimation
                type='fadeInLeft'
                delay='.3s'
                className='white-text text-center text-md-left col-md-6 mt-xl-5 mb-5'
              >
                 <Hidden xsDown>
            <MDBRow className="mb-3">
            <MDBCol size="2">
                
                <img src={require('images/a.svg')} />
              </MDBCol>
              <MDBCol xl="10" md="11" size="10">
                <h5 className="font-weight-bold mb-3 white-text">Register</h5>
                <p className="white-text r-p">
                Register in Corp2Corp.Jobs and get connected with 1000s of our clients/recruiters who are on hiring spree.
                </p>
              </MDBCol>
            </MDBRow>

            
            <MDBRow className="mb-3">
            <MDBCol size="2">
            <img src={require('images/Group397.svg')} />
              
              </MDBCol>
              <MDBCol xl="10" md="11" size="10">
                <h5 className="font-weight-bold mb-3 white-text">Complete Your Profile</h5>
                <p className="white-text r-p">
                Fill your complete details and help our clients/recruiters find you with your skills. 
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-3">
              <MDBCol size="2">
            
                <img src={require('images/Group398.svg')} />
               {/*} <MDBIcon icon="share" size="lg" className="white-text" />*/}
              </MDBCol>
              <MDBCol xl="10" md="11" size="10">
                <h5 className="font-weight-bold mb-3 white-text"> Upload Resume</h5>
                <p className="white-text r-p">
                Upload your updated resume and keep it up to date. 
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-3">
              <MDBCol size="2">
              
                <img src={require('images/Group399.svg')} />
               {/*} <MDBIcon icon="share" size="lg" className="white-text" />*/}
              </MDBCol>
              <MDBCol xl="10" md="11" size="10">
                <h5 className="font-weight-bold mb-3 white-text"> Find Your Job</h5>
                <p className="white-text r-p">
                Find and apply to jobs seamlessly and reach to our clients/recruiters on the go.  
                </p>
              </MDBCol>
            </MDBRow>
            </Hidden>
 {/*}
                <h1 className='h1-responsive font-weight-bold'>
                  Sign up right now!
                </h1>
                <hr className='hr-light' />
                <h6 className='mb-4'>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem
                  repellendus quasi fuga nesciunt dolorum nulla magnam veniam
                  sapiente, fugiat! Commodi sequi non animi ea dolor molestiae,
                  quisquam iste, maiores. Nulla.
                </h6>
                <MDBBtn outline color='white'>
                  Learn More
    </MDBBtn>*/}
              </MDBAnimation>

              <MDBCol md='6' xl='5' className='mb-4'>
                <MDBAnimation type='fadeInRight' delay='.3s'>
                  <MDBCard id='classic-card'>
                    <MDBCardBody className='black-text'>
                      <h3 className='text-center white-text'>
                        <MDBIcon icon='user' /> Register:
                      </h3>
                      <hr className='hr-light' />
      
  {/*
                      <div className='text-center mt-4 black-text'>
                        <MDBBtn color='indigo'>Sign Up</MDBBtn>
                        <hr className='hr-light' />
                        <div className='text-center d-flex justify-content-center white-label'>
                          <a href='#!' className='p-2 m-2'>
                            <MDBIcon
                              fab
                              icon='twitter'
                              className='white-text'
                            />
                          </a>
                          <a href='#!' className='p-2 m-2'>
                            <MDBIcon
                              fab
                              icon='linkedin'
                              className='white-text'
                            />
                          </a>
                          <a href='#!' className='p-2 m-2'>
                            <MDBIcon
                              fab
                              icon='instagram'
                              className='white-text'
                            />
                          </a>
                        </div>
                      </div>
                    */}
                    </MDBCardBody>
                  </MDBCard>
                </MDBAnimation>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBView>
        {/* <Footer /> */}
        <MDBContainer>
          {/*<MDBRow className='py-5'>
            <MDBCol md='12' className='text-center'>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </MDBCol>
</MDBRow>*/}

        </MDBContainer>
      </div>
    );
  }
}

export default withStyles(styles)(ClassicFormPage);