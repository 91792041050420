import React from "react";

const FullScreenLoader = () => {
  return (
    <div class="d-flex justify-content-center" style={{ flex: 1, alignItems: "center", position: "absolute", width: "100%", height: "100%" }}>
      <div class="spinner-grow text-warning" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default FullScreenLoader;
