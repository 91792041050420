import React, { useState, useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DialogBox({ isOpen, onSave, onCancel, fields }) {
  const [values, setValues] = useState({});
  
  useEffect(() => {
    if (fields) {
      let initialState = {};
      fields.forEach(f => {
        initialState[f.field]= f.value
      });
      setValues(initialState);
    }
  }, [fields])
  
  
  const updateField = (event) => {
    let newValues = {...values,[event.target.name]:event.target.value}
    setValues(newValues);
  };
  const handleClose = () => {
    onCancel();
  };

  const handleUpdate = () => {
    onSave(values);
  };

  let fieldEls = fields.map(f => {
    let addProps = f.type === "textarea" ? {
      multiline: true,
      rowsMax: 4
    } : {};
    return <TextField
      className="edit-field"
      label={f.label}
      name={f.field}
      value={values[f.field]}
      onChange={updateField}
      variant="outlined"
      {...addProps}
    />
  })

  return (
    <Dialog maxWidth="lg" onClose={handleClose} aria-labelledby="edit-dialog" open={isOpen}>
        <DialogTitle id="edit-dialog" onClose={handleClose}>
         Edit
        </DialogTitle>
        <DialogContent dividers>
         {fieldEls}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button autoFocus onClick={handleUpdate} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
  );
}
