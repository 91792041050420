import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import assets from "../../../assets";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(theme => ({
  container: {
    padding: "150px 0px"
  },
  bannerContainer: {
    padding: "60px 0px",
    backgroundImage: 'url("' + assets.images.bannerBackground + '")',
    backgroundPositionY: "75%",
    backgroundOrigin: "content-box",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "40px"
    }
  },
  uploadMessage: {
    fontFamily: "Helvetica",
    fontSize: "2em",
    textTransform: "uppercase",
    lineHeight: "1.1em",
    color: "white",
    fontWeight: "bold"
  },
  uploadButtonContainer: {
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left"
    }
  },
  uploadButton: {
    fontFamily: "Poppins",
    textTransform: "none",
    color: "black",
    backgroundColor: "white",
    borderRadius: "25px",
    padding: "10px 55px"
  },
  margin1: {
    width: "142px",
    marginRight: "10px",
    marginTop: "51px"
  },
  margin2: {
    width: "120px",
    marginLeft: "10px",
    marginTop: "51px"
  },
  text1: {
    textAlign: "center",
    fontFamily: "'Helvetica'",
    fontSize: "1.65em",
    marginTop: "90px"
  },
  downloadButtonContainer: {
    textAlign: "center"
  }
}));
const BannerComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.container}>
      <Grid container alignItems="center" className={classes.bannerContainer}>
        <Grid item xs={12} sm={2} />
        <Grid item xs={12} sm={4}>
          <p className={classes.uploadMessage}>Start Your Carrier By Uploading Your Resume</p>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.uploadButtonContainer}>
          <Button
            className={classes.uploadButton}
            onClick={() => {
              history.push("/onboarding");
            }}
          >
            Upload CV
          </Button>
        </Grid>
      </Grid>

      {/* <p className={classes.text1}>Download our app</p> */}
      {/* <Grid container>
                <Grid item xs={12} sm={6}>
                    <img src ={assets.images.googleplay} className={classes.logo+" "+classes.margin1}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <img src ={assets.images.applestore} className={classes.logo+" "+classes.margin2}/>
                </Grid>
            </Grid> */}
      {/* <div className={classes.downloadButtonContainer}>
                <img src ={assets.images.googleplay} className={classes.logo+" "+classes.margin1}/>
                <img src ={assets.images.applestore} className={classes.logo+" "+classes.margin2}/>
            </div> */}
    </div>
  );
};

export default BannerComponent;
