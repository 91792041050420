import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import MainContainer from "../../common/MainContainer/MainContainer";
import { Image, Root, VerticalCenter, ImageGrid, LoginCard } from "../login/LoginPage.styled";
import { Grid, Hidden, Paper, Container, Typography, TextField, FormControl, InputAdornment, Icon, Button } from "@material-ui/core";
import { toast } from "react-toastify";
import StorageManager from "utils/StorageManager";
import { useHistory } from "react-router-dom";
import axios from 'axios';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function LinearBuffer(props) {
  const classes = useStyles();
  const [completed, setCompleted] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);
  const history = useHistory();

useEffect(() => {
    const headers = {
        'Content-Type': 'application/json'
      }
      //const uid = StorageManager.instance.getUid();
      //alert(props.match.params.id);
      let user ={
          "uid":props.match.params.id
      }
    axios
      .post('https://live-corp2corp.pantheonsite.io/services/verify',user, {  headers: headers })
      .then(response => { toast.warn("Please login to continue");
      history.push("/jobseekers/login");});
  }, []);
  const progress = React.useRef(() => {});
  React.useEffect(() => {
    progress.current = () => {
      if (completed > 100) {
        setCompleted(0);
        setBuffer(10);
        return;
      } else {
       /* const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setCompleted(completed + diff);
        setBuffer(completed + diff + diff2);*/
      }
    };
  });

  React.useEffect(() => {
    function tick() {
      progress.current();
    }
    const timer = setInterval(tick, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
      <MainContainer>
    <div className={classes.root}>
    <Container style={{ padding: "8vw 0px" }}>
<Grid container component="main" justify="center">
<LoginCard elevation={12}>
       <Typography align="center" variant="h6" style={{ padding: "40px", fontFamily: "'Poppins', sans-serif" }}>
      Successfully Verified.
       </Typography>
       </LoginCard>

    </Grid>
    </Container>
      </div>
    </MainContainer>
  );
}
