import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import OtpInput from "react-otp-input";
import { Grid, Box } from "@material-ui/core";
const OtpVerification = ({ open, handleClose, onSubmit }) => {
  const [otp, setOtp] = useState("");
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Verify security code</DialogTitle>
      <DialogContent>
        <DialogContentText>Please enter security code</DialogContentText>
      </DialogContent>
      <Box marginX={12} marginBottom={4}>
        <Grid container justify="center">
          <Grid item>
            <OtpInput
              onChange={otp => setOtp(otp)}
              numInputs={6}
              value={otp}
              isInputNum={true}
              separator={<span>-</span>}
              inputStyle={{ width: "2.5rem", height: "2.5rem", margin: "5px", border: ".5px solid #E5E5E5", color: "#58514B" }}
            />
          </Grid>
        </Grid>
      </Box>
      <DialogActions>
        {/* <Button onClick={handleClose}>Cancel</Button> */}
        <Button
          onClick={() => {
            onSubmit && onSubmit(otp);
          }}
          color="primary"
          style={{ borderRadius:"10px"}}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OtpVerification;
