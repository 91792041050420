import React from 'react'
import Button from '@material-ui/core/Button'
import SocialLogin from 'react-social-login'
import { Grid } from "@material-ui/core";
import { MDBIcon, MDBContainer, MDBBtn } from 'mdbreact';

class SocialButton extends React.Component {

    render() {
        return (
          
        <Grid item xs={12}>
            <MDBBtn  style={{ textAlign: 'center'}} variant="contained" onClick={this.props.triggerLogin} {...this.props}>
              { this.props.children }
            </MDBBtn>
            </Grid>
            
        );
    }
}

export default SocialLogin(SocialButton);