import React, { useState } from "react";
import { makeStyles, Typography, GridList, GridListTile, Box } from "@material-ui/core";
import assets from "../../../../assets";
import { Grid } from "@material-ui/core";
import lodash from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(theme => ({
  categoryCarouselContainer: {
    padding: "0px 20px",
    display: "grid",
    gridTemplateColumns: "10vw 1fr 10vw"
  },
  carouselContainer: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.

    // overflow: '-moz-scrollbars-none',
    // '-ms-overflow-style': 'none',
    "&::-webkit-scrollbar": {
      width: "0 !important"
    },
    scrollBehavior: "smooth"
  },
  categoryCardContainer: {
    cursor:"pointer",
    backgroundColor: "transparent",
    border: "none",
    "&:hover": {
      background: "#0E2677"
    }
  },

  left: {
    margin: "auto",
    width: "100%",
    textAlign: "right",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "50%",
    backgroundPositionX: "80%"
    // backgroundImage: 'url("' + assets.images.navLeftArrow + '")'
  },
  right: {
    margin: "auto",
    width: "100%",
    textAlign: "left",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "50%",
    backgroundPositionX: "20%"
    // backgroundImage: 'url("' + assets.images.navRightArrow + '")'
  },
  leftAnchor: {
    margin: "auto",
    paddingRight: "20px"
  },
  rightAnchor: {
    margin: "auto",
    paddingLeft: "20px"
  }
}));

const CategoryCarousel = ({ categories, ...props }) => {
  const classes = useStyles();
  const history = useHistory();
  const [selCategory, setSelCategory] = useState(0);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const mediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getColCount = () => {
    if (smallScreen) return 3;
    if (mediumScreen) return 6;
    return 8;
  };

  const ref = React.createRef();

  return (
    <Grid container alignItems="center" style={{ border: "1px solid #f00 !important" }}>
      <Grid xs={2} justify="center" item container>
        <a
          onClick={e => {
            e.preventDefault();
            ref.current.scrollLeft -= 150;
          }}
        >
          <img src={assets.images.navLeftArrow}></img>
        </a>
      </Grid>

      <Grid item xs={8}>
        <GridList className={classes.carouselContainer} cols={getColCount()} cellHeight="auto" ref={ref}>
         {/* {lodash.isArray(categories) &&
            categories.map((category, index) => {
              return (
                <CategoryCard
                  className={classes.categoryCardContainer}
                  selected={selCategory === index}
                  name={category.name}
                  jobs={category.total_jobs}
                  image={category.image_url}
                  onClick={() => {
                    setSelCategory(index);
                       window.location.href = "/jobs?location=&title="+category.name);
                  }}
                />
              );
            })}
          */}
             <CategoryCard
                  className={classes.categoryCardContainer}
                  //selected={selCategory === index}
                  name="Information Technology"
                  //jobs={category.total_jobs}
                  image={require('../../../../images/001-laptop.svg')}
                  onClick={() => {
                    //setSelCategory(index);
                       window.location.href = "/jobs?location=&title=&category=13";
                  }}
                />
                 <CategoryCard
                  className={classes.categoryCardContainer}
                  //selected={selCategory === index}
                  name="Health Care"
                  //jobs={category.total_jobs}
                  image={require('../../../../images/002-stethoscope.svg')}
                  onClick={() => {
                    //setSelCategory(index);
                       window.location.href = "/jobs?location=&title=&category=14";
                  }}
                />
                 <CategoryCard
                  className={classes.categoryCardContainer}
                  //selected={selCategory === index}
                  name="Public administration"
                  //jobs={category.total_jobs}
                  image={require('../../../../images/003-team.svg')}
                  onClick={() => {
                    //setSelCategory(index);
                       window.location.href = "/jobs?location=&title=&category=15";
                  }}
                />
                 <CategoryCard
                  className={classes.categoryCardContainer}
                  //selected={selCategory === index}
                  name="Education Services"
                  //jobs={category.total_jobs}
                  image={require('../../../../images/004-online-course.svg')}
                  onClick={() => {
                    //setSelCategory(index);
                       window.location.href = "/jobs?location=&title=&category=16";
                  }}
                />
                 <CategoryCard
                  className={classes.categoryCardContainer}
                  //selected={selCategory === index}
                  name="Wholesale and retail trade"
                  //jobs={category.total_jobs}
                  image={require('../../../../images/005-market.svg')}
                  onClick={() => {
                    //setSelCategory(index);
                    window.location.href = '/jobs?location=&title=&category=17';
                    //   window.location.href = "/jobs?location=&title=&category=17");
                  }}
                />
                 <CategoryCard
                  className={classes.categoryCardContainer}
                  //selected={selCategory === index}
                  name="Manufacturing"
                  //jobs={category.total_jobs}
                  image={require('../../../../images/006-manufacturing.svg')}
                  onClick={() => {
                    //setSelCategory(index);
                    window.location.href = "/jobs?location=&title=&category=18";
                  }}
                />
                 <CategoryCard
                  className={classes.categoryCardContainer}
                  //selected={selCategory === index}
                  name="Leisure and Hospitality"
                  //jobs={category.total_jobs}
                  image={require('../../../../images/007-hospitality.svg')}
                  onClick={() => {
                    //setSelCategory(index);
                       window.location.href = "/jobs?location=&title=&category=19";
                  }}
                />
                   <CategoryCard
                  className={classes.categoryCardContainer}
                  //selected={selCategory === index}
                  name="Construction"
                  //jobs={category.total_jobs}
                  image={require('../../../../images/008-crane.svg')}
                  onClick={() => {
                    //setSelCategory(index);
                       window.location.href = "/jobs?location=&title=&category=20";
                  }}
                />
                   <CategoryCard
                  className={classes.categoryCardContainer}
                  //selected={selCategory === index}
                  name="Financial activities"
                  //jobs={category.total_jobs}
                  image={require('../../../../images/009-cash-flow.svg')}
                  onClick={() => {
                    //setSelCategory(index);
                       window.location.href = "/jobs?location=&title=&category=21";
                  }}
                />
                   <CategoryCard
                  className={classes.categoryCardContainer}
                  //selected={selCategory === index}
                  name="Transportation and utilities"
                  //jobs={category.total_jobs}
                  image={require('../../../../images/010-airplane.svg')}
                  onClick={() => {
                    //setSelCategory(index);
                       window.location.href = "/jobs?location=&title=&category=22";
                  }}
                />
                 <CategoryCard
                  className={classes.categoryCardContainer}
                  //selected={selCategory === index}
                  name="Other services"
                  //jobs={category.total_jobs}
                  image={require('../../../../images/other.svg')}
                  onClick={() => {
                    //setSelCategory(index);
                       window.location.href = "/jobs?location=&title=&category=23";
                  }}
                />
                 {/* <CategoryCard
                  className={classes.categoryCardContainer}
                  //selected={selCategory === index}
                  name="Data Science"
                  //jobs={category.total_jobs}
                  image={require('../../../../images/006-report.svg')}
                  onClick={() => {
                    //setSelCategory(index);
                       window.location.href = "/jobs?location=&title=");
                  }}
                />
                  <CategoryCard
                  className={classes.categoryCardContainer}
                  //selected={selCategory === index}
                  name="Other services"
                  //jobs={category.total_jobs}
                  image={require('../../../../images/other.svg')}
                  onClick={() => {
                    //setSelCategory(index);
                       window.location.href = "/jobs?location=&title=");
                  }}
                />*/}
        </GridList>
      </Grid>

      <Grid xs={2} justify="center" item container>
        <a
          className={classes.rightAnchor}
          href=""
          onClick={e => {
            e.preventDefault();
            ref.current.scrollLeft += 150;
          }}
        >
          <img src={assets.images.navRightArrow}></img>
        </a>
      </Grid>
    </Grid>
  );
};

const useCardStyles = makeStyles(theme => ({
  jobCountContainer: {
    padding: "0px 5px 15px 5px",
    textAlign: "right"
  },
  jobCount: {
    padding: "2px 5px",
    borderRadius: "10px",
    backgroundColor: "transparent",
    fontSize: "9px",
    color: "transparent"
  },
  jobCountSelected: {
    color: "white",
    backgroundColor: "#13C1D6"
  },
  categoryImageContainer: {
    textAlign: "center"
  },
  categoryIcon: {
    maxWidth: "40px",
    objectFit: "cover",
    height: "40px"
  },
  categoryName: {
    textAlign: "center",
    color: "#13C1D6",
    display: "block",
    margin: "10px 0px",
    wordWrap: "break-word"
  }
}));
const CategoryCard = ({ name, image, jobs, selected, style, className, onClick, ...rest }) => {
  const classes = useCardStyles();
  return (
    <GridListTile
      style={{ ...style, backgroundColor: `${selected ? "#0E2677" : "transparent"}` }}
      className={className}
      onClick={onClick}
      justify
      {...rest}
    >
      <div className={classes.jobCountContainer}>
        <span className={classes.jobCount + (selected ? " " + classes.jobCountSelected : "")}>{jobs + "+ jobs"}</span>
      </div>
      <div className={classes.categoryImageContainer}>
        <img className={classes.categoryIcon} src={image} />
      </div>

      <Typography className={classes.categoryName} variant="caption" align="center" style={{ fontFamily: "Poppins, sans-serif",fontSize:".9em" }}>
        {name}
      </Typography>
    </GridListTile>
  );
};

export default CategoryCarousel;
