import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  PhoneAndroidOutlined,
  PersonOutlineOutlined,
  MailOutline,
  Lock
} from "@material-ui/icons";
import { Button, LinearProgress, Grid } from "@material-ui/core";

import { Formik, Form, FastField } from "formik";

import * as Yup from "yup";
import { toast } from "react-toastify";
import { FormikTextInput } from "components/common/FormInputs/FormikInputs";

import { authenticateAction } from "services/endpoints/usermanagement/userManagementActions";
import UploadResume from "./UploadResume";
import Overlay from "./Overlay";

import { REGISTRATION_COMPLETE } from "constants/actionTypes";

const RegisterForm = ({
  onSubmitForm,
  onBackButton,
  registerData,
  loading,
  finalSubmitStatus
}) => {
  const formRef = useRef(null);
  const [uploadResume, setUploadResume] = useState(false);
  const [isFinalSubmit, setIsFinalSubmit] = useState(false);
  const [isEnterManually, setIsEnterManually] = useState(false);
  const [finalSubmitMessages, setFinalSubmitMessages] = useState([]);
  const { data: loginDetails } = useSelector(
    state => state.userManagement.authentication
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (finalSubmitStatus == "success") {
      setFinalSubmitMessages(["User created successfully"]);
      setUploadResume(true);
    } else if (finalSubmitStatus == "failed") {
      setIsFinalSubmit(false);
      setFinalSubmitMessages([]);
    }
  }, [finalSubmitStatus]);

  useEffect(() => {
    if (loginDetails) {
      if (loginDetails.access_token) {
        if (isEnterManually) {
          history.push("/onboarding/1");
        } else {
          dispatch({ type: REGISTRATION_COMPLETE });
        }
      } else {
        setIsFinalSubmit(false);
        setFinalSubmitMessages([]);
        toast.error(loginDetails.messages);
      }
    }
  }, [loginDetails]);

  const handleFormSubmit = (values, actions) => {
    onSubmitForm(values);
    setIsFinalSubmit(true);
  };

  const goBack = () => {
    
  };

  const onUpdateResumeUploadStatus = (status, more) => {
    if (status === "no_resume") {
      setUploadResume(false);
      dispatch(authenticateAction(registerData.email, registerData.password));
    } else if (status === "inprogress") {
      //more.percentage
    } else if (status === "complete") {
      setFinalSubmitMessages([
        ...finalSubmitMessages,
        "resume Upload completed"
      ]);
    } else if (status === "fid_updated") {
      setUploadResume(false);
      dispatch(authenticateAction(registerData.email, registerData.password));
      setFinalSubmitMessages([
        ...finalSubmitMessages,
        "resume processed successfully"
      ]);
    } else if (status === "enter_manually") {
      formRef.current.click();
      setIsEnterManually(true);
    }
  };

  let validationSchema = {
    fname: Yup.string().required("Please enter your name"),
    password: Yup.string()
      .required("Please enter your password")
      .min(6, "Seems a bit short...")
  };
  if (
    registerData.registrationMode === "mobile_register" ||
    registerData.registrationMode === "email_register"
  ) {
    validationSchema.email = Yup.string()
      .email("Invalid email")
      .required("Please enter your email");
  } else {
    validationSchema.mobile = Yup.number()
      .required("Please enter your mobile number")
      .test(
        "len",
        "Please enter a valid mobile number",
        val => val && val.toString().length === 10
      );
  }

  let initialValus = {
    fname: registerData.fname,
    email: registerData.email,
    mobile: registerData.mobile
  };

  return (
    <Formik
      initialValues={initialValus}
      validationSchema={Yup.object().shape(validationSchema)}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit }) => {
        return (
          <>
            <Form name="register" method="post" onSubmit={handleSubmit}>
              <Grid container>
                <div className="social-register">
                  <FastField
                    style={{
                      marginBottom: "20px",
                      transition:
                        "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                      color: "#495057",
                      borderRadius: ".6rem"
                    }}
                    name="fname"
                    component={FormikTextInput}
                    fullWidth
                    id="fname"
                    size="small"
                    labelText="Enter Full Name"
                    placeholder="Enter your Full Name"
                    variant="outlined"
                    Icon={PersonOutlineOutlined}
                  />

                  {registerData.registrationMode === "mobile_register" ||
                  registerData.registrationMode === "email_register" ? (
                    <FastField
                      style={{
                        marginBottom: "20px",
                        transition:
                          "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                        color: "#495057",
                        borderRadius: ".6rem"
                      }}
                      name="email"
                      component={FormikTextInput}
                      fullWidth
                      id="email"
                      size="small"
                      labelText="Enter Email"
                      placeholder="Enter you Email ID"
                      variant="outlined"
                      Icon={MailOutline}
                    />
                  ) : (
                    <FastField
                      style={{
                        marginBottom: "20px",
                        transition:
                          "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                        color: "#495057",
                        borderRadius: ".6rem"
                      }}
                      name="mobile"
                      component={FormikTextInput}
                      fullWidth
                      type="number"
                      id="mobile"
                      size="small"
                      labelText="Enter US Mobile Number"
                      placeholder="Enter Your US Mobile Number"
                      variant="outlined"
                      Icon={PhoneAndroidOutlined}
                    />
                  )}

                  <FastField
                    style={{
                      marginBottom: "20px",
                      transition:
                        "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                      color: "#495057",
                      borderRadius: ".6rem"
                    }}
                    name="password"
                    component={FormikTextInput}
                    type="password"
                    fullWidth
                    id="password"
                    size="small"
                    labelText="Choose Your Password"
                    placeholder="Choose Your Password"
                    variant="outlined"
                    Icon={Lock}
                  />
                  <UploadResume
                    startUpload={uploadResume}
                    uid={registerData.uid}
                    authorizationTocken={registerData.basicToken}
                    onUpdateUploadStatus={onUpdateResumeUploadStatus}
                  ></UploadResume>
                </div>
              </Grid>
              <Grid container>
                <Grid xs={6}>
                  <a className="back-button" onClick={onBackButton}>
                    Go Back
                  </a>
                </Grid>
                <Grid xs={6}>
                  <Button
                    type="submit"
                    fullWidth
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    ref={formRef}
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                      marginTop: "1em",
                      color: "white",
                      borderRadius: "10px"
                    }}
                  >
                    SUBMIT
                  </Button>
                  {loading && <LinearProgress />}
                </Grid>
              </Grid>
            </Form>
            <Overlay
              show={isFinalSubmit}
              messages={finalSubmitMessages}
            ></Overlay>
          </>
        );
      }}
    </Formik>
  );
};

export default RegisterForm;
