
import React, { useState, useEffect } from "react";
import { Box,CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UserManager from "utils/UserManager";
import { LOGIN_FACEBOOK_APP_ID } from "constants/generalConstants";
import { loginWithSocialAction } from "services/endpoints/usermanagement/userManagementActions";
import FacebookButton  from "./FacebookButton"
import { handleSocialLoginSuccess,handleSocialLoginFailure } from "./socialLoginHelper"

const FacebookLogin = ({ onLoginSuccess,onLoginFailed,isRegister }) => {
  const [isLoading, setLoading] = useState(false);
  const { data: loginResponse, error } = useSelector(
    state => state.userManagement.loginWithFacebook
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (loginResponse) {
      const redirect = handleSocialLoginSuccess(loginResponse, isRegister, onLoginSuccess,"facebook",dispatch);
      if (redirect) {
        history.push(redirect);
      }
      setLoading(false);  
    }
    if (error) {
      handleSocialLoginFailure("facebook");
    }
  }, [loginResponse, error]);
  
  const onSuccess = (response) => {
    UserManager.logout1();
    setLoading(true);
    dispatch(loginWithSocialAction(response._token.accessToken,'facebook'));
  }

  const onFailure = (error) => {
    handleSocialLoginFailure("facebook");
    setLoading(false);
  }
  return (
    <Box className="social-login-item">
      {!isLoading ?
        <FacebookButton
        provider='facebook'
        appId={LOGIN_FACEBOOK_APP_ID}
        onLoginSuccess={onSuccess}
        onLoginFailure={onFailure}
        /> :
        <CircularProgress />
      }
    </Box>
  );
};
export default FacebookLogin;