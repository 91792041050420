import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import { Grid, Hidden, Paper, Container, Typography, TextField, FormControl, InputAdornment, Icon, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import assets from "../../../assets";
import { Image, Root, VerticalCenter, ImageGrid, LoginCard } from "../login/LoginPage.styled";
import MyAppBar from "../../common/myappbar/MyAppBar";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { TextInput } from "../../common/FormInputs";
import LockIcon from "@material-ui/icons/Lock";
import MainContainer from "../../common/MainContainer";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ImageIcon, StyledLink } from "components/common/Misc";
import { BrowserRouter as Router } from 'react-router-dom';
import { authenticateAction, registerAction, generateOtpAction, verifyOtpAction } from "services/endpoints/usermanagement/userManagementActions";
import RegisterForm from "./RegisterForm";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import OtpVerification from "./otpverification/OtpVerification";
import SocialLogin from "components/common/SocialLogin/SocialLogin";
import LinkedInPage from "components/common/SocialLogin/index1";
import SocialFacebook from "components/common/SocialLogin/Social";

import UserManager from "utils/UserManager";
import Footer from "../home/FooterComponent1";
import axios from 'axios';
import StorageManager from "utils/StorageManager";

import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavItem,
    MDBNavLink,
    MDBNavbarToggler,
    MDBCollapse,
    MDBMask,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBBtn,
    MDBView,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBInput,
    MDBFormInline,
    MDBAnimation
  } from 'mdbreact';
  import './ClassicFormPage.css';
const RegistrationPage = () => {
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const userInfo = useRef({});
  const dispatch = useDispatch();

  const { loading, data, error } = useSelector(state => state.userManagement.register);
  const { loginLoading, data: loginData, error: loginError } = useSelector(state => state.userManagement.authentication);
  const { data: generateOtpData, error: generateOtpError } = useSelector(state => state.userManagement.generateOtp);
  const { data: verifyOtpData, error: verifyOtpError } = useSelector(state => state.userManagement.verifyOtp);

  const history = useHistory();
  useEffect(() => {
    if (error) {
      toast.error("Error, try again later");
    } else if (data) {
      login(userInfo.current.email, userInfo.current.password);
    } else if (generateOtpData && generateOtpData.status === 1) {
      toast.success("Please enter the OTP you received on your email to complete the registration process.");
      setOpenOtpModal(true);
    } else if (generateOtpData && generateOtpData.status === 0) {
      toast.error(generateOtpData.message);
    } else if (verifyOtpData && verifyOtpData.status === 1) {
      register(userInfo.current.email, userInfo.current.password, userInfo.current.role, userInfo.current.mobile, userInfo.current.fname);
    } else if (verifyOtpData && verifyOtpData.status === 0) {
      toast.error("Error, Invalid otp");
    } else if (loginData) {
      toast.success("Successfully Registered. Please complete your onboarding process.");
      if (UserManager.isCandidate()) {
        history.push("/onboarding");
      } else if (UserManager.isRecruiter()) {
        {
          history.push("/add-candidate");
        }
      }
    }
  }, [data, error, loginData, loginError, generateOtpData, generateOtpError, verifyOtpData, verifyOtpError]);

  const handleOtpClose = () => {
    setOpenOtpModal(false);
  };

  const login = (email, password) => {
    dispatch(authenticateAction(email, password));
  };

  const register = (email, password, role, mobile, fname, mobile1) => {
    dispatch(registerAction(email, password, role, mobile, fname, mobile1));
    //return;
  };
const SocialRegister=(email, password, role, mobile, fname) => {
  console.log("d",mobile.length );

if(mobile.length > 6 && mobile.length < 11) {  
  toast.error("Please enter valid mobile.");
}
localStorage.setItem("socialPhone","");
    const csrf = StorageManager.instance.getXCSRFToken();
    const uid = StorageManager.instance.getUid();
    const logout = StorageManager.instance.getLogoutToken();
    //const dispatch = useDispatch();
   

    UserManager.logout1();
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token':csrf
    }
    
    const data = {
      "fname": fname,
      "email": email,

      "mobile": mobile,
      "password": password

    };
    
    console.log(data);
    //return;
    //this.setState({disabled: true});
    axios.post(`https://live-corp2corp.pantheonsite.io/services/social-register`, data,{
      headers: headers
    })
    .then(response => { 
      console.log('succ',response)
      if(response.data.status === 0){
        //this.setState({disabled: false});
        toast.error("This email is already registered. Want to sign in or recover your password?");
        return;
      }
      toast.success("Successfully Registered Please verify your email id!", {
        position: toast.POSITION.TOP_RIGHT
      });
      
      const data = {
        "name": email,
        "pass":password,
        withCredentials: true,
  
      };
      axios.post(`https://live-corp2corp.pantheonsite.io/user/login?_format=json`, data,{
        headers: headers,
       withCredentials: true
      })
      .then(res => {
        console.log("dsaasd",res);
     
        // StorageManager.instance.setAccessToken(result.access_token);
        //StorageManager.instance.setBasicToken('aGFuaXNoQHdpbm5vcHJvLmNvbTox');
        StorageManager.instance.setBasicToken(btoa(`${email}:${password}`));
        StorageManager.instance.setUid(res.data.current_user.uid);
        StorageManager.instance.setXCSRFToken(res.data.csrf_token);
        StorageManager.instance.setLogoutToken(res.data.logout_token);
        StorageManager.instance.setAccessToken(res.data.access_token);
         //Not in the array
        console.log("Success1employer",res.data);
          console.log("Success1employer",res.data.current_user.roles);
          window.location.replace("/onboarding/1");  
      })
    
    })
  console.log("hghggh",email+ password+ "candidate"+ mobile+ fname+mobile)
}
  const generateOtp = (email, password, role, mobile, fname) => {
    userInfo.current = { email, password, role, mobile, fname };
    dispatch(generateOtpAction(email, "Register"));
  };

  const verifyOtp = otp => {
    dispatch(verifyOtpAction(otp, userInfo.current.email, "Register"));
  };

  return (

      <div id='classicformpage'>
<Router>
  <div>
    <MDBNavbar
      dark
      expand='md'
      scrolling
      fixed='top'
      style={{ marginTop: '0' }}
    >
      <MDBContainer>
        <MDBNavbarBrand>
        <StyledLink to="/">
        <img onClick={event => history.push('/')} src={require('./images/logowhite.svg')} />
        </StyledLink>
        </MDBNavbarBrand>
        
             </MDBContainer>
    </MDBNavbar>
 
  </div>
</Router>

<MDBView>
  <MDBMask className='d-flex justify-content-center align-items-center gradient' />
  <MDBContainer
    style={{ height: '100%', width: '100%', paddingTop: '3rem' }}
    className='mt-5   justify-content-center align-items-center'
  >
    <MDBRow>
      <MDBAnimation
        type='fadeInLeft'
        delay='.3s'
        className='white-text text-center text-md-left col-md-6 mt-xl-5 mb-5'
      >
         <Hidden xsDown>
    <MDBRow className="mb-3">
    <MDBCol size="2">
        
        <img src={require('./images/a.svg')} />
      </MDBCol>
      <MDBCol xl="10" md="11" size="10">
        <h5 className="font-weight-bold mb-3 white-text">Register</h5>
        <p className="white-text r-p">
        Register in Corp2Corp.Jobs and get connected with 1000s of our clients/recruiters who are on hiring spree.
        </p>
      </MDBCol>
    </MDBRow>

    
    <MDBRow className="mb-3">
    <MDBCol size="2">
    <img src={require('./images/Group397.svg')} />
      
      </MDBCol>
      <MDBCol xl="10" md="11" size="10">
        <h5 className="font-weight-bold mb-3 white-text">Complete Your Profile</h5>
        <p className="white-text r-p">
        Fill your complete details and help our clients/recruiters find you with your skills. 
        </p>
      </MDBCol>
    </MDBRow>
    <MDBRow className="mb-3">
      <MDBCol size="2">
    
        <img src={require('./images/Group398.svg')} />
       {/*} <MDBIcon icon="share" size="lg" className="white-text" />*/}
      </MDBCol>
      <MDBCol xl="10" md="11" size="10">
        <h5 className="font-weight-bold mb-3 white-text"> Upload Resume</h5>
        <p className="white-text r-p">
        Upload your updated resume and keep it up to date. 
        </p>
      </MDBCol>
    </MDBRow>
    <MDBRow className="mb-3">
      <MDBCol size="2">
      
        <img src={require('./images/Group399.svg')} />
       {/*} <MDBIcon icon="share" size="lg" className="white-text" />*/}
      </MDBCol>
      <MDBCol xl="10" md="11" size="10">
        <h5 className="font-weight-bold mb-3 white-text"> Find Your Job</h5>
        <p className="white-text r-p">
        Find and apply to jobs seamlessly and reach to our clients/recruiters on the go.  
        </p>
      </MDBCol>
    </MDBRow>
    </Hidden>
{/*}
        <h1 className='h1-responsive font-weight-bold'>
          Sign up right now!
        </h1>
        <hr className='hr-light' />
        <h6 className='mb-4'>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem
          repellendus quasi fuga nesciunt dolorum nulla magnam veniam
          sapiente, fugiat! Commodi sequi non animi ea dolor molestiae,
          quisquam iste, maiores. Nulla.
        </h6>
        <MDBBtn outline color='white'>
          Learn More
</MDBBtn>*/}
      </MDBAnimation>

      <MDBCol md='6' xl='5' className='mb-4'>
        <MDBAnimation type='fadeInRight' delay='.3s'>
        <MDBCard id='classic-card'>
                    <MDBCardBody className='black-text'>
                      <h3 className='text-center white-text'>
                         Join Corp2Corp.Jobs for FREE
                      </h3>
                      <hr className='hr-light' />
                      <SocialLogin style={{textAlign:"center"}} />
                      
                      <LinkedInPage   />
                      
                      <SocialFacebook  />
                      
                    
                    <hr className='hr-light' />
               {/*} <Typography align="center" variant="h6" style={{ fontFamily: "'Poppins', sans-serif" }}>
                  Register
                </Typography>
                <Typography align="center" variant="caption" display="block" style={{ fontFamily: "'Poppins', sans-serif", paddingTop: "10px" }}>
                  You are just a step away from your Dream Job
</Typography>*/}
                <Grid container item justify="center">
                  <FormControl style={{ marginTop: "2em", padding: "0px 10%" }} fullWidth>
                    <RegisterForm registerAction={SocialRegister} loading={loading || loginLoading} />
                  </FormControl>
                  <Box align="center" style={{ marginTop: "1em", width: "100%" }} maxWidth="sm">
                  <hr className='hr-light' />
                  
                   
                    <StyledLink to="/jobseekers/login">
                      <Button variant="outlined" fullWidth display="block" style={{ marginTop: "1em", marginBottom: "1em", width: "80%", border:"2px solid #fff", color:"#ffff",borderRadius: "10px" }}>
                        <Typography variant="caption" style={{ fontFamily: "'Poppins', sans-serif", padding: "6px" }} display="inline">
                          Login
                        </Typography>
                      </Button>
                    </StyledLink>
                  </Box>
                </Grid>
          </MDBCardBody>
          </MDBCard>
        
                </MDBAnimation>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
</MDBView>
<Footer />
<MDBContainer>
  {/*<MDBRow className='py-5'>
    <MDBCol md='12' className='text-center'>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
        enim ad minim veniam, quis nostrud exercitation ullamco laboris
        nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
        in reprehenderit in voluptate velit esse cillum dolore eu fugiat
        nulla pariatur. Excepteur sint occaecat cupidatat non proident,
        sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>
    </MDBCol>
</MDBRow>*/}

<OtpVerification
        open={openOtpModal}
        handleClose={handleOtpClose}
        onSubmit={otp => {
          verifyOtp(otp);
        }}
      />
    
</MDBContainer>

</div>

      
   
  );
};

export default RegistrationPage;