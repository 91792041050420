import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainContainer from "components/common/MainContainer";
import PersonalDetails from "./PersonalDetails";
import { Container, Typography, Grid,Hidden, Paper, Divider, Box } from "@material-ui/core";
import DoneTwoToneIcon from '@material-ui/icons/DoneTwoTone';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import PersonIcon from '@material-ui/icons/Person';
import FavoriteIcon from '@material-ui/icons/Favorite';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ArrowRightTwoToneIcon from '@material-ui/icons/ArrowRightTwoTone';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import AdditionalInformation from "./AdditionalInformation";
import Certifications from "./Certifications";
import InfoIcon from '@material-ui/icons/Info';
import Skills from "./Skills";
import {Helmet} from "react-helmet";
import WorkIcon from '@material-ui/icons/Work';
import IndeedResume from "./IndeedResume";
import WorkExperience from "./WorkExperience";
import { useParams, useHistory } from "react-router";
import { useSteps, OnBoarding } from "./useSteps";
import { getSkillsAction, getIndustriesAction, getCandidateDetailsAction } from "services/endpoints/jobs/jobActions";
import StorageManager from "utils/StorageManager";
import useMasterList from "utils/useMasterList";
import { useLocation } from "react-router-dom";
import lodash from "lodash";
const OnBoardingPage = (props) => {
  const location = useLocation();
  const [isRecruiter, setIsRecruiter] = useState(lodash.includes(location.pathname, "add-candidate"));
  const uid = StorageManager.instance.getUid();
  const { step } = useParams();
  const history = useHistory();
  const steps = useSteps(step || 1, 4);
  const [state] = steps;
  console.log("Location", location.pathname);
  useEffect(() => {
    if (isRecruiter) {
      history.push(`/add-candidate/${state.step}`);
    } else {
      history.push(`/onboarding/${state.step}`);
    }
  }, [state.step]);

  useEffect(() => {
    setIsRecruiter(lodash.includes(location.pathname, "add-candidate"));
  }, [location.pathname]);
  const dispatch = useDispatch();
  const { skills, industries } = useMasterList();
  const { data: candidateData } = useSelector(state => state.job.candidate);
  useEffect(() => {
    dispatch(getCandidateDetailsAction(uid));
  }, []);

  const getCurrentView = () => {
    switch (parseInt(step)) {
      case 1:
        return <WorkExperience candidate={candidateData} isRecruiter={isRecruiter} />;
        
      case 2:
        return <IndeedResume candidate={candidateData} isRecruiter={isRecruiter} />;
      case 3:
        return <PersonalDetails uid={uid} skills={skills} industries={industries} candidate={candidateData} isRecruiter={isRecruiter} />;
      // case 4:
      //   return <Skills candidate={candidateData} />;
      case 4:
        return <AdditionalInformation candidate={candidateData} isRecruiter={isRecruiter} />;
      // case 6:
      //   return <Certifications candidate={candidateData} />;
      default:
        return "Error";
    }
  };
  const stepId= parseInt(step);


  return (
    <OnBoarding.Provider value={steps}>
      <MainContainer>
        
      <Hidden only={['lg', 'md', 'sm', 'xl',]}>
        
      <React.Fragment>
               <Grid item sm={2} xs={12}>
            
               {stepId  === 1&& (

<div>      
  <Paper style={{  marginBottom: "10px",background:"#13C1D6" }}  fullWidth className="mpa">
   <Grid style={{ marginLeft: "4px" }} fullWidth >
   <Box p={3}>

   <Grid>
   <Divider orientation="vertical" />
   </Grid>
   <Typography style={{color: "white"}}><PersonIcon style={{ marginRight: "8px" }}/>Work Experience</Typography>
   </Box>
   </Grid>
</Paper>
<Hidden only={['lg', 'md', 'sm', 'xl',]}>
        <Grid item sm={10} xs={10} style={{marginLeft:"-11px"}}>
        
          {getCurrentView()}
        </Grid>
        </Hidden>
   <Paper style={{  marginBottom: "10px" }} fullWidth onClick={event =>  history.push('/onboarding/2')} >
   <Grid style={{ marginBottom: "10px" }} fullWidth >
   <Box p={3}>
   <Grid>
   <Divider orientation="vertical" />
   </Grid>

   <Typography style={{color:"#747474", verticalAlign: 'middle',
   display: 'inline-flex'}}><CastForEducationIcon style={{ marginRight: "8px" }}/>Education</Typography>
   </Box>
   </Grid>
</Paper>

<Paper style={{  marginBottom: "10px"}} fullWidth onClick={event =>  history.push('/onboarding/3')}>
   <Grid style={{ marginLeft: "4px" }} fullWidth>
   <Box p={3}>
   <Grid>
   <Divider orientation="vertical" />
   </Grid>
   <Typography style={{color: "#747474"}}><WorkIcon style={{ marginRight: "4px",color: "#747474"
   }} />Personal Details </Typography> 
   </Box>
   </Grid>

</Paper>
<Paper style={{  marginBottom: "10px" }} fullWidth  >
   <Grid style={{ marginBottom: "10px" }} fullWidth onClick={event =>  history.push('/onboarding/4')}>
   <Box p={3}>
   <Grid>
   <Divider orientation="vertical" />
   </Grid>

   <Typography style={{color:"#747474", verticalAlign: 'middle',
   display: 'inline-flex'}}><InfoIcon style={{ marginRight: "8px" }}/>Additional Information</Typography>
   </Box>
   </Grid>
</Paper>

</div>
) }
{stepId  === 2&& (

<div>      
<Paper style={{  marginBottom: "10px" }} fullWidth onClick={event =>  history.push('/onboarding/1')} >
 <Grid style={{ marginBottom: "10px" }} fullWidth >
 <Box p={3}>
 <Grid>
 <Divider orientation="vertical" />
 </Grid>

 <Typography style={{color:"#747474", verticalAlign: 'middle',
 display: 'inline-flex'}}><PersonIcon style={{ marginRight: "8px" }}/>Personal Details</Typography>
 </Box>
 </Grid>
</Paper>
<Paper style={{  marginBottom: "10px",background:"#13C1D6" }}  fullWidth className="mpa">
<Grid style={{ marginLeft: "4px" }} fullWidth >
<Box p={3}>
<Grid>
<Divider orientation="vertical" />
</Grid>
<Typography style={{color: "#ffff"}}><CastForEducationIcon style={{ marginRight: "8px",color:"#fff" }}/>Education</Typography>
</Box>
</Grid>
</Paper>
<Hidden only={['lg', 'md', 'sm', 'xl',]}>
        <Grid item sm={10} xs={10} style={{marginLeft:"-11px"}}>
        
          {getCurrentView()}
        </Grid>
        </Hidden>
<Paper style={{  marginBottom: "10px"}} fullWidth onClick={event =>  history.push('/onboarding/3')}>
<Grid style={{ marginLeft: "4px" }} fullWidth>
<Box p={3}>
<Grid>
<Divider orientation="vertical" />
</Grid>
<Typography style={{color: "#747474"}}><WorkIcon style={{ marginRight: "4px",color: "#747474"
}} />Work Experience </Typography> 
</Box>
</Grid>

</Paper>
<Paper style={{  marginBottom: "10px" }} fullWidth  >
<Grid style={{ marginBottom: "10px" }} fullWidth onClick={event =>  history.push('/onboarding/4')}>
<Box p={3}>
<Grid>
<Divider orientation="vertical" />
</Grid>

<Typography style={{color:"#747474", verticalAlign: 'middle',
display: 'inline-flex'}}><InfoIcon style={{ marginRight: "8px" }}/>Additional Information</Typography>
</Box>
</Grid>
</Paper>

</div>
) }
{stepId  === 3&& (
<div>      
<Paper style={{  marginBottom: "10px" }} fullWidth onClick={event =>  history.push('/onboarding/1')} >
<Grid style={{ marginBottom: "10px" }} fullWidth >
<Box p={3}>
<Grid>
<Divider orientation="vertical" />
</Grid>

<Typography style={{color:"#747474", verticalAlign: 'middle',
display: 'inline-flex'}}><PersonIcon style={{ marginRight: "8px" }}/>Personal Details</Typography>
</Box>
</Grid>
</Paper>
<Paper style={{  marginBottom: "10px"}}  fullWidth onClick={event =>  history.push('/onboarding/2')}>
<Grid style={{ marginLeft: "4px" }} fullWidth >
<Box p={3}>
<Grid>
<Divider orientation="vertical" />
</Grid>
<Typography style={{color: "#747474"}}><CastForEducationIcon style={{ marginRight: "8px" }}/>Education</Typography>
</Box>
</Grid>
</Paper>

<Paper style={{  marginBottom: "10px",background:"#13C1D6" }}  fullWidth className="mpa">
<Grid style={{ marginLeft: "4px" }} fullWidth>
<Box p={3}>
<Grid>
<Divider orientation="vertical" />
</Grid>
<Typography style={{color: "#fff"}}><WorkIcon style={{ marginRight: "4px",color: "#fff"
}} />Work Experience </Typography> 
</Box>
</Grid>

</Paper>
<Hidden only={['lg', 'md', 'sm', 'xl',]}>
        <Grid item sm={10} xs={10} style={{marginLeft:"-11px"}}>
        
          {getCurrentView()}
        </Grid>
        </Hidden>
<Paper style={{  marginBottom: "10px" }} fullWidth  >
<Grid style={{ marginBottom: "10px" }} fullWidth onClick={event =>  history.push('/onboarding/4')}>
<Box p={3}>
<Grid>
<Divider orientation="vertical" />
</Grid>

<Typography style={{color:"#747474", verticalAlign: 'middle',
display: 'inline-flex'}}><InfoIcon style={{ marginRight: "8px" }}/>Additional Information</Typography>
</Box>
</Grid>
</Paper>

</div>     ) }
{stepId  === 4&& (
<div>      
<Paper style={{  marginBottom: "10px" }} fullWidth onClick={event =>  history.push('/onboarding/1')} >
<Grid style={{ marginBottom: "10px" }} fullWidth >
<Box p={3}>
<Grid>
<Divider orientation="vertical" />
</Grid>

<Typography style={{color:"#747474", verticalAlign: 'middle',
display: 'inline-flex'}}><PersonIcon style={{ marginRight: "8px" }}/>Personal Details</Typography>
</Box>
</Grid>
</Paper>
<Paper style={{  marginBottom: "10px"}}  fullWidth onClick={event =>  history.push('/onboarding/2')}>
<Grid style={{ marginLeft: "4px" }} fullWidth >
<Box p={3}>
<Grid>
<Divider orientation="vertical" />
</Grid>
<Typography style={{color: "#747474"}}><CastForEducationIcon style={{ marginRight: "8px" }}/>Education</Typography>
</Box>
</Grid>
</Paper>

<Paper style={{  marginBottom: "10px"}}  fullWidth onClick={event =>  history.push('/onboarding/3')} >
<Grid style={{ marginLeft: "4px" }} fullWidth>
<Box p={3}>
<Grid>
<Divider orientation="vertical" />
</Grid>
<Typography style={{color: "#white"}}><WorkIcon style={{ marginRight: "4px",color: "#747474"
}} />Work Experience </Typography> 
</Box>
</Grid>

</Paper>
<Paper style={{  marginBottom: "10px",background:"#13C1D6"  }} fullWidth  className="mpa">
<Grid style={{ marginBottom: "10px" }} fullWidth>
<Box p={3}>
<Grid>
<Divider orientation="vertical" />
</Grid>

<Typography style={{color:"#fff", verticalAlign: 'middle',
display: 'inline-flex'}}><InfoIcon style={{ marginRight: "8px",color:"#fff" }}/>Additional Information</Typography>
</Box>
</Grid>
</Paper>
<Hidden only={['lg', 'md', 'sm', 'xl',]}>
        <Grid item sm={10} xs={10} style={{marginLeft:"-11px"}}>
        
          {getCurrentView()}
        </Grid>
        </Hidden>
</div>
) }
              </Grid>
           </React.Fragment>
             </Hidden>
        <Grid container style={{ backgroundColor: "#F7F7F7" }}>
        <Hidden only={['xs']}>
        <Grid item sm={2} xs={2} style={{position:"relative",left:"100px",top:"75px"}}>
          
      {stepId  === 1&& (

          <div>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>  Personal Details| Corp2Corp </title>
                
                           </Helmet>    
            <Paper style={{  marginBottom: "10px",background:"#13C1D6" }}  fullWidth className="mpa">
             <Grid style={{ marginLeft: "4px" }} fullWidth >
             <Box p={3}>
             <Grid>
             <Divider orientation="vertical" />
             </Grid>
             <Typography style={{color: "white"}}><PersonIcon style={{ marginRight: "8px" }}/>Work Experience</Typography>
             </Box>
             </Grid>
         </Paper>
             <Paper style={{  marginBottom: "10px" }} fullWidth onClick={event =>  history.push('/onboarding/2')} >
             <Grid style={{ marginBottom: "10px" }} fullWidth >
             <Box p={3}>
             <Grid>
             <Divider orientation="vertical" />
             </Grid>

             <Typography style={{color:"#747474", verticalAlign: 'middle',
             display: 'inline-flex'}}><CastForEducationIcon style={{ marginRight: "8px" }}/>Education</Typography>
             </Box>
             </Grid>
         </Paper>

 <Paper style={{  marginBottom: "10px"}} fullWidth onClick={event =>  history.push('/onboarding/3')}>
             <Grid style={{ marginLeft: "4px" }} fullWidth>
             <Box p={3}>
             <Grid>
             <Divider orientation="vertical" />
             </Grid>
             <Typography style={{color: "#747474"}}><WorkIcon style={{ marginRight: "4px",color: "#747474"
             }} />Personal Details </Typography> 
             </Box>
             </Grid>

 </Paper>
 <Paper style={{  marginBottom: "10px" }} fullWidth  >
             <Grid style={{ marginBottom: "10px" }} fullWidth onClick={event =>  history.push('/onboarding/4')}>
             <Box p={3}>
             <Grid>
             <Divider orientation="vertical" />
             </Grid>

             <Typography style={{color:"#747474", verticalAlign: 'middle',
             display: 'inline-flex'}}><InfoIcon style={{ marginRight: "8px" }}/>Additional Information</Typography>
             </Box>
             </Grid>
         </Paper>
     
         </div>
      ) }
      {stepId  === 2&& (

    <div>    
         <Helmet>
                <meta charSet="utf-8" />
                <title>  Education | Corp2Corp </title>
                
                           </Helmet>  
      <Paper style={{  marginBottom: "10px" }} fullWidth onClick={event =>  history.push('/onboarding/1')} >
           <Grid style={{ marginBottom: "10px" }} fullWidth >
           <Box p={3}>
           <Grid>
           <Divider orientation="vertical" />
           </Grid>

           <Typography style={{color:"#747474", verticalAlign: 'middle',
           display: 'inline-flex'}}><PersonIcon style={{ marginRight: "8px" }}/>Work Experience</Typography>
           </Box>
           </Grid>
       </Paper>
          <Paper style={{  marginBottom: "10px",background:"#13C1D6" }}  fullWidth className="mpa">
          <Grid style={{ marginLeft: "4px" }} fullWidth >
          <Box p={3}>
          <Grid>
          <Divider orientation="vertical" />
          </Grid>
          <Typography style={{color: "#ffff"}}><CastForEducationIcon style={{ marginRight: "8px",color:"#fff" }}/>Education</Typography>
          </Box>
          </Grid>
          </Paper>

          <Paper style={{  marginBottom: "10px"}} fullWidth onClick={event =>  history.push('/onboarding/3')}>
          <Grid style={{ marginLeft: "4px" }} fullWidth>
          <Box p={3}>
          <Grid>
          <Divider orientation="vertical" />
          </Grid>
          <Typography style={{color: "#747474"}}><WorkIcon style={{ marginRight: "4px",color: "#747474"
          }} />Personal Details </Typography> 
          </Box>
          </Grid>

          </Paper>
          <Paper style={{  marginBottom: "10px" }} fullWidth  >
          <Grid style={{ marginBottom: "10px" }} fullWidth onClick={event =>  history.push('/onboarding/4')}>
          <Box p={3}>
          <Grid>
          <Divider orientation="vertical" />
          </Grid>

          <Typography style={{color:"#747474", verticalAlign: 'middle',
          display: 'inline-flex'}}><InfoIcon style={{ marginRight: "8px" }}/>Additional Information</Typography>
          </Box>
          </Grid>
          </Paper>

          </div>
      ) }
     {stepId  === 3&& (
      <div>      
                 <Helmet>
                <meta charSet="utf-8" />
                <title>  Work Experience | Corp2Corp </title>
                
                           </Helmet>  
<Paper style={{  marginBottom: "10px" }} fullWidth onClick={event =>  history.push('/onboarding/1')} >
     <Grid style={{ marginBottom: "10px" }} fullWidth >
     <Box p={3}>
     <Grid>
     <Divider orientation="vertical" />
     </Grid>

     <Typography style={{color:"#747474", verticalAlign: 'middle',
     display: 'inline-flex'}}><PersonIcon style={{ marginRight: "8px" }}/>Work Experience</Typography>
     </Box>
     </Grid>
 </Paper>
    <Paper style={{  marginBottom: "10px"}}  fullWidth onClick={event =>  history.push('/onboarding/2')}>
    <Grid style={{ marginLeft: "4px" }} fullWidth >
    <Box p={3}>
    <Grid>
    <Divider orientation="vertical" />
    </Grid>
    <Typography style={{color: "#747474"}}><CastForEducationIcon style={{ marginRight: "8px" }}/>Education</Typography>
    </Box>
    </Grid>
    </Paper>

    <Paper style={{  marginBottom: "10px",background:"#13C1D6" }}  fullWidth className="mpa">
    <Grid style={{ marginLeft: "4px" }} fullWidth>
    <Box p={3}>
    <Grid>
    <Divider orientation="vertical" />
    </Grid>
    <Typography style={{color: "#fff"}}><WorkIcon style={{ marginRight: "4px",color: "#fff"
    }} />Personal Details </Typography> 
    </Box>
    </Grid>

    </Paper>
    <Paper style={{  marginBottom: "10px" }} fullWidth  >
    <Grid style={{ marginBottom: "10px" }} fullWidth onClick={event =>  history.push('/onboarding/4')}>
    <Box p={3}>
    <Grid>
    <Divider orientation="vertical" />
    </Grid>

    <Typography style={{color:"#747474", verticalAlign: 'middle',
    display: 'inline-flex'}}><InfoIcon style={{ marginRight: "8px" }}/>Additional Information</Typography>
    </Box>
    </Grid>
    </Paper>

    </div>     ) }
        {stepId  === 4&& (
          <div>      
            <Helmet>
                <meta charSet="utf-8" />
                <title>  Additional Information | Corp2Corp </title>
                
                           </Helmet>  
<Paper style={{  marginBottom: "10px" }} fullWidth onClick={event =>  history.push('/onboarding/1')} >
     <Grid style={{ marginBottom: "10px" }} fullWidth >
     <Box p={3}>
     <Grid>
     <Divider orientation="vertical" />
     </Grid>

     <Typography style={{color:"#747474", verticalAlign: 'middle',
     display: 'inline-flex'}}><PersonIcon style={{ marginRight: "8px" }}/>Work Experience</Typography>
     </Box>
     </Grid>
 </Paper>
    <Paper style={{  marginBottom: "10px"}}  fullWidth onClick={event =>  history.push('/onboarding/2')}>
    <Grid style={{ marginLeft: "4px" }} fullWidth >
    <Box p={3}>
    <Grid>
    <Divider orientation="vertical" />
    </Grid>
    <Typography style={{color: "#747474"}}><CastForEducationIcon style={{ marginRight: "8px" }}/>Education</Typography>
    </Box>
    </Grid>
    </Paper>

    <Paper style={{  marginBottom: "10px"}}  fullWidth onClick={event =>  history.push('/onboarding/3')} >
    <Grid style={{ marginLeft: "4px" }} fullWidth>
    <Box p={3}>
    <Grid>
    <Divider orientation="vertical" />
    </Grid>
    <Typography style={{color: "#white"}}><WorkIcon style={{ marginRight: "4px",color: "#747474"
    }} />Personal Details </Typography> 
    </Box>
    </Grid>

    </Paper>
    <Paper style={{  marginBottom: "10px",background:"#13C1D6"  }} fullWidth  className="mpa">
    <Grid style={{ marginBottom: "10px" }} fullWidth>
    <Box p={3}>
    <Grid>
    <Divider orientation="vertical" />
    </Grid>

    <Typography style={{color:"#fff", verticalAlign: 'middle',
    display: 'inline-flex'}}><InfoIcon style={{ marginRight: "8px",color:"#fff" }}/>Additional Information</Typography>
    </Box>
    </Grid>
    </Paper>

    </div>
      ) }
           </Grid>
        </Hidden>
        <Hidden only={['xs']}>
        <Grid item sm={8} xs={8}>
        
          {getCurrentView()}
        </Grid>
        </Hidden>
       
        </Grid>
        
      </MainContainer>
    </OnBoarding.Provider>
  );
};

export default OnBoardingPage;
