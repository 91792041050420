import { AUTHENTICATE, REGISTER, VERIFY_OTP, GENERATE_OTP, RESET_PASSWORD, SOCIAL_LOGIN,REGISTER_WITH_OTP,EMAIL_REGISTER,UPADTE_RESUME_REF,LOGIN_WITH_SOCIAL,LINKEDIN_TOKEN,LOGIN_STATITICS,EMPLOYER_DETAILS,EMPLOYER_PROFILE_EDIT,EMPLOYER_JOB_ACTIVATE,EMPLOYER_CANDIDATE_DETAILS,EMPLOYER_JOB_COUNT } from "constants/actionTypes";
import createApiReducer from "services/createAPIReducer";
const initialState = {
  authentication: {},
  updateUserArtist: {},
  register: {},
  verifyOtp: {},
  generateOtp: {},
  resetPassword: {},
  socialLogin: {},
  registerWithOtp:{"action":"request_verification_code"},
  uploadResume: {},
  loginWithGoogle: {},
  loginWithFacebook: {},
  loginWithLinkedIn: {},
  linkedInToken: {},
  loginStatitics:{},
  employerDetails:{},
  employerProfileEdit:{},
  employerJobActivate: {},
  candidateDetails: {},
  employerJobCount:{}
  
};

const userManagement = createApiReducer(initialState, [AUTHENTICATE, REGISTER, VERIFY_OTP, GENERATE_OTP, RESET_PASSWORD, SOCIAL_LOGIN,REGISTER_WITH_OTP,EMAIL_REGISTER,UPADTE_RESUME_REF,LOGIN_WITH_SOCIAL,LINKEDIN_TOKEN,LOGIN_STATITICS,EMPLOYER_DETAILS,EMPLOYER_PROFILE_EDIT,EMPLOYER_JOB_ACTIVATE,EMPLOYER_CANDIDATE_DETAILS,EMPLOYER_JOB_COUNT ]);

export default userManagement;
