import React from "react";
import { MDBListGroup, MDBListGroupItem, MDBContainer } from "mdbreact";
import { Typography, Grid, Paper, Divider, Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import lodash from "lodash";
const JobListItem = ({ item, isActive = false, onClick }) => {
  const theme = useTheme();
  return (
    <Paper style={{ background: `${isActive ? theme.palette.primary.main : "white"}`, marginBottom: "10px" }} fullWidth onClick={onClick}>
      <Grid style={{ background: "#fff", marginLeft: "4px" }} fullWidth>
        <Box p={3}>
          <Grid>
            <Divider orientation="vertical" />
          </Grid>
          <Typography style={{ color: `${isActive ? theme.palette.primary.main : "black"}` }}>{item.title}</Typography>
          <Typography variant="body2">{item.company_name}</Typography>
          
          {/* <small className="text-muted">3 days ago</small> */}
          <p className="mb-1">
            {lodash.get(item, "summary", "")
            /* .replace(/<[^>]*>?/gm, "")
              .substring(0, 60) + "..."} */
            }
          </p>
        </Box>
      </Grid>
    </Paper>
  );
};

export default JobListItem;
