import React from 'react';
import { MDBFooter, MDBBtn, MDBIcon } from 'mdbreact';

const Footer = () => {
    return (
        <MDBFooter color="blue" className="text-center font-small darken-2">
            <div className="pt-4">
                <MDBBtn outline color="white" tag="a" href="/job-posting" target="_blank">Post Job<MDBIcon icon="plus" className="ml-2"/></MDBBtn>
                   <hr className="my4"/>
            </div>
        
            <p className="footer-copyright mb-0 py-3 text-center">
                &copy; {new Date().getFullYear()} Copyright: <a href="https://www.corp2corp.jobs"> corp2corp.jobs </a>
            </p>
        </MDBFooter>
    );
}

export default Footer;