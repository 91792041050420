import React, { useState } from "react";
import MUIPlacesAutocomplete from "mui-places-autocomplete";
import { PlacesInput } from "./GooglePlaces.styled";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AlgoliaPlaces from "algolia-places-react";
const useStyles = makeStyles(theme => ({
  inputStyle: {
    outline: "none",
    width: "100%",
    padding: "10px"
  },
  InputStyle: {
    background: "white",
    width: "100%",
    padding: "0px",
    outline: "none"
  }
}));
const GooglePlaces = ({ onPlaceSelected }) => {
  var url_string = window.location.href; //window.location.href
  var url = new URL(url_string);
  var d = url.searchParams.get("location");
 
  const loc = d;
  const [address, setAddress] = useState(d);

  const handleChange = address => {
    setAddress(address);
    onPlaceSelected && onPlaceSelected(address);
  };
  
  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => console.log("Success", latLng))
      .catch(error => console.error("Error", error));
  };

  const classes = useStyles();
  return (
    <AlgoliaPlaces
      style={{ borderRadius: "10px" }}
      placeholder="Location"
      options={{
        appId: "plE79E3YU95N",
        apiKey: "ab6d2643563c402f95aaf5dca19afa95",
        language: "en",
        countries: ["us"],
        type: "city"
      }}
      value={address}
      onChange={({ query, rawAnswer, suggestion, suggestionIndex }) => {
        console.log("rawAnswer", rawAnswer);
        console.log("suggestion", suggestion);
        handleChange(suggestion);
      }}
    />
  );
};

export default GooglePlaces;
