import React, { useState, useEffect, useRef } from "react";
import JoditEditor from "jodit-react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import { Grid, Hidden, Paper, Container, Typography, TextField, FormControl, InputAdornment, Icon, Button, Divider } from "@material-ui/core";
//import { Typography,Hidden, Paper, Divider, Box } from "@material-ui/core";
import DoneTwoToneIcon from '@material-ui/icons/DoneTwoTone';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';

import FavoriteIcon from '@material-ui/icons/Favorite';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ArrowRightTwoToneIcon from '@material-ui/icons/ArrowRightTwoTone';
import { makeStyles } from "@material-ui/styles";
import assets from "../../../assets";
import { Image, Root, VerticalCenter, ImageGrid, LoginCard } from "../login/LoginPage.styled";
import MyAppBar from "../../common/myappbar/MyAppBar";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { TextInput } from "../../common/FormInputs";
import LockIcon from "@material-ui/icons/Lock";

import SvgIcon from "@material-ui/core/SvgIcon";
import { ImageIcon, StyledLink } from "components/common/Misc";
import { authenticateAction, registerAction, generateOtpAction, verifyOtpAction,socialLoginAction } from "services/endpoints/usermanagement/userManagementActions";
import Attach from "./attach";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import SocialLogin from "components/common/SocialLogin/SocialLogin";
import UserManager from "utils/UserManager";
import StorageManager from "utils/StorageManager";
import MainContainer from "../../common/MainContainer";
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import AlgoliaPlaces from 'algolia-places-react';
import Select from 'react-select';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader';
import './resume.css';
class Resume extends React.Component {
    state = {
        config: {
            readonly: false,
            toolbar: true,
            height: '500px',
            marginLeft:'14px'
        },
        resume: '',
        submit: "Save",
        emptyResume:false,
        setContent:'',
        spin: 1
    };
    handleOn = event => {
        console.log(event);
        this.setState({resume:event})

        let user = {
            "uid":"1616",
            "body":event
      
        }
        console.log(user);
        const headers = {
            'Content-Type': 'application/json'
          }
       /* axios.post(`https://live-corp2corp.pantheonsite.io/services/resume`,user, {  headers: headers })
        .then(res => {
          console.log(res);
          console.log(res.data);
        })  */
    }
    async componentWillMount() {  

        /*await axios.get(`https://corp2corpfile.blob.core.windows.net/corp2corp/Karthik%20Radhakrishnan%20Mechanical%20Project%20Engineer%20Resume.html`)
        .then(res => {
        // console.log(res.data);
         this.setState({resume:res.data})
        })*/
        let user = {
          "uid":"1616",
          "body":"hu"
    
      }
      console.log(user);
      const headers = {
          'Content-Type': 'application/json'
        }
        const uid = StorageManager.instance.getUid();
        await axios.get('https://live-corp2corp.pantheonsite.io/my-resume/'+uid, {  headers: headers })
        .then(res => {
         console.log("kiki",res.data[0].field_description);
         const v1 = res.data[0].field_description;
         const r1 = res.data[0].field_resume;
         
         if(r1 == ""){
         // toast.warn("Please upload resume!");
          //this.props.history.push("/onboarding/3");
          //window.location.href="/onboarding/3";
          this.setState({emptyResume:true});
          return;
         }else{
          axios.get('https://corp2corpfile.blob.core.windows.net/corp2corpfile/'+uid+'.html')
          .then(res => {
           console.log("reb",res.data);
         
            const unformated = res.data;
            //var result = formated.replaceAll('"','�');
            const formated = unformated.replace(/\�/g,'&nbsp')
           this.setState({resume: formated})
          })
          const formated = res.data[0].field_description.replace(/\�/g,'&nbsp')
          this.setState({resume: res.data[0].body});
          return;
         }
         if(v1 == ""){
            axios.get('https://corp2corpfile.blob.core.windows.net/corp2corpfile/'+uid+'.html')
            .then(res => {
             console.log("reb",res.data);
           
              const unformated = res.data;
              //var result = formated.replaceAll('"','�');
              const formated = unformated.replace(/\�/g,'&nbsp')
             this.setState({resume: formated})
            })
            .catch(error =>{ 
              //alert("dsdsd");

     
          let user = 
          {
            "tasks": {
              "import-my-file": {
                "operation": "import/url",
                "url": "https://live-corp2corp.pantheonsite.io/"+res.data[0].field_resume
              },
              "convert-my-file": {
                "operation": "convert",
                "filename":res.data[0].uid+".html",
                "input": "import-my-file",
                "output_format": "html"
              },
              "export-my-file": {
                      "operation": "export/azure/blob",
                      "input": "convert-my-file",
                       "storage_account": "corp2corpfile",
                     // "storage_access_key": "+MSfvjxoYF/gxaTR7WLPqS0iZiFe2kjP8HHFe4dQ1kQkS5lLz/tPGP9hkfL3pw/TPGT1ZZLdZ2nj463SZj6IAg==",
                     "storage_access_key": "WkmI+OO8NHUb5uVgZOu3M7elXJZoAxy0ijBL/votoabHnex5TMCo034LLPB4RlTe7dvjmvDu8Ois9vvM2Z3ELw==",
                      
                     "sas_token": "",
                      "container": "corp2corpfile",
                      "blob": ""
              }
            }
          }
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6Ijg1YWVkYzQyZjhjMDZiMzIzMjgyMTkxZWJiNjg1ZjhmYzM2NTVkZjFhZDU2YzliNjZiYjRjNjBkZmU0NTcwNmE1NjZkZTg3YzMwM2Y4N2I1In0.eyJhdWQiOiIxIiwianRpIjoiODVhZWRjNDJmOGMwNmIzMjMyODIxOTFlYmI2ODVmOGZjMzY1NWRmMWFkNTZjOWI2NmJiNGM2MGRmZTQ1NzA2YTU2NmRlODdjMzAzZjg3YjUiLCJpYXQiOjE1ODc3MjQyOTksIm5iZiI6MTU4NzcyNDI5OSwiZXhwIjo0NzQzMzk3ODk4LCJzdWIiOiI0MTg2NzA0MSIsInNjb3BlcyI6WyJ1c2VyLnJlYWQiLCJ1c2VyLndyaXRlIiwidGFzay5yZWFkIiwidGFzay53cml0ZSIsIndlYmhvb2sucmVhZCIsIndlYmhvb2sud3JpdGUiLCJwcmVzZXQucmVhZCIsInByZXNldC53cml0ZSJdfQ.INq4egGGUaPQOPvZ8dKYqxvCSuSMzbxvJk1uznpc_EtpCFTMprFoRqhKnPqjJwm3opaik5CpT0zam9Zc3fi07wmF5iQwCeG0OsDZyMmcwJcaVSsnS2N47hvj1aPjGifRk-CwueE1wEPTTaMXYpy55BgkKva6AlBGW5LKzmDlUiMXRgWLbNMSCG8UQcu379ts3VzDx2_tYPlNQn1mZbf-9wvAJYnBLXFzw6Va4Rj2mLr4qACReXn39WV3rJBXg4Zb-T3FuFyMSW9Vi-BrghxsArlxSTs6jf_v8vRCoTY_WhKtgQD-M99NZc8SOONg-Ctkzllkl8jWqHvv77_GPlkMtPNae_FUAiOJjrIQ49xeXdc1kHifw6_kZCne5BkmJsFvigXzMR2WVMNcE4Ec4vZLxsxHFOI75snei8TO_-KDio8iDnB1Imco5cKwS_aUeJwNxJEg85r-zzPm5PzLTKTUGDA96vYsr3dWHuQkAGnq7cyYZ-mN8HAw9dK1BAg4oe55d4vQikESaFLTflH1ExP7FMApC7WTYrgcIn6P-lIUobohmScQzbuYWdkjKSmz052GYF2wx3VxYHPcjOyNUUEufk1lUlsOpHv5Z52YujOLwJ6QWMA8cuQM0PY8wgXMw8Qyiabq8zmF-wpW8R5ZxiI5hR4nS3zML6301eVlNRt3jC8'
        }
        
        axios.post(`https://api.cloudconvert.com/v2/jobs`,user, {  headers: headers })
        .then(res => {
          console.log(res);
          console.log(res.data);
          window.location.replace("/processing");
        })
         
            })
         }else if(v1){
         
            let unformated = res.data[0].field_description;
            const formated = unformated.replace(/\�/g,'&nbsp')
            this.setState({resume:formated})
         }else{
     
          let user = 
          {
            "tasks": {
              "import-my-file": {
                "operation": "import/url",
                "url": "https://live-corp2corp.pantheonsite.io/"+res.data[0].field_resume
              },
              "convert-my-file": {
                "operation": "convert",
                "filename":res.data[0].uid+".html",
                "input": "import-my-file",
                "output_format": "html"
              },
              "export-my-file": {
                      "operation": "export/azure/blob",
                      "input": "convert-my-file",
                       "storage_account": "corp2corpfile",
                     // "storage_access_key": "+MSfvjxoYF/gxaTR7WLPqS0iZiFe2kjP8HHFe4dQ1kQkS5lLz/tPGP9hkfL3pw/TPGT1ZZLdZ2nj463SZj6IAg==",
                     "storage_access_key": "WkmI+OO8NHUb5uVgZOu3M7elXJZoAxy0ijBL/votoabHnex5TMCo034LLPB4RlTe7dvjmvDu8Ois9vvM2Z3ELw==",
                      
                     "sas_token": "",
                      "container": "corp2corpfile",
                      "blob": ""
              }
            }
          }
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6Ijg1YWVkYzQyZjhjMDZiMzIzMjgyMTkxZWJiNjg1ZjhmYzM2NTVkZjFhZDU2YzliNjZiYjRjNjBkZmU0NTcwNmE1NjZkZTg3YzMwM2Y4N2I1In0.eyJhdWQiOiIxIiwianRpIjoiODVhZWRjNDJmOGMwNmIzMjMyODIxOTFlYmI2ODVmOGZjMzY1NWRmMWFkNTZjOWI2NmJiNGM2MGRmZTQ1NzA2YTU2NmRlODdjMzAzZjg3YjUiLCJpYXQiOjE1ODc3MjQyOTksIm5iZiI6MTU4NzcyNDI5OSwiZXhwIjo0NzQzMzk3ODk4LCJzdWIiOiI0MTg2NzA0MSIsInNjb3BlcyI6WyJ1c2VyLnJlYWQiLCJ1c2VyLndyaXRlIiwidGFzay5yZWFkIiwidGFzay53cml0ZSIsIndlYmhvb2sucmVhZCIsIndlYmhvb2sud3JpdGUiLCJwcmVzZXQucmVhZCIsInByZXNldC53cml0ZSJdfQ.INq4egGGUaPQOPvZ8dKYqxvCSuSMzbxvJk1uznpc_EtpCFTMprFoRqhKnPqjJwm3opaik5CpT0zam9Zc3fi07wmF5iQwCeG0OsDZyMmcwJcaVSsnS2N47hvj1aPjGifRk-CwueE1wEPTTaMXYpy55BgkKva6AlBGW5LKzmDlUiMXRgWLbNMSCG8UQcu379ts3VzDx2_tYPlNQn1mZbf-9wvAJYnBLXFzw6Va4Rj2mLr4qACReXn39WV3rJBXg4Zb-T3FuFyMSW9Vi-BrghxsArlxSTs6jf_v8vRCoTY_WhKtgQD-M99NZc8SOONg-Ctkzllkl8jWqHvv77_GPlkMtPNae_FUAiOJjrIQ49xeXdc1kHifw6_kZCne5BkmJsFvigXzMR2WVMNcE4Ec4vZLxsxHFOI75snei8TO_-KDio8iDnB1Imco5cKwS_aUeJwNxJEg85r-zzPm5PzLTKTUGDA96vYsr3dWHuQkAGnq7cyYZ-mN8HAw9dK1BAg4oe55d4vQikESaFLTflH1ExP7FMApC7WTYrgcIn6P-lIUobohmScQzbuYWdkjKSmz052GYF2wx3VxYHPcjOyNUUEufk1lUlsOpHv5Z52YujOLwJ6QWMA8cuQM0PY8wgXMw8Qyiabq8zmF-wpW8R5ZxiI5hR4nS3zML6301eVlNRt3jC8'
        }
        
        axios.post(`https://api.cloudconvert.com/v2/jobs`,user, {  headers: headers })
        .then(res => {
          console.log(res);
          console.log(res.data);
          window.location.replace("/processing");
        })
         }
        /* if(res.data.data[0].field_description == ""){
            axios.get(`https://corp2corpfile.blob.core.windows.net/corp2corp/Karthik%20Radhakrishnan%20Mechanical%20Project%20Engineer%20Resume.html`)
            .then(res => {
            // console.log(res.data);
             this.setState({resume:res.data})
            })
         
         }else{
            this.setState({resume:res.data[0].field_description})
         }*/
        })
    
      /*axios.post(`https://api.cloudconvert.com/v2/jobs`,user, {  headers: headers })
      .then(res => {
        console.log(res);
        console.log(res.data);
      })  */
       
    }  
    editResume = e =>{
      event.preventDefault();
      this.setState({emptyResume:true});
    }
    confirmChanges = event =>{
        event.preventDefault();
        this.setState({submit:"Saving.."});
        console.log("new  one",this.state.resume);
        const uid = StorageManager.instance.getUid();
        let user = {
            "uid":uid,
            "body":this.state.resume
      
        }
        const headers = {
            'Content-Type': 'application/json'
          }
        axios.post(`https://live-corp2corp.pantheonsite.io/services/resume`,user, {  headers: headers })
      .then(res => {
        this.setState({submit:"Save"});
        console.log(res);
        console.log(res.data);
        toast.success("Successfully Submitted !", {
            position: toast.POSITION.TOP_RIGHT
          });
      })
    }
  render() {
   
    return (
      <MainContainer>
                <Grid container spacing={1}  justify="center"
  alignItems="center">

<React.Fragment>
   <Grid item sm={2} xs={12} lg={2}>
           
            <Paper style={{  marginBottom: "10px" }}  fullWidth >
                <Grid style={{ marginLeft: "4px" }} fullWidth onClick={event =>  this.props.history.push('/saved-jobs/')}>
                <Box p={3}>
                <Grid>
                <Divider orientation="vertical" />
                </Grid>
                <Typography style={{color: "#747474"}}><FavoriteIcon style={{ marginRight: "4px",color: "#747474" }}/>Saved Job</Typography>
                </Box>
                </Grid>
            </Paper>
                <Paper style={{  marginBottom: "10px" }} fullWidth  >
                <Grid style={{ marginBottom: "10px" }} fullWidth onClick={event =>  this.props.history.push('/applied-jobs/')}>
                <Box p={3}>
                <Grid>
                <Divider orientation="vertical" />
                </Grid>

                <Typography style={{color:"#747474", verticalAlign: 'middle',
                display: 'inline-flex'}}><DoneTwoToneIcon style={{ marginRight: "8px" }}/>Applied Job</Typography>
                </Box>
                </Grid>
            </Paper>

    <Paper style={{  marginBottom: "10px",background:"#13C1D6" }} fullWidth className="mpa">
                <Grid style={{ marginLeft: "4px" }} fullWidth>
                <Box p={3}>
                <Grid>
                <Divider orientation="vertical" />
                </Grid>
                <Typography style={{color: "white"}}><InsertDriveFileIcon style={{ marginRight: "4px",color: "white"
                }} />My Resume</Typography> 
                </Box>
                </Grid>

    </Paper>


         <Paper style={{  marginBottom: "10px" }} fullWidth  >
                <Grid style={{ marginBottom: "10px" }} fullWidth onClick={event =>  this.props.history.push('/onboarding/1')}>
                <Box p={3}>
                <Grid>
                <Divider orientation="vertical" />
                </Grid>

                <Typography style={{color:"#747474", verticalAlign: 'middle',
                display: 'inline-flex'}}><SettingsTwoToneIcon style={{ marginRight: "8px" }}/>My Settings</Typography>
                </Box>
                </Grid>
            </Paper>
            <Paper style={{  marginBottom: "10px" }} fullWidth  >
                <Grid style={{ marginBottom: "10px" }} fullWidth onClick={event =>  this.props.history.push('/jobs/')}>
                <Box p={3}>
                <Grid>
                <Divider orientation="vertical" />
                </Grid>

                <Typography style={{color:"#747474", verticalAlign: 'middle',
                display: 'inline-flex'}}><SearchTwoToneIcon style={{ marginRight: "8px" }}/>Search Jobs</Typography>
                </Box>
                </Grid>
               
            </Paper>
  </Grid>
  {!this.state.emptyResume && (
  <Grid item sm={12} xs={12} lg={8}>
  
  <JoditEditor
              value={this.state.resume}
              config={this.state.config}
              //onBlur={newContent => this.state.setContent(newContent)} 
              onChange={this.handleOn}
              //tabIndex={5} 
              //onChange={newContent => {}}
          />
            <Box align="center">
        
            <Button
              type="submit"
              fullWidth
              //disabled={loading}
              variant="contained"
              color="primary"
              alignItems="center"
              //style={{ fontFamily: "'Poppins', sans-serif", marginTop: "1em", color: "white" }}
              style={{ marginTop: "1em", marginBottom: "1em", width: "10%",color: "white" }}
              onClick={this.confirmChanges}
            >
              {this.state.submit}
            </Button>

          
            </Box>

<Box align="center">
<Button
  type="submit"
  fullWidth
  //disabled={loading}
  variant="contained"
  color="primary"
  alignItems="center"
  //style={{ fontFamily: "'Poppins', sans-serif", marginTop: "1em", color: "white" }}
  style={{ marginTop: "1em", marginBottom: "1em", width: "20%",color: "white" }}
  onClick={this.editResume}

>

Upload new resume
            </Button>
          
  </Box>

         

  </Grid> 
    )}
     {this.state.emptyResume && (
        <Attach />
     )}
  <Grid item sm={12} xs={12} lg={8}>
 

  </Grid>
</React.Fragment>
</Grid>

      </MainContainer>
    );
  }
}

export default Resume;